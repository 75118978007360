import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, DoCheck, ElementRef, forwardRef, EventEmitter, Input, Output, ViewChild, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from "rxjs/operators";
import { NgSelectComponent } from '@ng-select/ng-select';
import { IRejectionComment } from 'src/app/models/inderwriterNoteModel';
import { WorkFlowNoteService } from './work-flow-note.service';
import { builBlurHandler, buildFocusHandler, buildMoussHandler, buildngSelectFilter, customRejectionRegEx, formatCustomRejection, handleBackspace } from './workflowNoteUtils';
@Component({
  selector: 'app-a1-note',
  templateUrl: './a1-workflow-note.component.html',
  styleUrls: ['./a1-workflow-note-ng-select-basic.scss', './a1-workflow-note.component.scss'],
  animations: [
    trigger('placeHolder2', [
      transition(':leave', [
        animate('50ms', style({ position: 'relative', height: 0})),
      ]),
    ])]
})

export class WorkFlowNoteComponent implements DoCheck, AfterViewInit {
  mode_note= true;
  resetOnTrigger = true;
  _noteConfig = {
    required: false,
    type: 'comment',
    hasError: false,
    model: null,
    placeHolder:'',
    disabled: false
  };
  noteFormGroup: FormGroup;
  showPlaceHolder= true;
  isReady = false;
  originalValue='';
  updatedValue= null;
  rejectionReasonModel = null;
  notFoundText ='';
  readOnly = false;
  loading = false;
  moreCount = 0;
  mutationObserver: MutationObserver;
  rejectionReasonHasError = false;
  targteInput: HTMLInputElement;
  targetInputOriginalSize;
  _rejectionReasons: IRejectionComment;
  customRejectionReasonsInput: HTMLElement;

  rejectionReasonsOptions = [];

  hideCustomRejectionContainer = false;

  @ViewChild(forwardRef(()=> NgSelectComponent), {static: false}) ngSelect: NgSelectComponent;

  @Output() noteChanges: EventEmitter<any> = new EventEmitter();
  @Output() rejectionReasonsChange: EventEmitter<IRejectionComment> = new EventEmitter();

  @ViewChild('noteInput', { static: false }) noteInput: ElementRef;
  constructor(
    private elementRef: ElementRef,
    fb: FormBuilder,
    private workFlowNoteService: WorkFlowNoteService,
    private renderer: Renderer2
  ) {

    this.noteFormGroup = fb.group({
      'model': ''
    });
    this.noteFormGroup.controls['model'].valueChanges
    .pipe(debounceTime(500))
    .subscribe(val => {this.onNoteChanges(val);});

    if (!this.readOnly) {
      this.mutationObserver = new MutationObserver(() => {
          if(this.ngSelect && this.ngSelect.isOpen) {

            this.buidCustomInputElement();
            this.moreCount = 0;
          } else if (!this.mode_note) {
            this.calculateHasMoreChildren();

          }

      });

      this.mutationObserver.observe(this.elementRef.nativeElement, {
        childList: true,
        subtree: true
      });
    }

    this.rejectionReasonsOptions = this.workFlowNoteService.initRejectionCommentngSelectOption();

  }

  @Input()
  get note () {
    return this._noteConfig;
  }
  set note (val) {
    this._noteConfig= val;
    this.noteFormGroup.get('model').setValue(this._noteConfig?.model?.newValue);
    this.isReady = true;
    this.originalValue= this._noteConfig?.model?.newValue;
    if (this._noteConfig.model && this._noteConfig?.model?.newValue) {
      this.showPlaceHolder= false;
    } else {
      this.showPlaceHolder= true;
    }
  }


  @Input()
  get rejectionReasons() {
    return this._rejectionReasons;
  }

  set rejectionReasons (rejectionConf: IRejectionComment) {
    if (!rejectionConf) {
      this._rejectionReasons = this.workFlowNoteService.resetRejectionCommentConfig();
    } else {
      this._rejectionReasons = rejectionConf;
      this.mode_note = !rejectionConf.reasonMode;
      this.workFlowNoteService.initRejectionCommentConfig(rejectionConf);
      this.rejectionReasonsOptions = this.workFlowNoteService.rejectionCommentngSelectOption;
      this.rejectionReasonModel = this.workFlowNoteService.selectedRejectionReasons;
    }
  }


  @Input() disabled;

  placeHolderClick() {
    if(this._noteConfig && this._noteConfig.disabled) {
      return;
    }
    this.showPlaceHolder= false;
    this._noteConfig.hasError= false;
    this._rejectionReasons.hasError = false;

    this.noteInput.nativeElement.focus();
  }
  noteInputBlur() {
    this._noteConfig.hasError= false;
    if (this.updatedValue) {
      this.showPlaceHolder= false;
      return;
    }
    this.showPlaceHolder= true;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngDoCheck(): void {}


  onNoteChanges(val) {
    if (!this.disabled) {
      const filteredValue = (val || '').trim().length? val.trim() : null;
      if (this.updatedValue != filteredValue) {
        this.updatedValue = filteredValue;
        this.noteChanges.emit(this.updatedValue);
      }
    }
  }

  triggerMode() {
    this.mode_note =!this.mode_note;
    this._noteConfig.hasError= false;
    if (this.resetOnTrigger) {
      this.rejectionReasonModel = null;
    }
    const rejectionState = this.workFlowNoteService.refreshRejectionReasonModel(this.rejectionReasonModel);
    rejectionState.hasError = false;


    if(!this.mode_note) {
       if (this.ngSelect) {
        if (this.ngSelect.isOpen) {
          this.ngSelect.close();
         } else {
           this.ngSelect.open();
        }
       }
       if (this.customRejectionReasonsInput) {
        this.customRejectionReasonsInput.innerText = null;
       }
      rejectionState.reasonMode = true;
      this.rejectionReasonsChange.emit(rejectionState);
      return;
    }
    rejectionState.reasonMode = false;
    this.rejectionReasonsChange.emit(rejectionState);
  }

  /**
   *
   * @param $event
   */
  onRejectionReasonsUpdated ($event) {
    this.calculateHasMoreChildren();
    const newrejectionState = this.workFlowNoteService.refreshRejectionReasonModel(this.rejectionReasonModel);
    if (this.customRejectionReasonsInput) {
      this.customRejectionReasonsInput.textContent = ``;
    }

    this.rejectionReasonsChange.emit(newrejectionState);

  }

  /**
   *
   * @param $event
   */
  onRejectionReasonRemoved ($event) {}


  /**
   *
   * @param $event
   */
  searchRejectionReasonTerm($event) {
    if ($event && ($event?.term || '').length> 2 && $event?.items.length) {
      const found = $event?.items.filter(tm => tm.id.toLowerCase() == $event?.term.toLowerCase()).length;
      if (found) {
        this.hideCustomRejectionContainer = true;
        return;
      }

    }
    if (this.ngSelect) {
      const filtredTerm = (this.ngSelect.searchTerm || '').replace(/[\\]/gi, "");
      const compareWith = formatCustomRejection(filtredTerm, customRejectionRegEx, true, true);
      let foundModel;
      (this.rejectionReasonModel || []).forEach((itemModel) => {
        const itemToSearch = itemModel?.label ? (itemModel?.label || '') : itemModel || '';

        const transformedItemModel = formatCustomRejection(itemToSearch, customRejectionRegEx, true, true);

        if (transformedItemModel == compareWith) {
          foundModel = itemModel;
        }
      });

      if (foundModel) {
        this.hideCustomRejectionContainer = true;
        this.ngSelect.searchTerm = foundModel?.label ? foundModel?.label : foundModel;
        return;
      }

      this.ngSelect.searchTerm = formatCustomRejection(filtredTerm, customRejectionRegEx, false, false).replace(/[\\]/gi, "");

      let foundItem;
      const itemsListId = (this.ngSelect.itemsList.items || this.ngSelect.items || []).map(x => (x.label || '').toLowerCase());
      itemsListId.forEach(itemId => {
        const transformedItemLabel = formatCustomRejection(itemId, customRejectionRegEx, true, true);
        if (transformedItemLabel == compareWith) {
          foundItem = itemId;
        }
      });

      if (foundItem) {
        this.hideCustomRejectionContainer = true;
        this.ngSelect.searchTerm = formatCustomRejection(foundItem, customRejectionRegEx, false, false);
        return;
      }

      if (this.customRejectionReasonsInput && this.customRejectionReasonsInput.innerText != this.ngSelect.searchTerm) {
        this.customRejectionReasonsInput.innerText = this.ngSelect.searchTerm;
        this.onCustomFocus();
      }

      this.hideCustomRejectionContainer = false;
    }
  }

  /**
   *
   * @param a
   * @param b
   * @returns
   */
  compareSelectedValues(a, b) {
    return (a.id ?? a) == (b.id ?? b) || (a.label ?? a) == (b.label ?? b);
  }


/**
 *
 * @param name
 * @returns
 */
  addCustomRejectionReason(customReason) {
    const newReason = (customReason || '').trim();

    if (!newReason || newReason.length < 3) {
      return null;
    }
    const custumNewReason = 'Other - '+ newReason;
    if (this.ngSelect) {
      this.ngSelect.items = this.ngSelect?.items.filter(item => {
        return item.label != custumNewReason;
      });
    }
    return { id: newReason, label: custumNewReason, name: custumNewReason, tag: true, custom: true };
  }

  /**
   *
   * @returns
   */
  calculateHasMoreChildren() {
    if (this.readOnly || !this.elementRef.nativeElement) {
      return;
    }

    let totalWidth = 0;
    this.moreCount = 0;
    const TAGS_MARGIN = 5;
    const HEADER_COMMENT_SIZE = 126;

    const containerWidth = this.elementRef.nativeElement.querySelector('.ng-value-container').clientWidth;
    const children = this.elementRef.nativeElement.querySelectorAll('.ng-value:not(.a1-tags-more)');

    for (let i = 0; i < children.length; i++) {
      totalWidth += children[i].getBoundingClientRect().width + TAGS_MARGIN;
      if ((HEADER_COMMENT_SIZE + totalWidth) > containerWidth) {
        this.moreCount++;
      }
    }
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    this.calculateHasMoreChildren();
  }

  hideCount() {
    this.moreCount = 0;
    // if (this.ngSelect && this.customRejectionReasonsInput && (this.customRejectionReasonsInput.innerText || '').length) {
    //   this.ngSelect.searchTerm = this.customRejectionReasonsInput.innerText;
    //   this.onCustomFocus();
    //   this.ngSelect.filter(this.ngSelect.searchTerm);
    // }
  }



buidCustomInputElement(force = false) {

  if (this.elementRef.nativeElement.clientWidth &&  this.ngSelect.dropdownPanel) {
  this.renderer.setStyle(this.ngSelect.dropdownPanel.contentElementRef.nativeElement,'width',`${this.elementRef.nativeElement.clientWidth}`);
  }

  if(!this.customRejectionReasonsInput && this.ngSelect) {

    this.customRejectionReasonsInput = this.renderer.createElement('DIV') as HTMLElement;
    this.customRejectionReasonsInput.innerText = '';
    this.customRejectionReasonsInput.setAttribute('tabIndex', '1');
    this.customRejectionReasonsInput.setAttribute('id', 'a1-custom-id-ng-select');
    this.customRejectionReasonsInput.classList.add('a1-custom-reason-input');


    this.ngSelect.onInputFocus = buildFocusHandler(this.ngSelect, this.customRejectionReasonsInput);
    this.ngSelect.onInputBlur = builBlurHandler(this.ngSelect, this.customRejectionReasonsInput);
    this.ngSelect.filter = buildngSelectFilter(this.ngSelect, this.customRejectionReasonsInput);
    this.ngSelect.handleMousedown = buildMoussHandler(this.ngSelect, this.customRejectionReasonsInput);



    const parentContainer = this.ngSelect.searchInput.nativeElement.parentNode;
    this.customRejectionReasonsInput.contentEditable = "true";

    this.customRejectionReasonsInput.onblur = this.ngSelect.onInputBlur;
    this.customRejectionReasonsInput.addEventListener('onblur', (event) => {
      this.customRejectionReasonsInput.blur();
    });

    this.customRejectionReasonsInput.addEventListener('input', (event) => {
      this.ngSelect.filter(this.customRejectionReasonsInput.textContent);
    });

    this.renderer.appendChild(parentContainer, this.customRejectionReasonsInput);
    this.renderer.removeChild(parentContainer, this.ngSelect.searchInput.nativeElement);

    this.ngSelect.focus = () => {
      this.onCustomFocus();
      this.customRejectionReasonsInput.onfocus = ($event: Event) => {
        this.ngSelect.onInputFocus($event);
      };
    };

    this.ngSelect.handleKeyDown = (keyDown: KeyboardEvent) => {
      if (!keyDown) {
        return;
      }

      if (keyDown.key == 'Backspace') {
        handleBackspace(this.ngSelect, this.customRejectionReasonsInput);
      } else {
        this.ngSelect.handleKeyCode(keyDown);
      }
    };
  }
}




dropDownClosed($event) {}

resetErrors($event?) {
  this._noteConfig.hasError= false;
  const rejectionState = this.workFlowNoteService.refreshRejectionReasonModel(this.rejectionReasonModel);
  rejectionState.hasError = false;
  this._rejectionReasons = rejectionState;
  this.rejectionReasonsChange.emit(rejectionState);
}

resetContainers($event) {
  if (this.mode_note && this._noteConfig?.hasError) {
    this.placeHolderClick();
  } else if (this._rejectionReasons?.hasError) {
    this.resetErrors();
  }
}

ngSelectSearchFunction(a, b) {
  const toCompareWith = formatCustomRejection(a, customRejectionRegEx, true, true);
  if(!toCompareWith || toCompareWith.length < 3) {
    return true;
  }

  const formattedRef = formatCustomRejection((b.id ?? b), customRejectionRegEx, true, true);
  const match = (formattedRef.match(new RegExp('(' + toCompareWith + ')', 'gi')) || []).join('');
  return match.length >= 3;
}

removedItem($event) {
  if(this.ngSelect && this.customRejectionReasonsInput) {
    this.customRejectionReasonsInput.innerText = this.ngSelect.searchTerm;
    this.ngSelect.filter(this.ngSelect.searchTerm);
    this.onCustomFocus();
  }
}


onCustomFocus() {
  const selection = window.getSelection();
  const range = document.createRange();
  selection.removeAllRanges();
  range.selectNodeContents(this.customRejectionReasonsInput);
  range.collapse(false);
  selection.addRange(range);
  this.customRejectionReasonsInput.focus();
}

onNgSelectBlur($event) {}

}
