import { ListingProperty } from "../../data/listingPropety";
import { PropertyStatusType } from "../../data/propertyStatusType";
import { CONCESSION_ACTION, EVBUS_EVENTS_NAMES, UNDERWRITER_ACTIONS } from "../model/updateRequest";
import { IRequest } from "../model/requestStep";
import { VERSION_NAME } from "../model/versioning";
import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";
import { IStatusUpdate } from "../model/IRequest";
import { UNDERWRITER_COMMANDS_NAME, UNDERWRITER_OPTIONS_LABEL } from "../model/underwriterCommandsAndActions";


export function checkForNewStatusAndOfferReadyVersioning(underwriterPanel: UnderwriterPanelComponent, $event: any) {
    let versionFound;
    if ($event === UNDERWRITER_ACTIONS.HIDE) {
        // Yes, send Hidden as status 0, backend lambda will set it the flag to true and set the status to Underwritten
        underwriterPanel.currentUwProforma.listingStatus = PropertyStatusType.Hidden;
    } else if ($event === UNDERWRITER_ACTIONS.CONTINUE) {
        underwriterPanel.currentUwProforma.listingStatus = PropertyStatusType.OfferReady;
        versionFound = (
            ![
                PropertyStatusType.OfferReady,
                PropertyStatusType.OfferReadyUrgent,
                PropertyStatusType.OfferReadyReason,
                PropertyStatusType.OfferReadyUrgentTM,
                PropertyStatusType.OfferReadyUrgentTMReason,
            ].includes(underwriterPanel.currentProperty.underwritingStatus)
        )
            && underwriterPanel.currentProperty.status < PropertyStatusType.OfferReady
            ? true : false;

    } else if ($event === UNDERWRITER_ACTIONS.READY) {

        underwriterPanel.currentUwProforma.listingStatus = underwriterPanel.isPropertyCameFromTM
            ? PropertyStatusType.OfferReadyUrgent
            : PropertyStatusType.OfferReady;

        versionFound = (
            ![
                PropertyStatusType.OfferReady,
                PropertyStatusType.OfferReadyUrgent,
                PropertyStatusType.OfferReadyReason,
                PropertyStatusType.OfferReadyUrgentTM,
                PropertyStatusType.OfferReadyUrgentTMReason,
            ].includes(underwriterPanel.currentProperty.underwritingStatus)
        )
            && underwriterPanel.currentProperty.status < PropertyStatusType.OfferReady
            ? true : false;

    } else if (underwriterPanel.isPropertyCameFromTM || underwriterPanel.currentProperty?.underwritingStatus <= PropertyStatusType.Underwritten) {
        underwriterPanel.currentUwProforma.listingStatus = PropertyStatusType.Underwritten;
    }
    return underwriterPanel.enable_versioning && versionFound ? VERSION_NAME.OFFER_READY : false;
}


function hiddenStatusUpdateValidation(underwriterPanel: UnderwriterPanelComponent, $event: any): boolean {
    if ($event == UNDERWRITER_ACTIONS.HIDE) {
        return true;
    }
    return false;
}

function offerReadyStatusUpdateValidation(underwriterPanel: UnderwriterPanelComponent, $event: any): boolean {
    if ($event === UNDERWRITER_ACTIONS.CONTINUE || ($event === UNDERWRITER_ACTIONS.READY && !underwriterPanel.isPropertyCameFromTM)) {
        return true;
    }
    return false;
}

function offerReadyUrgentStatusUpdateValidation(underwriterPanel: UnderwriterPanelComponent, $event: any): boolean {
    if ($event === UNDERWRITER_ACTIONS.READY && underwriterPanel.isPropertyCameFromTM) {
        return true;
    }
    return false;
}

function underwrittenStatusUpdateValidation(underwriterPanel: UnderwriterPanelComponent, $event: any): boolean {
    if (![UNDERWRITER_ACTIONS.READY, UNDERWRITER_ACTIONS.CONTINUE].includes($event)
        && (underwriterPanel.isPropertyCameFromTM || underwriterPanel.currentProperty?.underwritingStatus <= PropertyStatusType.Underwritten)
        || (UNDERWRITER_ACTIONS.HIDE == $event && underwriterPanel.currentProperty.proformaId)
    ) {
        return true;
    }
    return false;
}

function updatedStatusUpdateValidation(underwriterPanel: UnderwriterPanelComponent, $event: any): boolean {
    if ($event == UNDERWRITER_ACTIONS.SHOW && underwriterPanel.currentProperty.status == PropertyStatusType.Updated) {
        return true;
    }
    return false;
}


function newStatusUpdateValidation(underwriterPanel: UnderwriterPanelComponent, $event: any): boolean {
    if ($event == UNDERWRITER_ACTIONS.SHOW && underwriterPanel.currentProperty.status !== PropertyStatusType.Updated && !underwriterPanel.currentProperty.proformaId) {
        return true;
    }
    return false;
}
const OfferReadyStatusUpdate: IStatusUpdate[] = [
    {
        status: PropertyStatusType.Hidden,
        rules: [hiddenStatusUpdateValidation]
    },
    {
        status: PropertyStatusType.New,
        rules: [newStatusUpdateValidation]
    },
    {
        status: PropertyStatusType.Updated,
        rules: [updatedStatusUpdateValidation]
    },
    {
        status: PropertyStatusType.OfferReady,
        rules: [offerReadyStatusUpdateValidation]
    },
    {
        status: PropertyStatusType.OfferReadyUrgent,
        rules: [offerReadyUrgentStatusUpdateValidation]
    },
    {
        status: PropertyStatusType.Underwritten,
        rules: [underwrittenStatusUpdateValidation]
    },

];

function offerReadyFlowStatusValidation(property: ListingProperty) {
    return ![
        PropertyStatusType.InspectionReview,
        PropertyStatusType.InvestorApprovalReady,
        PropertyStatusType.InvestorApprovalUpdated,
        PropertyStatusType.InvestorApprovalUrgent,
        PropertyStatusType.PreInspectionReview,
        PropertyStatusType.PreInvestorApprovalUpdated,
        PropertyStatusType.PreInvestorApprovalReady,
        PropertyStatusType.Counter,
        PropertyStatusType.CounterReason,
        PropertyStatusType.CounterSent,
        PropertyStatusType.CounterToUW,
        PropertyStatusType.Countered,
        PropertyStatusType.TraderCountered,
        PropertyStatusType.OfferReady,
        PropertyStatusType.OfferReadyReason,
        PropertyStatusType.OfferReadyUrgent,
        PropertyStatusType.OfferReadyUrgentTM,
        PropertyStatusType.OfferReadyUrgentTMReason,
        PropertyStatusType.ClearToCloseReady,
        PropertyStatusType.ClearToCloseUrgent,
        PropertyStatusType.Canceled,
        PropertyStatusType.LostDead,
        PropertyStatusType.OfferDelivered
    ].includes(property.underwritingStatus);
}

function eventBusValidatorOfferReadyFlow(underwriter: UnderwriterPanelComponent, ...uwArguments) {
    if (
        (!underwriter.currentUwProforma.sendToTransactionManager)
              && underwriter.currentUwProforma.listingStatus === PropertyStatusType.OfferReady
              && underwriter.currentProperty.source !== 3
    ) {
        return true;
    }
    return false;

}

function eventBusValidatorSendBulkFlow(underwriter: UnderwriterPanelComponent, ...uwArguments) {
    if (
        (!underwriter.currentUwProforma.sendToTransactionManager)
              && underwriter.currentUwProforma.listingStatus === PropertyStatusType.OfferReady
              && underwriter.currentProperty.source == 3
    ) {
        return true;
    }
    return false;

}

function checkForOfferReadyVersioning(underwriter: UnderwriterPanelComponent, $event: any) {
     return $event !== UNDERWRITER_ACTIONS.HIDE;
}

function offerReadyOptionsBuilder(underwriter: UnderwriterPanelComponent, options) {
    const property: ListingProperty = underwriter.currentProperty;
    if(!options || !property) {
        return false;
    }

    if(property.underwritingStatus !== PropertyStatusType.Hidden) {
        delete options?.show;
        return false;
    }

    Object.keys(options).forEach(op => {
        if (![UNDERWRITER_ACTIONS.SHOW.toString(), UNDERWRITER_ACTIONS.DRAFT.toString()].includes(op)) {
            delete options[op];
        }
    });
    if(options?.show) {
        options.show.disabled = false;
    }
    return true;
}

export const offerReady: IRequest = {
    name: 'OfferReadyFlow',
    displayName: null,
    fieldToWatch: null,
    externalLink: null,
    requester: null,


    rules: {
        status: offerReadyFlowStatusValidation,
        value: null,
        comment: null
    },

    steps: [
        {
            stepAction: null,
            listingName: null,
            listingIcon: null,
            level: null,
            activators: [offerReadyFlowStatusValidation],
            activeAction:{
                saveDraft: false,
                sendTM: false,
            },
            DisableHideForEveryOne: false,
            multipleActions: null,
            saveLabel: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
            proforma: {
                lockProforma: false,
                updateProforma: false,
                forceUpdate: false,
                updateProformaLabel: null,
                editLocked: false,
                editLockOverrides: null,
                updateOnChanges: false,
                message: null,
                style: null
            },
            commands: {
                underwriter: {
                    options: {
                        ready: {id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
                        hide: {id: UNDERWRITER_ACTIONS.HIDE, label: UNDERWRITER_OPTIONS_LABEL.HIDE, disabled: true, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
                        draft: {id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft },
                        show: {id: UNDERWRITER_ACTIONS.SHOW, label: UNDERWRITER_OPTIONS_LABEL.SHOW, disabled: true, main: false, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }

                    },
                    builder: offerReadyOptionsBuilder
                },
                panel : {
                    triggerConcessionFields: {
                      active: false,
                      action: CONCESSION_ACTION.UPDATE,
                      commands: {
                          active: false,
                          nextAction: null,
                          previousAction: null,
                          actionName: null
                      },
                      validators: null
                    },
                    preApproval: {
                        active: false
                    },
                }

            },
            actions : {
                saveDraft: {
                    active: true,
                    approval: {
                        active: false,
                    },
                    validators: null,
                    versioning: {
                        active: false,
                        update: false,
                        versionName: null,
                        validators: null
                    },
                    triggerEVBusEvent: {
                        active: false,
                        eventBus: [
                            {
                                active: false,
                                evbusEventName: null,
                                validators: null
                            }
                        ],
                        checkForPendingEvents: null
                    },
                    updateStatus: {
                        active: true,
                        status: PropertyStatusType.Underwritten,
                        validators: OfferReadyStatusUpdate,
                        fallBackStatus: null
                    }
                },

                sendTM: {
                    active: true,
                    approval: {
                        active: false,
                    },
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.OFFER_READY,
                        validators: [checkForOfferReadyVersioning]
                    },
                    triggerEVBusEvent: {
                        active: true,
                        eventBus: [
                            {
                                active: true,
                                evbusEventName: EVBUS_EVENTS_NAMES.OFFER_READY,
                                validators: [eventBusValidatorOfferReadyFlow]
                            },
                            {
                                active: true,
                                evbusEventName: EVBUS_EVENTS_NAMES.BULK_TO_TRANSACTION_MANAGER,
                                validators: [eventBusValidatorSendBulkFlow]
                            }
                        ],
                        checkForPendingEvents: null
                    },
                    updateStatus: {
                        active: true,
                        status: PropertyStatusType.OfferReady,
                        validators: OfferReadyStatusUpdate,
                        fallBackStatus: null
                    }
                },
            },
        },
    ]
};
