/*global google*/
/// <reference types="@types/google.maps" />
//@ts-nocheck

import {

  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from "rxjs/operators";

import {DataDogService, DownloadService, UnderwriterService} from 'src/app/services';
import {ListingProperty} from 'src/app/services/data/listingPropety';
import {Preferences} from 'src/app/models/preferences';
import {ComparablesGridComponent, SelectionChangeEvent} from './comparablesGrid/comparablesGrid.component';

import * as _ from 'lodash';
import * as JsUtils from 'src/app/utils/jsUtils';
import * as moment from 'moment';

import {ListingComps} from 'src/app/services/data/listingComps';
import {CompMapMarkerService} from './compMapMarker';
import {ComparablePreviewComponent} from './comparablePreview/comparablePreview.component';
import {
  ComparableListingColumnDefinition,
  ComparableListingPrintColumnDefinition
} from './comparablesGrid/columnDefinition';
import {GridFilterService} from 'src/app/ui/gridFilter/gridFilter.service';
import {UnderwriterViewModel} from '../underwriter/underwriterViewModel';
import {ColumnState} from "ag-grid-community";
import { FeatureFlagsService } from "src/app/services/featureFlagsService/feature-flags.service";
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {Loader} from "@googlemaps/js-api-loader";


@Component({
  selector: 'app-comparables-panel',
  styleUrls: ['comparables.component.scss'],
  templateUrl: 'comparables.component.html'
})
export class ComparablesPanelComponent implements  OnDestroy, OnInit {
  static readonly BASE_ZOOM = 13;

  static noPoi = [
    {
      featureType: "poi",
      stylers: [
        { visibility: "off" }
      ]
    }
  ];

  @ViewChild('gmap', { static: true }) gmapElement: ElementRef;
  @ViewChild('gmapLegend', { static: true }) gmapLegend: ElementRef;
  loader: Loader;
  map: google.maps.Map;
  mapInitialized: boolean = false;
  rentMarkers: google.maps.Marker[] = [];
  rentMarkersDots: google.maps.Marker[] = [];
  saleMarkers: google.maps.Marker[] = [];
  saleMarkersDots: google.maps.Marker[] = [];
  activeMarkers: google.maps.Marker[] = [];
  activeMarkersDots: google.maps.Marker[] = [];
  currentMarkerCollection: google.maps.Marker[];
  currentMarkerCollectionDots: google.maps.Marker[];
  infowindow: google.maps.InfoWindow;
  mapZoomListener = null;
  mapProp: google.maps.MapOptions = {
    zoom: ComparablesPanelComponent.BASE_ZOOM,
    mapTypeId: 'roadmap',
    styles: [
      {
        featureType: 'poi',
        elementType: "labels.text",
        stylers: [{ visibility: 'off' }, {}]
      },
    ],
  };

  @ViewChild('compGrid') compGrid: ComparablesGridComponent;

  private compsInitialyLoaded = false;
  private lastDistance = '';
  private lastLeaseDate = '';
  private _active: boolean;
  private _reload = false;

  @Input() printMode = false;

  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;

    if (value) {
      this.view = 'rent';
    } else {
      this.reset();
    }
  }

  private _viewModel: UnderwriterViewModel;

  @Input()
  get viewModel(): UnderwriterViewModel {
    return this._viewModel;
  }
  set viewModel(viewModel: UnderwriterViewModel) {

    if (!viewModel) {
      this._comparables = null;
      return;
    }

    (async () => {
      const previousID = this._viewModel ? this._viewModel.property.AOListingID : null;
      this._viewModel = viewModel;

      if (!viewModel || previousID != viewModel.property.AOListingID) {
        await this.initializeMap();
        this.compsInitialyLoaded = false;
        this.showLegend = false;
        this.view = 'rent';
        this.map.getStreetView().setVisible(false);

        if (this.printMode) {
          this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
        } else {
          this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
        }

        this.setPropertyMarkers();
      }

      this._reload = true;
      this.centerMap();
      this.applyZoomOrFilter(this.compGrid.getDefaultFilterModel());
    })();
  }

  @Input()
  get editLocked(): boolean {
    return this._editLocked;
  }
  set editLocked(editLocked: boolean) {
    this._editLocked = editLocked;
  }
  private _editLocked: boolean;

  private _comparables: ListingComps[];
  get comparables(): ListingComps[] {
    return this._comparables;
  }
  set comparables(value: ListingComps[]) {
    this._comparables = value;
    this.setComparableMarkers();
  }


  private _isBusy: boolean;
  @Input()
  get isBusy(): boolean {
    return this._isBusy;
  }
  set isBusy(value: boolean) {
    this._isBusy = value;
    this.handleBusy();
  }

  @Output() showChanged: EventEmitter<{ [key: string]: any }> = new EventEmitter();
  @Output() compareChanged: EventEmitter<any> = new EventEmitter();
  @Output() compsLoaded: EventEmitter<any> = new EventEmitter();

  @Input() loading: boolean = false;
  isCompsDownloading = false;
  markersSet = false;

  private zoomOrFilterChanged: Subject<any> = new Subject<any>();

  preferences;
  view = 'rent';

  rentRows = [];
  rentSelected = 0;
  saleRows = [];
  saleSelected = 0;
  activeRows = [];
  activeSelected = 0;

  compRef: ComponentRef<ComparablePreviewComponent>;

  currentSelectedMarker = null;

  private originalComps: number[];

  private _showSelectedCompsOnly = false;
  public get showSelectedCompsOnly() {
    return this._showSelectedCompsOnly;
  }
  public set showSelectedCompsOnly(value) {
    this._showSelectedCompsOnly = value;
  }

  // For Column editor
  columnDef;
  columnState;
  isColumnEditorPanelOpened = false;

  gridName = null;

  showLegend = false;

  flag2838 = null;
  flag3215 = null;
  retryCounter = 0;
  comprableFilters = null;
  reservedColumnsIds= ComparableListingColumnDefinition.reservedColumnsIdsDef;

  private errorToast: ActiveToast<any>;

  private toastrOptionsError = {
    disableTimeOut: true,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  constructor(
    public ngZone: NgZone,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private underwriterService: UnderwriterService,
    private gridFilterService: GridFilterService,
    private featureFlagsService: FeatureFlagsService,
    private dataDogService: DataDogService,
    private toastr: ToastrService,
    private downloadService: DownloadService
    ) {

  }

  async ngOnInit() {
    this.zoomOrFilterChanged
      .pipe(debounceTime(500))
      .subscribe((filterModel) => {
        this.applyZoomOrFilter(filterModel);
      });
    this.flag2838 = await this.featureFlagsService.getFeatureFlag('aom-2838');
    this.flag3215 = await this.featureFlagsService.getFeatureFlag('aom-3215');

    this.underwriterService.getPreferences().then((preferences: Preferences) => {
      this.preferences = preferences;

      // Only initialize name after preferences are loaded
      this.gridName = 'compsGrid';
      this.gridFilterService.init(this.gridName, ComparableListingColumnDefinition.columns, this.compGrid, preferences.compsListing, true);
    });

    this.currentMarkerCollection = this.rentMarkers;
    this.currentMarkerCollectionDots = this.rentMarkersDots;

    if (this.flag3215?.enabled){
      await this.initializeMap();
    } else {
      this.errorToast = this.toastr.error(
        'Google Maps Disabled',
        null,
        this.toastrOptionsError
      );
    }
  }

  ngOnDestroy() {
    if (this.compRef) {
      this.compRef.destroy();
    }
  }

  async initializeMap() {
    if (this.mapInitialized) {
      return;
    }

    this.flag3215 = this.flag3215 ?? await this.featureFlagsService.getFeatureFlag('aom-3215');

    this.loader = new Loader({
      apiKey: this.flag3215.key,
      version: "weekly",
      libraries: ['geometry']
    });

    await this.loader.load();

    this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);

    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
      this.gmapLegend.nativeElement
    );

    this.map.addListener("click", (event) => {
      if (this.infowindow) {
        this.infowindow.close();
        this.infowindow = null;
        this.setHighlight(this.currentSelectedMarker.data.AOCompID, false);
      }

      if (this.currentSelectedMarker) {
        this.currentSelectedMarker.highlight(false);
        this.currentSelectedMarker = null;
      }
    });

    google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
      this.markersSet = false;
      this.compsLoaded.emit(this._viewModel.property.AOListingID);
    });

    this.mapInitialized = true;
  }

  getCompMarkerHTML(comp: ListingComps) {
    // If it's owned by institution, we show a different icon from the default.
    // We show a pink icon if it was leased/sold within 6 months and purple otherwise.
    if (!_.isEmpty(comp.AOInstitutionalOwnerID)) {
      let instColorClass = Math.abs(moment(comp.LastLeaseDate).diff(moment(), 'months')) < 6
      ? "map-purple-inst"
      : "map-pink-inst";
      if (comp.AOInstitutionalOwnerID === 'AVENUEONE') {
        instColorClass = 'map-green-inst'
      }

      return `
        <div class="a1-comp-map-marker a1-comp-inst-map-marker ${instColorClass}">
          <div class="a1-marker-price">
            <div class="a1-marker-value id${comp.AOCompID}">
              <i class="a1-mask-icon ${instColorClass}"></i>
              ${JsUtils.formatCurrencyAbbreviate((comp.RentSale == 'R' ? comp.LastRent : comp.LastSalePrice))}
            </div>
          </div>
        </div>
      `;
    }

    return `
      <div class="a1-comp-map-marker">
        <div class="a1-marker-price">
          <div class="a1-marker-value id${comp.AOCompID}">${JsUtils.formatCurrencyAbbreviate((comp.RentSale == 'R' ? comp.LastRent : comp.LastSalePrice))}</div>
        </div>
      </div>
    `;
  }

  getCompMarkerDotHtml(comp: ListingComps) {
    const instColorClass = Math.abs(moment(comp.LastLeaseDate).diff(moment(), 'months')) < 6
        ? "map-purple-inst"
        : "map-pink-inst";
    return `
      <div class="a1-comp-map-marker ${!_.isEmpty(comp.AOInstitutionalOwnerID) ? `a1-comp-inst-map-marker ${instColorClass}` : ""}">
        <div class="a1-marker-price" style="opacity:0">
        </div>
        <div class="a1-marker-point">
          <i class="a1-mask-icon check"></i>
        </div>
      </div>
    `;
  }

  // Adds a marker to the map and push to the array.
  addCompMarker(markerCollection: any[], markerDotCollection: any[], location, comp: ListingComps) {
    const marker = new CompMapMarkerService().createCompsMapMarker(
      location,
      this.getCompMarkerHTML(comp),
      this._viewModel.property.AOListingID, // Be sure to use the property aoListingId not the Comp data.AOListingID
      comp,
      true
    );

    if (comp.selected) {
      marker.selected = true;
    }

    markerCollection.push(marker);

    marker.addListener('click', (event: MouseEvent) => {
      event.stopPropagation();

      this.showInfoWindow(marker);
    });


    const markerDot = new CompMapMarkerService().createCompsMapMarker(
      location,
      this.getCompMarkerDotHtml(comp),
      this._viewModel.property.AOListingID,  // Be sure to use the property aoListingId not the Comp data.AOListingID
      comp,
      true
    );

    markerDot.isDot = true;

    marker.sibling = markerDot;
    markerDot.sibling = marker;

    if (comp.selected) {
      markerDot.selected = true;
    }

    markerDotCollection.push(markerDot);

    markerDot.addListener('click', (event: MouseEvent) => {
      event.stopPropagation();

      this.showInfoWindow(markerDot);
    });
  }

  addCompMarkerDBG(markerCollection: any[], location, comp: ListingComps) {
    const markerOptions = {
      position: location,
      data: comp,
    };
    const marker = new google.maps.Marker(markerOptions);

    markerCollection.push(marker);
  }

  addPropertyMarker(markerCollection: any[], location, property: ListingProperty) {
    const marker = new CompMapMarkerService().createCompsMapMarker(location,
      `<div class="a1-prop-icon" ></div>`,
      this._viewModel.property.AOListingID,
      property,
      false
    );
    markerCollection.push(marker);

    marker.addListener('click', () => {
      // this.showInfoWindow(marker);
    });
  }

  addPropertyMarkerDBG(markerCollection: any[], location, property: ListingProperty) {
    const markerOptions = {
      position: location,
      data: property,
    };
    const marker = new google.maps.Marker(markerOptions);

    markerCollection.push(marker);
  }

  // Sets the map on all markers in the array.
  setMapOnAll(map) {
    _.each(this.currentMarkerCollection, (m) => {
      m.setMap(map);
    });

    _.each(this.currentMarkerCollectionDots, (m) => {
      m.setMap(map);
    });
  }

  // Removes the markers from the map, but keeps them in the array.
  clearMarkers(keepProperty = false) {
    if (this.currentMarkerCollection) {

      for (let i = (keepProperty ? 1 : 0); i < this.currentMarkerCollection.length; i++) {
        this.currentMarkerCollection[i].setMap(null);
      }

      _.each(this.currentMarkerCollectionDots, (m) => {
        m.setMap(null);
      });
    }
  }

  // Shows any markers currently in the array.
  showMarkers() {
    this.setMapOnAll(this.map);
  }

  // Deletes all markers in the array by removing references to them.
  deleteMarkers() {
    this.clearMarkers();
    this.rentMarkers = [];
    this.rentMarkersDots = [];
    this.saleMarkers = [];
    this.saleMarkersDots = [];
    this.activeMarkers = [];
    this.activeMarkersDots = [];
  }

  async setPropertyMarkers() {
    if (!this._viewModel) {
      return;
    }

    this.deleteMarkers();

    const position = new google.maps.LatLng(this._viewModel.property.Latitude, this._viewModel.property.Longitude);

    // ** keep this for debug to compare with original google pins **
    // See CompMapMarker.positionDiv() to adjust offset
    // this.addPropertyMarkerDBG(this.rentMarkers, position, this._viewModel.property);

    this.addPropertyMarker(this.rentMarkers, position, this._viewModel.property);
    this.addPropertyMarker(this.saleMarkers, position, this._viewModel.property);
    this.addPropertyMarker(this.activeMarkers, position, this._viewModel.property);

    // Show current location while waiting for the comps.
    this.currentMarkerCollection = this.rentMarkers;
    this.currentMarkerCollectionDots = this.rentMarkersDots;
    this.setMapOnAll(this.map);
  }

  setComparableMarkers() {
    if (!this._comparables) {
      return;
    }

    if (this.mapZoomListener) {
      google.maps.event.removeListener(this.mapZoomListener);
      this.mapZoomListener = null;
    }

    this.clearMarkers(true);

    this.rentRows = _.filter(this._comparables, { RentSale: 'R' });
    this.saleRows = _.filter(this._comparables, ( comp ) => { return comp.RentSale == 'S' && comp.Status == 'S'  });
    this.activeRows = _.filter(this._comparables, ( comp ) => { return comp.RentSale == 'S' && (comp.Status == 'A' || comp.Status == 'P')  });

    // ** keep this for debug to compare with original google pins **
    // const comp = this.rentRows[0];
    // const longlat = new google.maps.LatLng(comp.Latitude, comp.Longitude);
    // this.addCompMarkerDBG(this.rentMarkers, longlat, comp);
    // this.addCompMarker(this.rentMarkers, longlat, comp);

    this.rentSelected = 0;
    this.saleSelected = 0;
    this.activeSelected = 0;

    this.rentMarkers.splice(1);
    this.rentMarkersDots.splice(0);
    this.saleMarkers.splice(1);
    this.saleMarkersDots.splice(0);
    this.activeMarkers.splice(1);
    this.activeMarkersDots.splice(0);

    _.each(this.rentRows, (comp: ListingComps) => {
      const longlat = new google.maps.LatLng(comp.Latitude, comp.Longitude);
      this.addCompMarker(this.rentMarkers, this.rentMarkersDots, longlat, comp);

      if (comp.selected) {
        this.rentSelected++;
      }
    });

    _.each(this.saleRows, (comp: ListingComps) => {
      const longlat = new google.maps.LatLng(comp.Latitude, comp.Longitude);
      this.addCompMarker(this.saleMarkers, this.saleMarkersDots, longlat, comp);

      if (comp.selected) {
        this.saleSelected++;
      }
    });

    _.each(this.activeRows, (comp: ListingComps) => {
      const longlat = new google.maps.LatLng(comp.Latitude, comp.Longitude);
      this.addCompMarker(this.activeMarkers, this.activeMarkersDots, longlat, comp);

      if (comp.selected) {
        this.activeSelected++;
      }
    });

    this.setMapOnAll(this.map);

    // Do not recenter or adjust bounds if this is a subsequent load
    // triggered by the zoom or filters
    if (!this.compsInitialyLoaded) {

      const position = new google.maps.LatLng(this._viewModel.property.Latitude, this._viewModel.property.Longitude);

      this.compsInitialyLoaded = true;
    }

    this.mapZoomListener = google.maps.event.addListener(this.map, "zoom_changed", (event) => {
      if (this.map.getZoom() <= ComparablesPanelComponent.BASE_ZOOM) {
        const radius = this.getDistanceRadiusFromMap();

        this.compGrid.changeDistanceFilter(radius);
      }
    });

    this.markersSet = true;
  }

  reset() {
    this.closeInfoWindow();
    this.deleteMarkers();

    this.saleSelected = 0;
    this.rentSelected = 0;
    this.activeSelected = 0;
    this._viewModel = null;
    this._comparables = null;
  }

  closeInfoWindow() {
    if (this.currentSelectedMarker) {
      this.currentSelectedMarker.highlight(false);
    }

    this.currentSelectedMarker = null;

    if (this.infowindow) {
      this.infowindow.close();
      this.infowindow = null;
    }

    if (this.compRef) {
      this.compRef.destroy();
      this.compRef = null;
    }
  }

  showInfoWindow(marker) {
    this.closeInfoWindow();

    this.currentSelectedMarker = marker;
    marker.highlight(true);

    // Attach to GMap an Angular component.
    const compFactory = this.resolver.resolveComponentFactory(ComparablePreviewComponent);
    this.compRef = compFactory.create(this.injector);
    this.compRef.instance.compPanel = this;
    this.compRef.instance.marker = marker;
    this.compRef.instance.editLocked = this.editLocked;

    this.appRef.attachView(this.compRef.hostView);

    const div = document.createElement('div');
    div.appendChild(this.compRef.location.nativeElement);

    this.infowindow = new google.maps.InfoWindow({
      content: div,
      pixelOffset: new google.maps.Size(0, -54),
      zIndex: 1,
      maxWidth: 264,
    });

    google.maps.event.addListener(this.infowindow, 'domready', function () {
      const compPreview = document.getElementById('compPreview');
      if (compPreview) {
        const l = compPreview.parentElement.parentElement.parentElement.parentElement.parentElement;
        l.classList.add('a1-gmap-comp-preview');
      }
    });

    this.infowindow.open(this.map, marker);
  }

  onRowClicked(AOCompID) {
    const marker = _.find(this.currentMarkerCollection, (m) => m.data.AOCompID == AOCompID);
    this.showInfoWindow(marker);
  }

  onRowSelectionChanged(event: SelectionChangeEvent) {
    if (this.editLocked) {
      return;
    }

    const marker = _.find(this.currentMarkerCollection, (m) => {
      return m.data.AOCompID == event.AOCompID;
    });

    if(marker) {
      if (!this.printMode) {
        this.showInfoWindow(marker);
      }
    }

    const markerRent = _.find(this.rentMarkers, (m) => {
      return m.data.AOCompID == event.AOCompID;
    });

    if(markerRent) {
      markerRent.select(event.selected);
    }

    const markerSale = _.find(this.saleMarkers, (m) => {
      return m.data.AOCompID == event.AOCompID;
    });

    if(markerSale) {
      markerSale.select(event.selected);
    }

    const markerActive = _.find(this.activeMarkers, (m) => {
      return m.data.AOCompID == event.AOCompID;
    });

    if(markerActive) {
      markerActive.select(event.selected);
    }

    const rentSelectedIds: [] = _.map(_.filter(this.rentMarkers, { selected: true }), (m) => m.data.AOCompID);
    const saleSelectedIds: [] = _.map(_.filter(this.saleMarkers, { selected: true }), (m) => m.data.AOCompID);
    const activeSelectedIds: [] = _.map(_.filter(this.activeMarkers, { selected: true }), (m) => m.data.AOCompID);

    this.rentSelected = rentSelectedIds.length;
    this.saleSelected = saleSelectedIds.length;
    this.activeSelected = activeSelectedIds.length;

    const allIds: any[] = rentSelectedIds.concat(saleSelectedIds, activeSelectedIds);

    this._viewModel.uwProforma.selectedComparableIds = allIds;
    this._viewModel.uwProforma.selectedRentComparableIds = rentSelectedIds;
    this._viewModel.uwProforma.selectedSaleComparableIds = saleSelectedIds;
    this._viewModel.uwProforma.selectedActiveComparableIds = activeSelectedIds;

    const selectedComps = _.map(allIds, (id) => {
      return _.find(this._comparables, (lc: ListingComps) => lc.AOCompID == id);
    });

    // Keep a copy of the selected comps
    this._viewModel.uwProforma.selectedComparableRowsCopy = selectedComps;
  }

  onSelectionChanged() {
    this.compareChanged.emit();
  }

  onGridFilterChanged(filterModel) {
    if (this._active) {
      this.zoomOrFilterChanged.next(filterModel);
      this.gridFilterService.updateFilterModel(this.gridName, filterModel);
    }
  }

  onGridSortChanged(sortModel: any[]) {
    this.gridFilterService.updateSortModel(this.gridName, sortModel);
  }

  onViewClick(view) {
    if (this.view == view) {
      return;
    }

    this.view = view;

    this.setMapOnAll(null);

    let selectedIds;
    if (view == 'rent') {
      this.currentMarkerCollection = this.rentMarkers;
      this.currentMarkerCollectionDots = this.rentMarkersDots;
      selectedIds = this._viewModel.uwProforma.selectedRentComparableIds;
    } else if (view == 'sale') {
      this.currentMarkerCollection = this.saleMarkers;
      this.currentMarkerCollectionDots = this.saleMarkersDots;
      selectedIds = this._viewModel.uwProforma.selectedSaleComparableIds;
    } else {
      this.currentMarkerCollection = this.activeMarkers;
      this.currentMarkerCollectionDots = this.activeMarkersDots;
      selectedIds = this._viewModel.uwProforma.selectedActiveComparableIds;
    }

    const markers = _.filter(this.currentMarkerCollection, (m) => {
      return selectedIds.includes(m.data.AOCompID);
    });
    markers.forEach(marker => marker.select(true));

    this.setMapOnAll(this.map);
  }

  selectCompById(AOCompID) {
    this.compGrid.toggleSelectRow(AOCompID, true);

    if (this.infowindow) {
      this.infowindow.close();
    }

    if (this.compRef) {
      this.compRef.destroy();
    }
  }

  setHighlight(AOCompID, highlighted = true) {
    this.compGrid.alignRowInMiddle(AOCompID, false, highlighted);
  }

  /**
   * Calculate the distance from center of the map to the top border
   */
  getDistanceRadiusFromMap(): number {
    // http://stackoverflow.com/questions/3525670/radius-of-viewable-region-in-google-maps-v3
    // Get Gmap radius

    const bounds = this.map.getBounds();

    const nePoint = bounds.getNorthEast();
    const center = this.map.getCenter();

    const nPoint = new google.maps.LatLng(nePoint.lat(), center.lng());

    const centerToTopKM = google.maps.geometry.spherical.computeDistanceBetween(nPoint, center);
    const centerToTopMiles = centerToTopKM * 0.000621371192;

    // strip to 6 decimals
    return Math.round((centerToTopMiles + Number.EPSILON) * 1000000) / 1000000;
  }

  handleBusy() {
    if (!this.compGrid) {
      return;
    }

    if (this._isBusy) {
      this.compGrid.showLoader();
    } else {
      this.compGrid.hideLoader();
    }
  }

  // Columns
  openColumnEditorPanel() {
    this.columnState = this.compGrid.gridOptions.columnApi.getColumnState();
    this.columnDef = ComparableListingColumnDefinition.columns;

    this.isColumnEditorPanelOpened = true;
  }

  onColumnEditorPanelClosed() {
    this.columnState = null;
    this.columnDef = null;
    this.isColumnEditorPanelOpened = false;
  }

  saveColumns($event: any) {
    this.compGrid.gridOptions.columnApi.applyColumnState({
      state: $event.states,
      applyOrder: true,
      defaultState: {sort: null}
    });
    this.gridFilterService.updateColumnState(this.gridName, $event.states, false);
    this.compGrid.savePreferences();

    if(!$event.refreshSessionGridOnly) {
      this.compGrid.gridOptions.api.refreshServerSideStore({ purge: true });
    }
  }


  tmpFilter = null;
  tmpSort = null;

  showSelectedCompsOnlyClick() {
    this.showSelectedCompsOnly = !this.showSelectedCompsOnly;

    if (this.showSelectedCompsOnly) {
      if (this.tmpFilter == null) {
        this.tmpFilter = _.cloneDeep(this.compGrid.getFilterModel());
        this.tmpSort = _.cloneDeep(this.compGrid.getSortModel());
      }

      this.compGrid.updateFilterModel(null, null);

      this.comparables = this._viewModel.uwProforma.selectedComparableRowsCopy;
    } else {
      this.compGrid.updateFilterModel(this.tmpFilter, this.tmpSort);

      this.comparables = this._viewModel.comps;

      this.tmpFilter = null;
      this.tmpFilter = null;
    }
  }

  // Only reload from database if Distance or LastLeaseDate filters have changed
  private applyZoomOrFilter(filterModel) {
    if (!this._viewModel || (this.showSelectedCompsOnly && !this._reload)) {
      return;
    }

    const jsonDistanceFilter = filterModel.Distance ?? {};
    const jsonLeaseDateFilter = filterModel.LastLeaseDate ?? {};

    // If distance radius increase, fetch more comps
    if (this._reload || (jsonDistanceFilter && !_.isEqual(this.lastDistance, jsonDistanceFilter)) || (jsonLeaseDateFilter && !_.isEqual(this.lastLeaseDate, jsonLeaseDateFilter))) {
      const filter = {
        Distance: filterModel.Distance,
        LastLeaseDate: filterModel.LastLeaseDate
      };

      this.lastDistance = jsonDistanceFilter;
      this.lastLeaseDate = jsonLeaseDateFilter;

      this.loadComparables(filter);
    }

    this._reload = false;
  }

  private loadComparables(filter) {
    this.isBusy = true;
    this.comprableFilters = this.comprableFilters || filter;

    // Save the ID in case the user is looking to another property
    // while loading the comps or as Hidden (remove) a property from the viewModels array
    const thisViewModel = this._viewModel;
    this.loading = !this.comprableFilters;
    this.underwriterService.getListingComparables(this._viewModel.property.AOListingID, this._viewModel.uwProforma.proforma.id, filter).then((comps) => {
      if (!this._viewModel) {
        return;
      }
      // Ensure that Comps selected by the user always in the list
      const selectedComps = _.filter(comps, (cmp) => cmp.selected) || [];

      this._viewModel.originalSelectedComps = {
        rent: _.filter(selectedComps, (cmp) => cmp.RentSale == 'R').map(rt => rt = parseInt(rt.AOCompID, 10)),
        sale: _.filter(selectedComps, (cmp) => { return cmp.RentSale == 'S' && cmp.Status == 'S' }).map(rt => rt = parseInt(rt.AOCompID, 10)),
        active: _.filter(selectedComps, (cmp) => { return cmp.RentSale == 'S' && (cmp.Status == 'A' || cmp.Status == 'P')}).map(rt => rt = parseInt(rt.AOCompID, 10))
      };

      this.originalComps = [...this._viewModel.originalSelectedComps.rent, ...this._viewModel.originalSelectedComps.sale, ...this._viewModel.originalSelectedComps.active] || [];
      _.each(thisViewModel.uwProforma.selectedComparableRowsCopy, (copy: ListingComps) => {

        const fetched = _.find(comps, (c: ListingComps) => c.AOCompID == copy.AOCompID);
        if (!fetched) {
          comps.push(copy);
        } else {
          fetched.selected = true;
        }
      });

      if (this.printMode) {
        this.compGrid.gridOptions.api.setDomLayout('print');
        this.compGrid.gridOptions.api.setColumnDefs(ComparableListingPrintColumnDefinition.columns);
      }

      thisViewModel.comps = comps;

      if (thisViewModel.property.AOListingID == this._viewModel.property.AOListingID) {
        if (this.showSelectedCompsOnly) {
          this.comparables = this._viewModel.uwProforma.selectedComparableRowsCopy;
        } else {
          this.comparables = this._viewModel.comps;
        }
      }
      this.loading = false;
      this.isBusy = false;
    }, err => {
      const customErrorMessage = this.flag2838?.enabled ? this.flag2838.errorMessage : 'something went wrong';
      if (this.flag2838?.enabled && this.flag2838.retryCount > this.retryCounter) {
        this.isBusy = false;
        this.dataDogService.log(`Failed to load comprables: retrying count = ${this.retryCounter + 1}`, {errorObj: err}, 'warn');
        this.loadComparables(this.comprableFilters);
        this.retryCounter = this.retryCounter + 1;
      } else {
        this.isBusy = false;
        this.dataDogService.log('Failed to load comprables', {errorObj: err}, 'error');
        this.loading = false;

        this.errorToast = this.toastr.error(customErrorMessage, null, this.toastrOptionsError);
        console.log(err);
        this.errorToast.onTap.subscribe(() => {
          this.errorToast = null;
        });
      }
    });
  }

  resetRowSelection() {
    this.compGrid.gridOptions.api.forEachNode(node => {
      if ((this.originalComps || []).includes(node.data.AOCompID)) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
  }

  centerMap() {
    if (this.viewModel.property.CompsMapCenter) {
      this.map.setCenter(new google.maps.LatLng(parseFloat(this.viewModel.property.CompsMapCenter.split(',')[0]), parseFloat(this.viewModel.property.CompsMapCenter.split(',')[1])));
    } else {
      this.map.setCenter(new google.maps.LatLng(this._viewModel.property.Latitude, this._viewModel.property.Longitude));
    }

    if (this.viewModel.property.CompsMapZoom) {
      this.map.setZoom(parseInt(this.viewModel.property.CompsMapZoom));
    } else {
      this.map.setZoom(ComparablesPanelComponent.BASE_ZOOM);
    }
  }
  exportComps() {
    const filterModel = this.gridFilterService.getFilterModel(this.gridName);
    const aoListingID = this._viewModel.property.AOListingID
    const proformaId = this._viewModel.uwProforma.proforma?.id
    this.isCompsDownloading = true;

    this.downloadService.downloadComps(aoListingID, proformaId, filterModel).then((response) => {
      JsUtils.downloadFile(response.body.data as ArrayBuffer, response.contentType, response.filename, response.body.data.length);

      this.isCompsDownloading = false
    }, err => {
      this.isCompsDownloading = false
      alert(err);
    });
  }
}
