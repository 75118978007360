import { Component } from '@angular/core';
import { AuthenticationService, AwsCredentialsService, RouterExtService } from 'src/app/services';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { mainMenuItems } from 'src/app/main-menu-items';
import { FeatureFlagsService } from 'src/app/services/featureFlagsService/feature-flags.service';

@Component({
  selector: 'app-app-layout',
  styleUrls: ['./appLayout.component.scss'],
  templateUrl: './appLayout.component.html'
})
export class AppLayoutComponent {

  private subscriptions: Subscription[] = [];

  profile: any = {};

  mainMenu = mainMenuItems;

  profileMenuOptions = [{
    label: "Logout",
    id: "logout"
  }];

  constructor(private authenticationService: AuthenticationService,
              private routerExt: RouterExtService,
              private featurFlagsService: FeatureFlagsService) {

    this.profile = this.authenticationService.getProfile();

    this.subscriptions.push(this.routerExt.navigationEnded.subscribe((url: string) => {
      setTimeout(() => {
        this.update(url);
        if (!FeatureFlagsService.isActive) {
          this.featurFlagsService.initFlags().then(() => {
            FeatureFlagsService.isActive = true;
          });
        }
      });
    }));
  }

  update(url: string) {
    _.each(this.mainMenu, (m) => {
      m.active = false;
    });

    if (url.startsWith('/underwriting') || url.startsWith('/properties')) {
      this.mainMenu[0].active = true;
    } else if (url.startsWith('/bulk')) {
      this.mainMenu[1].active = true;
    }
  }

  onProfileMenuChange(event) {
    if (event == 'logout') {
      this.authenticationService.logout();
    }
  }
}
