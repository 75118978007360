export enum VERSION_NAME {
  OFFER_READY = 'OfferReady',
  REHAB_BUDGET = 'RehabBudget',
  COUNTER_OFFER = 'CounterOffer',
  CLOSING_PRICE = 'ClosingPrice',
  CANCELED = 'Canceled',
  LOST_DEAD = 'LostDead',
  OFFER_DELIVERED = 'OfferDelivered'
}


export interface IVersioning {
/**
 * boolean to toggle the save draft versioning mechanism
 */
active: boolean;

/**
 * boolean that toggles creating a new version or updating previous one
 */
update: boolean;

/**
 * used as an identifier for the version to create or update
 */
versionName: VERSION_NAME;

/**
 * optional version to compare with the one to create or update
 */
previousVersion?: VERSION_NAME;

/**
 * an array of functions to activate this mechanism
 * if all the functions return false this mechanism is skipped
 * if validators is empty and active is true this mechanism is activated
 * @returns boolean
 */
validators: Function[];
}

