
import { Validators, ValidatorFn } from '@angular/forms';

export enum requiredFieldsName {
  Beds = 'Beds',
  BathsCount = 'BathsCount',
  FullBaths = 'FullBaths',
  HalfBaths = 'HalfBaths',
  YearBuilt = 'YearBuilt',
  LivingAreaSqFt = 'LivingAreaSqFt',
}
export function buildOptions(min, max, path, prefix?) {
  const sequence = [];
  const sequenceLength = Math.ceil((max - min) / path);
  if (max <= min || [0, 1].includes(sequenceLength)) {
    sequence.push(
      {
        id: prefix ? prefix + '-' + min : min,
        label: min,
        name: min,
        selected: false
      }
    );
    sequence.push(
      {
        id: prefix ? prefix + '-' + max : max,
        label: max,
        name: max,
        selected: false
      }
    );
    return sequence;
  }
  sequence.push(
    {
      id: prefix ? prefix + '-' + min : min,
      label: min,
      name: min,
      selected: false
    }
  );

  let curentValue = min;
  for (let i = 0; i < sequenceLength; i++) {
    if ((curentValue + path) < max) {
      sequence.push(
        {
          id: prefix ? prefix + '-' + curentValue + path : curentValue + path,
          label: curentValue + path,
          name: curentValue + path,
          selected: false
        }
      );
    }
    curentValue += path;
  }

  sequence.push({
    id: prefix ? prefix + '-' + max : max,
    label: max,
    name: max,
    selected: false
  });
  return sequence;
}

export const requredListingFieldsConfig = {
  Beds: {
    value: null,
    missing: false,
    hasError: false,
    userInput: false,
    min: 1
  },
  BathsCount:{
    value: null,
    missing: false,
    hasError: false,
    userInput: false,
    min: 1
  },
  YearBuilt:{
    value: null,
    missing: false,
    hasError: false,
    userInput: false,
    min: 1900,
    max: new Date().getUTCFullYear()

  },
  LivingAreaSqFt:{
    value: null,
    required: true,
    missing: false,
    hasError: false,
    userInput: false,
    min: 1,
    max: 7499
  }
};

export function buildValidator(field): ValidatorFn[] {
const fieldValidators:  ValidatorFn[] = [];
const entries = Object.keys(field);
fieldValidators.push(Validators.required);
if (entries.includes('min')) {
  fieldValidators.push(Validators.min(field.min));
}

if (entries.includes('max')) {
  fieldValidators.push(Validators.max(field.max));
}
return fieldValidators;
}
