import { Component, ElementRef } from "@angular/core";
import { IHeaderParams, Column } from "ag-grid-community";
import { IHeaderAngularComp } from 'ag-grid-angular';

import * as _ from 'lodash';
import { IGridComponent } from '../../iGridComponent';

export interface MyParams extends IHeaderParams {
  menuIcon: string;
  enableFilter: boolean;
  grid: IGridComponent;
}

@Component({
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements IHeaderAngularComp {
  private elementRef: ElementRef;

  params: MyParams;
  sorted: 'asc' | 'desc' | null;
  isFilterEnable = true;
  isFilterActive = false;
  isSortable = true;
  colId: string;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  agInit(params: MyParams): void {
    this.params = params;
    this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.params.column.addEventListener('filterChanged', this.onFilterChanged.bind(this));
    this.onSortChanged();

    this.colId = this.params.column.getColId();
    this.isFilterEnable = this.params.enableFilter && this.params.column.isFilterAllowed();

    if (this.params.grid && this.isFilterEnable) {
      this.isFilterEnable = this.isFilterEnable && this.params.grid.isColumnFilterAvailable(this.colId);
      this.isFilterActive = this.params.column.isFilterActive();
      this.isSortable = this.params.enableSorting && this.params.grid.isColumnFilterAvailable(this.colId); // Might do a separate function later ?
    }
  }

  filterClick() {
    this.params.showColumnMenu(this.elementRef.nativeElement);
  }

  toggleSort(event) {
    if (this.isSortable) {
      switch(this.sorted) {
        case 'asc':
          this.sorted = 'desc';
          break;

        case 'desc':
          this.sorted = null;
          break;

        default:
          this.sorted = 'asc';
          break;
      }

      this.params.setSort(this.sorted, true);
    }
  }

  onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.sorted = 'asc';
    } else if (this.params.column.isSortDescending()) {
      this.sorted = 'desc';
    } else {
      this.sorted = null;
    }
  }

  onFilterChanged($event) {
    const filterColumn: Column = _.find($event.columns, { colId: this.colId });
    this.isFilterActive = filterColumn != null && filterColumn.isFilterActive();
  }

  refresh(params: any) {
    return true;
  }
}
