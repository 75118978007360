import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-proforma-history',
  templateUrl: './proforma-history.component.html',
  styleUrls: ['./proforma-history.component.scss'],
  animations: [
    trigger('showHideHistory', [
      transition(':enter', [
        animate('400ms ease-in', style({opacity: 1}))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({opacity: 0}))
      ]),
    ])
  ],
})
export class ProformaHistoryComponent {

  private _proformaHistory = [];
  @Input()
  set proformaHistory(val) {
    this._proformaHistory =val;
  }

  get proformaHistory() {
    return this._proformaHistory;
  }

  @Input() showHeader: boolean = false;

  @Input() isVisible: boolean = false;

  aoListingTooltip = 'Click to open';
  constructor() { }

  canDisplayExtraFieldsUnderwriten() {
    return true;
  }

  openChild($event) {
    window.open(`/underwriting/${$event}`, "_blank");
  }
}

