import { ProFormaModel } from "src/app/models/proformaModel";
import { ListingProperty } from "../../data/listingPropety";
import { PropertyStatusType } from "../../data/propertyStatusType";
import { CONCESSION_ACTION, EVBUS_EVENTS_NAMES, STEP_ACTION, UNDERWRITER_ACTIONS, UPDATE_REQUEST_LEVEL } from "../model/updateRequest";
import { IRequest } from "../model/requestStep";
import { VERSION_NAME } from "../model/versioning";
import * as _ from 'lodash';
import { PROFORMA_FIELDS_IDS } from "src/app/panels/proforma/proforma_config";
import { pendingEvBusEventsValidator } from "../utils/pendingEventsUtils";
import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";
import { UNDERWRITER_COMMANDS_NAME, UNDERWRITER_OPTIONS_LABEL } from "../model/underwriterCommandsAndActions";


function closingPriceFlowStatusValidation(currentProperty: ListingProperty) {
    return [
        PropertyStatusType.ClosingReady,
        PropertyStatusType.ClearToClose,
        PropertyStatusType.ClearToCloseUrgent,
        PropertyStatusType.ClearToCloseReady
    ].includes(currentProperty.status);
}

function closingPriceFlowValuesValidation(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
    if (currentUwProforma.proforma[fieldToWatch] != currentProperty?.finalPurchasePriceOffer) {
        return currentProperty?.finalPurchasePriceOffer;
    }
    return false;

}

function inspectionReviewStatus(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
    return [
        PropertyStatusType.ClosingReady,
        PropertyStatusType.ClearToClose,
        PropertyStatusType.ClearToCloseUrgent,
    ].includes(currentProperty.status);
}

function clearToCloseApproved(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
    // TODO: update logic when aa
    return !!_.get(currentUwProforma,'proforma.investorApproval', null);
}


function clearToCloseApprovedStatus(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
    return [
        PropertyStatusType.ClearToCloseUrgent,
        PropertyStatusType.ClearToCloseReady
    ].includes(currentProperty.status);
}


function closingPricePendingEventBusValidator(underwriter: UnderwriterPanelComponent, ...uwArguments) {
    if(
        underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.PRICE_OFFER_AMOUNT] != underwriter.proformaPanel.originalProforma[PROFORMA_FIELDS_IDS.PRICE_OFFER_AMOUNT]
        ) {
        return true;
    }
    return false;
}

export function approvalStatusValidator(underwriter: UnderwriterPanelComponent, ...uwArguments) {
    if(!!!underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL] && !!!underwriter.proformaPanel.originalProforma[PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL]) {
        return false;
    }
    if( underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL]) {
        return true;
    }
    return false;
}


const clearToCloseOverwriteFields = [
    PROFORMA_FIELDS_IDS.TARGET_CONDITION_SCORE,
    PROFORMA_FIELDS_IDS.MONTHLY_RENT_AMOUNT,
    PROFORMA_FIELDS_IDS.GROSS_POTENTIAL_REVENUE_AMOUNT,
    PROFORMA_FIELDS_IDS.VACANCY_ALLOWANCE_AMOUNT,
    PROFORMA_FIELDS_IDS.CREDIT_LOSS_AMOUNT,
    PROFORMA_FIELDS_IDS.RENTAL_CONCESSION_AMOUNT,
    PROFORMA_FIELDS_IDS.OTHER_INCOME_AMOUNT,
    PROFORMA_FIELDS_IDS.NET_REVENUE_AMOUNT,
    PROFORMA_FIELDS_IDS.TAXES_AMOUNT,
    PROFORMA_FIELDS_IDS.PROPERTY_MANAGEMENT_FEES_AMOUNT,
    PROFORMA_FIELDS_IDS.MAINTENANCE_TURNOVER_AMOUNT,
    PROFORMA_FIELDS_IDS.INSURANCE_AMOUNT,
    PROFORMA_FIELDS_IDS.HOA_AMOUNT,
    PROFORMA_FIELDS_IDS.LEASING_COSTS_AMOUNT,
    PROFORMA_FIELDS_IDS.TOTAL_EXPENSES_AMOUNT,
    PROFORMA_FIELDS_IDS.NET_OPERATION_INCOME_AMOUNT,
    PROFORMA_FIELDS_IDS.RECURRING_CAPEX_AMOUNT,
    PROFORMA_FIELDS_IDS.NET_CASH_FLOWA_MOUNT,
    PROFORMA_FIELDS_IDS.PRICE_OFFER_AMOUNT,
    PROFORMA_FIELDS_IDS.REPAIR_BUDGET_AMOUNT,
    PROFORMA_FIELDS_IDS.CARRY_COST_AMOUNT,
    PROFORMA_FIELDS_IDS.ACQUISITION_COST_AMOUNT,
    PROFORMA_FIELDS_IDS.STABILIZATION_FEE_AMOUNT,
    PROFORMA_FIELDS_IDS.TOTAL_LANDED_COST_AMOUNT,
    PROFORMA_FIELDS_IDS.CAP_RATE,
    PROFORMA_FIELDS_IDS.NET_YIELD,
    PROFORMA_FIELDS_IDS.HIGHEST_PURCHASE_PRICE_OFFER,
    PROFORMA_FIELDS_IDS.EXPECTED_RESALE_VALUE,
    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION,
    // PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL
];

export const closingPrice: IRequest = {
    name: 'ClosingPriceFlow',
    displayName: 'Purchase Price Offer',
    fieldToWatch: PROFORMA_FIELDS_IDS.PRICE_OFFER_AMOUNT,
    externalLink: null,
    requester: null,

    rules: {
        status: closingPriceFlowStatusValidation,
        value: closingPriceFlowValuesValidation,
        comment: null
    },

    steps: [
        {
            stepAction: null,
            listingName: 'Buyer Clear to Close - Updated',
            listingIcon: null,
            level: null,
            activators: [clearToCloseApprovedStatus, clearToCloseApproved],
            activeAction: {
                sendTM: false,
                saveDraft: false,
                hide: false
            },
            DisableHideForEveryOne: true,
            multipleActions: null,
            proforma: {
                lockProforma: false,
                updateProforma: false,
                forceUpdate: false,
                updateProformaLabel: null,
                editLocked: true,
                editLockOverrides: clearToCloseOverwriteFields,
                updateOnChanges: false,
                message: null,
                style: {
                    updateIcon: 'active-property'
                }
            },
            commands: {
                underwriter: {
                    options: {
                        ready: {id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
                        draft: {id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }

                    },
                    builder: null
                },
                panel: {
                    triggerConcessionFields: {
                        active: false,
                        action: CONCESSION_ACTION.UPDATE,
                        commands: {
                            active: false,
                            nextAction: null,
                            previousAction: null,
                            actionName: null
                        },
                        validators: null
                    },
                    preApproval: {
                        active: false
                    },
                }
            },

            actions: {
                saveDraft: {
                    active: true,
                    approval: null,
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.CLOSING_PRICE,
                        validators: null
                    },
                    triggerEVBusEvent: null,
                    updateStatus: {
                        active: false,
                        status: null,
                        validators: null,
                        fallBackStatus: null
                      }
                },

                sendTM: {
                    active: false,
                    approval: {
                        active: false,
                        options: [
                            { id: 1, label: 'Yes, I have investor approval' },
                        ],
                        preSelectedOption: 1
                    },
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.CLOSING_PRICE,
                        previousVersion: VERSION_NAME.REHAB_BUDGET,
                        validators: null
                    },
                    triggerEVBusEvent: null,
                    updateStatus: {
                        active: false,
                        status: PropertyStatusType.ClearToCloseUrgent,
                        validators: null,
                        fallBackStatus: null
                      }
                },


            }

        },
    ]
};
