import { ListingProperty } from "./listingPropety";
import { PropertyStatusType } from "./propertyStatusType";

export enum classificationCatagory {
  Actionable = 0,
  Error = 6,
  default = 5
}

export enum CLASSIFICATIONS_LABELS {
  standard = 'Standard',
  no_zone = 'None',
  null = 'Error',
  affordable = 'Affordable',
  premium = 'Premium'
}
export interface Classification {
  Classification: string;
  AOMarket: string;
  Rent: number;
  MedianIncome: number;
}

export function ClassificationCategoryTypeFromStatus(listing: ListingProperty, interim?: string): string {
  const offerReadyStatus = PropertyStatusType.OfferReady;
  const {underwritingStatus, buyBoxClassification, interimBuyBoxClassification} = listing;

  let category; 
  if (!underwritingStatus || underwritingStatus < offerReadyStatus || !buyBoxClassification || buyBoxClassification === CLASSIFICATIONS_LABELS['no_zone']) {
    category = classificationCatagory.default;
  } else {
    category = classificationCatagory.Actionable;
  }
  const label = interim || buyBoxClassification || interimBuyBoxClassification;
  if (label && label === CLASSIFICATIONS_LABELS['null']) {
    category = classificationCatagory.Error;
  }
  return 'a1-status-' +  category;
}

export function ClassificationToolTipSetup(listing: any, label: string) {
  listing.ClassificationToolTip = null;
  if (label && label === CLASSIFICATIONS_LABELS['null']) {
    listing.ClassificationToolTip = 'Unknown Error';
  } else if (label && label === CLASSIFICATIONS_LABELS['no_zone']) {
    listing.ClassificationToolTip = 'Region is not setup for classification';
  }
}
