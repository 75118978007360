import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthenticationService } from '../services';
import { RequiredPermissionDirective } from './required-permission.directive';
import { RequiredRoleDirective } from './required-role.directive';
import { RolesPermissionsService } from './roles-permissions.service';

@NgModule({
  declarations: [
    RequiredPermissionDirective,
    RequiredRoleDirective,
  ],
  exports: [
    RequiredPermissionDirective,
    RequiredRoleDirective,
  ],
  providers: [
    AuthenticationService,
  ]
})
export class RolesPermissionsModule {
  static forRoot(): ModuleWithProviders<RolesPermissionsModule> {
    return {
      ngModule: RolesPermissionsModule,
      providers: [
        RolesPermissionsService
      ]
    };
  }
}
