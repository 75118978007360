import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, ApiService } from 'src/app/services';

// import { AuthenticationService } from '../services/authentication.service';
// import { RestService } from '../services/rest.service';
// import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.scss']
})
export class AcceptComponent implements OnInit {
  acceptId = '';
  acceptEmail = '';
  appLoading = false;
  error = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private router: Router) {
      console.log("AcceptComponent constructor");
     }

  ngOnInit() {
    console.log("AcceptComponent");

    this.appLoading = true;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        this.acceptId = params.id;
        console.log(params);
      }
      if (params.email) {
        this.acceptEmail = params.email;
        console.log(params);
      }
    });

    this.apiService.postAccept(this.acceptEmail, this.acceptId, null)
    .then(result => {
      this.appLoading = false;
      this.error = null;
      console.log(result);
    }, error => {
      this.appLoading = false;
      this.error = error;
      console.log(error);
    });
  }

  slideInput() {
    // this.uiService.panelChanger = 1;
    this.router.navigateByUrl('home');
  }

  back() {
    // this.uiService.panelChanger = 0;
    this.router.navigateByUrl('home');
  }
}
