import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef } from '@angular/core';
import { LightboxComponent } from './lightbox.component';
import { PhotoItem } from 'src/app/services/data/photoItem';

@Injectable({ providedIn: 'root' })
export class LightboxService {
  private modalRef: ComponentRef<LightboxComponent>;

  private promise;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector) {
  }

  open(images: PhotoItem[], index: number): Promise<any> {
    this.promise = new Promise<any>((resolve, reject) => {

      this.modalRef = this.componentFactoryResolver
        .resolveComponentFactory(LightboxComponent)
        .create(this.injector);

      this.appRef.attachView(this.modalRef.hostView);

      this.modalRef.instance.open(images, index);
    });

    return this.promise;
  }

  close() {
    this.modalRef.instance.close();
  }
}
