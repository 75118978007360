import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from '@angular/router';

import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";

import * as _ from 'lodash';
import * as moment from 'moment';

import * as JsUtils from 'src/app/utils/jsUtils';

import {
  ColumnState,
  GetRowIdFunc,
  GetRowIdParams,
  GridOptions,
  IServerSideGetRowsParams,
  RowNode
} from 'ag-grid-community';
import 'ag-grid-enterprise';

import { ConfigService, UnderwriterService } from 'src/app/services';
import { PropertyListingColumnDefinition } from './columnDefinition';
import { Preferences } from 'src/app/models/preferences';
import { ListingProperty } from 'src/app/services/data/listingPropety';
import { HeaderComponent } from 'src/app/ui/agGrid/header-component/header.component';
import { IGridComponent } from 'src/app/ui/iGridComponent';
import { GridColumnTypes } from 'src/app/ui/agGrid/gridColumnTypes';
import { SelectCellGridComponent } from "src/app/ui/select-cell-grid/select-cell-grid.component";
import {
  UnderwriterStatusRendererComponent
} from "src/app/ui/agGrid/underwriter-status-renderer/underwriter-status-renderer.component";
import { ActiveToast, ToastrService } from "ngx-toastr";
import {
  InvestorApprovalStatusTypeFromDescription,
  PropertyStatusTypeFromDescription
} from "src/app/services/data/propertyStatusType";
import { ListingResult } from "src/app/services/data/listingResult";
import { DateRangeFilter } from "../../../services/data/dateRangeFilter";
import { RangePickerConfig } from "../../../ui/range-datepicker/range-picker-config.interface";
import { TimeRangeTypes } from "../../../ui/range-datepicker/time-range-configs";
import { TagsUtils } from "src/app/services/tagUtils";
import { AttributeRenderer } from "../../../ui/agGrid/attributeRenderer";
import { GridToolTipComponent } from "src/app/ui/grid-tool-tip/grid-tool-tip.component";
import {
  ClipboardCopyForListingRendererComponent
} from "src/app/ui/agGrid/clipboard-copy-for-listing-renderer/clipboard-copy-for-listing-renderer.component.";
import {
  CurrentPriceRendererComponent
} from '../../../ui/agGrid/current-price-renderer/current-price-renderer.component';
import {
  ListingStatusRendererComponent
} from '../../../ui/agGrid/listing-status-renderer/listing-status-renderer.component';
import { PropertyGridStateService } from "../../../services/propertyGridState.service";
import { GroupRendererComponent } from '../../../ui/agGrid/group-renderer/group-renderer.component';
import { EntityUtils } from "../../../services/entityUtils";
import { IViewSetting, IViewSettingOptions, ViewMode } from "src/app/ui/view-setting/viewSettingModel";
import { NoPropertiesFoundComponent } from "src/app/ui/no-properties-found/no-properties-found.component";
// ---> Remove when we remove the feature flag 1357 from here
import { FeatureFlagsService } from "src/app/services/featureFlagsService/feature-flags.service";
import { LinkRendererComponent } from "src/app/ui/agGrid/link-renderer/link-renderer.component";

// ---> to here

@Component({
  selector: 'app-property-grid',
  templateUrl: 'propertyGrid.component.html',
  styleUrls: ['../../styles/transactionGrid.scss'],
  providers: [PropertyGridStateService]
})
export class PropertyGridComponent implements IGridComponent, OnDestroy, OnInit {
  enableInterimBBC = false;
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();
  @Output() batchSelectionChanged: EventEmitter<any[]> = new EventEmitter();
  @Output() gridReady: EventEmitter<void> = new EventEmitter();
  @Output() gridContentUpdated: EventEmitter<any> = new EventEmitter();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() columnMoved: EventEmitter<any> = new EventEmitter();
  @Output() isFetchCount: EventEmitter<boolean> = new EventEmitter();
  @Output() clearFilter: EventEmitter<string> = new EventEmitter();
  @Output() clearAddress: EventEmitter<void> = new EventEmitter();
  @Output() dateRangeOverride: EventEmitter<TimeRangeTypes> = new EventEmitter();


  private _viewMode: ViewMode;
  @Input()
  get viewMode(): ViewMode {
    return this._viewMode;
  }
  set viewMode(viewMode: ViewMode) {
    this._viewMode = viewMode;
    if (viewMode === ViewMode.BULK) {
      this.gridName = 'bulkPropertyGrid';
      this.colDef = PropertyListingColumnDefinition.bulkColumns;
    } else {
      this.gridName = 'propertyGrid';
      this.colDef = PropertyListingColumnDefinition.columns;
    }
  }

  private _preferences: Preferences;
  @Input()
  get preferences(): Preferences {
    return this._preferences;
  }
  set preferences(preferences: Preferences) {
    this._preferences = preferences;
    if (preferences) {
      this.updatePreferences();
      this.resetListingProperties();
    }
  }

  filterSortFieldsMapping = {
    underwritingStatus: 'underwritingStatus',
    StatusUpdateDateHuman: 'StatusUpdateDate',
    Tags: 'Tags',
    lastUnderwrittenDateHuman: 'lastUnderwrittenDate',
    investorApproval: 'investorApproval',
  };
  filterSortFieldsMappingKeys = Object.keys(this.filterSortFieldsMapping);

  gridOptions: GridOptions;

  private _listingProperties: ListingProperty[] = [];

  private columnLayoutChanged: Subject<void> = new Subject<void>();
  private updateViewRequested: Subject<void> = new Subject<void>();

  private isReady = false;
  private preferencesInitialized = false;

  private lastActionIntervalHandle = null;

  private updateIntervalHandle = null;
  private updateToast: ActiveToast<any>;

  private offset = 0;
  private totalCount = 0;
  private countChanged: boolean = true;

  private gridName = 'propertyGrid';
  private colDef = PropertyListingColumnDefinition.columns;
  private originalColumnState;

  private _rangePickerConfig: RangePickerConfig;
  @Input()
  get rangePickerConfig(): RangePickerConfig {
    return this._rangePickerConfig;
  }
  set rangePickerConfig(rangePickerConfig: RangePickerConfig) {
    this._rangePickerConfig = rangePickerConfig;
    this.resetListingProperties();
  }

  private _viewSetting: IViewSettingOptions[];
  @Input()
  get viewSetting(): IViewSettingOptions[] {
    return this._viewSetting;
  }
  set viewSetting(setting: IViewSettingOptions[]) {
    this._viewSetting = setting;
    this.resetListingProperties();
  }

  private _filterModel: {} = {};
  @Input()
  get filterModel(): {} {
    return this._filterModel;
  }
  set filterModel(filterModel: {}) {
    this._filterModel = filterModel;
  }

  private _sortModel: any[] = [];
  @Input()
  get sortModel(): any[] {
    return this._sortModel;
  }
  set sortModel(sortModel: any[]) {
    this._sortModel = sortModel;
  }

  private _addressSearch: string;
  get addressSearch(): string {
    return this._addressSearch;
  }

  set addressSearch(value: string) {
    this._addressSearch = value;
    if (!value) {
      this.clearAddress.emit();
    }
  }

  @Input() listingName: string;

  private latestUpdate: moment.Moment = null;
  private dateRange: DateRangeFilter = null;
  private subscriptions: Subscription[] = [];

  private params: IServerSideGetRowsParams;
  private loadListingRequest: Subject<IServerSideGetRowsParams> = new Subject<IServerSideGetRowsParams>();
  private loadListingRequestObs: Observable<any>;
  noRowsOverlayComponent: any = NoPropertiesFoundComponent;
  private selectedRow: any[];
  private isSelectionUpdating = false;
  newListingIds: any[] = [];
  isLoadingChildren = false;

  context: any;

  defaultSortModel = [{ "sort": "asc", "colId": "underwritingStatus" }];
  // TODO: implement aws sdk v3
  // it comes with @aws-sdk/abort-controller which allows us to abort http requests
  // private abortSignal = new AbortController().signal;

  constructor(
    public router: Router,
    private underwriterService: UnderwriterService,
    private configService: ConfigService,
    private toastr: ToastrService,
    private propertyGridService: PropertyGridStateService,
    // ---> Remove when we remove the feature flag 1357 from here
    private featureFlagService: FeatureFlagsService,
    // ---> to here
  ) {
    // Debounce the column changes related events a bit so
    // we don't spam-save the preferences while resizing/moving a column
    this.columnLayoutChanged
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.savePreferences();
      });

    this.updateViewRequested
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.updateOpUpdatedColumn();
      });

    this.loadListingRequestObs = this.loadListingRequest.pipe(switchMap(async (params) => {
      const {
        startRow,
      } = params.request;

      this.params = params;
      const selectedColumns = this.getSelectedColumns();

      const filterModel = this.convertFilterFields(params.request.filterModel);
      const sortModel = this.convertSortFields(params.request.sortModel);
      this.dateRange = this.viewMode == ViewMode.BULK || !this.rangePickerConfig
        ? null
        : { fromDate: this.rangePickerConfig.startDate, toDate: this.rangePickerConfig.endDate };

      this.propertyGridService.currentGroupKeys = this.params.request.groupKeys;
      const viewSettingParams: IViewSetting = {};
      this._viewSetting.forEach(setting => {
        viewSettingParams[setting.id] = setting.checked ? 1 : 0;
      });
      this.isLoadingChildren = Array.isArray(this.params.request.groupKeys) ? this.params.request.groupKeys.length > 0 : !!this.params.request.groupKeys;
      return await this.underwriterService.getListings({
        selectedColumns,
        viewMode: this.viewMode,
        buyBoxId: null,
        groupKeys: this.params.request.groupKeys,
        dateRange: this.dateRange,
        viewSetting: viewSettingParams,
        fromLine: startRow,
        filters: filterModel,
        sort: sortModel,
        address: this.addressSearch
      });
    }));

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = <GridOptions>{};
    this.gridOptions.columnTypes = GridColumnTypes.columnTypes;

    this.gridOptions.noRowsOverlayComponent = NoPropertiesFoundComponent;
    this.gridOptions.sideBar = false;
    this.gridOptions.multiSortKey = 'ctrl';
    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.rowHeight = 32;
    this.gridOptions.suppressCellFocus = true;
    this.gridOptions.tooltipShowDelay = 0;
    this.gridOptions.stopEditingWhenCellsLoseFocus = true;
    this.gridOptions.groupDisplayType = 'custom';
    this.gridOptions.enableGroupEdit = true;

    this.gridOptions.rowModelType = 'serverSide';
    this.gridOptions.serverSideStoreType = 'partial';
    this.gridOptions.blockLoadDebounceMillis = 300; // ag grid important
    this.gridOptions.serverSideSortingAlwaysResets = true;
    this.gridOptions.serverSideFilteringAlwaysResets = true;

    this.gridOptions.defaultColDef = {
      headerComponent: <new () => HeaderComponent>HeaderComponent,
      headerComponentParams: {
        enableFilter: true,
        grid: this
      },

      menuTabs: ['filterMenuTab'],
      width: 150,
      minWidth: 40,
      enableRowGroup: false,
      enablePivot: false,
      resizable: true,
      sortable: true,
      filter: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      floatingFilter: false,
      filterParams: {
        newRowsAction: 'keep',
        debounceMs: 1000
      },
      tooltipComponent: 'GridToolTipComponent',
    };

    this.gridOptions.overlayLoadingTemplate =
      '<div class=" a1-grid-loader"><div class="a1-loader">Loading...</div></div>';

    this.gridOptions.components = {
      underwriterStatusRenderer: UnderwriterStatusRendererComponent,
      SelectCellGridComponent: SelectCellGridComponent,
      GridToolTipComponent: GridToolTipComponent,
      groupRenderer: GroupRendererComponent,
      CurrentPriceRenderer: CurrentPriceRendererComponent,
      ListingStatusRenderer: ListingStatusRendererComponent,
      attributeRenderer: AttributeRenderer,
      clipboardCopyForListingRenderer: ClipboardCopyForListingRendererComponent,
      // ---> Remove when we remove the feature flag 1357 from here
      linkRenderer: LinkRendererComponent,
      // ---> to here
    };

    this.gridOptions.rowClassRules = {
      'a1-parent-row': params => params.data?.isHeaderRow && params.data?.groupCount > 1,
      'a1-child-row': params => this.viewMode !== ViewMode.BULK && !params.data?.isHeaderRow,
      'a1-exception': params => params?.data?.Exception,
    };

    this.context = { componentParent: this };
  }

  noRowsOverlayComponentParams: any = {
    noRowsOverLayGridName: () => this.gridName,
  };

  ngOnInit() {
    this.lastActionIntervalHandle = setInterval(() => this.updateOpUpdatedColumn(), 60000);

    this.underwriterService.setPropertyGridComponent(this);
  }

  ngOnDestroy() {
    if (this.lastActionIntervalHandle) {
      clearInterval(this.lastActionIntervalHandle);
    }

    TagsUtils.resetTagUtils();
    EntityUtils.resetEntityUtils();
    if (this.updateIntervalHandle) {
      clearInterval(this.updateIntervalHandle);
      this.updateIntervalHandle = null;
    }

    if (this.updateToast) {
      this.toastr.clear();
      this.updateToast = null;
    }

    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = null;
  }

  convertFilterFields(filterModel) {
    const newModel = {};
    if (!filterModel) {
      return {};
    }
    Object.keys(filterModel || {}).forEach((key) => {
      if (this.filterSortFieldsMappingKeys.includes(key)) {
        if (key === "underwritingStatus") {
          newModel[this.filterSortFieldsMapping[key]] = {
            ...filterModel[key],
            values: filterModel[key].values.map((v) =>
              PropertyStatusTypeFromDescription(v)
            ),
          };
        } else if (key === "Tags") {
          newModel[this.filterSortFieldsMapping[key]] = {
            ...filterModel[key],
            values: filterModel[key].values.map((v) => TagsUtils.getTagsIds(v)
            ),
          };
        } else if (key == "investorApproval") {
          newModel[this.filterSortFieldsMapping[key]] = {
            ...filterModel[key],
            values: filterModel[key].values.map((v) =>
              InvestorApprovalStatusTypeFromDescription(v)
            ),
          };
        } else if (key === 'StatusUpdateDateHuman' || key === 'lastUnderwrittenDateHuman') {
          newModel[this.filterSortFieldsMapping[key]] = this.covertUtcDateFilters(filterModel, key);
        } else {
          newModel[this.filterSortFieldsMapping[key]] = filterModel[key];
        }
      } else if (key === 'AOPortfolioID') {
        this.covertUnderscoreFilters(filterModel, key);
        newModel[key] = filterModel[key];
      } else if (key === 'Channel') {
        newModel[key] = {
          ...filterModel[key],
          values: filterModel[key].values.map(v => {
            if (v === "Other") {
              return "-";
            }
            return v;
          })
        };
      } else if (key === 'LastUpdate') {
        newModel[key] = this.covertUtcDateFilters(filterModel, key);
      } else {
        newModel[key] = filterModel[key];
      }
    });
    return newModel;
  }


  private covertUtcDateFilters(filterModel: any, key: string) {
    const model = { ...filterModel[key] };
    if (model.condition1) {
      const condition1 = model.condition1;
      condition1.dateFrom = moment(condition1.dateFrom).utc().format('YYYY-MM-DD HH:mm:ss');

      const condition2 = model.condition2;
      condition2.dateFrom = moment(condition2.dateFrom).utc().format('YYYY-MM-DD HH:mm:ss');

      model.condition1 = condition1;
      model.condition2 = condition2;
    } else {
      model.dateFrom = moment(model.dateFrom).utc().format('YYYY-MM-DD HH:mm:ss');
    }
    return model;
  }

  private covertUnderscoreFilters(filterModel: any, key: string) {
    if (filterModel[key].condition1) {
      const condition1 = filterModel[key].condition1;
      condition1.filter = condition1.filter.replace(/ /gi, '_');

      const condition2 = filterModel[key].condition2;
      condition2.filter = condition2.filter.replace(/ /gi, '_');

      filterModel[key].condition1 = condition1;
      filterModel[key].condition2 = condition2;
    } else {
      filterModel[key].filter = filterModel[key].filter?.replace(/ /gi, '_');
    }
  }

  convertSortFields(sortModel) {
    if (!sortModel) {
      return [];
    }
    const newModel = sortModel.map((sort) => {
      if (this.filterSortFieldsMappingKeys.includes(sort.colId)) {
        sort.colId = this.filterSortFieldsMapping[sort.colId];
      }
      return sort;
    });

    if (!newModel.length) {
      newModel.push(this.defaultSortModel[0]);
    }
    return newModel;
  }

  private loadListing(params: IServerSideGetRowsParams) {
    if (this.countChanged) {
      this.gridContentUpdated.emit({ totalCount: 0 });
    }

    this.showLoader();
    this.loadListingRequest.next(params);
  }

  private _loadListing(data: ListingResult) {
    let { listings } = data;
    const { success } = this.params;
    const { startRow, endRow } = this.params.request;
    let isTagsSorting;
    const isFetchingChildren = this.propertyGridService.currentGroupKeys?.length > 0;

    if (isFetchingChildren) {
      listings = listings.filter(listing => !listing.isHeaderRow);
      const parent = this._listingProperties.filter(listing => listing.AOPropertyID == this.propertyGridService.currentGroupKeys[0])[0];
      if (parent) {
        parent.children = listings;
      }
    } else {
      this._listingProperties = startRow === 0 ? this._listingProperties = listings : this._listingProperties.concat(listings);
    }

    const startLoadedRows = this._listingProperties.length ? 1 : 0;

    let loadedGroupCount = 0;
    this._listingProperties.forEach(listing => {
      loadedGroupCount += listing.groupCount;
    });

    const endLoadedRows = loadedGroupCount;
    this.gridContentUpdated.emit({ startRow: startLoadedRows, endRow: endLoadedRows });

    if (!this.isReady) {
      TagsUtils.setColumnsType(this.colDef);
      this.isReady = true;
    }
    if (!this.updateIntervalHandle) {
      this.updateIntervalHandle = setInterval(() => this._checkUpdatedListings(), this.configService.getUwListingRefresh());
    }

    if (!isFetchingChildren) {
      // Ensure to get listing added between call request and response
      this.latestUpdate = _.maxBy(listings, (l: ListingProperty) => l.StatusUpdateDateMoment)?.StatusUpdateDateMoment;
      this.offset = startRow;
    }

    if (this.countChanged || (startRow == 0 && !isFetchingChildren)) {
      this.fetchCount();
      this.countChanged = false;
    }

    isTagsSorting = _.find(this.getSortModel() || [{}], { colId: "Tags" }) || Object.keys(this.gridOptions.api.getFilterModel() || {}).includes('Tags');

    let newListings: ListingProperty[] = listings;
    if (isTagsSorting) {
      TagsUtils.tagFilterInstance = this.gridOptions.api.getFilterInstance('Tags');
      newListings = TagsUtils.getSortedTagsListing(this.sortModel, listings, isTagsSorting.sort);
    }

    const currentLastRow = startRow + newListings.length;
    const rowCount = currentLastRow < endRow ? currentLastRow : -1;

    TagsUtils.isTagSorting = isTagsSorting;
    success({
      rowData: newListings,
      rowCount
    });

    this.hideLoader();
    if (!newListings.length && this.gridOptions.api && !this._listingProperties.length) {
      this.gridOptions.api.showNoRowsOverlay();
    } else if (this.isLoadingChildren) {
      this.newListingIds = [...this.newListingIds, ...newListings.map(nl => nl.AOListingID)];
    } else if (this.gridOptions.api) {
      this.newListingIds = [];
      this.gridOptions.api.forEachNode(nd => {
        if (nd.data) {
          this.newListingIds.push(nd.data.AOListingID);
        }
      });
    }
    this.updateSelectedRows();
  }

  searchByAddress() {
    // we fire the filter changed event because the component is not aware of the address search in this.addressSearch,
    // and we need to update the filter model
    this.dateRangeOverride.emit(this.addressSearch ? TimeRangeTypes.GridOverride : TimeRangeTypes.Last24Hours);
    this.onFilterChanged(null);
  }

  private fetchCount() {
    const dateRange: DateRangeFilter =
      this.viewMode == ViewMode.BULK || !this.rangePickerConfig
        ? null
        : { fromDate: this.rangePickerConfig.startDate, toDate: this.rangePickerConfig.endDate };
    const filterModel = this.convertFilterFields(this.filterModel);
    const sortModel = this.convertSortFields(this.sortModel);

    const viewSettingParams: IViewSetting = {};

    this.isFetchCount.emit(true);

    this._viewSetting.forEach(setting => {
      viewSettingParams[setting.id] = setting.checked ? 1 : 0;
    });
    this.underwriterService.getListings({
      viewMode: this.viewMode,
      buyBoxId: null,
      groupKeys: [],
      dateRange: dateRange,
      viewSetting: viewSettingParams,
      fromLine: this.offset,
      filters: filterModel,
      sort: sortModel,
      update: false,
      fetchTMUser: false,
      getCount: true,
      address: this.addressSearch
    }).then((data) => {
      const { count } = data;

      this.totalCount = count;
      this.isFetchCount.emit(false);
      if (this.totalCount == 1 && !data.listings[0].AOListingID) {
        this.totalCount = 0;
      }
      this.gridContentUpdated.emit({ totalCount: this.totalCount });
    }, err => {
      this.isFetchCount.emit(false);
      this.gridContentUpdated.emit({ resetFilteredCount: true, error: true });
      // TODO: add proper error handling
      console.log(err);
    });
  }

  getFilteredPropertiesParams() {
    const dateRange: DateRangeFilter = this.viewMode == ViewMode.BULK || !this.rangePickerConfig
      ? null
      : { fromDate: this.rangePickerConfig.startDate, toDate: this.rangePickerConfig.endDate };
    const filterModel = this.convertFilterFields(this.filterModel);
    const sortModel = this.convertSortFields(this.sortModel);

    const underwritingStatusSort = (sortModel || []).find(sortModelItem => {
      return sortModelItem.colId === 'underwritingStatus';
    });

    this.defaultSortModel = underwritingStatusSort ? [
      {
        sort: underwritingStatusSort.sort,
        colId: underwritingStatusSort.colId
      }]
      : this.defaultSortModel;

    const viewSettingParams: IViewSetting = {};

    this._viewSetting.forEach(setting => {
      viewSettingParams[setting.id] = setting.checked ? 1 : 0;
    });

    return {
      viewMode: this.viewMode,
      buyBoxId: null,
      dateRange: dateRange,
      viewSetting: viewSettingParams,
      groupKeys: [],
      fromLine: this.offset,
      limit: 0,
      filters: filterModel,
      sort: {
        gridSorting: sortModel,
        defaultSorting: this.defaultSortModel
      },
      update: false,
      fetchTMUser: false,
      getCount: false

    };

  }

  private _checkUpdatedListings() {
    const dateRange: DateRangeFilter = this.viewMode == ViewMode.BULK || !this.rangePickerConfig
      ? null
      : { fromDate: this.rangePickerConfig.startDate, toDate: this.rangePickerConfig.endDate };
    const filterModel = this.convertFilterFields(this.filterModel);
    const sortModel = this.convertSortFields(this.sortModel);
    const viewSettingParams: IViewSetting = {};
    this._viewSetting.forEach(setting => {
      viewSettingParams[setting.id] = setting.checked ? 1 : 0;
    });

    const selectedColumns = this.getSelectedColumns();

    this.underwriterService.getListings({
      selectedColumns,
      viewMode: this.viewMode,
      buyBoxId: null,
      groupKeys: [],
      dateRange: dateRange,
      viewSetting: viewSettingParams,
      fromLine: this.offset,
      filters: filterModel,
      sort: sortModel,
      update: true
    }).then((data) => {
      const { count, listings } = data;

      // Check that another loadListing() has not been triggered in the mean time.
      // The user might have change buybox , time scale or "show hidden"
      if (this.updateIntervalHandle && listings.length) {
        this.latestUpdate = _.maxBy(listings, (l: ListingProperty) => l.StatusUpdateDateMoment).StatusUpdateDateMoment;

        // Check if count is same or if at least one property is missing / was updated
        const hasNewUpdatesToShowToast = count !== this.totalCount || _.some(listings, (updated: ListingProperty) => {
          const propInList: ListingProperty = _.find(this._listingProperties, (l: ListingProperty) => l.AOListingID == updated.AOListingID);
          return (!propInList || !moment(propInList.LastUpdate).isSame(moment(updated.LastUpdate)));
        });

        if (!this.updateToast && hasNewUpdatesToShowToast) {
          this.updateToast = this.toastr.info('<div>New listings added</div><div class="a1-toaster-action">Reload list</div>', null, {
            disableTimeOut: true,
            closeButton: true,
            enableHtml: true,
            messageClass: 'toast-message a1-toast-message'
          });

          // Note: Toast might not be clicked before a long time.
          this.updateToast.onTap.subscribe(() => {
            this.updateToast = null;

            // TODO: prevent data fetch since we just made one (first approach didn't work w/ grid)
            this.resetListingProperties();
          });
        }
      }
    }, err => {
      this.hideLoader();
    });
  }

  resetListingProperties() {
    if (this.updateIntervalHandle) {
      clearInterval(this.updateIntervalHandle);
      this.updateIntervalHandle = null;
    }

    if (this.updateToast) {
      this.toastr.clear();
      this.updateToast = null;
    }

    this._listingProperties = [];

    if (this.isReady) {
      this.resetScrollAndStore();
    }
  }

  resetScrollAndStore() {
    this.gridOptions.api.ensureIndexVisible(0, 'top');
    this.gridOptions.api.refreshServerSideStore({ purge: true });
  }

  updateOpUpdatedColumn() {
    const updates = [];

    if (this.gridOptions.api) {
      return;
    }

    this.gridOptions.api.getRenderedNodes().forEach((node: RowNode) => {
      if (_.isNil(node) || _.isNil(node.data)) {
        return;
      }
      const tmp = JsUtils.getDateCalendarString(node.data.StatusUpdateDateMoment);
      const lastUnderwrittenDate = JsUtils.getDateCalendarString(node.data.lastUnderwrittenDateMoment);
      if (tmp != node.data.StatusUpdateDateHuman) {
        node.data.StatusUpdateDateHuman = tmp;
        updates.push(node);
      }
      if (lastUnderwrittenDate != node.data.lastUnderwrittenDateHuman) {
        node.data.lastUnderwrittenDateHuman = lastUnderwrittenDate;
        updates.push(node);
      }
    });

    // Don't use "gridOptions.api.applyTransaction()" to update the StatusUpdateDateHuman
    // as the actual data (StatusUpdateDateMoment) does not change and does not trigger a refresh of the cell
    if (updates.length) {
      this.gridOptions.api.refreshCells({
        rowNodes: updates,
        columns: ['StatusUpdateDateHuman'],
        force: true
      });
      this.gridOptions.api.refreshCells({
        rowNodes: updates,
        columns: ['lastUnderwrittenDateHuman'],
        force: true
      });
    }
  }

  getListingName() {
    return this.gridName === 'bulkPropertyGrid' ? 'bulkPropertyListing' : 'propertyListing';
  }

  unselectAll(aoListingIds?: any[]) {
    if (aoListingIds && aoListingIds.length > 0) {
      this.gridOptions.api.forEachNode(node => {
        if (aoListingIds.includes(node.data.AOListingID) && node.isSelected()) {
          node.setSelected(false);
        }
      });
    } else {
      this.gridOptions.api.refreshCells();
      this.gridOptions.api.deselectAll();
    }
  }


  updateProperties(properties?: ListingProperty[]) {
    if (!properties || !properties.length) {
      return;
    }
    const propertiesGroupObject = _.groupBy(properties, 'AOPropertyID');
    const propertiesGroup = [];

    Object.keys(propertiesGroupObject || {}).forEach(AOPropertyIDGroup => {
      propertiesGroup.push(propertiesGroupObject[AOPropertyIDGroup]);
    });

    propertiesGroup.forEach(propertyGroup => {
      this.updatePropertiesByGroup(propertyGroup);
    });
  }

  updatePropertiesByGroup(properties?: ListingProperty[]) {
    const propertiesIds = properties.map(p => p.AOListingID);
    const targetNodes = [];

    this.gridOptions.api.forEachNode(node => {
      if (propertiesIds.includes(node?.data?.AOListingID)) {
        targetNodes.push(node);
      }
    });

    if (!targetNodes.length) {
      return;
    }

    const currentHeaderNode: RowNode = targetNodes.filter(node => {
      return node?.data.isHeaderRow;
    })[0];

    const currentHeaderProperty = currentHeaderNode
      ? properties.filter(prop => {
        return prop.AOListingID == currentHeaderNode.data.AOListingID;
      })[0]
      : null;


    const newHeaderProperty = properties.filter(prop => {
      return prop.isHeaderRow;
    })[0];


    const newHeaderNode: RowNode = newHeaderProperty
      ? targetNodes.filter(node => {
        return node?.data.AOListingID == newHeaderProperty.AOListingID;
      })[0]
      : null;


    const oldHeaderNodeAOListingID = currentHeaderNode ? currentHeaderNode.data.AOListingID : null;
    const newHeaderNodeAOListingID = newHeaderNode ? newHeaderNode.data.AOListingID : null;

    const conflictIds = [];
    if (oldHeaderNodeAOListingID) {
      conflictIds.push(oldHeaderNodeAOListingID);
    }
    if (newHeaderNodeAOListingID) {
      conflictIds.push(newHeaderNodeAOListingID);
    }

    const shouldSwithHeader = !!oldHeaderNodeAOListingID && !!newHeaderNodeAOListingID && (oldHeaderNodeAOListingID != newHeaderNodeAOListingID);

    const viewSettingParams: IViewSetting = {};
    this._viewSetting.forEach(setting => {
      viewSettingParams[setting.id] = setting.checked ? 1 : 0;
    });
    const isInHiddenView = !!viewSettingParams.hiddenListing;

    let allVisibleProperties = true;

    properties.forEach(prop => {
      if (prop.isHiddenForAll) {
        allVisibleProperties = false;
      }
    });

    if (!shouldSwithHeader) {
      targetNodes.forEach(node => {
        const data = node.data;
        const newData = properties.filter(pr => pr.AOListingID == data.AOListingID);
        if (newData && newData[0] && data) {
          this.updateNodeData(newData[0], data, node, isInHiddenView, allVisibleProperties);
        }
      });
      return;
    }

    const notConflictedNodes = targetNodes.filter(nd => {
      return !conflictIds.includes(nd.data.AOListingID);
    });

    notConflictedNodes.forEach(node => {
      const data = node.data;
      const newData = properties.filter(pr => pr.AOListingID == data.AOListingID);
      if (newData && newData[0] && data) {
        this.updateNodeData(newData[0], data, node, isInHiddenView, allVisibleProperties);
      }
    });

    const isNewHeaderSelected = newHeaderNode.isSelected();
    const isOldHeaderSelected = currentHeaderNode.isSelected();
    currentHeaderNode.setSelected(isNewHeaderSelected);
    newHeaderNode.setSelected(isOldHeaderSelected);

    const newNodeData = newHeaderNode.data;
    const newPropertyData = newHeaderProperty;
    this.updateNodeData(newPropertyData, newNodeData, currentHeaderNode, isInHiddenView, allVisibleProperties, true);

    const currentNodeData = currentHeaderNode.data;
    const currentPropertyData = currentHeaderProperty;
    this.updateNodeData(currentPropertyData, currentNodeData, newHeaderNode, isInHiddenView, allVisibleProperties);

  }

  updateNodeData(propertyData, nodeData, targetNode: RowNode, isInHiddenView, allVisibleProperties, isHeader?) {
    if (propertyData && nodeData) {
      const isListingHidden = propertyData?.isHiddenForAll;
      const isInGroup = propertyData?.groupCount > 1 && propertyData?.isHeaderRow == 0;

      if ((!isInHiddenView && !isListingHidden) || (!isInHiddenView && isInGroup) ||
        (isInHiddenView && isListingHidden) || (isInHiddenView && !allVisibleProperties)) {
        const entries = Object.keys(nodeData || {});
        entries.forEach(key => {
          if (key === 'groupCount') {
            targetNode.data[key] = this.viewMode === ViewMode.BULK ? 1 : targetNode.data[key];
          } else if (key === 'Tags') {
            try {
              if (propertyData[key] && Array.isArray(Object.values(propertyData[key])) && Object.values(propertyData[key]).length) {
                const tagsValues = propertyData[key];
                if (!isNaN(parseInt(tagsValues[0]))) {
                  targetNode.data[key] = tagsValues;
                } else if (tagsValues) {
                  targetNode.data[key] = Object.keys(tagsValues);
                } else {
                  targetNode.data[key] = [];
                }
              } else {
                targetNode.data[key] = [];
              }
            } catch (e) {
              console.log('wrong Tags structure', e);
            }
          } else {
            targetNode.data[key] = propertyData[key];
          }
        });

        targetNode.setData(targetNode.data);

        this.gridOptions.api.refreshCells({
          rowNodes: [targetNode]
        });
      } else {
        this.removeSelectedRows([propertyData?.AOListingID]);
      }
    }
  }


  removeSelectedRows(aoListingIds?: any[]) {
    const selectedRows = this.gridOptions.api.getSelectedNodes();
    let selectedRowAolistingIDs: any[];

    if (!selectedRows || selectedRows.length === 0) {
      selectedRowAolistingIDs = [];
    } else {
      selectedRowAolistingIDs = selectedRows.map(singleRow => singleRow?.data?.AOListingID) || [];
      selectedRows.forEach(singleRow => singleRow.setSelected(false));
    }

    if (aoListingIds && aoListingIds.length > 0) {
      selectedRowAolistingIDs = selectedRowAolistingIDs.filter(srow => {
        return !aoListingIds.includes(srow);
      });

      this._listingProperties = this._listingProperties.filter(property => {
        return !aoListingIds.includes(property.AOListingID);
      });
    } else {
      selectedRowAolistingIDs = [];
      selectedRows.forEach(x => {
        const indexToRemove = this._listingProperties.indexOf(x.data);
        if (indexToRemove >= 0) {
          this._listingProperties.splice(indexToRemove, 1);
        }
      });
    }

    this.params.api.refreshServerSideStore({});
    if (selectedRowAolistingIDs.length > 0) {
      this.gridOptions.api.forEachNode(node => {
        if (selectedRowAolistingIDs.includes(node.data.AOListingID)) {
          node.setSelected(true);
        }
      });
    }
  }

  removeFilter(name: string) {
    // Get a reference to the 'name' filter instance
    const filterInstance = this.gridOptions.api.getFilterInstance(name);

    // Set the model for the filter
    // filterInstance.setModel({
    //     type: 'endsWith',
    //     filter: 'g'
    // });

    filterInstance.setModel(null);

    // Tell grid to run filter operation again
    this.gridOptions.api.onFilterChanged();

    this.filterModel = this.gridOptions.api.getFilterModel();
  }

  // called at less once when initialize preferences
  updateFilterModel(filterModel, sortModel) {
    let filterModelChanged = false;
    if (!_.isEqual(this.filterModel, filterModel)) {
      this.filterModel = filterModel;
      filterModelChanged = true;
    }

    let sortModelChanged = false;
    if (!_.isEqual(this.sortModel, sortModel)) {
      this.sortModel = sortModel;
      sortModelChanged = true;
    }

    if (!filterModelChanged && !sortModelChanged) {
      return;
    }

    if (filterModelChanged) {
      this.gridOptions.api.setFilterModel(this.filterModel);
    }

    if (sortModelChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: this.sortModel,
        defaultState: { sort: null }
      });
    }

    this.resetListingProperties();
  }

  initPreferences(filterModel, sortModel, columnsState) {
    let filterModelChanged = false;
    if (!_.isEqual(this.filterModel, filterModel) && !!filterModel) {
      this.filterModel = filterModel;
      filterModelChanged = true;
    }

    let sortModelChanged = false;
    if (!_.isEqual(this.sortModel, sortModel) && !!sortModel) {
      this.sortModel = sortModel;
      sortModelChanged = true;
    }

    let columnsStateChanged = false;
    if (columnsState) {
      columnsStateChanged = true;
    }

    if (!filterModelChanged && !sortModelChanged && !columnsStateChanged) {
      return;
    }

    if (filterModelChanged) {
      this.gridOptions.api.setFilterModel(this.filterModel);
    }

    if (sortModelChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: this.sortModel,
        defaultState: { sort: null }
      });
    }

    if (columnsStateChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: columnsState,
        applyOrder: true
      });
    }

    this.resetListingProperties();
  }

  getFilterModel() {
    return this.gridOptions.api.getFilterModel();
  }

  getSortModel(state?) {
    const colState = state ?? this.gridOptions.columnApi.getColumnState();
    const newModel = colState
      .map((s, index) => {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex, position: index };
      })
      .filter((s) => {
        return s.sort != null;
      }).sort((a, b) => {
        if (a?.sortIndex > b?.sortIndex) {
          return 1;
        }
        if (a?.sortIndex < b?.sortIndex) {
          return -1;
        }
        return 0;
      })
      ;
    return newModel;
  }

  // This is called when restoring models from filter service
  updateSortModel(sortModel) {
    this.sortModel = sortModel;
    this.gridOptions.columnApi.applyColumnState({
      state: this.sortModel,
      defaultState: { sort: null }
    });

    this.resetListingProperties();
  }

  updateColumnsStates(columns: ColumnState[]) {
    this.gridOptions.columnApi.applyColumnState({
      state: columns,
      applyOrder: true
    });
  }

  updatingPreferences = false;

  private updatePreferences() {
    if (!this.isReady || !this._preferences) {
      return;
    }

    this.updatingPreferences = true;

    if (this._preferences.propertyListing && !JsUtils.isNullOrEmpty(this._preferences.propertyListing.columns)) {

      // THIS IS TO FIX EARLY DEV PROBLEMS
      const idx = _.findIndex(this._preferences.propertyListing.columns, { colId: "id" });
      // def. = true;
      const aoListingIDState: ColumnState = this._preferences.propertyListing.columns[idx];
      aoListingIDState.hide = false;
      aoListingIDState.pinned = 'left';
      aoListingIDState.width = 48;

      if (idx != 0) {
        this._preferences.propertyListing.columns.splice(idx, 1);
        this._preferences.propertyListing.columns.unshift(aoListingIDState);
      }
      // end fix

      this.gridOptions.columnApi.applyColumnState({
        state: this._preferences.propertyListing.columns,
        defaultState: { sort: null }
      });
    }

    this.preferencesInitialized = true;
  }

  async savePreferences() {
    if (!this.preferencesInitialized) {
      return;
    }

    this.preferences.propertyListing.columns = this.gridOptions.columnApi.getColumnState();

    await this.underwriterService.savePreferences();
  }

  public async onReady() {

    const element = this.colDef.find(obj => obj.field === "Address");
    const index = this.colDef.findIndex(obj => obj.field === "Address");
    element.cellRenderer = "clipboardCopyForListingRenderer";
    this.colDef.splice(index, 1, element);

    this.gridOptions.api.setColumnDefs(this.colDef);
    this.originalColumnState = this.gridOptions.columnApi.getColumnState();
    this.isLoading.emit(true);
    this.gridOptions.api.showLoadingOverlay();

    this.loadListingRequestObs.subscribe((data: ListingResult) => this._loadListing(data));

    this.gridOptions.api.setServerSideDatasource({
      getRows: (params: IServerSideGetRowsParams) => this.loadListing(params),
      destroy: () => this.loadListingRequest.unsubscribe()
    });

    this.gridReady.emit();
  }

  showLoader() {
    this.isLoading.emit(true);
    this.gridOptions.api.showLoadingOverlay();
  }

  hideLoader() {
    this.isLoading.emit(false);
    if (this.gridOptions.api) {
      this.gridOptions.api.hideOverlay();
    }
  }

  updateSelectedRows() {
    if (!this.gridOptions.api) {
      return;
    }
    if (!this.selectedRow || !this.selectedRow.length) {
      this.selectedRow = [];
      this.gridOptions.api.forEachNode((node) => {
        node.setSelectedParams({ newValue: false, suppressFinishActions: true });
      });

      this.batchSelectionChanged.emit(this.selectedRow);
      return;
    }

    const oldSelectId = this.selectedRow.map(prop => prop.AOListingID);
    const newSelectedRow = [];
    this.isSelectionUpdating = true;
    this.gridOptions.api.forEachNode((node) => {
      if (node.data) {
        if (oldSelectId.includes(node.data.AOListingID)) {
          node.setSelectedParams({ newValue: true, suppressFinishActions: true });
          newSelectedRow.push(node.data);
        } else {
          node.setSelectedParams({ newValue: false, suppressFinishActions: true });
        }
      }
    });

    this.selectedRow = newSelectedRow;
    this.batchSelectionChanged.emit(this.selectedRow);
    this.isSelectionUpdating = false;

  }

  onSelectionChanged($event) {
    if (this.isSelectionUpdating) {
      return;
    }

    this.selectedRow = this.gridOptions.api.getSelectedNodes()
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .map(node => node.data)
      .filter(y => {
        return this.newListingIds.includes(y.AOListingID);
      }) || [];
    this.batchSelectionChanged.emit(this.selectedRow);
  }

  onFilterChanged($event) {
    this.countChanged = true;
    this.resetScrollAndStore();
    this.filterModel = this.gridOptions.api.getFilterModel();
    this.filterChanged.emit(this.filterModel);
    this.updateOpUpdatedColumn();
  }

  onSortChanged($event) {
    this.sortModel = this.getSortModel();

    _.each(this.sortModel, (m) => {

      const col = _.find(this.colDef, (c) => {
        return c.field == m.colId;
      });

      m.display = col.headerName;
    });

    this.resetScrollAndStore();
    this.sortChanged.emit(this.sortModel);

    this.updateOpUpdatedColumn();
  }

  // here we use one generic event to handle all the column type events.
  // the method just prints the event name
  onColumnEvent($event) {
    if (
      $event.source == 'api' || // 'api' are called when we programmatically set the columns width, etc,
      $event.type == "columnEverythingChanged" ||
      ($event.type == "columnResized" && !$event.finished)
    ) {
      return;
    }
    // the column moved event is handled by onColumnDragged method
    if (
      ($event.source == 'uiColumnDragged' ||
        $event.type == "columnMoved") && this.isReady
    ) {
      return;
    }

    // Notify changes to save layout preferences
    this.columnLayoutChanged.next();

    // force refresh tags rendering to properly count hidden tags after resize
    $event.api.refreshCells({
      columns: ['Tags'],
      force: true,
    });
  }

  onColumnDragged($event) {
    if (!this.isReady) {
      return;
    }
    const state = $event?.columnApi?.getColumnState();
    this.columnMoved.emit({ states: state, refreshSessionGridOnly: true });
  }

  // Use this event to get notified of a scroll to refresh the Last Action
  virtualRowRemoved($event) {
    this.updateViewRequested.next();
  }

  isColumnFilterAvailable(colId) {
    return true;
  }

  /**
   *
   */
  clearFilters() {
    this.clearFilter.emit(this.gridName);
  }

  clearAddressSearch() {
    this.addressSearch = '';
    this.clearAddress.emit();
    this.dateRangeOverride.emit(TimeRangeTypes.Last24Hours);
  }

  /**
   *
   */
  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    // if leaf level, we have ID
    if (params.data.id != null) {
      return params.data.id;
    }
    // this array will contain items that will compose the unique key
    const parts = [];
    // if parent groups, add the value for the parent group
    if (params.parentKeys) {
      parts.push(...params.parentKeys);
    }
    // it we are a group, add the value for this level's group
    const rowGroupCols = params.columnApi.getRowGroupColumns();
    const thisGroupCol = rowGroupCols[params.level];
    if (thisGroupCol && thisGroupCol.getColDef() && thisGroupCol.getColDef().field) {
      parts.push(params.data[thisGroupCol.getColDef().field]);
    }
    return parts.join('-');
  }

  getSelectedColumns() {
    const disallowedColumns = new Set(['underwritingStatus', 'missingDataTooltip', 'groupCount']);
    return this.params.columnApi.getColumnState()?.filter(column => !column.hide && !disallowedColumns.has(column.colId))
      .map(column => column.colId);
  }
}
