import {Component, ElementRef, forwardRef, HostListener, ViewChild, Output, EventEmitter} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { IFileInfo } from './FileInfo';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadFilesComponent),
      multi: true
    }
  ]
})
export class UploadFilesComponent implements ControlValueAccessor {
  @ViewChild("uploadFiles", {static: true}) inputFile: ElementRef<HTMLInputElement>;
  @Output() fileClicked: EventEmitter<IFileInfo> = new EventEmitter();
  files: IFileInfo[] = [];

  constructor() {
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const files: FileList = evt.dataTransfer.files;
    this.addFiles(files);
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  openUpload() {
    this.inputFile.nativeElement.click();
  }

  addFiles(files: FileList) {
    const filesUploaded = this.files?.length ? [...this.files] : [];

    for (let i = 0; i < files.length; i++) {
      filesUploaded.push(files[i]);
    }

    this.writeValue(filesUploaded);
  }

  deleteFile(indexFile) {
    let filesUploaded = [...this.files];

    if (filesUploaded.length == 1) {
      filesUploaded = [];
    } else {
      filesUploaded.splice(indexFile, 1);
    }

    this.writeValue(filesUploaded);
  }

  /**
   * @deprecated
   */

  openFile(indexFile) {
    const file = this.files[indexFile];

    if (file.isFileInfo) {
      this.fileClicked.emit(file);
    } else {
      const url = URL.createObjectURL(new Blob(file.filepath));
      window.open(url, '_blank');
    }
  }

  onChange = (files: IFileInfo[]) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(files: IFileInfo[]): void {
    this.files = files;
    this.onChange(this.files);
  }

}
