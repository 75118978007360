import {Component} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { PropertyStatusCategoryTypeFromStatus, PropertyStatusTypeText } from "src/app/services/data/propertyStatusType";


@Component({
  selector: 'app-underwriter-status-renderer',
  templateUrl: './underwriter-status-renderer.component.html',
  styleUrls: ['./underwriter-status-renderer.component.css']
})
export class UnderwriterStatusRendererComponent {
   private cellValue: string;
   underwriteStatusClass =['a1-status-label'];
   status: string;

   // gets called once before the renderer is used
   agInit(params: ICellRendererParams): void {
       this.cellValue = this.getValueToDisplay(params);
       this.underwriteStatusClass.push('a1-status-' + PropertyStatusCategoryTypeFromStatus(params.value));
       this.status = PropertyStatusTypeText(params.value);
   }

   // gets called whenever the cell refreshes
    // important
   refresh(params: ICellRendererParams) {
       // set value into cell again
       this.cellValue = this.getValueToDisplay(params);
   }
//  for testing
   buttonClicked() {
       alert(`${this.cellValue} medals won!`);
   }
 // important
   getValueToDisplay(params: ICellRendererParams) {
       return params.valueFormatted ? params.valueFormatted : params.value;
   }
}
