import { ModalConfig } from './modalConfig';
import { NgZone, ViewContainerRef, ViewChild, TemplateRef, Component, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class ParentModalComponent {
  @ViewChild('backdrop', { static: true }) backdropTpl: TemplateRef<any>;
  @ViewChild('panel', { static: true }) dropdownTpl: TemplateRef<any>;

  closed = new Subject<any>();

  isOpen = false;

  config = new ModalConfig();

  protected backdropHtml;
  protected modalHtml;
  protected view: any;
  protected backdropView: any;
  protected vcr: ViewContainerRef;
  protected zone: NgZone;

  close(result): Promise<any> {
    if (this.isOpen) {
      this.isOpen = false;

      return new Promise((resolve) => {
        setTimeout(() => {
          this.zone.run(() => {

            document.body.removeChild(this.modalHtml);
            this.modalHtml = null;

            document.body.removeChild(this.backdropHtml);
            this.backdropHtml = null;

            this.view.destroy();
            this.view = null;

            this.backdropView.destroy();
            this.backdropView = null;

            resolve(result);
          });
        }, 125);
      });
    }

    return Promise.resolve(null);
  }

  openModal() {
    this.backdropView = this.vcr.createEmbeddedView(this.backdropTpl);
    this.backdropHtml = this.backdropView.rootNodes[0];
    document.body.appendChild(this.backdropHtml);

    this.view = this.vcr.createEmbeddedView(this.dropdownTpl);
    this.modalHtml = this.view.rootNodes[0];

    document.body.appendChild(this.modalHtml);

    setTimeout(() => {
      this.zone.run(() => {
        this.isOpen = true;
      });
      this.updateConfig();
    }, 10);
  }

  open(config: ModalConfig): void {
    this.config = config;
    this.openModal();
  }

  updateConfig() {}
}
