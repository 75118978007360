declare const textMask: any;
import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as textMask from "vanilla-text-mask/dist/vanillaTextMask.js";
import * as moment from 'moment';
import { customStyles } from './datePickerStyle';
import createAutoCorrectedDatePipe from './datePickerPipes';
import { IAngularMyDpOptions, IMyDateModel } from '@nodro7/angular-mydatepicker';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() placeholder: string = 'Click to select a date';
  @ViewChild("maskedInput", {static: true}) maskedInput: ElementRef<HTMLInputElement>;

  @Output() onCalendarToggle: EventEmitter<any> = new EventEmitter<any>();

  dateValue: any = null;
  dateMaskInput: string = null;

  model: IMyDateModel = null;
  maskedInputController;
  autoCorrectedDatePipe;

  private customStyles: string = customStyles;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy',
    dayLabels: {su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S'},
    sunHighlight: false,
    showSelectorArrow: false,
    selectorWidth: '226px',
    stylesData: {
      selector: 'a1',
      styles: this.customStyles
    },
    appendSelectorToBody: true,
  };

  constructor() {
    this.autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maskedInputController = textMask.maskInput({
        inputElement: this.maskedInput.nativeElement,
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
        pipe: this.autoCorrectedDatePipe
      });
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }

  onChange = (value: string) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: string): void {
    this.dateValue = value;
    this.dateMaskInput = null;
    this.model = null;

    if (value) {
      const mInputValue = moment(value, 'YYYY-MM-DD');
      if (mInputValue.isValid()) {
        this.dateMaskInput = mInputValue.format("MM/DD/YYYY");
        this.dateValue = this.dateMaskInput;

        this.model = {
          isRange: false,
          singleDate: {
            // @ts-ignore
            jsDate: moment(value, 'YYYY-MM-DD').toDate()
          }
        };
      } else {
        this.dateValue = null;
      }
    }
  }

  datePickerChanged(dateModel: IMyDateModel) {
    this.model = dateModel;
    if (dateModel?.singleDate?.jsDate) {
      this.dateValue = moment(dateModel.singleDate.jsDate).utc().startOf('day').format();
      this.dateMaskInput = moment(dateModel.singleDate.jsDate).utc().startOf('day').format("MM/DD/YYYY");
      this.onChange(this.dateValue);
    } else {
      this.dateValue = null;
      this.dateMaskInput = null;
      this.onChange(null);
    }
  }

  onDateMaskInputBlur() {
    if (this.dateMaskInput == this.maskedInput.nativeElement.value || (!this.dateMaskInput && !this.maskedInput.nativeElement.value)) {
      return;
    }

    if (this.maskedInput.nativeElement.value) {
      const cleanedInput = this.maskedInput.nativeElement.value.replace(/_/gi, '');
      const mInputValue =  moment(cleanedInput, 'MM/DD/YYYY', true);
      if (mInputValue.isValid()) {
        this.dateValue = mInputValue.format();

        this.model = {
          isRange: false,
          singleDate: {
            // @ts-ignore
            jsDate: mInputValue.toDate()
          }
        };
      } else {
        this.dateValue = null;
        this.model = null;
      }
    } else if (this.dateValue != null) {
      this.dateValue = null;
      this.model = null;
    }
    this.onChange(this.dateValue);
  }

  onDateMaskInputChange() {
    console.log("onDateMaskInputChange");
    if (this.maskedInput.nativeElement.value) {
      const cleanedInput = this.maskedInput.nativeElement.value.replace(/_/gi, '');
      const mInputValue =  moment(cleanedInput, 'MM/DD/YYYY', true);

      if (mInputValue.isValid()) {
        this.dateValue = mInputValue.format();

        this.model = {
          isRange: false,
          singleDate: {
            // @ts-ignore
            jsDate: mInputValue.toDate()
          }
        };

        this.onChange(this.dateValue);
      }

      // don't reset while typing
    } else if (this.dateValue != null) {
      this.dateValue = null;
      this.model = null;
      this.onChange(null);
    }
  }
}
