import { Pipe, PipeTransform } from '@angular/core';
import * as JsUtils from 'src/app/utils/jsUtils';

import * as moment from 'moment';

/**
 * Purpose of this pipe is to prevent converting dates at 00:00:00 utc to local time and being 1 day off
 */
@Pipe({name: 'a1Date'})
export class A1DatePipe implements PipeTransform {
  transform(value: any): string {
    return JsUtils.getMDYDateString(value);
  }
}

@Pipe({name: 'a1DateRelative'})
export class A1DateRelativePipe implements PipeTransform {
  transform(value: any): string {
    return JsUtils.getDateCalendarString(value);
  }
}
