import { Injectable } from "@angular/core";
import {
  ITargetOption,
} from "src/app/panels/proforma/proforma";
import { ProForma } from "../data/proforma";
import * as _ from "lodash";
import { BehaviorSubject, Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { ListingProperty } from "../data/listingPropety";
import { EntityUtils } from "../entityUtils";
import { approvalSubTypeText, InvestorApprovalType, PropertyStatusType } from "../data/propertyStatusType";

type aoListingIdType = string | number;

@Injectable({
  providedIn: "root",
})
export class AutoApprovalService {
  private entities: ITargetOption[];
  private activeAoListingID: aoListingIdType;
  private _autoAssignOption: ITargetOption = {
    id: "Default",
    label: "Auto-assign",
    autoAssign: "Auto-assign",
  };

  private propertieEntitiesSubject = new BehaviorSubject<any>(undefined);
  private propertieEntitiesObservable: Observable<any>;
  static readonly RefreshPurchasingInterval = 20000;

  private proformaEntities: {
    [key in aoListingIdType]: {
      proforma: ProForma;
      entities: ITargetOption[];
    };
  };

  constructor() {
    this.proformaEntities = {};
    this.propertieEntitiesObservable = this.propertieEntitiesSubject
      .asObservable()
      .pipe(filter((v) => !!v));
    EntityUtils.getEntities()
      .subscribe(entities => {
        this.refresh(entities);
      });
  }

  setApprovalStatus(p: ListingProperty) {
    if (this.entities && this.entities.length) {
      const selected = this.entities.filter(
        (ent) => ent.label == p.purchasingEntity
      );
      if (p.underwritingStatus == PropertyStatusType.InvestorApprovalReady) {
        p.investorApproval = InvestorApprovalType.approved;
      } else if (selected && selected[0]?.autoApproval) {
        p.investorApproval = InvestorApprovalType.autoApproval;
      } else if (p.underwritingStatus == PropertyStatusType.InvestorApprovalUpdated) {
        p.investorApproval = InvestorApprovalType.pendingApproval;
      } else if (!p.investorApproval) {
        p.investorApproval = InvestorApprovalType.autoAssign;
      }
    }
  }

  getDefaultEntityOption() {
    return this._autoAssignOption;
  }

  updateSelectedEntityOptions(proforma: ProForma, isActive = true) {
    if (isActive) {
      this.activeAoListingID = proforma.AOListingID;
    }
    if (!this.proformaEntities[proforma.AOListingID]) {
      this.proformaEntities[proforma.AOListingID] = {
        proforma,
        entities: [],
      };
    }
    this.proformaEntities[proforma.AOListingID].proforma = proforma;
    const targetEntities = _.cloneDeep(this.entities);
    this.proformaEntities[proforma.AOListingID].entities = this.refreshProformaEntityOptions(targetEntities);
    return this.proformaEntities[proforma.AOListingID].entities;
  }

  refresh(entities: ITargetOption[]) {
    if (entities && entities.length) {
      this.entities = [
        ...[this._autoAssignOption],
        ...entities.filter((et) => !!et.id),
      ];
      for (const pro in this.proformaEntities) {
        if (pro) {
          if (this.activeAoListingID == pro) {
            const originalEntities = _.cloneDeep(
              this.proformaEntities[pro].entities || []
            );
            const updatedEntities = this.updateSelectedEntityOptions(
              this.proformaEntities[pro].proforma,
              false
            );
            if (!_.isEqual(originalEntities, updatedEntities)) {
              this.propertieEntitiesSubject.next(true);
            }
          } else {
            this.updateSelectedEntityOptions(
              this.proformaEntities[pro].proforma,
              false
            );
          }
        }
      }
    }
  }

  private refreshProformaEntityOptions(originalEntities: ITargetOption[]) {
    (originalEntities || []).forEach((oe) => {
      if (!oe.autoApproval) {
        oe.autoApproval = null;
      } else if (oe.autoApproval) {
        oe.subOption = approvalSubTypeText.autoApproval;
      }
      if (oe.id == this._autoAssignOption.id) {
        oe.subOption = approvalSubTypeText.default;
      }
    });
    return originalEntities;
  }

  initEntitiesChangesDetection() {
    return this.propertieEntitiesObservable;
  }
}
