import { rejectionReasonsSeparator } from "src/app/models/inderwriterNoteModel";

export function buildRejectionComment(config) {
  let oldComment;
  let newComment;
  if (config && config.reason) {
    if (config.reason.oldValue) {
      oldComment = (config.reason.oldValue || '').split(rejectionReasonsSeparator);
      return oldComment;
    }
    if (config.reason.newValue) {
      newComment = (config.reason.newValue || '').split(rejectionReasonsSeparator);
    }
  }
  return [];
}

export function buildMoussHandler(parent: any, targetElement) {
  return ($event: MouseEvent) => {

    const target = $event.target as HTMLElement;

    if (target.getAttribute('id') === 'a1-custom-id-ng-select') {
      target.focus();
    } else {
      $event.preventDefault();
      targetElement.blur();
    }

    if (target.classList.contains('ng-clear-wrapper')) {
      parent.handleClearClick();
      return;
    }

    if (target.classList.contains('ng-arrow-wrapper')) {
      parent.handleArrowClick();
      return;
    }

    if (target.classList.contains('ng-value-icon')) {
      return;
    }

    if(parent) {
      if (!parent.focused) {
        parent.focus();
      }

      if (parent.searchable && parent?.open) {
        parent.open();
      } else {
        parent.toggle();
      }
    }
  };
}

export function buildFocusHandler(parent: any, target) {

  return ($event) => {
    if (parent.focused) {
      return;
    }
    target.focus();
    if (parent._editableSearchTerm) {
      parent._setSearchTermFromItems();
    }

    parent.element.classList.add('ng-select-focused');
    parent.focusEvent.emit($event);
    parent.focused = true;
  };

}

export function builBlurHandler(parent: any, target) {

  return ($event) => {
    parent.element.classList.remove('ng-select-focused');
    parent.blurEvent.emit($event);
    if (!parent.isOpen && !parent.disabled) {
      parent._onTouched();
    }
    if (parent._editableSearchTerm) {
      parent._setSearchTermFromItems();
    }
    parent.focused = false;
  };

}

export function builBackspaceHandler(parent: any, target) {

  return ($event) => {
    parent.element.classList.remove('ng-select-focused');
    parent.blurEvent.emit($event);
    if (!parent.isOpen && !parent.disabled) {
      parent._onTouched();
    }
    if (parent._editableSearchTerm) {
      parent._setSearchTermFromItems();
    }
    parent.focused = false;
  };

}

export function handleBackspace(parent: any, target: HTMLElement) {
  if (parent.searchTerm || !parent.clearable || !parent.clearOnBackspace || !parent.hasValue) {
    return;
  }

  if (target && target.textContent) {
    return;
  }

  target.blur();

  if (parent.multiple) {
    parent.unselect(parent.itemsList.lastSelectedItem);
  } else {
    parent.clearModel();
  }
}

export function buildngSelectFilter(parent: any, target) {

  return (term: string, disableEmit?) => {
    if (parent._isComposing && !parent.searchWhileComposing) {
      return;
    }

    parent.searchTerm = term;
    if (parent._isTypeahead && (parent._validTerm || parent.minTermLength === 0)) {
      parent.typeahead.next(term);
    }

    if (!parent._isTypeahead) {
      parent.itemsList.filter(parent.searchTerm);
      if (parent.isOpen) {
        parent.itemsList.markSelectedOrDefault(parent.markFirst);
      }
    }

    if (!disableEmit) {
      parent.searchEvent.emit({ term, items: parent.itemsList.filteredItems.map(x => x.value) });
    }
    parent.open();
  };

}

export const customRejectionRegEx = /(other) ?-* ?/;

export function formatCustomRejection(input, baseRegex, trim, lowerCase) {
  let isCustomRejection;
  const regex = new RegExp(baseRegex, 'gim');
  let toFormat: string = trim? input.trim() : input;
  isCustomRejection = regex.exec(toFormat)?.index;

  if (isCustomRejection == 0) {
    const toRemove: string = toFormat.match(regex)[0] || '';
    toFormat = toFormat.slice(toRemove.length);
  }
  return lowerCase ? toFormat.toLowerCase() : toFormat;
}
