import { Component, Input, Output, EventEmitter, ViewContainerRef, NgZone, TemplateRef, HostBinding, forwardRef } from '@angular/core';

import * as JsUtils from 'src/app/utils/jsUtils';
import { createPopper, Placement } from '@popperjs/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITargetOption } from 'src/app/panels/proforma/proforma';

@Component({
  selector: 'app-select-investor',
  templateUrl: './select-investor.component.html',
  styleUrls: ['./select-investor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInvestorComponent),
      multi: true
    }
  ]
})
export class SelectInvestorComponent implements ControlValueAccessor {
  @HostBinding('class') class = 'a1-select';
  @Input() customClasses = null;
  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() labelKey = 'label';
  @Input() idKey = 'id';
  @Input() optionCss = '';
  @Input() dropdownIcon = `a1-dropdown-chevron-icon`;
  @Input() displayLabel = true;
  @Input() placement: Placement = 'bottom-end';
  @Input() offset = {
    skidding: 0,
    distance: 4,
  };

  private _options: ITargetOption[] = [];
  @Input()
  get options(): ITargetOption[] {
    return this._options;
  }
  set options(values: ITargetOption[]) {
    this._options = values;
    this.updateSelection();
  }

  private _selectedId = null;
  @Input()
  get selectedId(): any {
    return this._selectedId;
  }
  set selectedId(value: any) {
    this._selectedId = value;
    this.updateSelection();
  }

  @Input() nullable;

  @Input() placeholder;
  @Input() leftIconClass;
  @Input() optionRightIconClass;

  @Output() selectChange = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() optionRightButtonClicked: EventEmitter<any> = new EventEmitter();

  selectedModel: ITargetOption;

  isOpen = false;
  view: any;
  backdropView: any;
  popperRef: any;

  constructor(private vcr: ViewContainerRef, private zone: NgZone) { }

  get label() {
    return this.selectedModel ? this.selectedModel[this.labelKey] : this.placeholder;
  }
  get approval() {
    return !!this.selectedModel?.autoApproval;
  }

  get labelClass() {
    return this.selectedModel?.id !== 'Default' ? 'a1-button-label a1-button-label-filled' : 'a1-button-label';
  }

  get dropdownCustomClasses() {
    return this.customClasses ? this.customClasses + '' : '';
  }

  updateSelection() {
    if (this.options && !JsUtils.isNullOrEmpty(this.selectedId)) {
      this.selectedModel = this.options.find(
        currentOption => currentOption[this.idKey] === this.selectedId
      );
    } else {
      this.selectedModel = null;
    }
  }

  open(dropdownTpl: TemplateRef<any>, origin: any, backdropTpl: TemplateRef<any>, event) {
    if (this.disabled) {
      return;
    }

    this.backdropView = this.vcr.createEmbeddedView(backdropTpl);
    const bd = this.backdropView.rootNodes[0];
    document.body.appendChild(bd);

    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const dropdown = this.view.rootNodes[0];

    document.body.appendChild(dropdown);

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, dropdown, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this.offset.skidding, this.offset.distance],
            },
          },
          {
            name: "sameWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({ state }) => {
              state.styles.popper.minWidth = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.minWidth = `${state.elements.reference.getBoundingClientRect().width
                }px`;
            }
          },
          {
            name: 'flip',
            enabled: true,
            options: {
              flipVariations: false,
            },
          }
        ]
      });
    });

    this.isOpen = true;
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;

      this.closed.emit();
      this.popperRef.destroy();
      this.view.destroy();
      this.view = null;
      this.popperRef = null;

      this.backdropView.destroy();
      this.backdropView = null;
    }
  }

  isActive(option) {
    if (!this.selectedModel) {
      return false;
    }
    return option[this.idKey] === this.selectedModel[this.idKey];
  }

  select(option) {
    if (option) {
      this._selectedId = option[this.idKey];
      this.selectedModel = option;
    } else {
      this._selectedId = null;
      this.selectedModel = null;
    }

    this.selectChange.emit(this._selectedId);
    this.onChange(this._selectedId);

    this.close();
  }

  onBackdropClicked() {
    this.close();
  }

  optionRightButtonClick($event: MouseEvent, option) {
    $event.preventDefault();
    $event.stopPropagation();

    this.optionRightButtonClicked.emit(option[this.idKey]);
    this.close();
  }


  onChange = (value: string) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: any): void {
    this._selectedId = value;
    this.updateSelection();

    this.onChange(value);
  }
}
