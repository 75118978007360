import {Component, NgZone, ViewContainerRef} from '@angular/core';
import {ParentModalComponent} from "../parentModalComponent";

@Component({
  selector: 'app-business-rule-warning',
  templateUrl: './business-rule-warning.component.html',
  styleUrls: ['./business-rule-warning.component.scss', '../modal.component.scss']
})
export class BusinessRuleWarningComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  field;
  slicedfields = [];
  options: any;
  reason;
  onWarning: any[];
  multipleFields = false;
  onWarningCount;

  private readonly sliceSize = 10;


  currentPage = 0;

  propertyPager = '';

  constructor(vcr: ViewContainerRef, zone: NgZone) {
    super();
    this.vcr = vcr;
    this.zone = zone;
  }

  updateConfig() {
    this.initOnWarningOptions();
      this.field = this.onWarning ? this.onWarning[0] : undefined;
  }

  private initOnWarningOptions() {
    this.options = this.config?.options;
    this.onWarning = this.options.onWarning;
    this.setFieldsForPagination();


  }

  setFieldsForPagination() {
    if(this.onWarning.length === 0) {
      return;
    }
    if (this.onWarning.length <= this.sliceSize) {
      this.slicedfields.push(this.onWarning);
      return;
    }
    while (this.onWarning.length> 0 ) {
      this.slicedfields.push(this.onWarning.splice(0, this.sliceSize));
    }
    this.propertyPager =`${this.currentPage + 1} of ${this.slicedfields.length}`;

  }

  previous() {
    this.currentPage -= 1;
    this.propertyPager =`${this.currentPage + 1} of ${this.slicedfields.length}`;
  }
  next() {
    this.currentPage += 1;
    this.propertyPager =`${this.currentPage + 1} of ${this.slicedfields.length}`;
  }

  onOkClick() {
    this.onClickClose(BusinessRuleWarningComponent.OK);
  }

  onCancelClicked() {
    this.onClickClose(BusinessRuleWarningComponent.CANCEL);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      this.closed.next(closeResult);
    });
  }

}
