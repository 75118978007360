import {Component, NgZone, ViewContainerRef} from '@angular/core';
import {ParentModalComponent} from "../parentModalComponent";

@Component({
  selector: 'app-duplicate-property-sent',
  templateUrl: './duplicate-property-sent.component.html',
  styleUrls: ['./duplicate-property-sent.component.scss', '../modal.component.scss']
})
export class DuplicatePropertySentComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  agentName = "another agent";
  editedTime = "";

  constructor(vcr: ViewContainerRef, zone: NgZone) {
    super();
    this.vcr = vcr;
    this.zone = zone;
  }

  updateConfig() {
    this.agentName = this.config.options.agentName;
    this.editedTime = this.config.options.editedTime;
  }

  onOkClick() {
    this.onClickClose(DuplicatePropertySentComponent.OK);
  }

  onCancelClicked() {
    this.onClickClose(DuplicatePropertySentComponent.CANCEL);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      this.closed.next(closeResult);
    });
  }
}
