import {
  Component,
  HostBinding,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Input,
  OnDestroy,
  ElementRef,
  ViewChildren,
  QueryList,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime } from "rxjs/operators";

import * as JsUtils from 'src/app/utils/jsUtils';
import {ApiService, ProFormaCalculator, UnderwriterService } from 'src/app/services';
import { ProFormaModel } from 'src/app/models/proformaModel';
import { ListingProperty } from 'src/app/services/data/listingPropety';
import { ProForma } from 'src/app/services/data/proforma';
import { IConditionScoreData, IProformaRow, ITargetOption } from './proforma';
import { ProformaConfig, PROFORMA_FIELDS_IDS, VALIDATION_ERROR_TYPE } from './proforma_config';
import { PropertyStatusType } from 'src/app/services/data/propertyStatusType';
import { IUpdateRequest } from 'src/app/services/update-request-service/model/updateRequest';
import { NumericInputComponent } from 'src/app/ui';
import { animate, style, transition, trigger } from '@angular/animations';
import { IProformaVersionModel, PROFORMA_VERSION_SOURCE } from 'src/app/services/activityLogService/proformaVersioningService';
import { FeatureFlagsService } from 'src/app/services/featureFlagsService/feature-flags.service';
import { ActivityLogService } from 'src/app/services/activityLogService/activity-log.service';
import { ViewMode } from 'src/app/ui/view-setting/viewSettingModel';
import { UpdateRequestService } from 'src/app/services/update-request-service/update-request.service';

const MIN_NET_YIELD = 0.03;

@Component({
  selector: 'app-proforma-panel',
  styleUrls: ['proforma.component.scss'],
  templateUrl: 'proforma.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openCloseExtraFields', [
      transition(':leave', [
        animate('200ms ease-out', style({ height: 0, opacity: 0 }))
      ]),
      transition(':enter', [
        animate('100ms ease-in', style({ height: '*',  opacity: 1 }))
      ]),
    ])
  ],
})
export class ProformaPanelComponent implements OnDestroy, OnInit {
  @HostBinding('class') class = 'a1-proforma';

  // @ViewChild on ID (i.e. `'monthlyRentAmount'`) doesn't work here even if populated (`#{pc.id}`) on the template element.
  // using `@ViewChildren` + lookup instead.
  @ViewChildren(NumericInputComponent) numericInputs: QueryList<any>;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.theForm.disable({ emitEvent: false }) : this.theForm.enable({ emitEvent: false });
  }
  private _disabled = false;

  @Output() changed: EventEmitter<any> = new EventEmitter();

  @Output() onUpdateRequested: EventEmitter<any> = new EventEmitter();

  @Output() concessionConfigChanges: EventEmitter<any> = new EventEmitter();

  @Output() proformaHistoryExpanded: EventEmitter<any> = new EventEmitter();
  @Input()
  get editLocked(): boolean {
    return this._editLocked;
  }
  set editLocked(editLocked: boolean) {
    this._editLocked = editLocked;
  }

  @Input() loading: boolean = true;

  private _proformaHistory = null;
  hasProformaHistory = false;

  @Input()
  get proformaHistory() {
    return this._proformaHistory;
  }

  set proformaHistory(value) {
    this._proformaHistory = value;

    if (this._proformaHistory && this._proformaHistory.length> 1) {
      this.hasProformaHistory = true;

    }

    this.buildAvmProformaHistory();
  }

  proformaHistoryLabel = 'Show History';

  isLoadingHistory = false;
  @Input() isInvestorExport = false;

  private _editLocked: boolean;

  private _selectedPurchasingEntityId: boolean;

  private rehabBudgetFlowNames = ['PreInspectionReviewFlow', 'InspectionReviewFlow']

  @Input()
  get selectedPurchasingEntityId(): any {
    return this._selectedPurchasingEntityId;
  }
  set selectedPurchasingEntityId(selectedPurchasingEntityId: any) {
    this._selectedPurchasingEntityId = selectedPurchasingEntityId;
    this.onPurchasingEntityChange(false);
  }

  @Input()
  get selectedRentCompsAvg(): number {
    return this._selectedRentCompsAvg;
  }
  set selectedRentCompsAvg(selectedRentCompsAvg: number) {
    this._selectedRentCompsAvg = selectedRentCompsAvg;
    this.onSelectedRentCompsAvgChange();
  }

  @Input() viewMode: ViewMode;

  private _selectedRentCompsAvg = 0;

  property: ListingProperty = null;
  avmProforma: ProFormaModel = null;
  uwProforma: ProFormaModel = null;

  theForm: FormGroup;

  originalProforma: ProForma;

  conditionScorePanelExpanded = false;

  conditionsGrid: IConditionScoreData[] = [];

  private initializing = true;
  private subscriptions: Subscription[] = [];

  proforma_basic_config: ProformaConfig;

  rent: IProformaRow[];

  forceUpdate: boolean = false;

  avmProformaVersioning: {
    [key: string]: ProFormaModel
  } = {};

  avmProformaVersioningOptions = [
    { id: 'avmProforma', label: "AVM Pro Forma" },
  ];

  avmProformaVersioningSelectedId;

  versioning = false;

  updateRequested = false;

  proformaUnderwrittenStatus = 'Underwritten';

  styleVersionForExtraFields = false;

  revenue: IProformaRow[];

  expenses: IProformaRow[];

  assetPurchase: IProformaRow[];

  extraFields: IProformaRow[];

  requiredFields: IProformaRow[];

  shouldNotTriggerUWCalculationFieldsIds: string[];

  shouldNotOverwriteFields: string[];

  targetConditionButtonOptions: ITargetOption[];

  dependenciesRules: any;

  allFields: IProformaRow[];

  userEnableConcession = false;

  updateRequest: IUpdateRequest;

  editTargetConditionScore = false;

  minNetYield: {value: number, display: string};
  minCapRate: {value: number, display: string};
  rehabWeight: number = 1.15;

  targetConditionScore: number;

  validationFlag: any = {};
  errorLevelFlag: any;

  showProformaHistoryExpanded = false;
  avmProformaHistory = [];

  triggerProformaHistory(close?) {
    if (close) {
      this.showProformaHistoryExpanded = false;
      this.proformaHistoryExpanded.emit('hh');
      this.proformaHistoryLabel = 'Show History';
      this.avmProformaHistory = [];
      return;
    }

    if (!this.showProformaHistoryExpanded) {
      this.showProformaHistoryExpanded = !this.showProformaHistoryExpanded;
      this.proformaHistoryLabel = 'Hide History';
      this.refreshAndBuildProformaHistory(false);
    } else {
      this.proformaHistoryExpanded.emit('hh');
      this.proformaHistoryLabel = 'Show History';
      setTimeout(() => {
        this.showProformaHistoryExpanded = !this.showProformaHistoryExpanded;
      }, 400);
    }
  }

  refreshAndBuildProformaHistory(init= true) {
    this.underwriterService.getListings(
      {
        viewMode: this.viewMode,
        buyBoxId: null,
        groupKeys: [this.property.AOPropertyID],
        dateRange: null,
        viewSetting: null,
        fromLine: 0,
        filters: null,
        sort: null,
        update: false,
        fetchTMUser: false
      }
    ).then((data) => {
      this._proformaHistory = data.listings;
      this.buildAvmProformaHistory(init);
    });
  }


  buildAvmProformaHistory(init= true) {
    this.avmProformaHistory = [];
    if(!init) {
      this.isLoadingHistory = true;
    }
    if (!this._proformaHistory || this._proformaHistory.length === 0 || !this.property) {
      return;
    }

    const isLoadMatch = this._proformaHistory.filter(pfItem => {
      return pfItem?.AOPropertyID != this.property.AOPropertyID;
    });

    if (!isLoadMatch) {
      return;
    }
    if (this._proformaHistory && this._proformaHistory.length) {
      const filtredProformaHistory = this._proformaHistory.filter((ph) => {
        return ph.AOListingID != this.property.AOListingID;
      });

      const proformaHistoryToBuild = _.orderBy(filtredProformaHistory.slice(0,2),'pf.updateDate', 'desc');
      const historyCount = proformaHistoryToBuild.length;

      let fetchedHistory = 0;
      proformaHistoryToBuild.forEach(pf => {
        if (pf.underwritingStatus == 1) {
          const avm = new ProFormaModel();
          avm.proforma= new ProForma();

          this.proFormaCalculator.calculate(pf, avm, true, false);
          const versionItem = this.initProformaHistoryConfig(pf, avm, 0);

          this.avmProformaHistory.push(versionItem);
          ++fetchedHistory;
          if (fetchedHistory === historyCount) {
            setTimeout(() => {
              this.isLoadingHistory = false;
            }, 400);
          }
          if (this.showProformaHistoryExpanded) {
            this.proformaHistoryExpanded.emit(this.avmProformaHistory.length === 1? 'sh1' : 'sh2');
          }

        } else if (![PropertyStatusType.Hidden, PropertyStatusType.Underwritten].includes(pf.underwritingStatus)) {

          this.activityLogService.getAuditHistory(pf.AOListingID, PROFORMA_VERSION_SOURCE.FROM_PROFORMA, false).then((data) => {
            ++fetchedHistory;
            if (fetchedHistory === historyCount) {
              setTimeout(() => {
                this.isLoadingHistory = false;
              }, 400);
            }
            if (data && data.length && (data[0].AOListingID == pf.AOListingID)) {

              const avm = this.buildProformaHistory(data[0], pf);
              const versionItem = this.initProformaHistoryConfig(pf, avm, 0);
              this.avmProformaHistory.push(versionItem);

              if (this.showProformaHistoryExpanded) {
                this.proformaHistoryExpanded.emit(this.avmProformaHistory.length === 1? 'sh1' : 'sh2');
              }
            }
          });
        } else {
          this.underwriterService.getProforma(pf.AOListingID).then((uwPf) => {
            ++fetchedHistory;
            if (fetchedHistory === historyCount) {
              setTimeout(() => {
              this.isLoadingHistory = false;
            }, 400);
            }

            let avm;
            let versionItem;
            if (uwPf && uwPf.proforma && (uwPf.proforma.AOListingID == pf.AOListingID)) {
              if (!uwPf.originalProforma) {
                avm = new ProFormaModel();
                avm.proforma= new ProForma();

                this.proFormaCalculator.calculate(pf, avm, true, false);
                versionItem = this.initProformaHistoryConfig(pf, avm, 0);
              } else {
                avm = this.buildProformaHistory(uwPf.proforma, pf);
                versionItem = this.initProformaHistoryConfig(pf, avm, 1);
              }

              this.avmProformaHistory.push(versionItem);
              if (this.showProformaHistoryExpanded) {
                this.proformaHistoryExpanded.emit(this.avmProformaHistory.length === 1? 'sh1' : 'sh2');
              }
            }
          });
        }
      });
    }

  }

  initProformaHistoryConfig(pf, avm, source) {
    const proforma_basic_config = new ProformaConfig(this.isInvestorExport);
    const rent = proforma_basic_config.rent;
    const revenue = proforma_basic_config.revenue;
    const expenses = proforma_basic_config.expenses;
    const assetPurchase = proforma_basic_config.assetPurchase;
    let extraFields = proforma_basic_config.extraFields;
    const filled = proforma_basic_config.statusFields.some(sfield => !_.isNil(avm.proforma[sfield.id]));
    if (filled) {
      extraFields = [...extraFields, ...proforma_basic_config.statusFields];
    }

    this.bindRowsForHistory(rent, avm, source);
    this.bindRowsForHistory(revenue, avm, source);
    this.bindRowsForHistory(expenses, avm, source);
    this.bindRowsForHistory(assetPurchase, avm, source);
    this.bindRowsForHistory(extraFields, avm, source);

    const finalVersion = {
      pf,
      avm,
      isInvestorExport: this.isInvestorExport,
      rent,
      revenue,
      expenses,
      assetPurchase,
      extraFields
    };
    return finalVersion;

  }


  buildProformaHistory(proformaHistory, listing) {
    const avmProformaBasic = new ProFormaModel();
    avmProformaBasic.proforma= new ProForma();

    this.proFormaCalculator.calculate(listing, avmProformaBasic, true, false);
    const proformaHistoryItem = proformaHistory;

    const entries= Object.keys(proformaHistoryItem);
    entries.forEach(field => {
      if (field != undefined) {
        avmProformaBasic.proforma[field] = proformaHistoryItem[field];
      }
    });

    if ([PropertyStatusType.Hidden, PropertyStatusType.Underwritten].includes(listing.underwritingStatus) && !proformaHistory.userOverwrittenFields) {
      proformaHistory.userOverwrittenFields = [];
      ['buyerPaidCommission','monthlyRentAmount', 'taxesAmount', 'hoaAmount', 'priceOfferAmount', 'priceOfferAmount', 'repairBudgetAmount', 'highestPurchasePriceOffer', 'expectedResaleValue'].forEach((fieldName) => {
        if (avmProformaBasic.proforma[fieldName] != proformaHistory[fieldName]) {
          proformaHistory.userOverwrittenFields.push(fieldName);
        }
      });
    }

    return avmProformaBasic;
  }


  initStatusFields() {
    const filled = this.proforma_basic_config.statusFields.some(sfield => !_.isNil(this.uwProforma.proforma[sfield.id]));

    if (/*[PropertyStatusType.OfferReady, PropertyStatusType.OfferReadyReason].includes(this.property.underwritingStatus) ||*/ filled) {
      this.extraFields =[...this.proforma_basic_config.extraFields, ...this.proforma_basic_config.statusFields];

      this.allFields =[...this.rent, ...this.revenue, ...this.expenses, ...this.assetPurchase, ...this.extraFields, ...this.proforma_basic_config.statusFields];

      this.shouldNotTriggerUWCalculationFieldsIds = [... this.shouldNotTriggerUWCalculationFieldsIds, ...this.proforma_basic_config.statusFields.map(field => {
        return field.id;
      })];

      this.proforma_basic_config.statusFields.forEach(sfield => {
        this.theForm.addControl(sfield.id, new FormControl('',[]));
      });

    } else {
      this.proforma_basic_config.statusFields.forEach(sfield => {
        if(this.uwProforma.proforma[sfield.id]) {
          this.uwProforma.proforma[sfield.id] = null;
        }
      });
      this.extraFields = this.proforma_basic_config.extraFields;
      this.allFields =[...this.rent, ...this.revenue, ...this.expenses, ...this.assetPurchase, ...this.extraFields];
      this.shouldNotTriggerUWCalculationFieldsIds = this.proforma_basic_config.shouldNotTriggerUWCalculationFieldsIds;

      this.proforma_basic_config.statusFields.forEach(sfield => {
        this.theForm.removeControl(sfield.id);
      });
    }
  }


  triggerStatusFields(action) {
    this.userEnableConcession = true;

    if (action) {
      this.extraFields =[...this.proforma_basic_config.extraFields, ...this.proforma_basic_config.statusFields];

      this.allFields =[...this.rent, ...this.revenue, ...this.expenses, ...this.assetPurchase, ...this.extraFields];

      this.shouldNotTriggerUWCalculationFieldsIds = [... this.shouldNotTriggerUWCalculationFieldsIds, ...this.proforma_basic_config.statusFields.map(field => {
        return field.id;
      })];

      this.proforma_basic_config.statusFields.forEach(sfield => {
        this.theForm.addControl(sfield.id, new FormControl('',[]));
      });
      setTimeout(() => {
        this.scrollAndFocusToFields(this.proforma_basic_config.statusFields);
        this.uwProforma.isDirty = _.some(Object.keys(this.originalProforma), (k) => {
          if(k != 'userOverwrittenFields') {
            return this.originalProforma[k] != this.uwProforma.proforma[k];
           }
           return false;
        });
        if (this.uwProforma.isDirty) {
          this.changed.emit(false);
        }
      },300);

    } else {
      this.proforma_basic_config.statusFields.forEach(sfield => {
        if(!_.isNil(this.uwProforma.proforma[sfield.id])) {
          this.uwProforma.proforma[sfield.id] = null;
        }
      });
      this.extraFields = this.proforma_basic_config.extraFields;
      this.allFields =[...this.rent, ...this.revenue, ...this.expenses, ...this.assetPurchase, ...this.extraFields];
      this.shouldNotTriggerUWCalculationFieldsIds = this.proforma_basic_config.shouldNotTriggerUWCalculationFieldsIds;

      setTimeout(() => {
        this.uwProforma.isDirty = _.some(Object.keys(this.originalProforma), (k) => {
          if(k != 'userOverwrittenFields') {
            return this.originalProforma[k] != this.uwProforma.proforma[k];
           }
           return false;
        });
        if (this.uwProforma.isDirty) {
          this.changed.emit(false);
        }
      },300);

      this.proforma_basic_config.statusFields.forEach(sfield => {
        this.theForm.removeControl(sfield.id);
      });
    }

    this.initDepenndencies();
    this.subscribeFormChangedEvent();
    this.updateUnderwriterNote(action);
  }

  updateUnderwriterNote(action?) {
    if (![
          PropertyStatusType.InspectionReview,
          PropertyStatusType.InvestorApprovalUrgent,
          PropertyStatusType.InvestorApprovalUpdated,
      ].includes(this.property?.status)) {
      this.concessionConfigChanges.emit({concessionChanges: false});
      return;
    }

    const allFieldsIds = this.allFields.map(fd => fd.id);
    const isConcessionDirty = allFieldsIds.filter(fId => {
      return [PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION as string, PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION as string].includes(fId);
    });
    this.concessionConfigChanges.emit({
      concessionChanges: !!isConcessionDirty.length || action
    });
  }

  constructor(
    private fb: FormBuilder,
    private proFormaCalculator: ProFormaCalculator,
    private ref: ElementRef,
    private featureFlagsService: FeatureFlagsService,
    private activityLogService: ActivityLogService,
    private underwriterService: UnderwriterService,
    private apiService: ApiService,
    private updateRequestService: UpdateRequestService,
    ) {

    // !! When adding editable fields here, be sure to update clearProformaForRecalculation()
    this.theForm = fb.group({
      expectedResaleValue: ['', []],
      targetConditionScore: ['', [Validators.required]],
      monthlyRentAmount: ['', [Validators.required]],
      vacancyAllowanceAmount: ['', [Validators.required]],
      creditLossAmount: ['', [Validators.required]],
      taxesAmount: ['', [Validators.required]],
      propertyManagementFeesAmount: ['', [Validators.required]],
      maintenanceTurnoverAmount: ['', [Validators.required]],
      hoaAmount: ['', [Validators.required]],
      leasingCostsAmount: ['', [Validators.required]],
      priceOfferAmount: ['', [Validators.required]],
      repairBudgetAmount: ['', [Validators.required]],
      acquisitionCostAmount: ['', [Validators.required]],
      stabilizationFeeAmount: ['', [Validators.required]],
      highestPurchasePriceOffer: ['', [Validators.required]],
      purchasingEntity: ['', []],
      buyerPaidCommission: ['', []],
    });
    // !! When adding editable fields here, be sure to update clearProformaForRecalculation()
  }

  ngOnDestroy() {
    this.unsubscribeFormChangedEvent();
  }

  async ngOnInit() {
    this.initProformaBasicConfig();
    await this.buildValidationAndErrorsLevelFlags();
  }

  async buildValidationAndErrorsLevelFlags() {
    const flags = await this.featureFlagsService.getFeatureFlag('aom-2232');
    if (flags && flags.enabled) {
      delete flags.enabled;
      Object.keys(flags || {}).forEach(flag => {
        this.validationFlag[flag] = JSON.parse(flags[flag]);
      });
    }

    const errorLevels = await this.featureFlagsService.getFeatureFlag('aom-2876');
    this.errorLevelFlag = (errorLevels && errorLevels.enabled)? errorLevels.level : null;

  }

  initProformaBasicConfig() {
    this.proforma_basic_config = new ProformaConfig(this.isInvestorExport);
    this.rent = this.proforma_basic_config.rent;
    this.revenue = this.proforma_basic_config.revenue;
    this.expenses = this.proforma_basic_config.expenses;
    this.assetPurchase = this.proforma_basic_config.assetPurchase;
    this.extraFields = this.proforma_basic_config.extraFields;
    this.requiredFields = this.proforma_basic_config.requiredFields;
    this.shouldNotTriggerUWCalculationFieldsIds = this.proforma_basic_config.shouldNotTriggerUWCalculationFieldsIds;
    this.shouldNotOverwriteFields = this.proforma_basic_config.shouldNotOverwriteFields;
    this.targetConditionButtonOptions = this.proforma_basic_config.targetConditionButtonOptions;
    this.dependenciesRules = this.proforma_basic_config.dependenciesRules;
    this.allFields = [...this.rent, ...this.revenue, ...this.expenses, ...this.assetPurchase, ...this.extraFields];
    this.avmProformaVersioningSelectedId = this.proforma_basic_config.availableVersions.avmProforma;
  }

  subscribeFormChangedEvent() {
    this.unsubscribeFormChangedEvent();

    _.each(Object.keys(this.originalProforma), (k) => {
      const ctrl = this.theForm.get(k);
      if (ctrl) {
        this.subscriptions.push(ctrl.valueChanges
          .pipe(debounceTime(400))
          .subscribe((event) => {
          this.onValueChanges(k, event);
        }));
      }
    });
  }

  unsubscribeFormChangedEvent() {
    _.each(this.subscriptions, (s) => s.unsubscribe());
    this.subscriptions = [];
  }

  async initialize(property: ListingProperty, avmProforma: ProFormaModel, uwProforma: ProFormaModel, updateRequestes: IUpdateRequest, firstLoading, versioning?: IProformaVersionModel) {

    try {
      this.proforma_basic_config = new ProformaConfig(this.isInvestorExport);
      this.initializing = true;

      this.property = property;
      this.avmProforma = avmProforma;
      this.updateRequest = updateRequestes;
      const lockProforma = !!updateRequestes?.activeStep?.proforma?.lockProforma;
      this.forceUpdate = _.get(updateRequestes, 'activeStep.proforma.forceUpdate', true);

      this.property.MinNetYield = this.property.MinNetYield ?? MIN_NET_YIELD;

      if (!lockProforma || this.forceUpdate) {
        this.uwProforma = uwProforma;
        this.updateRequested = false;
        this.proformaUnderwrittenStatus = 'Underwritten';
      } else {
        this.uwProforma = new ProFormaModel(); // for rehab epic
        this.uwProforma.proforma = new ProForma(); // for rehab epic
        this.updateRequested = true;
        this.targetConditionScore = uwProforma?.proforma?.targetConditionScore;
        // this.proformaUnderwrittenStatus = updateRequestes.displayName;
      }

      this.uwProforma.proforma.AOListingID = uwProforma.proforma.AOListingID;
      this.initStatusFields();

      const handlePreUserOverwrittenFields = this.canHandlePreUserOverwrittenFields(uwProforma);
      this.initializeConditionsGrid();

      // this.originalProforma = _.cloneDeep(this.uwProforma.proforma);

      this.proFormaCalculator.calculate(this.property, this.avmProforma, true, false);

      // initialize purchasingEntity
      this.uwProforma.userInputProforma['purchasingEntity'] = this.uwProforma.proforma['purchasingEntity'];

      if (versioning && !_.isEmpty(versioning)) {
        this.buildProformaVersion(versioning);
        this.versioning = true;
        this.checkPreviousVersion(updateRequestes);

      } else {
        this.versioning = false;
        this.avmProformaVersioningSelectedId = this.proforma_basic_config.availableVersions.avmProforma;
      }

      if (!!updateRequestes?.activeStep?.actions?.sendTM?.versioning?.active && this.updateRequest?.flowName !== 'OfferReadyFlow') {
        this.proformaUnderwrittenStatus = _.startCase(updateRequestes.activeStep.actions.sendTM.versioning.versionName);
      }
      if ((this.uwProforma.proforma.id || this.uwProforma.isEdited()) && (!this.updateRequested || this.forceUpdate)) {
        this.proFormaCalculator.calculate(this.property, this.uwProforma, false, false);
      }
      if (this.uwProforma.proforma.modelUpload) {
        this.uwProforma.proforma = Object.keys(this.uwProforma.originalProforma).reduce((mergedObj, key) => {
          mergedObj[key] = this.uwProforma.originalProforma[key] ? this.uwProforma.originalProforma[key] : this.uwProforma.proforma[key];
          return mergedObj;
        }, this.uwProforma.proforma);
      }

      // Handle the Proforma saved before we track the user's fields overwrite
      // this will highglight the edit boxes that were overriden at the time
      if (handlePreUserOverwrittenFields && (!this.updateRequested || this.forceUpdate)) {
        // always set targetConditionScore
        this.uwProforma.userInputProforma['targetConditionScore'] = this.uwProforma.proforma['targetConditionScore'];

        // Only if different
        ['buyerPaidCommission', 'monthlyRentAmount', 'taxesAmount', 'hoaAmount', 'priceOfferAmount', 'priceOfferAmount', 'repairBudgetAmount', 'highestPurchasePriceOffer', 'expectedResaleValue'].forEach((fieldName) => {
          if (this.avmProforma.proforma[fieldName] != this.uwProforma.proforma[fieldName]) {
            this.uwProforma.userInputProforma[fieldName] = this.uwProforma.proforma[fieldName];
          }
        });
      }

      if (this.uwProforma.initialProforma) {
        this.originalProforma = _.cloneDeep(this.uwProforma.initialProforma);
      } else {
        this.originalProforma = _.cloneDeep(this.uwProforma.proforma);
        this.uwProforma.initialProforma = this.originalProforma;
      }

      if (!this.updateRequested || this.forceUpdate) {
        this.theForm.get('expectedResaleValue').setValue(this.uwProforma.proforma.expectedResaleValue, {emitEvent: false});
        this.theForm.get('targetConditionScore').setValue(this.uwProforma.proforma.targetConditionScore, {emitEvent: false});
        this.theForm.get('highestPurchasePriceOffer').setValue(this.uwProforma.proforma.highestPurchasePriceOffer, {emitEvent: false});
      }

      this.bindRows(this.rent);
      this.bindRows(this.revenue);
      this.bindRows(this.expenses);
      this.bindRows(this.assetPurchase);
      this.bindRows(this.extraFields, true);
      this.styleVersionForExtraFields = this.extraFields.some(exField => exField.userOverwriteVersion);

      this.initializing = false;

      this.initDepenndencies();

      this.subscribeFormChangedEvent();

      this.initTargetConditionScore(lockProforma);

      const proformaRequestConf = this.updateRequest.activeStep.proforma;
      if (proformaRequestConf && (proformaRequestConf.lockProforma || proformaRequestConf.editLocked) && proformaRequestConf.editLockOverrides) {
        this.unlockFields(proformaRequestConf.editLockOverrides || []);
      }
      this.updateUnderwriterNote();

      if (firstLoading) {
        this.triggerProformaHistory(true);
      }

      this.buildAvmProformaHistory();

      if (this.property.minYieldRate) {
        this.minNetYield = {value: this.property.minYieldRate , display: JsUtils.formatPercent2(this.property.minYieldRate )};
      } else {
        this.minNetYield = null;
      }
  
      if (this.property.minCapRate) {
        this.minCapRate = {value: this.property.minCapRate , display: JsUtils.formatPercent2(this.property.minCapRate )};
      } else {
        this.minCapRate = null
      }

    } catch (e) {
      console.log(e);
    }
    setTimeout(() => {
    });

  }

  initTargetConditionScore(lockProforma: boolean, targetConditionScore = 4) {
    if(!this.uwProforma.proforma[PROFORMA_FIELDS_IDS.TARGET_CONDITION_SCORE]
    && this.property.underwritingStatus < PropertyStatusType.Underwritten
    && (!lockProforma || this.forceUpdate)
    ) {
      this.theForm.get(PROFORMA_FIELDS_IDS.TARGET_CONDITION_SCORE).setValue(targetConditionScore, { emitEvent: false });
      this.onValueChanges(PROFORMA_FIELDS_IDS.TARGET_CONDITION_SCORE, null);
      this.uwProforma.automaticUpdate = true;
    }
  }

  canHandlePreUserOverwrittenFields(uwProforma: ProFormaModel) {
    let handlePreUserOverwrittenFields = false;
    if (!this.uwProforma.userInputProforma) {
      this.uwProforma.userInputProforma = new ProForma();
      this.uwProforma.userInputProforma.id = uwProforma.proforma.id;
      this.uwProforma.userInputProforma.AOListingID = uwProforma.proforma.AOListingID;

      // Based on the list of overridden fields, get data and assigned to userInputProforma;
      // Then reset the proforma (which came from DB with all fields) to restart calculations (calculation formulas might have changed)
      if (JsUtils.isNullOrEmpty(uwProforma.proforma.userOverwrittenFields)) {
        handlePreUserOverwrittenFields = true;
      } else if (!this.updateRequested || this.forceUpdate) {
        _.each(uwProforma.proforma.userOverwrittenFields, (overwrittenField) => {
          this.uwProforma.userInputProforma[overwrittenField] = uwProforma.proforma[overwrittenField];
        });

        this.clearProformaForRecalculation(this.uwProforma.proforma);

        const allFieldsIds = this.allFields.map(fd => fd.id);
        const fieldToInitialize = Object.keys(this.uwProforma.userInputProforma).filter(fd=> allFieldsIds.includes(fd));
        fieldToInitialize.splice(fieldToInitialize.indexOf('investorApproval'), 1);
        fieldToInitialize.splice(fieldToInitialize.indexOf('purchasingEntity'), 1);
        _.each(fieldToInitialize, (k) => {
          this.uwProforma.proforma[k] = this.uwProforma.userInputProforma[k];
        });
      }
    }
    return handlePreUserOverwrittenFields;
  }

  checkPreviousVersion(updateRequestes: IUpdateRequest) {
    if (!!updateRequestes?.activeStep?.actions?.sendTM?.versioning?.previousVersion) {
      const previousVersion = this.avmProformaVersioningOptions.find(versionOption => {
        return versionOption.id.includes(updateRequestes.activeStep.actions.sendTM.versioning.previousVersion);
      });

      if (previousVersion) {
        this.avmProformaVersioningSelectedId = previousVersion.id;
        this.onVersionChanges(previousVersion.id);
      }
    }
  }

  checkIfNetYieldWithin(netYield) {
    return netYield ? this.minNetYield?.value <= netYield : true;
  }

  checkIfCapRateWithin(capRate) {
    return capRate ? this.minCapRate?.value <= capRate : true;
  }

  buildProformaVersion(versioning: IProformaVersionModel) {
    this.buildVersioningFromAuditLog(versioning);
  }


  buildVersioningFromAuditLog(versioning: IProformaVersionModel) {
    this.avmProformaVersioningOptions = [];
    this.avmProformaVersioning = {};
    this.avmProformaVersioning[this.proforma_basic_config.availableVersions.avmProforma] = _.cloneDeep(this.avmProforma);

    const avmProformaBasic = _.cloneDeep(this.avmProforma);

    this.avmProformaVersioningSelectedId = this.proforma_basic_config.availableVersions.avmProforma;

    const versionGroupKey = Object.keys(versioning.data);

    if(versionGroupKey.includes(this.proforma_basic_config.availableVersions.OfferReady)) {
      this.proformaUnderwrittenStatus = _.startCase(this.proforma_basic_config.availableVersions.OfferReady);
    }

    versionGroupKey.forEach(versionGroup => {
      const versioningReverse = versioning.source === PROFORMA_VERSION_SOURCE.FROM_AUDIT_LOG? versioning.data[versionGroup].reverse() : versioning.data[versionGroup];
    versioningReverse.forEach((vk, index) => {

      for (const key in vk) {
        if (key != undefined) {
          avmProformaBasic.proforma[key] = versioningReverse[index][key];
        }
      }

      const nextVersion = index > 0 ? ` - ${index}` : '';
      const proformaNextVersion = this.avmProformaVersioning[versionGroup + nextVersion] ? this.avmProformaVersioning[versionGroup + nextVersion].proforma : undefined;
      if (!this.avmProformaVersioning[versionGroup + nextVersion] || !_.isEqual(proformaNextVersion, avmProformaBasic.proforma)) {
        this.avmProformaVersioning[versionGroup + nextVersion] = _.cloneDeep(avmProformaBasic);

        if(!this.avmProformaVersioningOptions.find(vOption => {
          return vOption.id == versionGroup + nextVersion;
        })) {

          this.avmProformaVersioningOptions.push(
            {
              id: versionGroup + nextVersion,
              label:  (_.startCase(versionGroup) + nextVersion)
            }
          );

        }
      }

    });

  });

  this.avmProformaVersioningOptions.push({ id: 'avmProforma', label: "AVM Pro Forma" });
  this.avmProformaVersioningOptions = this.avmProformaVersioningOptions.reverse();

  }


  targetChange($event) {
    this.theForm.get('targetConditionScore').setValue($event);
  }


  onVersionChanges($event) {
    if (this.avmProformaVersioning[$event]) {
      this.avmProforma = this.avmProformaVersioning[$event];
      this.avmProformaVersioningSelectedId = $event;

      this.bindRows(this.rent);
      this.bindRows(this.revenue);
      this.bindRows(this.expenses);
      this.bindRows(this.assetPurchase);
      this.bindRows(this.extraFields, true);
      this.styleVersionForExtraFields = this.extraFields.some(exField => exField.userOverwriteVersion);
    }
  }


  unlockFields (fieldsToEdit: string[], proformaFields?: IProformaRow[]) {
    if((fieldsToEdit || []).includes('targetConditionScore')) {
      this.editTargetConditionScore = true;
    } else {
      this.editTargetConditionScore = true;
    }

    let fieldsToUnlock: IProformaRow[];
    if(proformaFields) {
      fieldsToUnlock = proformaFields;
    } else {
      fieldsToUnlock = [...this.rent, ... this.revenue, ...this.expenses, ...this.assetPurchase, ...this.extraFields];
    }
    fieldsToUnlock.forEach(row => {
      if(fieldsToEdit.includes(row.id)) {
        row.forceEditing = true;
      } else {
        row.forceEditing = false;
      }
    });

  }


  canDisplayExtraFieldsUnderwriten() {
   return !this.isInvestorExport && this.avmProformaVersioningSelectedId!= 'avmProforma' &&
    this.extraFields.some((exField => {
     return (this.avmProformaVersioning[this.avmProformaVersioningSelectedId]
      && this.avmProformaVersioning[this.avmProformaVersioningSelectedId].proforma
      && (
        (this.avmProformaVersioning[this.avmProformaVersioningSelectedId].proforma[exField.id] != this.uwProforma.proforma[exField.id])
        ||
        !_.isNil(this.avmProformaVersioning[this.avmProformaVersioningSelectedId].proforma[exField.id])
      )
      );
    }));

  }


  initializeConditionsGrid() {
    let repairs;
    let rents;
    let capRates;

    if (this.conditionsGrid.length == 0) {
      repairs = {
        label: 'Est. Rehab Cost',
        values: []
      } as IConditionScoreData;
      rents = {
        label: 'AVM Rent',
        values: []
      } as IConditionScoreData;
      capRates = {
        label: 'Cap Rate',
        values: []
      } as IConditionScoreData;

      this.conditionsGrid.push(repairs);
      this.conditionsGrid.push(rents);
      this.conditionsGrid.push(capRates);
    } else {
      repairs = this.conditionsGrid[0];
      rents = this.conditionsGrid[1];
      capRates = this.conditionsGrid[2];

      repairs.values = [];
      rents.values = [];
      capRates.values = [];
    }

    for (let i = 0; i < 6; i++) {
      const propertyCopy = new ListingProperty();
      const model = new ProFormaModel();
      model.proforma = new ProForma();

      Object.assign(propertyCopy, this.property);
      propertyCopy.TargetConditionScore = i + 1;

      this.proFormaCalculator.calculate(propertyCopy, model, true, true);

      repairs.values.push(JsUtils.formatCurrency(model.proforma.repairBudgetAmount, 0));
      repairs.values.push(JsUtils.formatCurrency(model.proforma.buyerPaidCommission, 0));
      rents.values.push(JsUtils.formatCurrency(model.proforma.monthlyRentAmount, 0));
      capRates.values.push(JsUtils.formatPercent2(model.proforma.capRate));
    }
  }

  async onPurchasingEntityChange(isUpdate, selectedOption?) {
    if (!this.initializing) {
      if (isUpdate) {
        if (selectedOption) {
          if (
            selectedOption
            && selectedOption[0]
          ) {
            if (
              this.originalProforma['purchasingEntity'] != selectedOption[0].label.toLocaleString()
              && (
                (!this.originalProforma['purchasingEntity'] && selectedOption[0]?.label.toLocaleString() != 'Auto-assign')
                || (this.originalProforma['purchasingEntity'])
              )
            ) {
              this.uwProforma.proforma['purchasingEntity'] = (selectedOption[0]?.label.toLocaleString() == 'Auto-assign') ? null : selectedOption[0].label.toLocaleString();
              this.uwProforma.userInputProforma['purchasingEntity'] = this.uwProforma.proforma['purchasingEntity'];
            } else {
              this.uwProforma.proforma['purchasingEntity'] = this.originalProforma['purchasingEntity'];
            }

            const UQResponse = await this.underwriterService.queryUQAPI(
              this.property.AOListingID,
              this.uwProforma.proforma['purchasingEntity'],
              this.property.Region,
              this.property.UQClassification
            );
            if (Object.keys(UQResponse).length) {
              this.property.minYieldRate = UQResponse.minYieldRate;
              this.property.minCapRate = UQResponse.minCapRate;
              this.minNetYield = {value: UQResponse.minYieldRate, display: JsUtils.formatPercent2(UQResponse.minYieldRate)};
              this.minCapRate = {value: UQResponse.minCapRate, display: JsUtils.formatPercent2(UQResponse.minCapRate)};

              this.property.renovationFeePercent = UQResponse.renovationFeePercent;
              const updatedActiveStep = this.updateRequestService.getUpdatedDisplayValue(this.updateRequest.getReference())
              this.updateRequest.displayValue = updatedActiveStep.displayValue
              this.updateRequest.value = updatedActiveStep.value
            } else {
              this.minNetYield = null;
              this.minCapRate = null;
              this.property.renovationFeePercent = null;
            }
          }
        }
      }
      const isProformaDirty = _.some(Object.keys(this.originalProforma), (k) => {
        if (k != 'userOverwrittenFields') {
          return this.originalProforma[k] != this.uwProforma.proforma[k];
        }
        return false;
      });
      const isPurchasingEntityDirty = this.originalProforma.purchasingEntity != this.uwProforma.proforma.purchasingEntity;

      this.uwProforma.isDirty = isProformaDirty || isPurchasingEntityDirty;
      if (this.uwProforma.isDirty) {
        this.changed.emit({purchasingEntity: this.uwProforma.proforma['purchasingEntity']});
      }
    }
  }



  onSelectedRentCompsAvgChange() {
    if (this.selectedRentCompsAvg && !this.property["userManualOverwriteRentAmount"]) {
      this.theForm.get('monthlyRentAmount').setValue(this.selectedRentCompsAvg);
    }
  }

  onValueChanges(key: string, event) {
    const oldValues = this.uwProforma.userInputProforma;

    if (this.initializing) {
      return;
    }

    this.uwProforma.automaticUpdate = false;
    if(this.updateRequest
      && this.updateRequest.activeStep.proforma.updateProforma
      && this.updateRequest.activeStep.proforma.updateOnChanges
      && [PROFORMA_FIELDS_IDS.TARGET_CONDITION_SCORE, this.updateRequest.field].includes(key)
      ) {
        // the script update all proforma on blur it avoid bad UX
        // const self = this.ref.nativeElement;
        // const firstTarget = (self.querySelector(`#${key} input`) || self.querySelector(`#${key}`)) as HTMLInputElement;
        // const blurListener = (ev) => {
        //   firstTarget.removeEventListener('blur',blurListener);
        //   this.onUpdateRequested.emit(this.theForm.get(key).value);
        // }
        // if(firstTarget) {
        //   firstTarget.addEventListener('blur', blurListener);
        // } else {
        //   this.onUpdateRequested.emit(this.theForm.get(key).value);
        // }
        this.onUpdateRequested.emit({
          key,
          value: this.theForm.get(key).value
        });
      return;

    }
    // Should not trigger an Underwriting calculation
    if ([...this.shouldNotOverwriteFields, ...this.shouldNotTriggerUWCalculationFieldsIds].includes(key) && this.uwProforma.proforma[key] != this.theForm.get(key).value) {

      this.checkProformaDependencies(key);
      this.checkWorkFlowDependencies(key);


      if(this.shouldNotOverwriteFields.includes(key)) {
        this.clearProformaForRecalculation(this.uwProforma.proforma, true);
      }
    }

    if (!this.shouldNotTriggerUWCalculationFieldsIds.includes(key)) {
      const allFieldsIds = this.allFields.map(fd => fd.id);
      if (key == 'targetConditionScore') {
        // If target condition change, reset everything
        let tcs = this.theForm.get('targetConditionScore').value;
        if (tcs == '') {
          tcs = null;
        }

        this.uwProforma.proforma.targetConditionScore = tcs;

        this.clearProformaForRecalculation(this.uwProforma.proforma);


        this.uwProforma.userInputProforma = new ProForma();

        [...this.shouldNotOverwriteFields, ...this.shouldNotTriggerUWCalculationFieldsIds].forEach( field => {
          this.uwProforma.userInputProforma[field] = oldValues[field];
        });

        this.uwProforma.userInputProforma.AOListingID = this.originalProforma.AOListingID;
        this.uwProforma.userInputProforma.id = this.uwProforma.proforma.id;

        this.uwProforma.userInputProforma[key] = tcs;
        } else {
          this.clearProformaForRecalculation(this.uwProforma.proforma, this.shouldNotOverwriteFields.includes(key));
          this.uwProforma.userInputProforma[key] = this.theForm.get(key).value;

          const fieldToInitialize = Object.keys(this.uwProforma.userInputProforma).filter(fd=> allFieldsIds.includes(fd));
          fieldToInitialize.splice(fieldToInitialize.indexOf('investorApproval'), 1);
          _.each(fieldToInitialize, (k) => {
              this.uwProforma.proforma[k] = this.uwProforma.userInputProforma[k];
          });
        }

      this.refreshProformaDataForStatusFields(oldValues);
      this.proFormaCalculator.calculate(this.property, this.uwProforma, false, false);

      this.updateRowValues(this.rent);
      this.updateRowValues(this.revenue);
      this.updateRowValues(this.expenses);
      this.updateRowValues(this.assetPurchase);

    }

    const isDirty = _.some(Object.keys(this.originalProforma), (k) => {
     if(k != 'userOverwrittenFields') {
      return this.originalProforma[k] != this.uwProforma.proforma[k];
     }
     return false;
    });

    if (this.uwProforma.isDirty || this.uwProforma.isDirty != isDirty) {
      this.uwProforma.isDirty= isDirty;
      this.changed.emit(false);
    }

    if ([PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION as string, PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION as string].includes(key)) {
      this.updateUnderwriterNote();
    }

  }

  private checkProformaDependencies(key: string) {
    const targetField: IProformaRow = _.find(this.extraFields, { id: key, hasDependencies: true });
    if (targetField && this.dependenciesRules[targetField.id].proforma) {
      const newValue = this.theForm.get(key).value;
      this.refreshProformaData(key);
      const proformaDependencies: any[] = this.dependenciesRules[targetField.id].proforma;

      proformaDependencies.forEach(dependenciesItem => {

        const dependOnIndex = _.findIndex(this.extraFields, (extraFieldItem => {
          return extraFieldItem.id == dependenciesItem.field;
        }));

        if (dependOnIndex !== -1) {
          dependenciesItem.rules.forEach(depRule => {
            depRule(targetField, newValue, dependOnIndex, this.extraFields, this.allFields, this.uwProforma, dependenciesItem.reverse);
          });
        }
      });

      this.requiredFields = this.allFields.filter(field => {
        return field.isRequired === true || !!field.hasError;
      });

    } else {
      this.refreshProformaData(key);
    }
  }

  private checkWorkFlowDependencies(key: string) {
    const targetField: IProformaRow = _.find(this.extraFields, { id: key, hasDependencies: true });
    if (targetField && this.dependenciesRules[targetField.id].workflow) {
      const newValue = this.theForm.get(key).value;
      this.refreshProformaData(key);
      const workflowDependencies: any[] = this.dependenciesRules[targetField.id].workflow;

      workflowDependencies.forEach(dependenciesItem => {

          if (dependenciesItem.flowName == this.updateRequest.flowName) {
            dependenciesItem.rules.forEach(depRule => {
              depRule(targetField, newValue, this.originalProforma[targetField.id], this.uwProforma.pendingEvents, this.extraFields, this.allFields, this.updateRequest, dependenciesItem.errorMessage, this.errorLevelFlag);
            });
          }
      });

      this.requiredFields = this.allFields.filter(field => {
        return field.isRequired === true || !!field.hasError;
      });
    } else {
      this.refreshProformaData(key);
    }
  }

  private refreshProformaData(key: string) {
    this.uwProforma.proforma[key] = this.theForm.get(key).value;
    this.uwProforma.userInputProforma[key] = this.uwProforma.proforma[key];
    const targetRows = [...this.rent, ...this.extraFields].filter(row => row.id == key) || [];
    this.updateRowValues(targetRows, true);
  }


  private refreshProformaDataForStatusFields(oldValues: ProForma) {
    const fieldsWithValidator = this.allFields.filter(field => {
      return field.validators;
    });

    [...this.shouldNotOverwriteFields,...this.shouldNotTriggerUWCalculationFieldsIds].forEach((item) => {
      let isValid = true;
      let errorMessage = '';
      const target = fieldsWithValidator.find(field => {
        return field.id == item;
      });
      if (target) {
        target.validators.forEach( (validator) => {
          const isRuleValid = validator.rule(oldValues[item]);
          if (!isRuleValid && !errorMessage) {
            errorMessage = validator.errorMessage;
          }
          isValid = isValid && isRuleValid;
          if (!isValid) {
            target.toolTip = errorMessage;
          }
        }
        );
        if(isValid) {
          this.uwProforma.userInputProforma[item] = oldValues[item];
          this.uwProforma.proforma[item] = this.uwProforma.userInputProforma[item];
        } else if (this.uwProforma.proforma[item]) {
          this.uwProforma.userInputProforma[item] = null;
          this.theForm.get(item).setValue(this.uwProforma.proforma[item], { emitEvent: false });
        }
      } else {
        if(oldValues[item]) {
          this.uwProforma.userInputProforma[item] = oldValues[item];
          this.uwProforma.proforma[item] = this.uwProforma.userInputProforma[item];
        } else if (this.uwProforma.proforma[item]) {
            this.uwProforma.userInputProforma[item] = null;
            this.theForm.get(item).setValue(this.uwProforma.proforma[item], { emitEvent: false });
        }
      }
    });
  }

  private initDepenndencies() {
    this.extraFields.forEach(
      (targetField) => {
        const newValue = this.theForm.get(targetField.id).value;

        if(!this.dependenciesRules[targetField.id]) {
          return;
        }

        const proformaDeps: any[] = this.dependenciesRules[targetField.id]?.proforma || [];
        proformaDeps.forEach(dependenciesItem => {

          const dependOnIndex = _.findIndex(this.extraFields,(extraFieldItem => {
            return extraFieldItem.id == dependenciesItem.field;
          }));

          if (dependOnIndex !== -1) {
              dependenciesItem.rules.forEach(depRule => {
                depRule(targetField, newValue, dependOnIndex, this.extraFields, this.allFields, this.uwProforma, dependenciesItem.reverse);
              });
          }
        });

        this.requiredFields = this.allFields.filter(field => {
          return field.isRequired === true;
      });

      }
    );


  }

  // handler for all required fields
  checkForMissingRequiredFields(focus= false): any {
    let isNotValid = VALIDATION_ERROR_TYPE.NONE;
    const fieldsWithError =[];

    this.requiredFields = this.allFields.filter(field => {
      return field.isRequired === true;
    });

    this.requiredFields.forEach(x => {
      if (this.uwProforma.proforma[x.id] === null || !!x.hasError) {
        x.hasError = (x.hasError > VALIDATION_ERROR_TYPE.REQUIRED) ? x.hasError : VALIDATION_ERROR_TYPE.REQUIRED;
        fieldsWithError.push(x);
        isNotValid = x.hasError;
      }
    });

    if(isNotValid != VALIDATION_ERROR_TYPE.NONE && focus) {
      this.scrollToError(fieldsWithError);
    }
    return { isNotValid,  fieldsWithError};
  }


  // handler for all warning fields
  checkFieldsForWarning(focus= false): any[] {
    return this.allFields.filter(field => {
      return field.hasError === VALIDATION_ERROR_TYPE.WARN;
    });
  }

  focusOnMonthlyRentInput() {
    // lookup component on its ID
    // @ts-ignore
    const monthlyRentAmountInput: any = _.first(this.numericInputs.toArray(), (input: any) => input.ngControl.name == 'monthlyRentAmount');
    monthlyRentAmountInput?.elRef.nativeElement.focus();
  }

  // focus to the first invalid field
  private scrollToError(fieldsWithError: IProformaRow[]) {
    const self = this.ref.nativeElement;
    const firstTarget = (self.querySelector(`#${fieldsWithError[0].id} input`) || self.querySelector(`#${fieldsWithError[0].id}`)) as HTMLElement;
    if(firstTarget) {
      firstTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }

    setTimeout(() => {
      firstTarget.focus();
    }, 300);
  }

  scrollAndFocusToFields(targetFields, focus: boolean = true, id?: string) {
    const self = this.ref.nativeElement;
    let fieldId;
    if (id) {
      fieldId = id;
    } else {
      fieldId = targetFields[0].id;
    }
    const firstTarget = (self.querySelector(`#${fieldId} input`) || self.querySelector(`#${fieldId}`)) as HTMLElement;
    if (firstTarget) {
      firstTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });

      if (focus) {
        setTimeout(() => {
          firstTarget.focus();
        }, 300);
      }
    }
  }

  private clearProformaForRecalculation(proforma: ProForma, notClearMonthlyRent?) {
    if(!notClearMonthlyRent) {
      proforma.monthlyRentAmount = null;
    }
    proforma.vacancyAllowanceAmount = null;
    proforma.creditLossAmount = null;
    proforma.taxesAmount = null;
    proforma.propertyManagementFeesAmount = null;
    proforma.maintenanceTurnoverAmount = null;
    proforma.hoaAmount = null;
    proforma.leasingCostsAmount = null;
    proforma.priceOfferAmount = null;
    proforma.repairBudgetAmount = null;
    proforma.acquisitionCostAmount = null;
    proforma.stabilizationFeeAmount = null;
    proforma.buyerPaidCommission = null;
  }

  private bindRows(rows: IProformaRow[], usePropertyCurrentPrice = false) {
    const overWritenFields = JSON.parse(this.avmProforma.proforma.userOverwrittenFields? this.avmProforma.proforma.userOverwrittenFields: '[]');
    _.each(rows, (row: IProformaRow) => {
      row.avmValue = this.avmProforma.proforma[row.id];
      row.avmDisplay = row.formatter ? row.formatter(row.avmValue) : row.avmValue;
      row.userOverwrite = this.uwProforma.userInputProforma[row.id] != null;

      if(Array.isArray(overWritenFields)) {
        row.userOverwriteVersion = overWritenFields.includes(row.id);
      }

      row.hasError = VALIDATION_ERROR_TYPE.NONE;

      const isNotEdited = this.uwProforma.isNotEdited();
      if (isNotEdited) {
        const hasHighestPurchasePriceOffer =
          row.id === 'highestPurchasePriceOffer' &&
          this.uwProforma.proforma.highestPurchasePriceOffer !== null;

        row.uwValue = hasHighestPurchasePriceOffer
          ? this.uwProforma.proforma.highestPurchasePriceOffer
          : null;

        row.uwDisplay = '-';

        row.diff = row.diff? hasHighestPurchasePriceOffer
          ? this.calculateDiff(row.uwValue, this.property?.CurrentPrice)
          : '0.0%' : null;

      } else {
        this.updateRow(row, usePropertyCurrentPrice);
      }

      if (row.editable) {
        this.theForm.get(row.id).setValue(row.uwValue, { emitEvent: false });
      }
    });
  }

  private bindRowsForHistory(rows: IProformaRow[], avmProforma, source = 0) {
    const overWrittenFields = !source? JSON.parse(avmProforma.proforma.userOverwrittenFields? avmProforma.proforma.userOverwrittenFields: '[]')
                              : avmProforma.proforma.userOverwrittenFields || [];

    _.each(rows, (row: IProformaRow) => {
      row.avmValue = avmProforma.proforma[row.id];
      row.avmDisplay = row.formatter ? row.formatter(row.avmValue) : row.avmValue;

      if(Array.isArray(overWrittenFields)) {
        row.userOverwriteVersion = overWrittenFields.includes(row.id);
      }
    });
  }
  private updateRowValues(rows: IProformaRow[], usePropertyCurrentPrice = false) {
    _.each(rows, (row: IProformaRow) => {
      this.updateRow(row, usePropertyCurrentPrice);

      if (row.editable) {
        this.theForm.get(row.id).setValue(row.uwValue, { emitEvent: false });
      }

    });
  }

  validateFieldWitFlags(row) {
    let isValid = true;
    let errorMessage = '';
    if ((this.validationFlag[row.id] && this.validationFlag[row.id].enabled) || !this.validationFlag[row.id]) {
      row.validators.forEach((validator) => {
        const isRuleValid = validator.rule(this.uwProforma.proforma[row.id]);
        if (!isRuleValid && !errorMessage) {
          errorMessage = (this.validationFlag[row.id] && this.validationFlag[row.id].errorMessage)? this.validationFlag[row.id].errorMessage : validator.errorMessage;
        }
        isValid = isValid && isRuleValid;
      });
      return {isValid, emsg: errorMessage};
    }

    return {isValid, emsg: errorMessage};
  }

  private updateRow(row: IProformaRow, usePropertyCurrentPrice) {
    let errorMessage = '';
    const avmValue = usePropertyCurrentPrice
      ? this.property?.CurrentPrice
      : row.avmValue;
    row.uwValue = this.uwProforma.proforma[row.id];
    row.uwDisplay = row.formatter ? row.formatter(row.uwValue) : row.uwValue;
    row.userOverwrite = usePropertyCurrentPrice? !!row.uwValue || this.uwProforma.userInputProforma[row.id] != null : this.uwProforma.userInputProforma[row.id] != null;

    if(row.validators) {
      // feature flags
      const  {isValid, emsg} = this.validateFieldWitFlags(row);
      errorMessage = emsg;

      // row.validators.forEach((validator) => {
    //   const isRuleValid = validator.rule(this.uwProforma.proforma[row.id]);
    //   if (!isRuleValid && !errorMessage) {
    //     errorMessage = validator.errorMessage;
    //   }
    //   isValid = isValid && isRuleValid;
    // });
      row.hasError = !isValid? VALIDATION_ERROR_TYPE.REQUIRED : VALIDATION_ERROR_TYPE.REQUIRED > row.hasError? row.hasError : VALIDATION_ERROR_TYPE.NONE;
    } else {
      row.hasError = (!row.userOverwrite && row.isRequired)? VALIDATION_ERROR_TYPE.REQUIRED :  VALIDATION_ERROR_TYPE.REQUIRED > row.hasError? row.hasError : VALIDATION_ERROR_TYPE.NONE;
    }

    if(row.hasError == VALIDATION_ERROR_TYPE.REQUIRED) {
      row.toolTip = errorMessage;
    } else if (row.hasError == VALIDATION_ERROR_TYPE.NONE) {
      row.toolTip = null;
    }

    row.diff = row.diff? this.calculateDiff(row.uwValue, avmValue): null;
  }

  private calculateDiff(a: number, b: number): string {
    // a is the user input value and b is the value from proforma version
    // if we have zero is proforma we need to handle based on user input value
    // expression (b - -a / a) to make sure that the value on Ui will react even if the proforma version value is zero.
    // and make it positive value. 
    if ((a || a == 0) && (b || b == 0)) {
      const diff = b != 0 ? (a - b) / b : b - -a / a;
      return (diff > 0 ? '+' : '') + JsUtils.formatPercent(diff);
    }
    return '0.0%';
  }

  userManualOverwriteRentAmount(event: KeyboardEvent) {
    if(/^[0-9]$/i.test(event.key)) {
      this.property["userManualOverwriteRentAmount"] = true;
    }
  }
}
