import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AwsCredentialsService } from './awsCredentials.service';
import { DataDogService } from './data-dog.service';
import { FeatureFlagsService } from './featureFlagsService/feature-flags.service';
import { UnderwriterService } from './underwriter.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(false);

  constructor(
    protected awsCredentialsService: AwsCredentialsService,
    private router: Router,
    private dataDogService: DataDogService,
    private underwriterService: UnderwriterService) {

    this.checkToken();
  }

  private checkToken() {
    const res = localStorage.getItem(AwsCredentialsService.STORAGE_ID);
    this.authenticationState.next(res != null);

    this.awsCredentialsService.refreshCredentials().then(() => {
      this.authenticationState.next(true);

    }, (err) => {
      if (err === AwsCredentialsService.LOGIN_TOKEN_EXPIRED) {
        this.logout();
      }
    });
  }

  async login(email: string, password: string) {
    try {
      const userPayload: any = await this.awsCredentialsService.login(email, password);
      this.dataDogService.refreshUserInfo(this.awsCredentialsService.getProfile());
      if (userPayload) {
        this.authenticationState.next(true);
      } else {
        this.logout();
      }
    } catch (err) {
      this.logout();
      throw err;
    }
  }

  async logout() {
    try {
      this.awsCredentialsService.logout();
      this.authenticationState.next(false);
      FeatureFlagsService.isActive = false;
      this.underwriterService.resetPreferences();
      sessionStorage.clear();

      this.router.navigate(['/login']);
    } catch (err) {
      throw err;
    }
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  getProfile() {
    return this.awsCredentialsService.getProfile();
  }
}
