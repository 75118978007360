import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'columnsOrdring'
})
export class ColumnsOrdringPipe implements PipeTransform {
  transform(columns: any[]): any[] {
    const selected = columns.filter(x => x.isVisible);
    let notSelected = columns.filter(x => !x.isVisible);
    notSelected = _.orderBy(notSelected, ['label']);
    return selected.concat(notSelected);
  }
}
