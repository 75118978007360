import { Injectable } from '@angular/core';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { environment } from '../../environments/environment';
import { datadogLogs, StatusType } from '@datadog/browser-logs';



@Injectable({
  providedIn: 'root'
})
export class DataDogService {

  private userInfo;
  constructor() {
    const service = 'a1-maestro';
    const env =  environment.stage;
    const version =  environment.version.toLowerCase();

    const sessionSampleRate = 100;

    const initRUMConfiguration: RumInitConfiguration = {
      applicationId: '536aced8-dae1-4234-bf7a-301d19d037db',
      clientToken: 'pub1468a256af79e5a26a84b4716235e8ab',
      site: 'datadoghq.com',
      service,
      env,
      sessionSampleRate,
      sessionReplaySampleRate: sessionSampleRate,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      version,
      defaultPrivacyLevel: 'allow',
      actionNameAttribute: 'data-rum-action',
      usePartitionedCrossSiteSessionCookie: true,
      useSecureSessionCookie: true,
    };

    datadogLogs.init({
      clientToken: 'pubaedd7cd679bc8e48121438b0e0b27852',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate,
      service,
      env,
      version,
    });

    datadogRum.init(initRUMConfiguration);
   }

   refreshUserInfo(userInfo) {
    if (!this.userInfo) {
      this.userInfo = userInfo;
      datadogRum.setUser({
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        email: userInfo.email
      });
      datadogRum.startSessionReplayRecording();
    }
   }

   log(message, messageContext, status: StatusType) {
    datadogLogs.logger.log(message, messageContext, status);
   }
}
