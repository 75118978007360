import * as _ from 'lodash';
import { AuditItemModel } from "src/app/models/auditItemModel";
import { AuditEventSourceType } from "../data/auditEventSourceType";
import { AuditItem } from "../data/auditItem";
import * as JsUtils from 'src/app/utils/jsUtils';
import { ConcessionStatusTypeText } from '../data/concessionStatusType';
import { PropertyStatusCategoryTypeFromStatus, PropertyStatusType, PropertyStatusTypeText } from '../data/propertyStatusType';
import { CommentType } from '../data/commentType';


/**
 * @namespace
 */
const fieldDefinitions: {
  /**
   * Refer to this by {@link fieldDefinitions."#key"}.
   * @namespace
   */
  [key: string]: {
    /**
     * Refer to this by {@link fieldDefinitions."#key".display}.
     * @type {string}
     * @defaultValue
     */
    display: string;
    /**
     * Refer to this by {@link fieldDefinitions."#key".formatter}.
     * @type {Function}
     * @defaultValue null
     * @return formatted Value or null
     */
    formatter?: Function
  }
} = {
  // UW PROFORMA
  expectedResaleValue: { display: "Expected Resale Value", formatter: JsUtils.formatCurrency },
  targetConditionScore: { display: "Condition Score" },
  estimatedRentAmount: { display: "Monthly Rent", formatter: JsUtils.formatCurrency },
  monthlyRentAmount: { display: "Monthly Rent Amount", formatter: JsUtils.formatCurrency }, // handle early typo
  grossPotentialRevenueAmount: { display: "Gross Potential Revenue", formatter: JsUtils.formatCurrency },
  vacancyAllowanceAmount: { display: "Vacancy Allowance", formatter: JsUtils.formatCurrency },
  creditLossAmount: { display: "Credit Loss", formatter: JsUtils.formatCurrency },
  proformaConcessionAmount: { display: "Rental Concession", formatter: JsUtils.formatCurrency },
  otherIncomeAmount: { display: "Other Income", formatter: JsUtils.formatCurrency },
  netRevenueAmount: { display: "Net Revenue", formatter: JsUtils.formatCurrency },
  highestPurchasePriceOffer: {display: "Highest Purchase Price Offer", formatter: JsUtils.formatCurrency},

  taxesAmount: { display: "Taxes", formatter: JsUtils.formatCurrency },
  propertyManagementFeesAmount: { display: "Property Management Fees", formatter: JsUtils.formatCurrency },
  maintenanceTurnoverAmount: { display: "Maintenance & Turnover", formatter: JsUtils.formatCurrency },
  insuranceAmount: { display: "Insurance", formatter: JsUtils.formatCurrency },
  hoaAmount: { display: "HOA", formatter: JsUtils.formatCurrency },
  leasingCostsAmount: { display: "Leasing Costs", formatter: JsUtils.formatCurrency },
  totalExpensesAmount: { display: "Total Expenses", formatter: JsUtils.formatCurrency },
  netOperationIncomeAmount: { display: "Net Operation Income", formatter: JsUtils.formatCurrency },
  recurringCapExAmount: { display: "Recurring Capex", formatter: JsUtils.formatCurrency },
  netCashFlowAmount: { display: "Net Cash Flow", formatter: JsUtils.formatCurrency },

  priceOfferAmount: { display: "Purchase Price Offer", formatter: JsUtils.formatCurrency },
  repairBudgetAmount: { display: "Rehab Budget", formatter: JsUtils.formatCurrency },
  carryCostAmount: { display: "Carry Cost", formatter: JsUtils.formatCurrency },
  acquisitionCostAmount: { display: "Acquisition Costs", formatter: JsUtils.formatCurrency },
  stabilizationFeeAmount: { display: "A1 Stabilization Fee", formatter: JsUtils.formatCurrency },
  totalLandedCostAmount: { display: "Total Landed Cost", formatter: JsUtils.formatCurrency },
  buyerPaidCommission: { display: "Buyer Paid Commission", formatter: JsUtils.formatCurrency },
  netYield: { display: "Net Yield", formatter: JsUtils.formatPercent2 },
  capRate: { display: "Cap Rate", formatter: JsUtils.formatPercent2 },

  // Listings
  CurrentPrice: { display: "Current Price", formatter: JsUtils.formatCurrency },

  // Transaction Manager
  concessionDeclinedDate: { display: "Concession Declined", formatter: JsUtils.getMDYDateString },

  counterEarnestMoneyDueDate: { display: "Counter Earnest Money Due Date", formatter: JsUtils.getMDYDateString },

  dueDiligenceExtensionCounterAcceptedEndDate: { display: "Due Diligence End", formatter: JsUtils.getMDYDateString },
  dueDiligenceExtensionSellerProposedEndDate: { display: "Due Diligence Extension Seller Proposed End Date", formatter: JsUtils.getMDYDateString },

  inspectionRepairEstimate: { display: "Inspection Repair Estimate", formatter: JsUtils.formatCurrency },

  offerAcceptedDate: { display: "Offer Accepted Date", formatter: JsUtils.getMDYDateString },

  sellerCounterOfferAmount: { display: "Seller Counter Offer Amount", formatter: JsUtils.formatCurrency },

  offerTerminateDate: { display: "Terminate Date", formatter: JsUtils.getMDYDateString },

  // CLOSING (OLD TM)
  finalPurchasePriceOffer: { display: "Final Purchase Price Offer", formatter: JsUtils.formatCurrency },
  finalPurchasePriceDate: { display: "Final Purchase Price Date", formatter: JsUtils.getMDYDateString },

  // CLOSING SUITE
  closingInitiatedDate: { display: "Closing Ordered Date", formatter: JsUtils.getMDYDateString },
  closingModuleLink: { display: "Link to Closing Partner" },
  titleReportReceivedDate: { display: "Title Report Received Date", formatter: JsUtils.getMDYDateString },
  confirmedIntentToProceedDate: { display: "Confirmed Intent To Proceed Date", formatter: JsUtils.getMDYDateString },
  eSigningAvailableDate: { display: "Closing Documents Available Date", formatter: JsUtils.getMDYDateString },
  eSigningCompletedDate: { display: "Closing Documents Signed Date", formatter: JsUtils.getMDYDateString },
  fundsWiredDate: { display: "Funds Wired Date", formatter: JsUtils.getMDYDateString },
  trailingDocsPublishedDate: { display: "Trailing Documents Published Date", formatter: JsUtils.getMDYDateString },
  deedTransferredDate: { display: "Deed Transferred Date", formatter: JsUtils.getMDYDateString },
  sentToLeasingDate: { display: "Sent To Leasing Date", formatter: JsUtils.getMDYDateString },
  sentToConstructionDate: { display: "Sent To Construction Date", formatter: JsUtils.getMDYDateString },
  inspectionContingencyReleasedDate: { display: "Inspection Contingency Released Date", formatter: JsUtils.getMDYDateString },


  // CONSTRUCTION SUITE

  allVendorsAssignedDate: { display: "Date Completed Vendor Assignment", formatter: JsUtils.getMDYDateString },
  preConstructionWalkthroughDate: { display: "Date Pre-Construction Walkthrough", formatter: JsUtils.getMDYDateString },
  finalAdjustedAmount: { display: "Final Adjusted SOW Amount", formatter: JsUtils.formatCurrency },
  estimatedConstructionStartDate: { display: "Estimated Start Date", formatter: JsUtils.getMDYDateString },
  estimatedCompletionDate: { display: "Estimated End Date", formatter: JsUtils.getMDYDateString },
  startedConstructionDate: { display: "Date Started Construction", formatter: JsUtils.getMDYDateString },
  constructionQuartelyCompletedDate: { display: "25% Completed Date", formatter: JsUtils.getMDYDateString },
  constructionHalfyCompletedDate: { display: "50% Completed Date", formatter: JsUtils.getMDYDateString },
  constructionAlmostCompletedDate: { display: "75% Completed Date", formatter: JsUtils.getMDYDateString },
  constructionCompletedDate: { display: "100% Completed Date", formatter: JsUtils.getMDYDateString },
  constructionPunchlistCompleteDate: { display: "Date Construction Punchlist Completed", formatter: JsUtils.getMDYDateString },
  finalConstructionCostsAmount: { display: "Final Construction Costs", formatter: JsUtils.formatCurrency },
  allVendorsPaidDate: { display: "Date all Vendors Paid", formatter: JsUtils.getMDYDateString },

  // LEASING SUITE
  listedForRentDate: { display: "Listing Date", formatter: JsUtils.getMDYDateString },
  rentedDate: { display: "Date Rented/Occupied", formatter: JsUtils.getMDYDateString },
  listedRentAmount: { display: "Listing Rental Amount", formatter: JsUtils.formatCurrency },
  // monthlyRentAmount: { display: "Monthly Rent", formatter: JsUtils.formatCurrency },
  leaseType: { display: "Lease Type", formatter: formatLeaseType },
  leaseExpirationDate: { display: "Lease Expiration Date", formatter: JsUtils.getMDYDateString },
  listingLink: { display: "Link To Listing" },
  avgAnnualMaintenanceAmount: { display: "Avg Annual Maintenance Amount", formatter: JsUtils.formatCurrency },

  // extra Fields
  minimumAcceptableConcession: { display: "Minimum Acceptable Concession", formatter: JsUtils.formatCurrency },
  recommendedConcession: { display: "Recommended Concession", formatter: JsUtils.formatCurrency },
  // new Field from Monday
  closingPrice: { display: "Closing Price", formatter: JsUtils.formatCurrency },

  // status
  TMStatus: { display: "Status" },
  subStatus: { display: "Substatus" },
};

/**
 * same as {@link JsUtils.formatCurrency} the difference is the default Value
 * @param value
 * @returns formatted value as currency
 * @defaultValue 'N/A'
 */
function formatConcession(value) {
  if (parseInt(value)) {
    return JsUtils.formatCurrency(parseInt(value));
  }
  return 'N/A';
}

/**
 * return leaseType to display based on leaseType value
 * @param leaseType
 * @returns 'Fixed Term' | 'Month-to-Month'
 * @defaultValue leaseType value
 */
function formatLeaseType(leaseType) {
  if (leaseType == 'fixed') {
      return 'Fixed Term';
  } else if (leaseType == 'month') {
      return 'Month-to-Month';
  }

  return leaseType;
}

/**
 * Return contract Type to display based on contactType value
 * @param contactType string value in (L, S)
 * @returns 'Listing Agent' | 'Seller'
 * @defaultValue 'Tenant'
 */
function formatInspectionContactType(contactType) {
  if (contactType == 'L') {
    return 'Listing Agent';
  } else if (contactType == 'S') {
    return 'Seller';
  }
  return 'Tenant';
}

/**
 * Return concession description based on {@link ConcessionStatusTypeText} formatter
 * @param value
 * @returns
 */
function formatConcessionStatus(value) {
  return ConcessionStatusTypeText(parseInt(value));
}

/**
 * Format and return diligence to display based on diligenceType value
 * @param diligenceType
 * @returns 'Business Days' | 'Calendar Days'
 * @defaultValue 'Calendar Days'
 */
function formatDueDiligenceType(diligenceType) {
  if (diligenceType == 'B') {
    return 'Business Days';
  }
  return 'Calendar Days';
}

/**
 * Return value to display and formatter for each field defined in {@link fieldDefinitions} if not found return fieldName
 * @param fieldName field to map
 * @returns field definition
 * @type { display: string, formatter: Function}
 */
export function getFieldDisplayName(fieldName: string) {
  const definition = fieldDefinitions[fieldName];
  if (!definition) {
    return _.startCase(fieldName);
  }

  return definition.display;
}

/**
 * Build Models each invocation create New model and added to models
 * @param identityIds underwriter identityId
 * @param groupByUsers events grouped By identity id
 * @param date event date
 * @param models models to build
 * @return null build by reference
 */
export function buildModel(identityIds: any, groupByUsers: any, date: any, models: AuditItemModel[]) {
  _.each(identityIds, (id) => {

    const userEvents = _.orderBy(groupByUsers[id], ['id'], ['asc']);

    const model = new AuditItemModel();
    model.auditItems = userEvents;
    model.eventDate = date;

    model.timestamptext = JsUtils.getTimestampString(model.eventDate);
    if (userEvents[0].firstName) {
      model.timestamptext += ` by ${userEvents[0].firstName} ${userEvents[0].lastName}`;
      model.initials = userEvents[0].firstName[0] + userEvents[0].lastName[0];
    }

    // FIXME!! this could be better :)
    // Technical debt alert!!
    let idx;
    model.auditItems.forEach((item, itemId) => {
      if (item.message === 'purchasingEntity' && !item.newValue) {
        idx = itemId;
      }
    });
    if (!!idx) {
      model.auditItems.splice(idx, 1);
    }

    // use some() to be able to exit as soon as possible
    _.some(model.auditItems, (a: AuditItem) => {
      if (a.eventType == AuditEventSourceType.Imported || a.eventType == AuditEventSourceType.Update) {
        model.capRateText = `AVM Cap Rate: ${JsUtils.formatPercent2(a.avmCapRate)} · AVM Purchase Price: ${JsUtils.formatCurrency(a.avmPurchasePrice)}`;
        return true;
      } else if (!_.isNil(a.uwCapRate) && a.eventType != AuditEventSourceType.ListingDataUpdated) {
        model.capRateText = `UW Cap Rate: ${JsUtils.formatPercent2(a.uwCapRate)} · UW Purchase Price: ${JsUtils.formatCurrency(a.uwPurchasePrice)}`;
        return true;
      }
      return false;
    });

    models.push(model);
  });
}

/**
 *
 * @param {string} fieldName - field to transform based on {@link fieldDefinitions} mapping
 * @param value
 * @returns
 */
export function getFieldValue(fieldName: string, value: string) {
  if (value === null) {
    return 'N/A';
  }

  const definition = fieldDefinitions[fieldName];
  if (!definition) {
    return value;
  }

  if (definition.formatter) {
    return definition.formatter(value);
  }
  return value;
}
/**
 * @deprecated still in use but the business logic is disabled (to confirm)
 * @param model
 * @param addDueDiligenceCount
 * @param addDueDiligenceType
 * @param currentDueDiligenceDaysCount
 * @param currentDueDiligenceDaysType
 */
export function addDueDiligenceDays(model, addDueDiligenceCount, addDueDiligenceType, currentDueDiligenceDaysCount, currentDueDiligenceDaysType) {

  if (addDueDiligenceCount || addDueDiligenceType) {
    // Initial values
    if (addDueDiligenceType && addDueDiligenceType.oldValue === null && addDueDiligenceCount && addDueDiligenceCount.oldValue === null) {
      model.text.push(`Due Diligence Days: ${addDueDiligenceCount.newValue} ${formatDueDiligenceType(addDueDiligenceType.newValue)}`);
    } else if (addDueDiligenceType && addDueDiligenceCount) {
      model.text.push(`Due Diligence Days: ${addDueDiligenceCount.oldValue} ${formatDueDiligenceType(addDueDiligenceType.oldValue)} to  ${addDueDiligenceCount.newValue} ${formatDueDiligenceType(addDueDiligenceType.newValue)}`);
    } else if (addDueDiligenceType) {
      model.text.push(`Due Diligence Days: ${currentDueDiligenceDaysCount} ${formatDueDiligenceType(addDueDiligenceType.oldValue)} to  ${currentDueDiligenceDaysCount} ${formatDueDiligenceType(addDueDiligenceType.newValue)}`);
    } else {
      model.text.push(`Due Diligence Days: ${addDueDiligenceCount.oldValue} ${formatDueDiligenceType(currentDueDiligenceDaysType)} to  ${addDueDiligenceCount.newValue} ${formatDueDiligenceType(currentDueDiligenceDaysType)}`);
    }
  }

}

/**
 * build Underwriter name for each model
 * @param ai auditItem
 * @param model activityLog model
 */
export function handleUnderwriterName(ai, model) {
  if(ai.firstName == 'Maestro' && ai.lastName == 'Integration') {
    JsUtils.getTimestampString(model.eventDate);
    const lastName ='Underwriter';
    model.timestamptext = ` ${JsUtils.getTimestampString(model.eventDate)} by ${ai.firstName} ${lastName}`;
    model.initials = '';

  }
}

/**
 * Return mapped status description based on value
 * @param value status value type number
 * @returns description of the status
 */
export function formatUnderwritingStatus(value) {
  return PropertyStatusTypeText(parseInt(value));
}

/**
 * Build Model of type comment (underwriter note see proforma ui)
 * @param ai auditItem
 * @param currentProperty property
 * @param model activityLog model
 */
export function setListingForComment(ai: AuditItem, currentProperty: any, model: AuditItemModel) {
  if (!_.isEmpty(ai.newValue)) {
    if (!_.isEmpty(ai.message)) {
      let propertyStatusType;

      switch (ai.message) {
        case CommentType.Reason:
          propertyStatusType = PropertyStatusType.OfferReadyReason;
          break;

        case CommentType.CounterOffer:
          propertyStatusType = currentProperty?.reasonFewComps ? PropertyStatusType.CounterReason : PropertyStatusType.Counter;
          break;
      }

      model.header = true;
      model.underwriting = propertyStatusType;
      if(propertyStatusType) {
        model.underwritingStatusClass = 'a1-status-' + PropertyStatusCategoryTypeFromStatus(propertyStatusType);
        model.underwritingStatusText = formatUnderwritingStatus(propertyStatusType);
      }
    }
    model.comments.push({
        type: 'comment',
        message: ai.newValue,
        noteType: ai.noteType
      });
  }
}

/**
 * build  models grouped by identityId (underwriter) then by date
 * @param auditItems
 * @param models activityLog models
 * @returns built  models
 */
export function buildModelsFromIdentityIdAndDateGroups(auditItems: AuditItem[], models: AuditItemModel[]) {
  const groupByDates = _.groupBy(auditItems, 'eventDate');
  const dates = _.keys(groupByDates);

  _.each(dates, (d) => {
    // list of events by each date
    const events = groupByDates[d];

    // Chances are low that more than 1 users have have updated the proforma at same msec. Better be sure.
    const groupByUsers = _.groupBy(events, 'identityId');
    const identityIds = _.keys(groupByUsers);
    buildModel(identityIds, groupByUsers, d, models);
  });

  // Then order by date time
  models = _.orderBy(models, ['eventDate'], ['asc']);
  return models;
}


