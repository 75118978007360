import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";
import { EVBUS_EVENTS_NAMES, UNDERWRITER_ACTIONS } from "../model/updateRequest";
import * as _ from "lodash";
import { PROFORMA_FIELDS_IDS } from "src/app/panels/proforma/proforma_config";
import { IEventBusRequest, IPendingEvents } from "../model/IRequest";
import { IRequest, IRequestStep } from "../model/requestStep";

export function pendingEvBusEventsValidator(underwriter: UnderwriterPanelComponent, $userEvent: UNDERWRITER_ACTIONS, evBus: string[], checkForPendingEvents: IPendingEvents) {

  const events = checkForPendingEvents.pendingEvents || [];
  const updateProperty = !!checkForPendingEvents.updateProperty;

  const pendingEventsNames = Object.keys(underwriter.currentUwProforma.pendingEvents || {});
  const updateEvents = [
    UNDERWRITER_ACTIONS.DRAFT,
    UNDERWRITER_ACTIONS.APPROVAL,
    UNDERWRITER_ACTIONS.UNDO_APPROVAL
  ];

  if( updateEvents.includes($userEvent) && updateProperty) {
    events.forEach( ev => {
      if(evBus.includes(ev.evbusEventName)) {
        if(pendingEventsNames.includes(ev.evbusEventName)) {
          delete underwriter.currentUwProforma.pendingEvents[ev.evbusEventName];
        }
      } else {
        if (!underwriter.currentUwProforma.pendingEvents) {
          underwriter.currentUwProforma.pendingEvents = {};
        }
        if(pendingEventsNames.includes(ev.evbusEventName)) {
          ev.fieldsToWatch.forEach( ftc => {
            if (underwriter.currentUwProforma.pendingEvents[ev.evbusEventName][ftc].newValue != underwriter.currentUwProforma.proforma[ftc]) {
              underwriter.currentUwProforma.pendingEvents[ev.evbusEventName][ftc].newValue= underwriter.currentUwProforma.proforma[ftc];
            }
          });
          const minimuDiff = ev.fieldsToWatch.find( ftc => {
            return underwriter.currentUwProforma.pendingEvents[ev.evbusEventName][ftc].oldValue
                        != underwriter.currentUwProforma.pendingEvents[ev.evbusEventName][ftc].newValue;
          }
          );
          if(!!!minimuDiff) {
            delete underwriter.currentUwProforma.pendingEvents[ev.evbusEventName];
          }

        } else {
          let valid = true;
          if(ev.validators) {
            valid = ev.validators.every(vd => {
              return !!vd(underwriter);
            });
          }
          if (valid) {
            underwriter.currentUwProforma.pendingEvents[ev.evbusEventName] = {};
            ev.fieldsToWatch.forEach( ftc => {
              underwriter.currentUwProforma.pendingEvents[ev.evbusEventName][ftc] = {
                oldValue: underwriter.currentUwProforma.originalProforma? underwriter.currentUwProforma.originalProforma[ftc] : null,
                newValue: underwriter.currentUwProforma.proforma[ftc]
              };
            });
            const minimuDiff = ev.fieldsToWatch.find( ftc => {
              return underwriter.currentUwProforma.pendingEvents[ev.evbusEventName][ftc].oldValue
                             != underwriter.currentUwProforma.pendingEvents[ev.evbusEventName][ftc].newValue;
            }
            );
            if(!!!minimuDiff) {
              delete underwriter.currentUwProforma.pendingEvents[ev.evbusEventName];
            }
          }

        }
      }
    });
  } else if(![UNDERWRITER_ACTIONS.DRAFT, UNDERWRITER_ACTIONS.HIDE].includes($userEvent) && !updateProperty) {
    if(!_.isEmpty(pendingEventsNames)) {
      events.forEach(ev => {
        if(pendingEventsNames.includes(ev.evbusEventName)) {
        }
        const isEventIncluded = evBus.includes(ev.evbusEventName);

        if(!isEventIncluded && pendingEventsNames.includes(ev.evbusEventName)) {
          let valid = true;
          if(ev.validators) {
            valid = ev.validators.every(vd => {
              return !!vd(underwriter, underwriter.currentUwProforma.pendingEvents[ev.evbusEventName]);
            });
          }
          if (valid) {
            evBus.push(ev.evbusEventName);
            delete underwriter.currentUwProforma.pendingEvents[ev.evbusEventName];
          }
          delete underwriter.currentUwProforma.pendingEvents[ev.evbusEventName];

        }
        if(isEventIncluded) {
          if (isNewValueAndOldValueDifferentes(underwriter,ev)) {
            delete underwriter.currentUwProforma.pendingEvents[ev.evbusEventName];
          } else {
            const index = evBus.indexOf(ev.evbusEventName);
            if (index > -1) {
              evBus.splice(index, 1);
            }
          }
        } else {
        }
      });

    }

  }

  if(_.isEmpty(underwriter.currentUwProforma.pendingEvents)) {
    underwriter.currentUwProforma.pendingEvents = null;
  }
  return evBus;

}



function isNewValueAndOldValueDifferentes(underwriter: UnderwriterPanelComponent, ev: IEventBusRequest) {
  if(
    !ev.fieldsToWatch
        || _.isEmpty(ev.fieldsToWatch)
        || !underwriter.currentUwProforma.pendingEvents
        || !underwriter.currentUwProforma.pendingEvents[ev.evbusEventName]
  ) {
    return true;
  }

  const diff = ev.fieldsToWatch.find(field => {
    const fld = underwriter.currentUwProforma.pendingEvents[ev.evbusEventName][field];
    if(!fld) {
      return false;
    }
    return underwriter.currentUwProforma.proforma[field] != fld.oldValue;
  });

  return diff;
}

export function concessionEventBusValidator(underwriter: UnderwriterPanelComponent, ...uwArguments) {
  if(
    underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION] != underwriter.proformaPanel.originalProforma[PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION]
    || underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION] != underwriter.proformaPanel.originalProforma[PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION]
  ) {
    return true;
  }
  return false;
}

export function approvalEventBusValidator(underwriter: UnderwriterPanelComponent, ...uwArguments) {
  return !!underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL];
}

export function checkAvailablePendingEvents (pattern: UnderwriterPanelComponent, activeRequest: IRequest, newValue: number, activeStep: IRequestStep) {
  const pendingEventsNames = Object.keys(pattern.currentUwProforma.pendingEvents || {}) as EVBUS_EVENTS_NAMES[];
  if(!pendingEventsNames || _.isEmpty(pendingEventsNames) || !activeStep.actions.sendTM.triggerEVBusEvent ||!activeStep.actions.sendTM.triggerEVBusEvent.active) {
    return;
  }
  const activeEvents = (activeStep.actions.sendTM.triggerEVBusEvent.eventBus || []).filter(ev => {
    return ev.active;
  }).map(ev => {
    return ev.evbusEventName;
  });
  const intersection = pendingEventsNames.filter(pendingEventsName => {
    return activeEvents.includes(pendingEventsName);
  });

  if(intersection &&  !_.isEmpty(intersection)) {
      activeStep.activeAction.sendTM = true;
      // TODO:
      activeStep.activeAction.submitApproval = true;
      const pendingEvents = pattern.currentUwProforma.pendingEvents;
      if (_.find(pendingEvents, activeRequest.fieldToWatch)) {
          activeStep.proforma.updateProforma = false;
          activeStep.proforma.lockProforma = false;
      }
    }
}
