import { ProFormaModel } from "src/app/models/proformaModel";
import { ListingProperty } from "../../data/listingPropety";
import { PropertyStatusType } from "../../data/propertyStatusType";
import { CONCESSION_ACTION, EVBUS_EVENTS_NAMES, STEP_ACTION, UNDERWRITER_ACTIONS, UPDATE_REQUEST_LEVEL } from "../model/updateRequest";
import { IRequest } from "../model/requestStep";
import { VERSION_NAME } from "../model/versioning";
import * as _ from "lodash";
import { concessionEventBusValidator, pendingEvBusEventsValidator } from "../utils/pendingEventsUtils";
import { PROFORMA_FIELDS_IDS } from "src/app/panels/proforma/proforma_config";
import { UNDERWRITER_COMMANDS_NAME, UNDERWRITER_OPTIONS_LABEL } from "../model/underwriterCommandsAndActions";
import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";


function concessionFlowStatusValidation(property: ListingProperty) {
    return [
        PropertyStatusType.OfferReady,
        PropertyStatusType.OfferReadyReason,
        PropertyStatusType.OfferReadyUrgent,
        PropertyStatusType.OfferReadyUrgentTM,
        PropertyStatusType.OfferReadyUrgentTMReason,
    ].includes(property.underwritingStatus);
}

function concessionApprovedStatus(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
    return [
        PropertyStatusType.New,
        PropertyStatusType.Updated,
        PropertyStatusType.Underwritten,
        PropertyStatusType.OfferReady,
    ].includes(currentProperty.status);
}

function disableTMOnReady(underwriter: UnderwriterPanelComponent, options) {
    const property: ListingProperty = underwriter.currentProperty;

    Object.keys(options).forEach(op => {
        if (![UNDERWRITER_ACTIONS.SHOW.toString(), UNDERWRITER_ACTIONS.DRAFT.toString()].includes(op)) {
            delete options[op];
        }
    });
}

export const concession: IRequest = {
    name: 'concessionFlow',
    displayName: null,
    fieldToWatch: null,
    externalLink: null,
    requester: null,

    rules: {
        status: concessionFlowStatusValidation,
        value: null,
        comment: null
    },

    steps: [
        {
            stepAction: null,
            listingName: null,
            listingIcon: null,
            level: null,
            activators: [concessionApprovedStatus],
            activeAction: {
              saveDraft: false,
              sendTM: false,
            },
            DisableHideForEveryOne: true,
            multipleActions: null,
            saveLabel: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
            proforma: {
                lockProforma: false,
                updateProforma: false,
                forceUpdate: false,
                updateProformaLabel: null,
                editLocked: false,
                editLockOverrides: [PROFORMA_FIELDS_IDS.MONTHLY_RENT_AMOUNT],
                updateOnChanges: false,
                message: null,
                style: {
                    updateIcon: 'blocker'
                }
            },
            commands: {
              underwriter: {
                options: {
                    draft: {id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }
                },
                builder: null
            },
            panel: {
               
                triggerConcessionFields: {
                  active: false,
                  action: CONCESSION_ACTION.UPDATE,
                  commands: {
                      active: false,
                      nextAction: null,
                      previousAction: null,
                      actionName: null
                  },
                  validators: null
                },
                preApproval: {
                    active: false
                },
            }
            },
            actions: {
              saveDraft: {
                active: true,
                approval: null,
                validators: null,
                versioning: {
                    active: true,
                    update: true,
                    versionName: VERSION_NAME.OFFER_READY,
                    validators: null
                },
                triggerEVBusEvent: {
                    active: false,
                    eventBus: [
                        {
                            active: true,
                            evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                            validators: [concessionEventBusValidator]
                        }
                    ],
                    checkForPendingEvents: {
                        active: false,
                        validators: [pendingEvBusEventsValidator],
                        updateProperty: true,
                        pendingEvents : [
                            {
                                active: true,
                                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                fieldsToWatch: [
                                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                                ],
                                validators: [concessionEventBusValidator]
                              }
                        ]
                    }
                },
                
                updateStatus: {
                    active: false,
                    status: null,
                    validators: null,
                    fallBackStatus: null
                  }
            },

            sendTM: {
                active: true,
                approval: {
                    active: false,
                },
                validators: null,
                versioning: {
                    active: true,
                    update: true,
                    versionName: VERSION_NAME.OFFER_READY,
                    validators: null
                },
                triggerEVBusEvent: {
                    active: false,
                    eventBus: [
                        {
                            active: false,
                            evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                            validators: [concessionEventBusValidator]
                        }
                    ],
                    checkForPendingEvents: {
                        active: false,
                        validators: [pendingEvBusEventsValidator],
                        updateProperty: false,
                        pendingEvents : [
                            {
                                active: false,
                                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                fieldsToWatch: [
                                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                                ],
                                validators: null
                              }
                        ]
                    }
                },
                updateStatus: {
                    active: false,
                    status: null,
                    validators: null,
                    fallBackStatus: null
                  }
            },
            }

        },
    ]
};
