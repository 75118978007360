import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {environment} from '../../environments/environment';
// @ts-ignore
import {default as versionConfig} from '../../config/dev/config.json';
import {ApiService} from "./api.service";
import {ModalConfig} from "../ui/modal/modalConfig";
import {ModalService} from "../ui/modal/modal.service";
import {NetworkService} from "./network.service";
import { FeatureFlagsService } from './featureFlagsService/feature-flags.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  private versionConfig;
  private isOpen = false;
  private interval = null;

  constructor(private apiService: ApiService,
              private featurFlagsService: FeatureFlagsService,
              private modalService: ModalService,
              private networkService: NetworkService,
              private authenticationService: AuthenticationService) {
  }

  async initialize() {
    if (FeatureFlagsService.isActive) {
      await this.featurFlagsService.initFlags();
    }
    this.versionConfig = versionConfig;
    if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
      this.interval = setInterval(async () => {
        await this.checkVersion();
      }, this.versionConfig.app.versioningRefresh);
    }
  }

  private async checkVersion() {
    try {
      const currentVersion = environment.version;
      const newVersion = await this.getVersion();
      if (newVersion !== currentVersion && !this.isOpen) {
        this.open();
      } else if (FeatureFlagsService.isActive) {
        const isFlagsUpdated = await this.featurFlagsService.checkFlags();
        if (isFlagsUpdated) {
          this.open();
        }
      }
    } catch (error) {
      if(this.authenticationService.isAuthenticated()) {
        console.log(error);
      }
    }
  }

  private getVersion() {
    return new Promise<any>(async (resolve, reject) => {
      const isOnline = await this.networkService.checkIsOnline();
      if (!isOnline) { return; }

      this.apiService.getVersion().then((dataVersion) => {
        resolve(dataVersion.body.version);
      }, (error) => {
        reject(error);
      });
    });
  }

  private open() {
    this.isOpen = true;
    const config = new ModalConfig();
    config.type = 'ok';
    config.title = 'New version of Maestro available';
    config.text = 'You must refresh your browser to update and continue using Maestro.';
    config.okText = 'Refresh page';
    config.width = '600px';

    this.modalService.open(config, 'RefreshModalComponent').then(async () => location.reload());
    clearInterval(this.interval);
  }
}
