import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { ActivityLogBaseService } from 'src/app/services/activityLogService/activityLogBaseService';
import { ApiService } from '../api.service';
import { AuditItemFileType, AuditItemModel } from "src/app/models/auditItemModel";
import { AuditEventSourceType } from '../data/auditEventSourceType';
import { AuditItem, IAuditItemObject, IPropertyAudit } from "../data/auditItem";
import { PropertyStatusCategoryTypeFromStatus, PropertyStatusType } from '../data/propertyStatusType';
import { addDueDiligenceDays, buildModelsFromIdentityIdAndDateGroups, formatUnderwritingStatus, setListingForComment } from "./activityLogUtils";
import { fileTypeStrings } from './activityLogDefinitions';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService  extends ActivityLogBaseService {

  public activeStatus = false;

  constructor(apiService: ApiService) {
    super(apiService);
  }

  getListingPropertyAudit(currentProperty: any): Promise<AuditItemModel[]> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.getListingPropertyAudit(currentProperty?.AOListingID).then((auditItems: AuditItem[]) => {
        let models: AuditItemModel[] = [];

        // Group events by identityId, then from same date time together
        models = buildModelsFromIdentityIdAndDateGroups(auditItems, models);

        // offerDueDiligenceDaysType: { display: "Offer Due Diligence Days Type", formatter: this.formatDueDiligenceType },
        // offerDueDiligenceDaysCount: { display: "Offer Due Diligence Days Count" },

        // Due Diligence is in 2 fields , the types and the number of days but display as 1 entry  "Due Diligence: 10 Business Days"
        // Both may change at the same time or separately on a different update
        // So we need to recreate the history to have the complete string
        const propertyAudit: IPropertyAudit = {
              currentOfferDueDiligenceDaysCount : null,
              currentOfferDueDiligenceDaysType : null,
              currentSellerCounterDueDiligenceDaysCount : null,
              currentSellerCounterDueDiligenceDaysType : null,
              currentCounterOfferDueDiligenceDaysCount : null,
              currentCounterOfferDueDiligenceDaysType : null,
              currentCounterSellerCounterDueDiligenceDaysCount : null,
              currentCounterSellerCounterDueDiligenceDaysType : null,
              previousUnderwritingStatusText : null
        };

        const modelsToRemove = [];

        this.lastStatusBeforeHidden = undefined;
        _.each(models, (model: AuditItemModel, modelsIndex) => {

          // TODO : temporary fix for pre-inspection review until rework of auditLog
          const idxPreinspection = model.auditItems.findIndex( auditItem => ((auditItem.message == "status" && auditItem.newValue == 1120) 
                                                                              || auditItem.message == "buyBoxClassification" 
                                                                              || auditItem.message == "documentsFolderUrl"));
          if(idxPreinspection > -1 ) {
            const excludeFields = ['updateIdentityId', 'updateDate'];
            model.auditItems = model.auditItems.filter((item, index)=> {
              return !excludeFields.includes(item.message);
            });
          }

          const addStatement: IAuditItemObject = {
                  addOfferDueDiligenceCount: null,
                  addOfferDueDiligenceType: null,
                  addCounterOfferDueDiligenceDaysCount: null,
                  addCounterOfferDueDiligenceDaysType: null,
                  addSellerCounterDueDiligenceDaysCount: null,
                  addSellerCounterDueDiligenceDaysType: null,
                  addCounterSellerCounterDueDiligenceDaysCount: null,
                  addCounterSellerCounterDueDiligenceDaysType: null
                };

          let shouldRemoveModel = false;
          _.each(model.auditItems, (ai: AuditItem, modelIndex) => {
            if(ai.message == "TMStatus" || ai.message == "subStatus" || ai.message == "inspectionStatus" ) {

              shouldRemoveModel = this.setListingForAuditAndUpdate(model, propertyAudit, ai, addStatement);

            } else if (ai.eventType == AuditEventSourceType.Imported) {
              // model.text.push("New Listing");
              this.lastStatusBeforeHidden = PropertyStatusType.New;
              model.underwritingStatusClass = 'a1-status-' + PropertyStatusCategoryTypeFromStatus(PropertyStatusType.New);
              model.underwritingStatusText = propertyAudit.previousUnderwritingStatusText = formatUnderwritingStatus(PropertyStatusType.New);

            } else if (ai.eventType == AuditEventSourceType.Comment) {

              setListingForComment(ai, currentProperty, model);

            } else if (ai.eventType == AuditEventSourceType.Comps) {
              model.compsOld = JSON.parse(ai.oldValue);
              model.compsNew = JSON.parse(ai.newValue);

            } else if (ai.eventType == AuditEventSourceType.Audit || ai.eventType == AuditEventSourceType.Update || ai.eventType == AuditEventSourceType.ListingDataUpdated) {

              shouldRemoveModel = this.setListingForAuditAndUpdate(model, propertyAudit, ai, addStatement);

            } else if (ai.eventType == AuditEventSourceType.Status || ai.eventType == AuditEventSourceType.Undo) {
              shouldRemoveModel = this.setListingForStatusAndUndo(ai, model, propertyAudit, currentProperty);

            } else if (ai.eventType == AuditEventSourceType.File) {

              let fileType = _.find(model.fileTypes, (ft) => ft.type == ai.s3Type);
              if (!fileType) {
                fileType = new AuditItemFileType();
                fileType.type = ai.s3Type;
                fileType.label = fileTypeStrings[ai.s3Type];
                model.fileTypes.push(fileType);
              }

              fileType.hasDeleted = fileType.hasDeleted || ai.s3IsDeleted;
              if (!ai.s3IsDeleted) {
                fileType.files.push({ filename: ai.s3Filename, filepath: ai.s3Filepath });
              }
            } else if (ai.eventType == AuditEventSourceType.Note) {
              this.setListingForNotes(ai, model);
            } else if (ai.eventType == AuditEventSourceType.Tags) {
              this.setListingForTags(ai, model);
            }

          });

          if(shouldRemoveModel) {
            modelsToRemove.push(modelsIndex);
          }

          addDueDiligenceDays(model, addStatement.addOfferDueDiligenceCount, addStatement.addOfferDueDiligenceType, propertyAudit.currentOfferDueDiligenceDaysCount, propertyAudit.currentOfferDueDiligenceDaysType);
          addDueDiligenceDays(model, addStatement.addSellerCounterDueDiligenceDaysCount, addStatement.addSellerCounterDueDiligenceDaysType, propertyAudit.currentSellerCounterDueDiligenceDaysCount, propertyAudit.currentSellerCounterDueDiligenceDaysType);
          addDueDiligenceDays(model, addStatement.addCounterOfferDueDiligenceDaysCount, addStatement.addCounterOfferDueDiligenceDaysType, propertyAudit.currentCounterOfferDueDiligenceDaysCount, propertyAudit.currentCounterOfferDueDiligenceDaysType);
          addDueDiligenceDays(model, addStatement.addCounterSellerCounterDueDiligenceDaysCount, addStatement.addCounterSellerCounterDueDiligenceDaysType, propertyAudit.currentCounterSellerCounterDueDiligenceDaysCount, propertyAudit.currentCounterSellerCounterDueDiligenceDaysType);

        });

        models = models.filter((value, index)=> {
          return modelsToRemove.indexOf(index) == -1;
        });

        resolve(models);
      }, (error) => {
        reject(error);
      });
    });
  }
}
