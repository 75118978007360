import { Component, Input, Output, EventEmitter, ViewContainerRef, NgZone, TemplateRef, HostBinding, forwardRef, SimpleChanges } from '@angular/core';

import * as JsUtils from 'src/app/utils/jsUtils';
import { createPopper, Placement } from '@popperjs/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-a1-select',
  styleUrls: ['./select.component.scss'],
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]

})
export class SelectComponent implements ControlValueAccessor {
  @HostBinding('class') class = 'a1-select';
  @Input() customClasses = null;
  @Input() isFilter = false;
  @Input() isLoading = false;
  @Input() labelKey = 'label';
  @Input() idKey = 'id';
  @Input() optionCss = '';
  @Input() dropdownIcon = `a1-dropdown-chevron-icon`;
  @Input() displayLabel = true;
  @Input() placement: Placement = 'bottom';
  @Input() checked = false;
  @Input() isSharedFilter = false;
  @Input() isMaxHeight = false;

  @Input() offset = {
    skidding: 0,
    distance: 4,
  };

  _isDisabled = false;
  @Input()
  get disabled() {
    return this._isDisabled;
  }
  set disabled(value) {
    this._isDisabled = value;
  }

  protected _optionsFilter = [];
  protected _options = [];
  

  get optionsFilter(): any[] {
    return this._optionsFilter;
  }
  
  @Input()
  get options(): any[] { 
    this.filterOptions(null);
    return this._options;
  }

  set options(values: any[]) {
    this._options = values;
    this.filterOptions(values);
    this.updateSelection();
  }
  
  protected _selectedId = -1;
  @Input()
  get selectedId(): any {
    return this._selectedId;
  }
  set selectedId(value: any) {
    this._selectedId = value ? value : -1;
    this.updateSelection();
  }

  protected _selectedShareFilter;
  @Input()
  get selectedShareFilter(): any {
    return this._selectedShareFilter;
  }
  set selectedShareFilter(value: any) {
    this._selectedShareFilter = value ? value : false;
  }

  @Input() nullable;

  @Input() placeholder;
  @Input() leftIconClass;
  @Input() isSuperUser;
  @Input() optionRightIconClass;

  @Output() selectChange = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() optionRightButtonClicked: EventEmitter<any> = new EventEmitter();

  selectedModel;

  isOpen = false;
  view: any;
  backdropView: any;
  popperRef: any;

  constructor(private vcr: ViewContainerRef, private zone: NgZone) { }

  get label() {
    if (this.selectedModel) {
      if (this.selectedModel.autoAssign) {
        return this.selectedModel.autoAssign;
      }

      if (this.selectedModel[this.labelKey]) {
        return this.selectedModel[this.labelKey];
      }
    }
    return this.placeholder;
  }

  get labelClass() {
    return this.selectedModel ? 'a1-button-label a1-button-label-filled' : 'a1-button-label';
  }

  get dropdownCustomClasses() {
    return this.customClasses? this.customClasses + '' :  '';
  }

  updateSelection() {
    if (this.options && !JsUtils.isNullOrEmpty(this.selectedId)) {
      this.selectedModel = this.options.find(
        currentOption =>  this.isSharedFilter ? currentOption[this.idKey] === this.selectedId && this.selectedShareFilter === !!currentOption.shareFilter : currentOption[this.idKey] === this.selectedId
      );
    } else {
      this.selectedModel = null;
    }
  }

  filterOptions(values) {
    if(this._options && this._options.length > 0 && this.isSharedFilter) {
      this._optionsFilter = this._options.filter(option => !option.isTitle);
      this._optionsFilter = this._optionsFilter.sort((opt1, opt2) => opt1.shareFilter === opt2.shareFilter ? 0 : opt1.shareFilter ? -1 : 1);

      if(this._optionsFilter.findIndex(option => option.shareFilter) !== -1) {
        this._optionsFilter.unshift({ name: 'Shared Filters', isTitle: true});
      }

      const idx = this._optionsFilter.findIndex(option => !option.shareFilter && !option.isTitle);
      if(idx === 0) {
        this._optionsFilter.unshift({ name: 'My Filters', isTitle: true});
      } else if (idx !== -1) {
        this._optionsFilter.splice(idx, 0, { name: 'My Filters', isTitle: true});
      }
    } else if (values) {
      this._optionsFilter = values;
    }
  }

  open(dropdownTpl: TemplateRef<any>, origin: any, backdropTpl: TemplateRef<any>, event) {
    if (this.disabled) {
      return;
    }

    this.backdropView = this.vcr.createEmbeddedView(backdropTpl);
    const bd = this.backdropView.rootNodes[0];
    document.body.appendChild(bd);

    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const dropdown = this.view.rootNodes[0];

    document.body.appendChild(dropdown);

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, dropdown, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this.offset.skidding, this.offset.distance],
            },
          },

          // modifier to make popper the same width as the referencing element
          // reference https://popper.js.org/docs/v2/modifiers/community-modifiers/
          {
            name: "sameWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({ state }) => {
              state.styles.popper.minWidth = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.minWidth = `${
                state.elements.reference.getBoundingClientRect().width
              }px`;
            }
          },

          // modifier to prevent popper from controlling the placement
          // allows us to position popper where we want even if it overflows
          {
            name: 'flip',
            enabled: true,
            options: {
              flipVariations: false,
            },
          }
        ]
      });
    });
    this.filterOptions(null);
    this.isOpen = true;
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;

      this.closed.emit();
      this.popperRef.destroy();
      this.view.destroy();
      // this.searchControl.patchValue('');
      this.view = null;
      this.popperRef = null;

      this.backdropView.destroy();
      this.backdropView = null;
    }
  }

  isActive(option) {
    if (!this.selectedModel) {
      return false;
    }
    return option[this.idKey] === this.selectedModel[this.idKey];
  }

  select(option) {
    if (option) {
      this._selectedId = option[this.idKey];
      this._selectedShareFilter = option.shareFilter;
      this.selectedModel = option;
    } else {
      this._selectedId = -1;
      this._selectedShareFilter = false;
      this.selectedModel = null;
    }

    if(this.isSharedFilter) {
      this.selectChange.emit({filterId: this._selectedId, shareFilter: this._selectedShareFilter});
    } else {
      this.selectChange.emit(this._selectedId);
    }

    this.close();
  }

  onBackdropClicked() {
    this.close();
  }

  optionRightButtonClick($event: MouseEvent, option) {
    $event.preventDefault();
    $event.stopPropagation();

    if(this.isSharedFilter) {
      this.optionRightButtonClicked.emit({filterId: option[this.idKey], shareFilter: option.shareFilter});
    } else {
      this.optionRightButtonClicked.emit(option[this.idKey]);
    }
    
    this.close();
  }


  onChange(value: Number) {

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: any): void {
    this._selectedId = value;
    this.updateSelection();

    this.onChange(value);
  }
}
