import { ListingProperty } from "../../data/listingPropety";
import { PropertyStatusType } from "../../data/propertyStatusType";
import {
  UNDERWRITER_COMMANDS_NAME,
  UNDERWRITER_OPTIONS_LABEL,
} from "../model/underwriterCommandsAndActions";
import { UNDERWRITER_ACTIONS } from "../model/updateRequest";
import * as _ from "lodash";

function hiddenFlowStatusValidation(property: ListingProperty) {
  return [PropertyStatusType.Hidden].includes(property.underwritingStatus);
}

function newFlowStatusValidation(property: ListingProperty) {
  return [
    PropertyStatusType.New,
  ].includes(property.underwritingStatus);
}

function offerReadyFlowStatusValidation(property: ListingProperty) {
  return ![
    PropertyStatusType.InspectionReview,
    PropertyStatusType.InvestorApprovalReady,
    PropertyStatusType.InvestorApprovalUpdated,
    PropertyStatusType.InvestorApprovalUrgent,
    PropertyStatusType.Counter,
    PropertyStatusType.CounterReason,
    PropertyStatusType.CounterSent,
    PropertyStatusType.CounterToUW,
    PropertyStatusType.Countered,
    PropertyStatusType.TraderCountered,
    PropertyStatusType.OfferReady,
    PropertyStatusType.OfferReadyReason,
    PropertyStatusType.OfferReadyUrgent,
    PropertyStatusType.OfferReadyUrgentTM,
    PropertyStatusType.OfferReadyUrgentTMReason,
    PropertyStatusType.OfferDelivered,
    PropertyStatusType.ClearToCloseReady,
    PropertyStatusType.ClearToCloseUrgent,
    PropertyStatusType.PreInspectionReview,
    PropertyStatusType.PreInvestorApprovalUpdated,
    PropertyStatusType.PreInvestorApprovalReady,
    PropertyStatusType.LostDead,
    PropertyStatusType.Canceled
  ].includes(property.underwritingStatus);
}

function concessionFlowStatusValidation(property: ListingProperty) {
  return [
    PropertyStatusType.OfferReady,
    PropertyStatusType.OfferReadyReason,
    PropertyStatusType.OfferReadyUrgent,
    PropertyStatusType.OfferReadyUrgentTM,
    PropertyStatusType.OfferReadyUrgentTMReason,
  ].includes(property.underwritingStatus);
}

function counterOfferFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.Counter,
    PropertyStatusType.CounterReason,
    PropertyStatusType.CounterSent,
    PropertyStatusType.CounterToUW,
    PropertyStatusType.TraderCountered,
    PropertyStatusType.Countered,
  ].includes(currentProperty.status);
}

function closingPriceFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.ClosingReady,
    PropertyStatusType.ClearToClose,
    PropertyStatusType.ClearToCloseUrgent,
    PropertyStatusType.ClearToCloseReady,
  ].includes(currentProperty.status);
}

function inspectionReviewFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.InspectionReview,
    PropertyStatusType.InvestorApprovalUrgent,
    PropertyStatusType.InvestorApprovalUpdated,
    PropertyStatusType.InvestorApprovalReady,
  ].includes(currentProperty.underwritingStatus);
}

function preInspectionReviewFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.PreInspectionReview,
    PropertyStatusType.PreInvestorApprovalUpdated,
    PropertyStatusType.PreInvestorApprovalReady,
  ].includes(currentProperty.underwritingStatus);
}

function canceledFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.Canceled
  ].includes(currentProperty.status);
}

function lostDeadFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.LostDead
  ].includes(currentProperty.status);
}

function offerDeliveredFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.OfferDelivered
  ].includes(currentProperty.status);
}

function hiddenFlowActionBuilder(propertiesStatus, basicActionsIds: string[], flowActionId: string[], fn: string, baseListingActions: any, flowNumber) {
  basicActionsIds.forEach(bAId => {
    if (!flowActionId.includes(bAId)) {
      if (bAId != 'hide') {
        delete baseListingActions[bAId];
      } else {
        baseListingActions[bAId].disabled = true;
      }
    }
  });
  if (fn == 'HiddenFlow' && flowNumber == 1) {
    delete baseListingActions['hide'];
  }
}


function FlowActionBuilder(propertiesStatus, basicActionsIds: string[], flowActionId: string[], fn: string, baseListingActions: any, flowNumber) {
  basicActionsIds.forEach(bAId => {
    if (!flowActionId.includes(bAId)) {
        delete baseListingActions[bAId];
    }
  });
}

function inspectionReviewFlowActionsBuilder(propertiesStatus, basicActionsIds: string[], flowActionId: string[], fn: string, baseListingActions: any, flowNumber) {
  basicActionsIds.forEach(bAId => {
    if (!flowActionId.includes(bAId)) {
        delete baseListingActions[bAId];
    }
  });
  const isNotApproval = !!_.intersection(propertiesStatus, [
    PropertyStatusType.InspectionReview,
    PropertyStatusType.InvestorApprovalUrgent,
    PropertyStatusType.InvestorApprovalReady,
  ]).length;
  if (isNotApproval && flowActionId.includes('ready')) {
    baseListingActions['ready'].disabled = true;
  }
}

function preInspectionReviewFlowActionsBuilder(propertiesStatus, basicActionsIds: string[], flowActionId: string[], fn: string, baseListingActions: any, flowNumber) {
  basicActionsIds.forEach(bAId => {
    if (!flowActionId.includes(bAId)) {
        delete baseListingActions[bAId];
    }
  });
  const isNotApproval = !!_.intersection(propertiesStatus, [
    PropertyStatusType.PreInspectionReview,
    PropertyStatusType.PreInvestorApprovalReady,
  ]).length;
  if (isNotApproval && flowActionId.includes('ready')) {
    baseListingActions['ready'].disabled = true;
  }
}

export const listingsFlows = [
  {
    flowName: "HiddenFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },
      show: {
        id: UNDERWRITER_ACTIONS.SHOW,
        label: UNDERWRITER_OPTIONS_LABEL.SHOW,
        disabled: false,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.show,
      },
      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
    },
    validator: hiddenFlowStatusValidation,
    builder: hiddenFlowActionBuilder
  },
  {
    flowName: "NewFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },
      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: false,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      }
    },
    validator: newFlowStatusValidation,
    builder: FlowActionBuilder
  },
  {
    flowName: "UnderwrittenFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },
      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: false,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      }
    },
    validator: offerReadyFlowStatusValidation,
    builder: FlowActionBuilder
  },
  {
    flowName: "OfferReadyFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },
      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: false,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      },
    },
    validator: concessionFlowStatusValidation,
    builder: FlowActionBuilder
  },
  {
    flowName: "counterOfferFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },

      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      },
    },
    validator: counterOfferFlowStatusValidation,
    builder: FlowActionBuilder
  },
  {
    flowName: "ClosingPriceFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },

      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      },
    },
    validator: closingPriceFlowStatusValidation,
    builder: FlowActionBuilder
  },
  {
    flowName: "PreInspectionReviewFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },

      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: false,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      },
      approval: {
        id: UNDERWRITER_ACTIONS.APPROVAL,
        label: UNDERWRITER_OPTIONS_LABEL.SUBMIT_FOR_APPROVAL,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.submitApproval,
      },
    },
    validator: preInspectionReviewFlowStatusValidation,
    builder: preInspectionReviewFlowActionsBuilder
  },
  {
    flowName: "InspectionReviewFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },

      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: false,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      },
      approval: {
        id: UNDERWRITER_ACTIONS.APPROVAL,
        label: UNDERWRITER_OPTIONS_LABEL.SUBMIT_FOR_APPROVAL,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.submitApproval,
      },
    },
    validator: inspectionReviewFlowStatusValidation,
    builder: inspectionReviewFlowActionsBuilder
  },
  {
    flowName: "CanceledFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },

      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      },
    },
    validator: canceledFlowStatusValidation,
    builder: FlowActionBuilder
  },

  {
    flowName: "LostDeadFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },

      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      },
    },
    validator: lostDeadFlowStatusValidation,
    builder: FlowActionBuilder
  },
  {
    flowName: "OfferDeliveredFlow",
    options: {
      drilldown: {
        id: UNDERWRITER_ACTIONS.DRILLDOWN,
        label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
        disabled: false,
        main: true,
        actionID: UNDERWRITER_COMMANDS_NAME.drilldown,
      },
      ready: {
        id: UNDERWRITER_ACTIONS.READY,
        label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      hide: {
        id: UNDERWRITER_ACTIONS.HIDE,
        label: UNDERWRITER_OPTIONS_LABEL.HIDE,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.sendTM,
      },
      draft: {
        id: UNDERWRITER_ACTIONS.DRAFT,
        label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
        disabled: true,
        main: false,
        actionID: UNDERWRITER_COMMANDS_NAME.saveDraft,
      }
    },
    validator: offerDeliveredFlowStatusValidation,
    builder: FlowActionBuilder
  },
];

export function getActiveListingsFlows(properties: ListingProperty[]) {
  const activeFlows = {};
  properties.forEach((property) => {
    const flow = listingsFlows.find((lf) => {
      return lf.validator(property);
    });
    if (!activeFlows[flow?.flowName]) {
      activeFlows[flow?.flowName] = {
        ...flow.options,
      };
    }
  });
  return activeFlows;
}

const listingConfigActions = {
  baseListingActions: {
    drilldown: {id: UNDERWRITER_ACTIONS.DRILLDOWN, label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN, disabled: false, main: true, actionID: UNDERWRITER_COMMANDS_NAME.drilldown },
    ready: {id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
    show: {id: UNDERWRITER_ACTIONS.SHOW, label: UNDERWRITER_OPTIONS_LABEL.SHOW, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.show },
    hide: {id: UNDERWRITER_ACTIONS.HIDE, label: UNDERWRITER_OPTIONS_LABEL.HIDE, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.hide }
  },
  defaultListingActions: {
    drilldown: {id: UNDERWRITER_ACTIONS.DRILLDOWN, label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN, disabled: false, main: true, actionID: UNDERWRITER_COMMANDS_NAME.drilldown },
    ready: {id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
    hide: {id: UNDERWRITER_ACTIONS.HIDE, label: UNDERWRITER_OPTIONS_LABEL.HIDE, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.show }
  }
};

function getPropertiesStatus(properties: ListingProperty[]) {
  const propertiesStatus = [];
  if(!properties.length) {
    return propertiesStatus;
  }
  const status = properties.map(property => property.underwritingStatus);
  status.forEach(st => {
    if (!propertiesStatus.includes(st)) {
      propertiesStatus.push(st);
    }
  });
  return propertiesStatus;
}


function buildActionFlow(propertiesStatus, basicActionsIds, flowActionId, flowName, baseListingActions, flowNumber) {
  const flow = listingsFlows.filter(lf => lf.flowName == flowName) || [];
  if (flow[0] && flow[0].builder) {
    flow[0].builder(propertiesStatus, basicActionsIds, flowActionId, flowName, baseListingActions, flowNumber);
  }
}

export function getListingActions(properties: ListingProperty[]) {
  const propertiesStatus = getPropertiesStatus(properties);

  const activeFlows = getActiveListingsFlows(properties);
  const activeFlowsNames = Object.keys(activeFlows);

  const baseListingActions = _.cloneDeep(listingConfigActions.baseListingActions);

  const finalActions = [];
  const basicActionsIds = Object.keys(baseListingActions);

  activeFlowsNames.forEach(fn => {
    const flowActionId = Object.keys(activeFlows[fn]);
    buildActionFlow(propertiesStatus, basicActionsIds, flowActionId, fn, baseListingActions, activeFlowsNames.length);

    flowActionId.forEach(aId => {
      if (!basicActionsIds.includes(aId)) {
        delete activeFlows[fn][aId];
      } else if (activeFlows[fn][aId].disabled && baseListingActions[aId]) {
        baseListingActions[aId].disabled = true;
      }
    });
  });

  Object.keys(baseListingActions).forEach(ba => {
    finalActions.push(baseListingActions[ba]);
  });

  return finalActions;
}

function build(basicActionsIds: string[], flowActionId: string[], fn: string, baseListingActions: any) {
  basicActionsIds.forEach(bAId => {
    if (!flowActionId.includes(bAId)) {
      if (bAId != 'hide') {
        console.log(fn, bAId);
        delete baseListingActions[bAId];
      } else if (bAId == 'hide' && fn == 'HiddenFlow' && basicActionsIds.length == 1) {
        console.log(fn, bAId);
        delete baseListingActions[bAId];
      } else {
        baseListingActions[bAId].disabled = true;
      }
    }
  });
}

