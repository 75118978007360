import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { basicRejectionReasons, IRejectionComment, rejectionReasonsSeparator } from "src/app/models/inderwriterNoteModel";

@Injectable({
  providedIn: 'root'
})
export class WorkFlowNoteService {
  rejectionCommentngSelectOption;
  selectedRejectionReasons = [];

  rejectionConfig: IRejectionComment = {
    active: false,
    disabled: false,
    updated: false,
    reasonMode: false,
    reason: {
      oldValue: null,
      newValue: null
    },
    hasError: false
  };

  constructor() {}

  initRejectionCommentngSelectOption(rejectionReasonList?: any[]) {
    this.rejectionCommentngSelectOption = [];
    const currentList = rejectionReasonList ?? basicRejectionReasons;
    currentList.forEach((item) => {
      const isCustomReason = !basicRejectionReasons.includes(item.trim());
      this.rejectionCommentngSelectOption.push(
        {
          id: isCustomReason? item.trim().slice(8) : item.trim(),
          name: item.trim(),
          label: item.trim(),
          selected: false,
          custom: isCustomReason? true : false
        }
      );
    });

    return this.rejectionCommentngSelectOption;
  }

  resetRejectionCommentConfig() {
    this.rejectionCommentngSelectOption = [];
    this.selectedRejectionReasons = [];

    this.rejectionConfig = {
      active: false,
      disabled: false,
      updated: false,
      reasonMode: false,
      reason: {
        oldValue: null,
        newValue: null
      },
      hasError: false
    };
    return this.rejectionConfig;
  }

  initRejectionCommentConfig(config: IRejectionComment) {
    this.rejectionConfig = _.cloneDeep(config);

    this.rejectionConfig.reason.oldValue = [];
    this.rejectionConfig.reason.newValue = [];

    if(config && config.reason) {
      if(config.reason.oldValue) {
        this.rejectionConfig.reason.oldValue = (config.reason.oldValue || '').split(rejectionReasonsSeparator);
      }
      if(config.reason.newValue) {
       this.rejectionConfig.reason.newValue = (config.reason.newValue || '').split(rejectionReasonsSeparator);
      }
    }
    this.buildSelectedRejectionReasons(config);
    this.buildRejectionReasonsList();

  }

  buildRejectionReasonsList() {
    const transformedbasicRejectionReasons = [];
    const customRejectionReason = [];

    basicRejectionReasons.forEach(item => {
      const tItem = (item || '').trim().toLowerCase();
      if (tItem) {
        transformedbasicRejectionReasons.push(tItem);
      }
    });

    this.rejectionConfig.reason.oldValue.forEach(oldReason => {
      const transformedOldReason = (oldReason || '').trim();
      if (transformedOldReason && !transformedbasicRejectionReasons.includes(transformedOldReason.toLowerCase())) {
        customRejectionReason.push(transformedOldReason);
      }
    });
   this.rejectionConfig.reason.newValue.forEach(newReason => {
      const transformedNewReason = (newReason || '').trim();
      if (transformedNewReason && !transformedbasicRejectionReasons.includes(transformedNewReason.toLowerCase()) && !customRejectionReason.includes(transformedNewReason)) {
        customRejectionReason.push(transformedNewReason);
      }
    });

    this.initRejectionCommentngSelectOption([...basicRejectionReasons, ...customRejectionReason]);

  }

  buildSelectedRejectionReasons(config) {
    let selectedReasons;
    this.selectedRejectionReasons = [];

    if (config.updated) {
      selectedReasons =this.rejectionConfig.reason.newValue;
    } else {
      selectedReasons = this.rejectionConfig.reason.oldValue;
    }

    selectedReasons.forEach(item => {
      const isCustomReason = !basicRejectionReasons.includes(item.trim());
      this.selectedRejectionReasons.push(
        {
          id: isCustomReason? item.trim().slice(8) : item.trim(),
          name: item.trim(),
          label: item.trim(),
          selected: false,
          custom: isCustomReason? true : false
        }
      );
    });
  }

  refreshRejectionReasonModel(newValue: string[]): IRejectionComment {
   this.rejectionConfig.reason.newValue = this.formateNewValue (newValue);
    let newUpdate = false;

    if (this.rejectionConfig.reason.oldValue.length &&this.rejectionConfig.reason.newValue.length) {

     this.rejectionConfig.reason.newValue.forEach(oldItem => {
        if (!this.rejectionConfig.reason.oldValue.includes(oldItem)) {
          newUpdate = true;
        }
      });

      if (!newUpdate && (this.rejectionConfig.reason.oldValue.length !==this.rejectionConfig.reason.newValue.length)) {
        newUpdate = true;
      }

    } else if ((!this.rejectionConfig.reason.oldValue.length &&this.rejectionConfig.reason.newValue.length) || (this.rejectionConfig.reason.oldValue.length && !this.rejectionConfig.reason.newValue.length)) {
      newUpdate = true;
    }

    this.rejectionConfig.updated = newUpdate;
    this.rejectionConfig.hasError = false;
    return this.rejectionConfig;
  }



  formateNewValue (newValue: any[]) {
    if (!newValue) {
      return [];
    }
    const formattedNewValue: string [] = [];
    newValue.forEach(nv => {
      if (nv && nv.name) {
        formattedNewValue.push(nv.name);
      } else {
        formattedNewValue.push(nv);
      }
    });

    return formattedNewValue;

  }
}
