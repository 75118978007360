import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from "ag-grid-enterprise";

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey(`CompanyName=Avenue One,LicensedApplication=avenueone.com,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-027382,ExpiryDate=20_October_2023_[v2]_MTY5Nzc1NjQwMDAwMA==6b905e9174c1f5fd415090386f0ae69c`);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
