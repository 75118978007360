import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {PropertyGridComponent} from 'src/app/pages/propertylist/propertyGrid/propertyGrid.component';
import {DownloadService, UnderwriterService} from '../../services';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent implements OnInit {
  addressSearchTerm = new FormControl('', [Validators.minLength(3)]);
  propertyGridComponent: PropertyGridComponent;

  constructor(
    private underwriterService: UnderwriterService,
    private downloadService: DownloadService
  ) {
  }

  ngOnInit(): void {
    this.underwriterService.propertyGridComponent$.subscribe(propertyGridComponent => {
      if (propertyGridComponent) {
        this.propertyGridComponent = propertyGridComponent;
        this.resetAddressSearchTerm();

        this.propertyGridComponent.clearAddress.subscribe(() => {
          this.resetAddressSearchTerm();
        });
      }
    });
  }

  onSubmit(): void {
    if (this.addressSearchTerm.valid && this.propertyGridComponent) {
      this.propertyGridComponent.addressSearch = encodeURIComponent(this.addressSearchTerm.value.trim());
      const searchActive = this.propertyGridComponent.addressSearch ? true : false;
      this.downloadService._isSearchAddressActive.next(searchActive);
      this.propertyGridComponent.searchByAddress();
    }
  }

  private resetAddressSearchTerm(): void {
    this.downloadService._isSearchAddressActive.next(false);  
    this.addressSearchTerm.setValue('', {emitEvent: false});
  }
}
