import { Component, ViewContainerRef, NgZone } from '@angular/core';
import { ParentModalComponent } from '../parentModalComponent';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProformaConfig } from 'src/app/panels/proforma/proforma_config';
import { ITargetOption } from 'src/app/panels/proforma/proforma';
import { EntityUtils } from "../../../services/entityUtils";


@Component({
  selector: 'app-missing-fields-modal',
  templateUrl: './missing-fields-modal.component.html',
  styleUrls: ['../modal.component.scss','./missing-fields-modal.component.scss']
})
export class MissingFieldsModalComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  modalForm: FormGroup;

  selectedEntityOptions: ITargetOption[] = new ProformaConfig().selectedEntityOptions;

  constructor(vcr: ViewContainerRef, zone: NgZone, fb: FormBuilder) {
    super();

    this.modalForm = fb.group({
      purchasingEntity: ''
    });

    EntityUtils.getEntities().subscribe({
      next: values => this.selectedEntityOptions = values.filter(e => !!e.id),
      error: err => console.log(err)
    });

    this.vcr = vcr;
    this.zone = zone;
  }

  get purchasingEntity() {
    return this.modalForm.get('purchasingEntity').value;
  }

  get isSaveDisabled() {
    return !this.purchasingEntity;
  }

  onCancelClicked() {
    this.onClickClose(MissingFieldsModalComponent.CANCEL);
  }

  onOkClick() {
    this.onClickClose(MissingFieldsModalComponent.OK);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      this.closed.next({action: closeResult, purchasingEntity: this.purchasingEntity});
    });
  }

  purchasingEntityChange($event) {
    if($event) {
      const selectedOption = this.selectedEntityOptions.filter(entityOptions => {
        return entityOptions.id == $event;
      });
      if ( selectedOption && selectedOption[0]) {
        this.modalForm.get('purchasingEntity').setValue(selectedOption[0].label);
        return;
      }

    }
      this.modalForm.get('purchasingEntity').setValue($event);
  }
}
