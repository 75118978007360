import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";
import { IUpdateRequest } from "./model/updateRequest";

export class UpdateRequestFactory implements IUpdateRequest {
    underwriteReference: UnderwriterPanelComponent;
    activeStep;
    field;
    flowName;
    value: number | string;
    comment: string;
    displayValue;
    displayName: string;
    requester: string;
    externalLink: string;

    initUwReference(uwReference: UnderwriterPanelComponent) {
        this.underwriteReference = uwReference;
    }
    getReference() {
        return this.underwriteReference;
    }
    setProperty(propertyName) {
        if (!this[propertyName]) {
            this[propertyName] = null;
        }
    }
    constructor(option) {
        this.externalLink = option.externalLink;
        this.flowName = option.flowName;
        this.activeStep = option.activeStep;
        this.field = option.field;
        this.value = option.value;
        this.comment = option.comment;
        this.displayValue = option.displayValue;
        this.displayName = option.displayName;
    }
}
