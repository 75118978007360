import { FormGroup } from '@angular/forms';

export class PasswordFormValidator {
  static checkMatchingPassword(group: FormGroup): any {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;

    if (password != confirmPassword) {
      group.get('confirmPassword').setErrors( {MatchPassword: true} );
      return;
    } else {
      return null;
    }
  }
}
