/*global google*/
/// <reference types="@types/google.maps" />

import { ListingComps } from 'src/app/services/data/listingComps';

export class CompMapMarkerService{

  createCompsMapMarker(latlng, html, aoListingId, data, isComp){
    return new this.CompMapMarker(latlng, html, aoListingId, data, isComp);
  }

  CompMapMarker = class extends google.maps.OverlayView {

    latlng;
    html;
    div: HTMLDivElement;
    aoListingId;
    data: ListingComps;
    isComp = false;
    isDot = false;

    selected = false;
    highlighted = false;

    sibling: any;

    /**
     * Contructor
     * @param latlng Latitude/Longitude
     * @param html marker html
     * @param aoListingId ** The Maestro listings.AOListingID. Don't use data.AOListingID ***
     * @param data can be a listing or compListing
     * @param isComp to know what data type is the data param
     */
    constructor(latlng, html, aoListingId, data, isComp = true) {
      super();
      this.latlng = latlng;
      this.html = html;
      this.aoListingId = aoListingId;
      this.data = data;
      this.isComp = isComp;
      // this.setMap(null);
    }

    highlight(highlighted: boolean) {
      this.highlighted = highlighted;
      this.applyStyle();

      if (this.sibling) {
        this.sibling.highlighted = highlighted;
        this.sibling.applyStyle();
      }
    }


    select(selected: boolean) {
      this.selected = selected;
      this.applyStyle();

      if (this.sibling) {
        this.sibling.selected = selected;
        this.sibling.applyStyle();
      }
    }

    createDiv() {
      this.div = document.createElement("div");
      this.div.className = this.isComp ? "a1-map-marker" : "a1-prop-map-marker";
      this.div.style.position = "absolute";

      if (this.isDot) {
        this.div.classList.add('dot');
      }

      if (this.html) {
        this.div.innerHTML = this.html;
      }
      this.div.addEventListener("click", event => {
        google.maps.event.trigger(this, "click", event);
      });
    }

    appendDivToOverlay() {
      const panes = this.getPanes();
      panes.floatPane.appendChild(this.div);
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      const offsetx = this.isComp ? 28 : 10;
      const offsety = this.isComp ? 54 : 24;
      if (point) {
        this.div.style.left = `${point.x - offsetx}px`;
        this.div.style.top = `${point.y - offsety}px`;
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv();
        this.appendDivToOverlay();
      }
      this.positionDiv();
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }

    getPosition() {
      return this.latlng;
    }

    getDraggable() {
      return false;
    }

    private applyStyle() {
      if (!this.div) {
        return;
      }

      this.div.classList.toggle('selected', this.selected);
      this.div.classList.toggle('highlighted', this.highlighted);
    }
  }
}
