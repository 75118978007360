export enum AuditEventSourceType {
  None= 0,
  /**
   * DataIngestion Initial Import from AO
   */
  Imported= 1,
  /**
   * Status change
   */
  Status= 5,
  /**
   * Data value change
   */
  Audit= 10,
  /**
   * Comps selection change
   */
  Comps= 15,
  /**
   * Comment
   */
  Comment= 20,
  /**
   * DataIngestion Update from AO
   */
  Update= 25,
  /**
   * File ? (unused ?)
   */
  File= 30,
  /**
   * Undo changes
   */
  Undo= 35,

  /**
   * listing data update
   */
   ListingDataUpdated= 40,
   
  /**
   * note changes
   */
  Note= 100,

  /**
   * tags changes
   */
  Tags= 110
}
