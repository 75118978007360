export class AttributeRenderer {
  gui: HTMLElement;

  /***
   * Init the attribute renderer
   * @param using ag-grid colDef you can add a new parameter called attributes.
   * Attributes is an array the can contain a string or object (can be a mix of both)
   *
   * string
   * If it's a string, the raw value will be used for the attribute data.
   * ...
   * cellRendererParams: {
   *    attributes: ['data-date']
   *  }
   * ...
   *
   * object
   * If it's an object with a key and value, they will be used respectively.
   * ...
   * cellRendererParams: {
   *    attributes: [{key: 'data-test', value: 'Test Value'}]
   *  }
   * ...
   */
  init(params) {
    const attributes = params.attributes && Array.isArray(params.attributes)
      ? params.attributes
      : [];
    const defaultValue = params.valueFormatted;
    const attributeValue = params.value;

    this.gui = document.createElement('span');
    attributes.forEach(att => {
      if (typeof att === 'string') {
        this.gui.setAttribute(att, attributeValue);
      } else if (typeof att === 'object' && att.key && att.value) {
        this.gui.setAttribute(att.key, att.value);
      }
    });

    this.gui.textContent = defaultValue;
  }

  getGui() {
    return this.gui;
  }
}
