import { Component, Input, Output, EventEmitter, ViewContainerRef, NgZone, TemplateRef, HostBinding } from '@angular/core';

import { createPopper } from '@popperjs/core';


@Component({
  selector: 'app-a1-menu',
  styleUrls: ['./menu.component.scss'],
  templateUrl: './menu.component.html'
})
export class MenuComponent {
  @HostBinding('class') class = 'a1-menu';


  @Input() labelKey = 'label';
  @Input() idKey = 'id';

  private _options = [];
  @Input()
  get options(): any[] {
    return this._options;
  }
  set options(values: any[]) {
    this._options = values;
    this.updateOptions();
  }

  private _selectedId = [];
  @Input()
  get selectedId(): any {
    return this._selectedId;
  }
  set selectedId(value: any) {
    this._selectedId = value;
    this.updateSelection();
  }

  @Input() placeholder;
  @Input() leftIconClass;

  @Output() selectChange = new EventEmitter();
  @Output() closed = new EventEmitter();


  selectedModel;

  isOpen = false;
  // originalOptions;

  view: any;
  backdropView: any;
  popperRef: any;


  constructor(private vcr: ViewContainerRef, private zone: NgZone) {}

  get label() {
    return this.placeholder;
  }

  updateOptions() {
    if (this.options) {
      // this.originalOptions = [...this.options];
    } else {
      // this.originalOptions = [];
      return;
    }


    this.updateSelection();
  }

  updateSelection() {
    if (this.options && this.selectedId !== undefined) {
      this.selectedModel = this.options.find(
        currentOption => currentOption[this.idKey] === this.selectedId
      );
    } else {
      this.selectedModel = null;
    }
  }

 open(dropdownTpl: TemplateRef<any>, origin: any, backdropTpl: TemplateRef<any>, event) {
  this.backdropView = this.vcr.createEmbeddedView(backdropTpl);
  const bd = this.backdropView.rootNodes[0];
  document.body.appendChild(bd);

   this.view = this.vcr.createEmbeddedView(dropdownTpl);
   const dropdown = this.view.rootNodes[0];

   document.body.appendChild(dropdown);
   dropdown.style.width = `${origin.offsetWidth}px`;
  dropdown.style.minWidth = `${origin.offsetWidth}px`;


   this.zone.runOutsideAngular(() => {
     this.popperRef = createPopper(origin, dropdown, {
       modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
        {
          name: "autoWidth",
          enabled: true,
          fn: ({ state }) => {
             state.styles.popper.width = 'auto';
          },
          phase: "beforeWrite",
          requires: ["computeStyles"],
        }
       ]

     });
   });

   this.isOpen = true;
 }

  close() {
    if (this.isOpen) {
      this.isOpen = false;

      this.closed.emit();
      this.popperRef.destroy();
      this.view.destroy();
      // this.searchControl.patchValue('');
      this.view = null;
      this.popperRef = null;

      this.backdropView.destroy();
      this.backdropView = null;
    }
  }

  isActive(option) {
    if (!this.selectedModel) {
      return false;
    }

    return option[this.idKey] === this.selectedModel[this.idKey];
  }

  select(option) {
    if (option) {
      this._selectedId = option[this.idKey];
      this.selectedModel = option;
      this.selectChange.emit(option[this.idKey]);

    } else {
      this._selectedId = null;
      this.selectedModel = null;
      this.selectChange.emit(null);
    }

    this.close();
  }

  onBackdropClicked() {
    this.close();
  }

}
