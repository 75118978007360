import { Component, Output, EventEmitter, Input } from "@angular/core";
import { Router } from '@angular/router';

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import * as _ from 'lodash';
import * as moment from 'moment';

import { GridOptions, RowNode, ColumnState, PostSortRowsParams } from 'ag-grid-community';

// only import this if you are using the ag-Grid-Enterprise
import 'ag-grid-enterprise';

import * as JsUtils from 'src/app/utils/jsUtils';

import { UnderwriterService } from 'src/app/services';
import { ComparableListingColumnDefinition } from './columnDefinition';
import { Preferences } from 'src/app/models/preferences';
import { ListingProperty } from 'src/app/services/data/listingPropety';
import { HeaderComponent } from 'src/app/ui/agGrid/header-component/header.component';
import { ListingComps } from 'src/app/services/data/listingComps';
import { PinnedPropertyRowRendererComponent } from './pinnedPropertyRowRenderer';
import { IGridComponent } from 'src/app/ui/iGridComponent';
import { GridColumnTypes } from 'src/app/ui/agGrid/gridColumnTypes';
import { DateFormatRenderer } from "../../../ui/agGrid/dateFormatRenderer";
import { GridToolTipComponent } from "src/app/ui/grid-tool-tip/grid-tool-tip.component";
import { ClipboardCopyRendererComponent } from "src/app/ui/agGrid/clipboard-copy-renderer/clipboard-copy-renderer.component.";

export interface SelectionChangeEvent {
  AOCompID: any;
  selected: boolean;
}

@Component({
  selector: 'app-comparables-grid',
  templateUrl: 'comparablesGrid.component.html',
  styleUrls: ['comparablesGrid.scss'],
})
export class ComparablesGridComponent implements IGridComponent {
  @Output() filterChanged: EventEmitter<{ [key: string]: any }> = new EventEmitter();
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();
  @Output() rowSelected: EventEmitter<SelectionChangeEvent> = new EventEmitter();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  @Output() gridReady: EventEmitter<void> = new EventEmitter();
  @Output() clearFilter: EventEmitter<string> = new EventEmitter();
  @Output() clearAddress: EventEmitter<void> = new EventEmitter();

  @Input() listingName: string;
  @Input()
  showSelectedCompsOnly = false;
  previousViewTab = '';

  private _preferences: Preferences;
  @Input()
  get preferences(): Preferences {
    return this._preferences;
  }
  set preferences(preferences: Preferences) {
    this._preferences = preferences;
    if (preferences) {
      this.initialize();
    }
  }

  private _property: ListingProperty;
  @Input()
  get property(): ListingProperty {
    return this._property;
  }
  set property(value: ListingProperty) {
    this._property = value;

    this.pinnedRows = [];
    this.pinnedRows.push(value);
  }


  private _listingComps: ListingComps[] = [];
  @Input()
  get listingComps(): ListingComps[] {
    return this._listingComps;
  }
  set listingComps(comps: ListingComps[]) {
    this._listingComps = comps;
    this.createRowData();
  }


  private _view = 'rent';
  @Input()
  get view(): string {
    return this._view;
  }
  set view(value: string) {
    this.changeView(value);
  }

  private _editLocked: boolean;
  @Input()
  get editLocked(): boolean {
    return this._editLocked;
  }
  set editLocked(editLocked: boolean) {
    this._editLocked = editLocked;
  }

  private columnLayoutChanged: Subject<void> = new Subject<void>();
  private preferencesInitialized = false;

  gridOptions: GridOptions;
  gridName = 'comparablesGrid';

  pinnedRows = [];
  rentSelected = 0;
  saleSelected = 0;
  activeSelected = 0;

  rentRows: ListingComps[];
  saleRows: ListingComps[];
  activeRows: ListingComps[];
  rentRowsInitialized = false;
  saleRowsInitialized = false;
  activeRowsInitialized = false;

  filterModel = null;
  sortModel = null;
  filterInitialized = false;

  filterModelDefault = {
    Distance: {
      filter: 2,
      filterTo: null,
      filterType: "number",
      type: "lessThanOrEqual"
    },
    LastLeaseDate: {
      dateFrom: null,
      dateTo: null,
      filterType: "date",
      type: "greaterThan",
    }
  };

  isReady = false;

  constructor(
    public router: Router,
    private underwriterService: UnderwriterService) {

    this.filterModel = this.filterModelDefault;
    this.filterModel.LastLeaseDate.dateFrom = moment().subtract(1, 'y').subtract(1, 'd').format('YYYY-MM-DD');

    // Debounce the column changes related events a bit so
    // we don't spam-save the preferences while resizing/moving a column
    this.columnLayoutChanged
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.savePreferences();
      });

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = <GridOptions>{};
    this.gridOptions.columnTypes = GridColumnTypes.columnTypes;

    this.gridOptions.sideBar = false;
    this.gridOptions.multiSortKey = 'ctrl';
    this.gridOptions.rowSelection = 'multiple';

    this.gridOptions.rowHeight = 32;

    this.gridOptions.suppressCellFocus = true;
    this.gridOptions.tooltipShowDelay = 0;
    this.gridOptions.columnDefs = ComparableListingColumnDefinition.columns;

    this.gridOptions.rowClassRules = {
      'a1-exception': this.setClassRuleForException,
    };

    this.gridOptions.getRowClass = function (params) {
      if (params?.node?.data?.Exception) {
        return 'a1-exception';
      }
      return null;
    };

    this.gridOptions.defaultColDef = {
      headerComponent: <new () => HeaderComponent>HeaderComponent,
      headerComponentParams: {
        enableFilter: true,
        grid: this
      },

      menuTabs: ['filterMenuTab'],
      width: 150,
      minWidth: 32,
      enableValue: false,
      enableRowGroup: false,
      enablePivot: false,
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: false,
      filterParams:  {
        newRowsAction: 'keep'
      },
      tooltipComponent: 'GridToolTipComponent',
    };

    this.gridOptions.components = {
      customPinnedRowRenderer: PinnedPropertyRowRendererComponent,
      GridToolTipComponent: GridToolTipComponent,
      dateFormatRenderer: DateFormatRenderer,
      clipboardCopyRenderer: ClipboardCopyRendererComponent
    };

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { 'font-weight': '600', 'border-bottom': '2px solid #E4EEF7', 'border-top-style': 'none' };
      }
      return null;
    };

    this.gridOptions.rowClassRules = {
      'ag-row-selected': (node) => {
        return node?.data?.highlighted;
      }
    };

    this.gridOptions.overlayLoadingTemplate =
      '<div class=" a1-comp-grid-loader"><div class="a1-loader">Loading...</div></div>';
    this.gridOptions.overlayNoRowsTemplate =
      '<span class=""a1-empty-grid-message>No comparables available</span>';

    this.gridOptions.postSortRows = (params: PostSortRowsParams) => {
      let nextInsertPos = 0;
      for (let i = 0; i < params.nodes.length; i++) {
        if (params.nodes[i].isSelected()) {
          params.nodes.splice(nextInsertPos, 0, params.nodes.splice(i, 1)[0]);
          nextInsertPos++;
        }
      }
    };
  }

  initialize() {
    this.preferencesInitialized = false;

    this.updatePreferences();
    this.changeView('rent');
  }

  updateGridHeader() {
    this.setDateFormat();

    if (this.gridOptions.api.getColumnDef("LastLeaseDate")) {
      this.gridOptions.columnApi.setColumnsVisible(["LastLeasePrice"], true);
      this.gridOptions.columnApi.setColumnsVisible(["LastLeaseDate"], true);
      this.gridOptions.columnApi.setColumnsVisible(["LastSalePrice"], false);
      if (this.view == 'sale') {
        this.gridOptions.api.getColumnDef("LastLeasePrice").headerName = "Sale";
        this.gridOptions.api.getColumnDef("LastLeaseDate").headerName = "Sale Date";
      } else if (this.view == 'rent') {
        this.gridOptions.api.getColumnDef("LastLeasePrice").headerName = "Rent";
        this.gridOptions.api.getColumnDef("LastLeaseDate").headerName = "Rent Date";
      } else {
        this.gridOptions.columnApi.setColumnsVisible(["LastLeasePrice"], false);
        this.gridOptions.columnApi.setColumnsVisible(["LastLeaseDate"], false);
        
        this.gridOptions.columnApi.setColumnsVisible(["LastSalePrice"], true);
        this.gridOptions.columnApi.setColumnsVisible(["LastListingDate"], true);
      }
    }

    this.gridOptions.api.refreshHeader();
  }

  changeView(view) {
    if (!this.isReady) {
      return;
    }

    this._view = view;
    if (['listings'].includes(this.previousViewTab)) {
      const filters = {...this.filterModel, ...{LastLeaseDate: this.filterModelDefault.LastLeaseDate}};
      this.filterModel = filters
    }

    if (this.view == 'rent') {
      this.previousViewTab = 'rent';
      if (this.rentRows) {
        this.gridOptions.api.setRowData(this.rentRows);

        _.each(this.rentRows, (c: ListingComps) => {
          if (c.selected) {
            this.selectRow(c.AOCompID, true);
          }
        });

        this.updateFilterModel(this.filterModel, this.sortModel, true);
      }
    } else if(this.view == 'sale') {
      this.previousViewTab = 'sale';
      if (this.saleRows) {
        this.gridOptions.api.setRowData(this.saleRows);

        _.each(this.saleRows, (c: ListingComps) => {
          if (c.selected) {
            this.selectRow(c.AOCompID, true);
          }
        });

        this.updateFilterModel(this.filterModel, this.sortModel, true);
      }
    } else {
      if (this.activeRows) {
        this.previousViewTab = 'listings';
        this.gridOptions.api.setRowData(this.activeRows);

        _.each(this.activeRows, (c: ListingComps) => {
          if (c.selected) {
            this.selectRow(c.AOCompID, true);
          }
        });
        // need to remove filter LastLeaseDate
        const activeCompsFilterDefault = {...this.filterModel};
        delete activeCompsFilterDefault.LastLeaseDate;
        this.updateFilterModel(activeCompsFilterDefault, this.sortModel, true);
      }
    }

    this.updateGridHeader();
  }

  createRowData() {
    if (this.isReady) {
      const data = this._listingComps;

      this.rentRows = _.filter(data, { RentSale: 'R' });
      this.activeRows = _.filter(data, (cmp) => { return cmp.RentSale == 'S' && (cmp.Status == 'A' || cmp.Status == 'P')});
      this.saleRows = _.filter(data, (cmp) => { return cmp.RentSale == 'S' && cmp.Status == 'S' });

      this.changeView(this._view);
    }
  }

  toggleSelectRow(AOCompID, ensureVisible = false) {
    if (this.editLocked) {
      return;
    }

    const nodes: RowNode[] = this.gridOptions.api.getSelectedNodes();
    const selectedNode = _.find(nodes, (n: RowNode) => {
      return n.data.AOCompID == AOCompID;
    });

    if (selectedNode) {
      selectedNode.setSelected(false);
    } else {
      this.selectNode(AOCompID, ensureVisible);
    }
  }

  selectRow(AOCompID, ensureVisible = false) {
    const nodes: RowNode[] = this.gridOptions.api.getSelectedNodes();
    const selectedNode = _.find(nodes, (n: RowNode) => {
      return n.data.AOCompID == AOCompID;
    });

    if (!selectedNode) {
      this.selectNode(AOCompID, ensureVisible);
    }

    this.setDateFormat();
  }

  alignRowInMiddle(AOCompID, selected = false, highlighted = false) {
    let foundIdx = -1;

    this.gridOptions.api.forEachNodeAfterFilterAndSort((node, idx) => {
      node.data.highlighted = false;

      if (node.data.AOCompID == AOCompID) {
        foundIdx = idx;

        if (highlighted) {
          node.data.highlighted = true;
        }

        if (selected) {
          node.setSelected(true, false, false);
        }
      }
    });

    this.gridOptions.api.redrawRows();
    this.gridOptions.api.ensureIndexVisible(foundIdx, 'middle');
  }

  private selectNode(AOCompID, ensureVisible) {
    if (ensureVisible) {
      this.alignRowInMiddle(AOCompID, true);
    }
  }

  updateFilterModel(filterModel, sortModel, force = false) {
    if(force) {
      this.gridOptions.api.setFilterModel(filterModel);
      this.gridOptions.columnApi.applyColumnState({
        state: sortModel
      });

      return;
    }

    let filterModelChanged = false;
    if(!_.isEqual(this.filterModel, filterModel)) {
      this.filterModel = filterModel;
      filterModelChanged = true;
    }

    let sortModelChanged = false;
    if(!_.isEqual(this.sortModel, sortModel)) {
      this.sortModel = sortModel;
      sortModelChanged = true;
    }

    if (!filterModelChanged && !sortModelChanged) {
      return;
    }

    if (filterModelChanged) {
      this.gridOptions.api.setFilterModel(this.filterModel);
    }

    if (sortModelChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: this.sortModel
      });
    }
  }

  initPreferences(filterModel, sortModel, columnsState) {
    let filterModelChanged = false;
    if(!_.isEqual(this.filterModel, filterModel) && !!filterModel) {
      this.filterModel = filterModel;
      filterModelChanged = true;
    }

    let sortModelChanged = false;
    if(!_.isEqual(this.sortModel, sortModel) && !!sortModel) {
      this.sortModel = sortModel;
      sortModelChanged = true;
    }

    let columnsStateChanged = false;
    if(columnsState) {
      columnsStateChanged = true;
    }

    if (!filterModelChanged && !sortModelChanged && !columnsStateChanged) {
      return;
    }

    if (filterModelChanged) {
      this.gridOptions.api.setFilterModel(this.filterModel);
    }

    if (sortModelChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: this.sortModel
      });
    }

    if (columnsStateChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: columnsState,
        applyOrder: true,
        defaultState: {sort: null}
      });
    }
  }

  getDefaultFilterModel() {
    return this.filterModelDefault;
  }

  getFilterModel() {
    return this.gridOptions.api.getFilterModel();
  }

  getListingName() {
    return this.listingName;
  }

  getSortModel() {
    const colState = this.gridOptions.columnApi.getColumnState();
    return colState
    .filter((s) => {
      return s.sort != null;
    })
    .map((s) => {
      return { colId: s.colId, sort: s.sort };
    });
  }

  updateSortModel(sortModel) {
    this.sortModel = sortModel;
    this.gridOptions.columnApi.applyColumnState({
      state: this.sortModel
    });
  }

  updateColumnsStates(columns:ColumnState[]) {
    this.gridOptions.columnApi.applyColumnState(
      {
        state: columns,
        defaultState: { sort: null }
      }
      );
  }

  removeFilter(name: string) {
    // Get a reference to the 'name' filter instance
    const filterInstance = this.gridOptions.api.getFilterInstance(name);
    filterInstance.setModel(null);

    // Tell grid to run filter operation again
    this.gridOptions.api.onFilterChanged();
  }

  updateFilter() {
    this.gridOptions.api.setFilterModel(this.filterModel);
  }

  /**
   * Used to sync Distance from the map
   */
  changeDistanceFilter(distance) {
    const filterInstance = this.gridOptions.api.getFilterInstance('Distance');

    const distanceFilter = {
      filterType: "number",
      type: "lessThanOrEqual",
      filter: distance,
      filterTo: null
    };

    if (this.filterModel) {
      this.filterModel.Distance = distanceFilter;
    } else {
      this.filterModel = {
        Distance: distanceFilter
      };
    }

    filterInstance.setModel(distanceFilter);

    // Tell grid to run filter operation again
    this.gridOptions.api.onFilterChanged();
  }

  showLoader() {
    if (this.gridOptions.api) {
      this.gridOptions.api.showLoadingOverlay();
    }
  }

  hideLoader() {
    if (this.gridOptions.api) {
      this.gridOptions.api.hideOverlay();
    }
  }

  private updatePreferences() {
    if (!this.isReady || !this._preferences) {
      return;
    }

    if (!JsUtils.isNullOrEmpty(this._preferences.compsListing.columns)) {
      this.updateGridHeader();
      this.gridOptions.columnApi.applyColumnState({
        state: this._preferences.compsListing.columns,
        applyOrder: true,
        defaultState: {sort: null}
      });
    }
  }

  async savePreferences() {
    if (!this.preferencesInitialized) {
      this.preferencesInitialized = true;
      return;
    }

    this.preferences.compsListing.columns = this.gridOptions.columnApi.getColumnState();

    await this.underwriterService.savePreferences();
  }


  onReady() {
    this.updateGridHeader();

    this.gridOptions.api.setFilterModel(this.filterModel);

    this.isReady = true;

    this.gridReady.emit();
  }

  /**
   * When a row is selected by its checkbox
   */
  onRowSelected($event) {
    $event.data.selected = $event.node.selected;
    this.rowSelected.emit({ AOCompID: $event.data.AOCompID, selected: $event.node.selected });
  }

  onSelectionChanged() {
    this.selectionChanged.emit();
  }

  onFilterChanged($event) {
    this.filterModel = this.gridOptions.api.getFilterModel();
    this.filterChanged.emit(this.filterModel);
  }

  onSortChanged($event) {
    this.sortModel = this.getSortModel();

    _.each(this.sortModel, (m) => {

      const col = _.find(ComparableListingColumnDefinition.columns, (c) => {
        return c.field == m.colId;
      });

      m.display = col.headerName;
    });

    this.sortChanged.emit(this.sortModel);
  }

  onRowClicked($event) {
    if ($event.node.data.AOCompID) {
      this.rowClicked.emit($event.node.data.AOCompID);
    }
  }

  // here we use one generic event to handle all the column type events.
  // the method just prints the event name
  onColumnEvent($event) {

    if (
      $event.source == 'api' || // 'api' are called when we programmatically set the columns width, etc,
      $event.type == "columnEverythingChanged" ||
      ($event.type == "columnResized" && !$event.finished) ||
      ($event.type == "columnResized" && !$event.finished)
    ) {

      return;
    }

    // Notify changes to save layout preferences
    this.columnLayoutChanged.next();
  }

  isColumnFilterAvailable(colId) {
    return true;
  }

  private setDateFormat() {
    if (this.gridOptions.api.getColumnDef("LastLeaseDate")) {
      const dateFormat = (this.view === 'sale' ? "YYYY-MM" : "YYYY-MM-DD");
      this.gridOptions.api.getColumnDef("LastLeaseDate").cellRendererParams["format"] = dateFormat;
    }
  }
  setClassRuleForException (params) {
    if (params?.data?.Exception) {
      return true;
    }
    return false;
  }

  clearFilters() {
    this.clearFilter.emit(this.gridName);
  }

  clearAddressSearch() {
    this.clearAddress.emit();
  }
}
