import { Injectable } from '@angular/core';
import { UnderwriterPanelComponent } from 'src/app/panels/underwriter/underwriter.component';
import { ProFormaModel } from '../../models/proformaModel';
import { formatCurrency } from '../../utils/jsUtils';
import { IUpdateRequest, STEP_ACTION } from './model/updateRequest';
import { inspectionReviewFlow } from './repository/inspectionReview';
import * as _ from 'lodash';
import { ProFormaCalculator } from '../proFormaCalculator.service';
import { UpdateRequestFactory } from './updateRequestFactory';
import { offerReady } from './repository/offerReady';
import { IRequest, IRequestStep } from './model/requestStep';
import { APPROVAL_ACTIONS } from './model/IRequest';
import { concession } from './repository/concession';
import { counterOffer } from './repository/counterOffer';
import { closingPrice } from './repository/clearToClose';
import { lostDeadFlow } from './repository/lostdead';
import { preInspectionReviewFlow } from './repository/preInspectionReview';
import { canceledFlow } from './repository/canceled';
import { ListingProperty } from '../data/listingPropety';
import * as flowBuilder from './utils/flowBuilderUtils';
import { checkAvailablePendingEvents } from './utils/pendingEventsUtils';
import { getListingActions } from './utils/listingsFlowActions';
import { offerDeliveredFlow } from './repository/offerDelivered'


const activeRequests: IRequest[] = [
  offerReady,
  concession,
  preInspectionReviewFlow,
  inspectionReviewFlow,
  counterOffer,
  closingPrice,
  canceledFlow,
  lostDeadFlow,
  offerDeliveredFlow
];

@Injectable({
  providedIn: 'root'
})

export class UpdateRequestService {
  ActiveComment = true;
  constructor(
    private proFormaCalculator: ProFormaCalculator,
  ) { }


  buildUpdateAction(pattern: UnderwriterPanelComponent, activeRequest: IRequest, activeStep: IRequestStep, newValue, comment: string): IUpdateRequest {
    return new UpdateRequestFactory({
      externalLink: flowBuilder.buildExternalLink(pattern, activeRequest.externalLink),
      flowName: activeRequest.name,
      field: activeRequest.fieldToWatch,
      value: newValue,
      comment,
      displayValue: newValue? formatCurrency(newValue) : null,
      displayName: activeRequest.displayName,
      requester: activeRequest.requester,
      activeStep
    });
  }

  checkForUpdate(pattern: UnderwriterPanelComponent) {
    const activeRequest: IRequest = flowBuilder.checkStatus(pattern, activeRequests);

    if (!activeRequest) {
      return null;
    }

    let newValue = flowBuilder.getValueToUpdate(pattern, activeRequest);
    const comment = this.ActiveComment? flowBuilder.getUpdateComment(pattern, activeRequest) : null;
    const originalActiveStep = flowBuilder.getNextStep(pattern, activeRequest, newValue);

    if (!originalActiveStep) {
      return null;
    }

    const activeStep: IRequestStep = _.cloneDeep(originalActiveStep);

    this.updateActiveStepSetting(pattern, activeRequest, newValue, activeStep);
    checkAvailablePendingEvents(pattern, activeRequest, newValue, activeStep);

    newValue = flowBuilder.buildCustomUpdateMessage(pattern, activeRequest, activeStep, newValue);

    return this.buildUpdateAction(pattern, activeRequest, activeStep, newValue, comment);
  }


  private updateActiveStepSetting(pattern: UnderwriterPanelComponent, activeRequest: IRequest, newValue: number, activeStep: IRequestStep) {
    const newUwProformaModel: ProFormaModel = _.cloneDeep(pattern.currentUwProforma);
    newUwProformaModel.proforma[activeRequest.fieldToWatch] = newValue;
    this.proFormaCalculator.calculate(pattern.currentProperty, newUwProformaModel, false, false);

    if (pattern.currentUwProforma.proforma[activeRequest.fieldToWatch] == newUwProformaModel.proforma[activeRequest.fieldToWatch]) {
      activeStep.proforma.lockProforma = false;
      activeStep.proforma.updateProforma = false;
      if (activeStep.stepAction && activeStep.stepAction !== STEP_ACTION.INFO && activeRequest.fieldToWatch) {
        activeStep.activeAction.sendTM = true;
      // TODO:
      activeStep.activeAction.submitApproval = true;
      }
    }

    this.handleApprovalAction(pattern, activeStep);
  }


  private handleApprovalAction(pattern: UnderwriterPanelComponent, activeStep: IRequestStep) {
    if(!activeStep.commands.panel.preApproval.active) {
      return;
    }
    if (pattern.currentUwProforma.proforma.investorApproval) {
      if ([APPROVAL_ACTIONS.TRIGGER, APPROVAL_ACTIONS.REMOVE].includes(activeStep.commands.panel.preApproval.availableActions)) {
        activeStep.commands.panel.preApproval.action = APPROVAL_ACTIONS.REMOVE;
      } else {
        activeStep.commands.panel.preApproval.active = false;
      }
    } else {
      if ([APPROVAL_ACTIONS.TRIGGER, APPROVAL_ACTIONS.ADD].includes(activeStep.commands.panel.preApproval.availableActions)) {
        activeStep.commands.panel.preApproval.action = APPROVAL_ACTIONS.ADD;
      } else {
        activeStep.commands.panel.preApproval.active = false;
      }
    }
  }


  getListingActions (properties: ListingProperty[]) {
    return getListingActions(properties);
  }

  getUpdatedDisplayValue(pattern: UnderwriterPanelComponent) {
    const activeRequest: IRequest = flowBuilder.checkStatus(pattern, activeRequests);

    if (!activeRequest) {
      return null;
    }

    const value = flowBuilder.getValueToUpdate(pattern, activeRequest);

    return { value, displayValue: formatCurrency(value) }
  }

}
