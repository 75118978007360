import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef } from '@angular/core';
import { ModalComponent } from './modal.component';
import { Subscription } from 'rxjs';
import { ModalConfig } from './modalConfig';
import { ParentModalComponent } from "./parentModalComponent";
import { TerminateModalComponent } from './terminate/terminateModal.component';
import { SendToTMFewCompsModalComponent } from './sendToTMFewComps/sendToTMFewCompsModal.component';
import { RefreshModalComponent } from "./refresh/refresh-modal.component";
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { MissingFieldsModalComponent } from './missing-fields-modal/missing-fields-modal.component';
import { InvestorApprovalComponent } from './investor-approval/investor-approval.component';
import { DuplicateSubmissionComponent } from './duplicate-submission/duplicate-submission.component';
import { DuplicatePropertySentComponent } from "./duplicate-property-sent/duplicate-property-sent.component";
import { MissingPropertyValuesComponent } from "./missing-property-values/missing-property-values.component";
import { BusinessRuleWarningComponent } from './business-rule-warning/business-rule-warning.component';
import { UploadModalComponent } from  "./upload-modal/upload-modal.component";

type modalComponentType = 'ModalComponent' | 'UploadModal' | 'TerminateModalComponent' | 'DueDiligenceExtensionModalComponent' | 'SendToTMFewCompsModalComponent'
| 'AssignExternalVendorComponent' | 'RefreshModalComponent' | 'ErrorModalComponent' | 'MissingFieldsModalComponent' | 'InvestorApprovalComponent' | 'DuplicateSubmissionComponent'
  | 'DuplicatePropertySentComponent' | 'MissingPropertyValuesComponent' | 'BusinessRuleWarningComponent';
@Injectable({ providedIn: 'root' })
export class ModalService {
  private modalRef: ComponentRef<ParentModalComponent>;

  private promise;
  private subscription: Subscription;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector) {
  }

  open(config: ModalConfig, type: modalComponentType = 'ModalComponent'): Promise<any> {
    this.promise = new Promise<any>((resolve, reject) => {
      let instance: object;

      switch (type) {
        case 'ModalComponent':
          instance = ModalComponent;
          break;

        case 'UploadModal':
          instance = UploadModalComponent;
          break;

        case 'TerminateModalComponent':
          instance = TerminateModalComponent;
          break;

        case 'SendToTMFewCompsModalComponent':
          instance = SendToTMFewCompsModalComponent;
          break;

        case 'RefreshModalComponent':
          instance = RefreshModalComponent;
          break;

        case 'ErrorModalComponent':
          instance = ErrorModalComponent;
          break;

        case 'MissingFieldsModalComponent':
          instance = MissingFieldsModalComponent;
          break;

        case 'InvestorApprovalComponent':
          instance = InvestorApprovalComponent;
          break;

        case 'DuplicateSubmissionComponent':
          instance = DuplicateSubmissionComponent;
          break;

        case 'DuplicatePropertySentComponent':
          instance = DuplicatePropertySentComponent;
          break;

        case 'MissingPropertyValuesComponent':
          instance = MissingPropertyValuesComponent;
          break;
          case 'BusinessRuleWarningComponent':
            instance = BusinessRuleWarningComponent;
            break;
      }

      this.modalRef = this.componentFactoryResolver
      // @ts-ignore
        .resolveComponentFactory(instance)
        .create(this.injector) as ComponentRef<ParentModalComponent>;

      this.appRef.attachView(this.modalRef.hostView);

      this.modalRef.instance.open(config);

      this.subscription = this.modalRef.instance.closed.subscribe((retValue) => {
        resolve(retValue);

        this.subscription.unsubscribe();
      });
    });

    return this.promise;
  }

  close(value: any) {
    this.modalRef.instance.close(value);
  }
}
