import { Component, OnInit } from '@angular/core';
import {ICellRendererParams} from "ag-grid-community";
import {ListingProperty} from "../../../services/data/listingPropety";
import {ListingSources} from "../../../services/data/ListingSources";

@Component({
  selector: 'app-clipboard-copy-renderer',
  templateUrl: './clipboard-copy-renderer.component.html',
})
export class ClipboardCopyRendererComponent {
  cellValue: string;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
  }

  private getValueToDisplay(params: ICellRendererParams) {
    return params.data ? params.data[params.colDef.field] : '-';
  }
}
