import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { RolesPermissionsService } from './roles-permissions.service';
import { UserPermission } from './types';
import _ from 'lodash';

@Directive({
  selector: '[requiredPermission]'
})
export class RequiredPermissionDirective {
  private userPermissions: UserPermission[];
  private operator: 'OR' | 'AND' = 'OR';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly rolesPermissionsService: RolesPermissionsService) {}

  @Input()
  set requiredPermission(userPermissions: UserPermission[]) {
    this.userPermissions = userPermissions;
    this.updateView();
  }

  @Input()
  set requiredPermissionOperator(operator: 'OR' | 'AND') {
    this.operator = operator;
    this.updateView();
  }

  private updateView() {
    if(_.isEmpty(this.userPermissions)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    if (this.operator === 'AND') {
      this.rolesPermissionsService.hasAllRequiredPermissions(this.userPermissions).pipe(
        first()
      ).subscribe(hasAllRequiredPermissions => hasAllRequiredPermissions ?
        this.viewContainer.createEmbeddedView(this.templateRef) :
        this.viewContainer.clear()
      );
    } else {
      this.rolesPermissionsService.hasOneRequiredPermission(this.userPermissions).pipe(
        first()
      ).subscribe(hasOneRequiredPermission => hasOneRequiredPermission ?
        this.viewContainer.createEmbeddedView(this.templateRef) :
        this.viewContainer.clear()
      );
    }
  }
}
