import { ProFormaModel } from "src/app/models/proformaModel";
import { ListingProperty } from "../../data/listingPropety";
import { InvestorApprovalType, PropertyStatusType } from "../../data/propertyStatusType";
import { EVBUS_EVENTS_NAMES, STEP_ACTION, UPDATE_REQUEST_LEVEL, CONCESSION_ACTION, PROFORMA_EVENT_ACTION, UNDERWRITER_ACTIONS, WORKFLOW_STATES } from "../model/updateRequest";
import { IRequest } from "../model/requestStep";
import { VERSION_NAME } from "../model/versioning";
import * as _ from 'lodash';
import { PROFORMA_FIELDS_IDS } from "src/app/panels/proforma/proforma_config";
import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";
import { approvalEventBusValidator, concessionEventBusValidator, pendingEvBusEventsValidator } from "../utils/pendingEventsUtils";
import { UNDERWRITER_COMMANDS_NAME, UNDERWRITER_OPTIONS_LABEL } from "../model/underwriterCommandsAndActions";
import { APPROVAL_ACTIONS } from "../model/IRequest";

const REHAB_BUDGET_VALUE_WEIGHTING = .15;

function PreInspectionReviewFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.PreInspectionReview,
    PropertyStatusType.PreInvestorApprovalUpdated,
    PropertyStatusType.PreInvestorApprovalReady,
  ].includes(currentProperty.underwritingStatus);
}

function PreInspectionReviewFlowValuesValidation(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: IRequest) {
  if (currentUwProforma.proforma[fieldToWatch.fieldToWatch] != currentProperty?.transactionInspectionRepairEstimate) {
    return ((currentProperty.renovationFeePercent ||  REHAB_BUDGET_VALUE_WEIGHTING) * currentProperty?.transactionInspectionRepairEstimate) + currentProperty?.transactionInspectionRepairEstimate;
  }
  return null;

}

function preInspectionReviewStatus(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
  return [
    PropertyStatusType.PreInspectionReview
  ].includes(currentProperty.status) && currentProperty.workflowState !== WORKFLOW_STATES[PropertyStatusType.PreInspectionReview];
}


function investorValidationUrgentStatus(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
  return [
    PropertyStatusType.PreInspectionReview
  ].includes(currentProperty.status) && currentProperty.workflowState === WORKFLOW_STATES[PropertyStatusType.PreInspectionReview];
}

function investorApprovalUpdatedStatus(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
  return [PropertyStatusType.PreInvestorApprovalUpdated].includes(currentProperty.status);
}
function investorApprovalStatusReady(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
  return [PropertyStatusType.PreInvestorApprovalReady].includes(currentProperty.status);
}

function rehabBudgetEventBusValidatorApproval(underwriter: UnderwriterPanelComponent, ...uwArguments) {
  if (
    underwriter.currentUwProforma.proforma.investorApproval
  ) {
    return true;
  }
  return false;

}

function getApprovalExternalLink(underwriter: UnderwriterPanelComponent) {
  return underwriter.currentProperty.inspectionPortalUrl;
}

function investorApprovalBuilder(underwriter: UnderwriterPanelComponent, options) {
  const selectedPurchasingEntity = underwriter?.currentUwProforma?.proforma?.purchasingEntity;
  const purchasingEntityOptions = underwriter.selectedEntityOptions;

  if (selectedPurchasingEntity && purchasingEntityOptions && purchasingEntityOptions.length) {
    const entity = purchasingEntityOptions.filter(peo => peo.label == selectedPurchasingEntity)[0];
    if (entity) {
      if (entity.autoApproval) {
        options[UNDERWRITER_ACTIONS.APPROVAL].hidden= true;
        options[UNDERWRITER_ACTIONS.READY].hidden= false;
        underwriter.updateRequests.activeStep.actions.submitApproval.active = false;
        underwriter.updateRequests.activeStep.actions.sendTM.active = true;
        underwriter.currentUwProforma.proforma.investorApproval = InvestorApprovalType.autoApproval;
        return false;
      }
      if (underwriter.currentProperty.underwritingStatus == PropertyStatusType.PreInvestorApprovalUpdated) {
        underwriter.currentUwProforma.proforma.investorApproval = InvestorApprovalType.pendingApproval;
      } 
      else {
        underwriter.currentUwProforma.proforma.investorApproval = InvestorApprovalType.autoAssign;
      }
    }
  }

  options[UNDERWRITER_ACTIONS.APPROVAL].hidden= false;
  options[UNDERWRITER_ACTIONS.READY].hidden= true;
  underwriter.updateRequests.activeStep.actions.submitApproval.active = true;
  underwriter.updateRequests.activeStep.actions.sendTM.active = false;
  return false;
}

export const preInspectionReviewFlow: IRequest = {
  name: 'PreInspectionReviewFlow',
  displayName: 'Rehab Budget',
  fieldToWatch: PROFORMA_FIELDS_IDS.REPAIR_BUDGET_AMOUNT,
  externalLink: getApprovalExternalLink,
  requester: null,

  rules: {
    status: PreInspectionReviewFlowStatusValidation,
    value: PreInspectionReviewFlowValuesValidation,
    comment: null
  },

  steps: [
    /** step - (1) - */
    {
      stepAction: STEP_ACTION.UPDATE,
      listingName: 'Pre-Inspection Review *',
      listingIcon: null,
      level: UPDATE_REQUEST_LEVEL.ALERT,
      activators: [
        preInspectionReviewStatus,
        // RehabValuesValidation
      ],
      activeAction: {
        saveDraft: false,
        sendTM: false,
        hide: false
      },
      DisableHideForEveryOne: true,
      multipleActions: PROFORMA_EVENT_ACTION.CONCESSION_APPROVAL,
      saveLabel: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
      proforma: {
        lockProforma: true,
        updateProforma: true,
        forceUpdate: false,
        updateProformaLabel: null,
        editLocked: false,
        editLockOverrides: [
          PROFORMA_FIELDS_IDS.REPAIR_BUDGET_AMOUNT
        ],
        updateOnChanges: true,
        message: 'Rehab Budget:',
        style: {
          updateIcon: 'blocker'
        }
      },
      commands: {
        underwriter: {
          options: {
            ready: { id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
            draft: { id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft },
            approval: { id: UNDERWRITER_ACTIONS.APPROVAL, label: UNDERWRITER_OPTIONS_LABEL.SUBMIT_FOR_APPROVAL, disabled: true, main: false, actionID: UNDERWRITER_COMMANDS_NAME.submitApproval }
          },
          builder: investorApprovalBuilder
        },
        panel: {
          triggerConcessionFields: {
            active: false,
            action: CONCESSION_ACTION.UPDATE,
            commands: {
              active: false,
              nextAction: null,
              previousAction: null,
              actionName: null
            },
            validators: null,
          },
          preApproval: {
            active: false,
            availableActions: APPROVAL_ACTIONS.TRIGGER
          },
        }
      },
      actions: {
        saveDraft: {
          active: true,
          approval: null,
          validators: null,
          versioning: {
            active: false,
            update: true,
            versionName: VERSION_NAME.REHAB_BUDGET,
            validators: null
          },
          triggerEVBusEvent: {
            active: false,
            eventBus: [
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              },
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                validators: [rehabBudgetEventBusValidatorApproval]
              }
            ],
            checkForPendingEvents: {
              active: false,
              validators: [pendingEvBusEventsValidator],
              updateProperty: true,
              pendingEvents: [
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: [concessionEventBusValidator]
                },
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                  ],
                  validators: [approvalEventBusValidator]
                }
              ]
            }
          },
          updateStatus: {
            active: false,
            status: null,
            validators: null,
            fallBackStatus: null
          }
        },

        sendTM: {
          active: true,
          approval: null,
          validators: null,
          versioning: {
            active: true,
            update: true,
            versionName: VERSION_NAME.REHAB_BUDGET,
            previousVersion: VERSION_NAME.OFFER_READY,
            validators: null
          },
          triggerEVBusEvent: {
            active: true,
            eventBus: [
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.PRE_INSPECTION,
                validators: [rehabBudgetEventBusValidatorApproval]
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                validators: [approvalEventBusValidator]
              }
            ],
            checkForPendingEvents: {
              active: true,
              validators: [pendingEvBusEventsValidator],
              updateProperty: false,
              pendingEvents: [
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: null
                },
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                  ],
                  validators: null
                }
              ]
            }
          },
          updateStatus: {
            active: true,
            status: PropertyStatusType.PreInvestorApprovalReady,
            validators: null,
            fallBackStatus: PropertyStatusType.PreInvestorApprovalUpdated
          }
        },
        submitApproval: {
          active: true,
          approval: null,
          validators: null,
          versioning: {
            active: false,
            update: false,
            versionName: VERSION_NAME.REHAB_BUDGET,
            previousVersion: VERSION_NAME.OFFER_READY,
            validators: null
          },
          triggerEVBusEvent: {
            active: false,
            eventBus: [
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.PRE_INSPECTION,
                validators: [rehabBudgetEventBusValidatorApproval]
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                validators: [approvalEventBusValidator]
              }
            ],
            checkForPendingEvents: {
              active: false,
              validators: [pendingEvBusEventsValidator],
              updateProperty: true,
              pendingEvents: [
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: [concessionEventBusValidator]
                },
                {
                  active: true,
                  evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                  ],
                  validators: [approvalEventBusValidator]
                }
              ]
            }
          },
          updateStatus: {
            active: true,
            status: PropertyStatusType.PreInvestorApprovalUpdated,
            validators: null,
            fallBackStatus: null
          }
        },

      }




    },

    /** step - (2) - */
    {
      stepAction: STEP_ACTION.UPDATE,
      listingName: 'Pre-Inspection Review *',
      listingIcon: null,
      level: UPDATE_REQUEST_LEVEL.ALERT,
      activators: [investorValidationUrgentStatus
      ],activeAction: {
        saveDraft: false,
        sendTM: false,
        hide: false,
              submitApproval: true
      },
      DisableHideForEveryOne: true,
      multipleActions: PROFORMA_EVENT_ACTION.CONCESSION_APPROVAL,
      proforma: {
        lockProforma: false,
        updateProforma: false,
        forceUpdate: true,
        updateProformaLabel: null,
        editLocked: false,
        editLockOverrides: [
                  PROFORMA_FIELDS_IDS.REPAIR_BUDGET_AMOUNT
              ],
        updateOnChanges: true,
        message: 'Rehab Budget:',
        style: {
          updateIcon: 'blocker'
        }
      },
      commands: {
        underwriter: {
          options: {
            ready: { id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
            draft: { id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft },
            approval: { id: UNDERWRITER_ACTIONS.APPROVAL, label: UNDERWRITER_OPTIONS_LABEL.SUBMIT_FOR_APPROVAL, disabled: true, main: false, actionID: UNDERWRITER_COMMANDS_NAME.submitApproval }
          },
          builder: investorApprovalBuilder
        },
        panel: {
          triggerConcessionFields: {
            active: false,
            action: CONCESSION_ACTION.UPDATE,
            commands: {
              active: false,
              nextAction: null,
              previousAction: null,
              actionName: null
            },
            validators: null,
          },
          preApproval: {
            active: false,
            availableActions: APPROVAL_ACTIONS.TRIGGER
          },
        }
      },
      actions: {
        saveDraft: {
          active: true,
          approval: null,
          validators: null,
          versioning: {
            active: false,
            update: false,
            versionName: VERSION_NAME.REHAB_BUDGET,
            validators: null
          },
          triggerEVBusEvent: {
            active: false,
            eventBus: [
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              },
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                validators: [rehabBudgetEventBusValidatorApproval]
              }
            ],
            checkForPendingEvents: {
              active: false,
              validators: [pendingEvBusEventsValidator],
              updateProperty: true,
              pendingEvents: [
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: [concessionEventBusValidator]
                },
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                  ],
                  validators: [approvalEventBusValidator]
                }
              ]
            }
          },
          updateStatus: {
            active: false,
            status: null,
            validators: null,
            fallBackStatus: null
          }
        },

        sendTM: {
          active: true,
          approval: null,
          validators: null,
          versioning: {
            active: true,
            update: true,
            versionName: VERSION_NAME.REHAB_BUDGET,
            previousVersion: VERSION_NAME.OFFER_READY,
            validators: null
          },
          triggerEVBusEvent: {
            active: true,
            eventBus: [
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.PRE_INSPECTION,
                validators: [rehabBudgetEventBusValidatorApproval]
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                validators: [approvalEventBusValidator]
              }
            ],
            checkForPendingEvents: {
              active: false,
              validators: [pendingEvBusEventsValidator],
              updateProperty: false,
              pendingEvents: [
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: null
                },
                {
                  active: true,
                  evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                  ],
                  validators: null
                }
              ]
            }
          },
          updateStatus: {
            active: true,
            status: PropertyStatusType.PreInvestorApprovalReady,
            validators: null,
            fallBackStatus: PropertyStatusType.PreInvestorApprovalUpdated
                  }
              },
        submitApproval: {
            active: true,
            approval: null,
            validators: null,
            versioning: {
                active: false,
                update: false,
                versionName: VERSION_NAME.REHAB_BUDGET,
                previousVersion: VERSION_NAME.OFFER_READY,
                validators: null
            },
            triggerEVBusEvent: {
                active: false,
                eventBus: [
                    {
                        active: false,
                        evbusEventName: EVBUS_EVENTS_NAMES.PRE_INSPECTION,
                        validators: [rehabBudgetEventBusValidatorApproval]
                    },
                    {
                        active: false,
                        evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                        validators: [concessionEventBusValidator]
                    },
                    {
                        active: false,
                        evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                        validators: [approvalEventBusValidator]
                    }
                ],
                checkForPendingEvents: {
                    active: false,
                    validators: [pendingEvBusEventsValidator],
                    updateProperty: true,
                    pendingEvents: [
                        {
                            active: false,
                            evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                            fieldsToWatch: [
                                PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                                PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                            ],
                            validators: [concessionEventBusValidator]
                        },
                        {
                            active: false,
                            evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                            fieldsToWatch: [
                                PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                            ],
                            validators: [approvalEventBusValidator]
                        }
                    ]
                }
            },
            updateStatus: {
              active: true,
              status: PropertyStatusType.PreInvestorApprovalUpdated,
              validators: null,
              fallBackStatus: null
            }
        },

    }},

    /** step - (3) - */
    {
      stepAction: STEP_ACTION.UPDATE,
      listingName: 'Pre-Inspection Review - Submitted for Approval',
      listingIcon: null,
      level: UPDATE_REQUEST_LEVEL.WARN,
      activators: [investorApprovalUpdatedStatus],
      activeAction: {
        saveDraft: false,
        sendTM: true,
        hide: false
      },
      DisableHideForEveryOne: false,
      multipleActions: PROFORMA_EVENT_ACTION.CONCESSION_APPROVAL,
      commands: {
        underwriter: {
          options: {
            undo: { id: UNDERWRITER_ACTIONS.UNDO_APPROVAL, label: UNDERWRITER_OPTIONS_LABEL.UNDO_APPROVAL, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.undoApproval, level: UPDATE_REQUEST_LEVEL.ALERT },
            ready: { id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
            draft: { id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft },

          },
          builder: null
        },
        panel: {
          triggerConcessionFields: {
            active: false,
            action: CONCESSION_ACTION.UPDATE,
            commands: {
              active: false,
              nextAction: null,
              previousAction: null,
              actionName: null
            },
            validators: null,
          },
          preApproval: {
            active: false,
            availableActions: APPROVAL_ACTIONS.ADD
          },
        }
      },

      proforma: {
        lockProforma: false,
        updateProforma: false,
        forceUpdate: false,
        updateProformaLabel: null,
        editLocked: false,
        editLockOverrides: null,
        updateOnChanges: false,
        message: 'Requires investor approval',
        hideValue: true,
        style: {
          updateIcon: 'alert-approval'
        }
      },

      actions: {
        saveDraft: {
          active: false,
          approval: null,
          validators: null,
          versioning: {
            active: false,
            update: false,
            versionName: VERSION_NAME.REHAB_BUDGET,
            validators: null
          },
          triggerEVBusEvent: {
            active: false,
            eventBus: [
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              },
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                validators: [rehabBudgetEventBusValidatorApproval]
              }
            ],
            checkForPendingEvents: {
              active: true,
              validators: [pendingEvBusEventsValidator],
              updateProperty: true,
              pendingEvents: [
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: [concessionEventBusValidator]
                },
                {
                  active: true,
                  evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                  ],
                  validators: [approvalEventBusValidator]
                }
              ]
            }
          },
          updateStatus: {
            active: false,
            status: PropertyStatusType.PreInvestorApprovalUpdated,
            validators: null,
            fallBackStatus: null
          }
        },

        sendTM: {
          active: true,
          approval: {
            active: false,
            options: [
              { id: 1, label: 'Yes, I have investor approval' },
            ],
            preSelectedOption: 1
          },
          validators: null,
          versioning: {
            active: true,
            update: true,
            versionName: VERSION_NAME.REHAB_BUDGET,
            previousVersion: VERSION_NAME.OFFER_READY,
            validators: null
          },
          triggerEVBusEvent: {
            active: true,
            eventBus: [
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.PRE_INSPECTION,
                validators: null
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                validators: null
              }
            ],
            checkForPendingEvents: {
              active: false,
              validators: [pendingEvBusEventsValidator],
              updateProperty: false,
              pendingEvents: [
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: null
                },
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                  ],
                  validators: null
                }
              ]
            }
          },
          updateStatus: {
            active: true,
            status: PropertyStatusType.PreInvestorApprovalReady,
            validators: null,
            fallBackStatus: PropertyStatusType.PreInvestorApprovalUpdated
          }
        },
        undoApproval: {
          active: true,
          approval: {
            active: false,
            options: [
              { id: 1, label: 'Yes, I have investor approval' },
            ],
            preSelectedOption: 1
          },
          validators: null,
          versioning: {
            active: false,
            update: false,
            versionName: VERSION_NAME.REHAB_BUDGET,
            previousVersion: VERSION_NAME.OFFER_READY,
            validators: null
          },
          triggerEVBusEvent: {
            active: false,
            eventBus: [
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.PRE_INSPECTION,
                validators: [rehabBudgetEventBusValidatorApproval]
              },
              {
                active: false,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              },
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                validators: [approvalEventBusValidator]
              }
            ],
            checkForPendingEvents: {
              active: false,
              validators: [pendingEvBusEventsValidator],
              updateProperty: true,
              pendingEvents: [
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: null
                },
                {
                  active: false,
                  evbusEventName: EVBUS_EVENTS_NAMES.INVESTOR_APPROVAL,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.INVESTOR_APPROVAL,
                  ],
                  validators: null
                }
              ]
            }
          },
          updateStatus: {
            active: true,
            status: PropertyStatusType.PreInspectionReview,
            validators: null,
            fallBackStatus: PropertyStatusType.PreInspectionReview
          }
        },
      },
    },

    /** step - (4) - */
    {
      stepAction: null,
      listingName: 'Pre-Inspection Review - Approved',
      listingIcon: /*'active-property'*/ null,
      level: null,
      activators: [investorApprovalStatusReady],
      activeAction: {
        saveDraft: false,
        sendTM: false,
        hide: false,
      },
      DisableHideForEveryOne: false,
      multipleActions: null,
      commands: {
        underwriter: {
          options: {
            ready: { id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
            draft: { id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }

          },
          builder: null
        },
        panel: {
          triggerConcessionFields: {
            active: false,
            action: CONCESSION_ACTION.UPDATE,
            commands: {
              active: false,
              nextAction: null,
              previousAction: null,
              actionName: null
            },
            validators: null
          },
          preApproval: {
            active: false,
            availableActions: APPROVAL_ACTIONS.ADD
          },
        }
      },

      proforma: {
        lockProforma: false,
        updateProforma: false,
        forceUpdate: false,
        updateProformaLabel: null,
        editLocked: false,
        editLockOverrides: null,
        updateOnChanges: false,
        message: 'Rehab Budget:',
        style: {
          updateIcon: 'active-property'
        }
      },

      actions: {
        saveDraft: {
          active: true,
          approval: null,
          validators: null,
          versioning: {
            active: true,
            update: true,
            versionName: VERSION_NAME.REHAB_BUDGET,
            validators: null
          },
          triggerEVBusEvent: {
            active: false,
            eventBus: [
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              }
            ],
            checkForPendingEvents: {
              active: true,
              validators: [pendingEvBusEventsValidator],
              updateProperty: true,
              pendingEvents: [
                {
                  active: true,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: [concessionEventBusValidator]
                }
              ]
            }
          },
          updateStatus: {
            active: false,
            status: null,
            validators: null,
            fallBackStatus: null
          }
        },

        sendTM: {
          active: false,
          approval: null,
          validators: null,
          versioning: {
            active: true,
            update: true,
            versionName: VERSION_NAME.REHAB_BUDGET,
            previousVersion: VERSION_NAME.OFFER_READY,
            validators: null
          },
          triggerEVBusEvent: {
            active: false,
            eventBus: [
              {
                active: true,
                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                validators: [concessionEventBusValidator]
              }
            ],
            checkForPendingEvents: {
              active: true,
              validators: [pendingEvBusEventsValidator],
              updateProperty: true,
              pendingEvents: [
                {
                  active: true,
                  evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                  fieldsToWatch: [
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                  ],
                  validators: null
                }
              ]
            }
          },
          updateStatus: {
            active: false,
            status: null,
            validators: null,
            fallBackStatus: null
          }
        },
      }
    },
  ]
};
