import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";

export class EntityUtils {
  static entitySubject = new BehaviorSubject<any>(undefined);
  static entityObservable: Observable<any> = EntityUtils.entitySubject.asObservable();
  static entities;
  static entityFilterInstance = null;
  static alreadyLoaded = false;
  static entitiesInitialized = false;
  static startInitialized = false;

  static resetEntityUtils() {
    this.entities= null;
    this.entityFilterInstance = null;
    this.alreadyLoaded = false;
  }

  static manageFilterInstance() {
    if(!this.entityFilterInstance) {
      return;
    }
    const model =  this.entityFilterInstance.getModel();
    if(!model || !model.values || model.values.length === 0) {
      return;
    }
    this.entityFilterInstance.setModel({ values: model.values });
  }

  static canLoadEntities = () => {
    return  !EntityUtils.alreadyLoaded || !EntityUtils.entities;
  }

  static getEntities() {
    return this.entityObservable.pipe(
      filter(val => !!val)
      ,tap((val) => {
        this.entities = val;
      }),
      map((list) => {
        if (list) {
          const entityArray = [];
          for (const key in list) {
            if (list[key]) {
              entityArray.push(list[key]);
            }
          }

          EntityUtils.manageFilterInstance();
          return entityArray;
        }
        return null;
      })
    );
  }
}
