import { NgModule, APP_INITIALIZER, Injector, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ClipboardModule } from '@angular/cdk/clipboard';

import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

// ag-grid
import { AgGridModule } from "ag-grid-angular";

// Drag and drop
import { DragulaModule } from 'ng2-dragula';

// Photo carousel
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

// Date picker
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

// Toaster
import { ToastrModule } from 'ngx-toastr';

// NGX Mask
import { NgxMaskModule } from "ngx-mask";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

import * as Services from './services';
import { ApiMockService } from './services/api.mock.service';
import { ApiHttpService } from './services/api.http.service';

import { setAppInjector } from './app.injector';
import { WINDOW_PROVIDERS } from './window.provider';

import { AppComponent } from "./app.component";
import { DateComponent } from "./ui/agGrid/date-component/date.component";
import { HeaderComponent } from "./ui/agGrid/header-component/header.component";
import { AppRoutingModule } from './app-routing.module';
import { ComparablesPanelComponent } from './panels/comparables/comparables.component';
import { PropertyListComponent } from './pages/propertylist/propertylist.component';
import { CacheRouteReuseStrategy } from './cacheRouteReuseStrategy';

import { LoginComponent } from './pages/auth/login/login.component';
import { ResetPasswordComponent } from './pages/auth/resetPassword/resetPassword.component';
import { LostPasswordComponent } from './pages/auth/lostPassword/lostPassword.component';
import { ChangePasswordComponent } from './pages/auth/changePassword/changePassword.component';

import { ProformaPanelComponent } from './panels/proforma/proforma.component';
import { A1CurrencyPipe } from './pipes';
import { A1DatePipe } from './pipes';
import { A1DateRelativePipe } from './pipes';
import { A1DateTimePipe } from './pipes';
import { NumericInputComponent } from './ui';
import { AppLayoutComponent } from './ui/appLayout/appLayout.component';
import { SelectComponent } from './ui/select/select.component';
import { AcceptComponent } from './pages/auth/accept/accept.component';
import { SaveFilterDialogComponent } from './ui/saveFilterDialog/saveFilterDialog.component';
import { SectionGridComponent } from "./ui/sectionGrid/sectionGrid.component";
import { UnderwriterPanelComponent } from './panels/underwriter/underwriter.component';
import { PropertyGridComponent } from './pages/propertylist/propertyGrid/propertyGrid.component';
import { ComparablesGridComponent } from './panels/comparables/comparablesGrid/comparablesGrid.component';
import { MenuComponent } from './ui/menu/menu.component';
import { ColumnEditorPanelComponent } from './panels/columnEditor/columnEditor.component';
import { ActivityLogPanelComponent } from './panels/activityLog/activityLog.component';
import { ModalComponent } from './ui/modal/modal.component';
import { SplitButtonComponent } from './ui/splitbutton/splitbutton.component';
import { ProperDetailsComponent } from './panels/propertyDetails/propertyDetails.component';
import { AccordionComponent } from './ui/accordion/accordion.component';
import { PinnedPropertyRowRendererComponent } from './panels/comparables/comparablesGrid/pinnedPropertyRowRenderer';
import { CommonModule } from '@angular/common';
import { ComparablePreviewComponent } from './panels/comparables/comparablePreview/comparablePreview.component';
import { ModalService } from './ui/modal/modal.service';
import { SortDropdownComponent } from './ui/sortDropdown/sortDropdown.component';
import { GridFilterRowComponent } from './ui/gridFilter/gridFilterRow/gridFilterRow.component';
import { GridFilterButtonsComponent } from './ui/gridFilter/gridFilterButtons/gridFilterButtons.component';
import { GridFilterService } from './ui/gridFilter/gridFilter.service';
import { PhotosPanelComponent } from './panels/photos/photos.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LightboxComponent } from './ui/lightbox/lightbox.component';
import { LightboxService } from './ui/lightbox/lightbox.service';
import { StyleguideComponent } from './pages/styleguide/styleguide.component';
import { DatepickerComponent } from './ui/datepicker/datepicker.component';
import { UploadFilesComponent } from './ui/uploadFiles/upload-files.component';
import { TerminateModalComponent } from './ui/modal/terminate/terminateModal.component';
import { RolesPermissionsModule } from './roles-permissions';
import { SendToTMFewCompsModalComponent } from './ui/modal/sendToTMFewComps/sendToTMFewCompsModal.component';
import { RangeDatepickerComponent } from './ui/range-datepicker/range-datepicker.component';
import { PropertyNotesComponent } from './panels/property-notes/property-notes.component';
import { SelectCellGridComponent } from './ui/select-cell-grid/select-cell-grid.component';
import { UnderwriterStatusRendererComponent } from './ui/agGrid/underwriter-status-renderer/underwriter-status-renderer.component';
import { RefreshModalComponent } from './ui/modal/refresh/refresh-modal.component';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { ErrorModalComponent } from './ui/modal/error-modal/error-modal.component';
import { MissingFieldsModalComponent } from './ui/modal/missing-fields-modal/missing-fields-modal.component';
import { ExportPDFComponent } from "./pages/exportPDF/exportPDF.component";
import { ExportPreviewComponent } from "./pages/exportPDF/exportPreview/exportPreview.component";
import { InvestorApprovalComponent } from './ui/modal/investor-approval/investor-approval.component';
import { UpdateRequestComponent } from './ui/update-request/update-request.component';
import { GridToolTipComponent } from './ui/grid-tool-tip/grid-tool-tip.component';
import { LinkRendererComponent } from './ui/agGrid/link-renderer/link-renderer.component';
import { GroupRendererComponent } from './ui/agGrid/group-renderer/group-renderer.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { DuplicateSubmissionComponent } from './ui/modal/duplicate-submission/duplicate-submission.component';
import { DuplicatePropertySentComponent } from './ui/modal/duplicate-property-sent/duplicate-property-sent.component';
import { SelectInvestorComponent } from './ui/select-investor/select-investor.component';
import { WorkFlowNoteComponent } from './ui/a1-note/a1-workflow-note.component';
import { ViewSettingComponent } from './ui/view-setting/view-setting.component';
import { CurrentPriceRendererComponent } from "./ui/agGrid/current-price-renderer/current-price-renderer.component";
import { ListingStatusRendererComponent } from "./ui/agGrid/listing-status-renderer/listing-status-renderer.component";
import { NoPropertiesFoundComponent } from './ui/no-properties-found/no-properties-found.component';
import { StatusBarPanelComponent } from "./panels/statusBar/statusBar.component";
import { MissingPropertyValuesComponent } from './ui/modal/missing-property-values/missing-property-values.component';
import { ColumnsOrdringPipe } from './pipes/columns-ordring.pipe';
import { ExportFiltredComponent } from './ui/export-filtred/export-filtred.component';
import { ClipboardCopyRendererComponent } from "./ui/agGrid/clipboard-copy-renderer/clipboard-copy-renderer.component.";
import { ClipboardCopyForListingRendererComponent } from "./ui/agGrid/clipboard-copy-for-listing-renderer/clipboard-copy-for-listing-renderer.component.";
import { BusinessRuleWarningComponent } from './ui/modal/business-rule-warning/business-rule-warning.component';
import { LogHistoryComponent } from './ui/log-history/log-history.component';
import { ProformaHistoryComponent } from './ui/proforma-history/proforma-history.component';
import { AngularMyDatePickerModule } from "@nodro7/angular-mydatepicker";
import { AddressSearchComponent } from './ui/address-search/address-search.component';
import {UploadModalComponent} from "./ui/modal/upload-modal/upload-modal.component";


export function apiFactory(awsCredentials: Services.AwsCredentialsService, configService: Services.ConfigService, sigv4: Services.Sigv4Http) {
  if (configService.FAKE) {
    return new ApiMockService(awsCredentials, configService, sigv4);
  } else {
    return new ApiHttpService(awsCredentials, configService, sigv4);
  }
}

const initializerConfigFn = (appConfig: Services.ConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AgGridModule,
    SwiperModule,
    DragulaModule.forRoot(),
    InfiniteScrollModule,
    AngularMyDatePickerModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
    }),
    RolesPermissionsModule.forRoot(),
    DragDropModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMaskModule.forRoot(),
    ClipboardModule
  ],
  declarations: [
    A1CurrencyPipe,
    A1DatePipe,
    A1DateRelativePipe,
    A1DateTimePipe,
    AppComponent,
    AppLayoutComponent,
    AcceptComponent,
    LoginComponent,
    ProformaPanelComponent,
    ComparablesPanelComponent,
    ComparablesGridComponent,
    UnderwriterPanelComponent,
    PropertyListComponent,
    PhotosPanelComponent,
    PropertyGridComponent,
    DateComponent,
    HeaderComponent,
    ChangePasswordComponent,
    LostPasswordComponent,
    ResetPasswordComponent,
    NumericInputComponent,
    SelectComponent,
    SaveFilterDialogComponent,
    SectionGridComponent,
    MenuComponent,
    ColumnEditorPanelComponent,
    ActivityLogPanelComponent,
    ModalComponent,
    TerminateModalComponent,
    SplitButtonComponent,
    ProperDetailsComponent,
    AccordionComponent,
    PinnedPropertyRowRendererComponent,
    ComparablePreviewComponent,
    SortDropdownComponent,
    GridFilterButtonsComponent,
    GridFilterRowComponent,
    LightboxComponent,
    StyleguideComponent,
    DatepickerComponent,
    UploadFilesComponent,
    SendToTMFewCompsModalComponent,
    PropertyNotesComponent,
    SelectCellGridComponent,
    UnderwriterStatusRendererComponent,
    RangeDatepickerComponent,
    RefreshModalComponent,
    LoadingSpinnerComponent,
    ErrorModalComponent,
    MissingFieldsModalComponent,
    ExportPDFComponent,
    ExportPreviewComponent,
    InvestorApprovalComponent,
    UpdateRequestComponent,
    GridToolTipComponent,
    LinkRendererComponent,
    GroupRendererComponent,
    TooltipDirective,
    DuplicateSubmissionComponent,
    DuplicatePropertySentComponent,
    SelectInvestorComponent,
    WorkFlowNoteComponent,
    ViewSettingComponent,
    CurrentPriceRendererComponent,
    ListingStatusRendererComponent,
    NoPropertiesFoundComponent,
    StatusBarPanelComponent,
    MissingPropertyValuesComponent,
    ColumnsOrdringPipe,
    ExportFiltredComponent,
    ClipboardCopyRendererComponent,
    ClipboardCopyForListingRendererComponent,
    BusinessRuleWarningComponent,
    LogHistoryComponent,
    ProformaHistoryComponent,
    AddressSearchComponent,
    UploadModalComponent
  ],
  providers: [
    WINDOW_PROVIDERS,
    HttpClientModule,
    ModalService,
    LightboxService,
    GridFilterService,
    Services.AuthenticationService,
    Services.AwsCredentialsService,
    Services.ConfigService,
    Services.ProFormaCalculator,
    Services.RouterExtService,
    Services.S3Service,
    Services.Sigv4Http,
    Services.UnderwriterService,
    Services.DownloadService,
    Services.RefreshService,
    Services.DataDogService,
    {
      provide: Services.ApiService,
      useFactory: apiFactory,
      deps: [Services.AwsCredentialsService, Services.ConfigService, Services.Sigv4Http]
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerConfigFn,
      multi: true,
      deps: [Services.ConfigService],
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
