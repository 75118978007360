import * as _ from 'lodash';
import * as numeral from 'numeral';
import * as moment from 'moment';
import { Classification } from '../services/data/classification';

/**
 * @namespace
 * value to return if the field value does'nt match any mapping or rule {@link cellDefaultValue}.
 */
const cellDefaultValue = '-';

(<any>(window.global)).numberRoundDecimal = function (v, n) {
  return Math.round((v + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, n);
};


// When just want to check if any properties exists, this is
// significantly better than Object.key().length
export function objectHasProperties(object): boolean {
  for (const prop in object) {
    if (object.hasOwnProperty(prop)) {
      return true;
    }
  }
  return false;
}

/**
 * Round to nearest 'cents'.
 * From: DM code: rms/util/MathUtils.java
 * @param value The value to round
 */
export function roundToCents(value: number): number {
  return roundToDigits(value, 2);
}

/**
 * Round to number of Digits right of the decimal
 * From: DM code: rms/util/MathUtils.java
 * @param value The value to round
 * @param digits Number of digits  i.e. 2 for '#.##'
 */
export function roundToDigits(value: number, digits: number): number {
  const powerOf10 = Math.pow(10, digits);
  return Math.round(value * powerOf10) / powerOf10;
}


export function roundToInteger(value: number): number {
  // https://stackoverflow.com/questions/596467/how-do-i-convert-a-float-number-to-a-whole-number-in-javascript
  // A bitwise or operator can be used to truncate floating point figures and it works for positives as well as negatives:

  // eslint-disable-next-line no-bitwise
  return value | 0;
}

export function nullIfZero(value: number): number {
  if (value == 0) {
    return null;
  }
  return value;
}

/**
 * Returns a trimmed string. If null it returns an empty string.
 * @param value
 */
export function safeString(value): string {
  if (_.isNil(value)) {
    return '';
  }

  return value.trim();
}

/**
 * Returns the number while checking for null
 * @param value
 * @param defaultValue If null or empty, return this value. Default = 0
 */
export function safeFloat(value: number | string, defaultValue = 0): number {
  if (_.isNil(value)) {
    return defaultValue;
  }

  if (typeof value == 'number') {
    return value;
  }

  const v = value.trim();
  return parseFloat(v);
}

/**
 * Convert a string to integer.
 * @param value Number as string. if already a number, simply return it.
 * @returns null, undefined or blanks are returned as 0
 */
export function safeInt(value: string | number): number {
  if (isNullOrEmpty(value)) {
    return 0;
  }

  if (typeof value == "number") {
    return value;
  }

  return parseInt(value);
}

/**
 * Check if an object, array or string are null or undefined, or if they have a length of 0
 * @param value String | Array | any
 */
export function isNullOrEmpty(value: any): boolean {
  if (_.isNil(value)) {
    return true;
  }

  if (typeof value == 'string') {
    return value.trim().length == 0;
  }

  if (Array.isArray(value)) {
    return value.length == 0;
  }

  return false;
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

/**
 * format date using momentJS format:"ll [at] HH:mm:ss"
 * the date value should be valid date
 * @param date date value to transform
 * @returns moment date"
 */
export function getTimestampString(date: any) {
  const mdate = moment(date);
  return mdate.format("ll [at] HH:mm:ss");
}

/**
 * format date using momentJS format:"YYYY-MM-DD"
 * the date value should be valid date
 * @param date value to transform
 * @param isUTC
 * @returns moment date
 * @defaultValue {@link cellDefaultValue}
 */

export function getDateString(date: any, isUTC = false) {
  const mdate = isUTC ? moment.utc(date) : moment(date);
  if (mdate.isValid()) {
    return mdate.format("YYYY-MM-DD");
  }
  return cellDefaultValue;
}

/**
 * format date using momentJS format:"MMM DD, YYYY"
 * the date value should be valid date
 * @param date date value to transform
 * @returns moment date
 * @defaultValue N/A
 */
export function getMDYDateString(date: any) {
  const mdate = moment(date).utc();
  if (mdate.isValid()) {
    return mdate.format('MMM DD, YYYY');
  }
  return 'N/A';
}

/**
 * get local date using momentJS format:"MMM DD, YYYY [at] hh:mm A"
 * the date value should be valid date
 * @param date date value to transform
 * @returns moment date
 * @defaultValue N/A
 */
export function getLocalDateTimeString(value: string) {
  return moment.utc(value, 'YYYY-MM-DD HH:mm:ss').local().format('MMM DD, YYYY [at] hh:mm A');
}

/**
 * 'Today', 'Yesterday' , date
 * @param date
 * @return string
 */
export function getDateCalendarString(date: any) {
  const mdate = moment(date).local();
  if (mdate.isValid()) {
    const now = moment();

    if (mdate.isSame(now, 'date')) {
      return mdate.fromNow();// 'Today';
    } else if (mdate.isSame(now.subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    } else if (mdate.isSame(now.subtract(1, 'day'), 'day')) {
      return '2 days ago';
    } else {
      return mdate.format("YYYY-MM-DD");
    }
  }
  return cellDefaultValue;
}

/**
 * format difference between date value and current date as less than 1 month, month(s), years(s)
 * @param date
 * @returns date difference as string
 * @defaultValue {@link cellDefaultValue}
 */
export function getYearsMonthsElapsedNowString(date: any) {
  const mdate = moment(date);
  if (mdate.isValid()) {
    const now = moment();

    const years = now.diff(mdate, 'year');
    mdate.add(years, 'years');
    const months = now.diff(mdate, 'months');

    const sYears = years && years > 0 ? `${years} years` : '';
    const sMonths = months && months > 0 ? `${months} months` : '';

    return sYears || sMonths ? `${sYears} ${sMonths}`.trim() : 'less than 1 month';
  }
  return cellDefaultValue;
}

/**
 * format value as currency based on decimal value {@link formatCurrency}
 * @param value value to format
 * @param decimals decimal to apply
 * @returns formatted value as currency
 * @defaultValue {@link cellDefaultValue}
 */
export function formatCurrency (value, decimals = 2) {
  if (value == null) {
    return cellDefaultValue;
  }

  if (decimals == 0) {
    return numeral(value).format('$0,0');
  } else if (decimals == 1) {
    return numeral(value).format('$0,0.0');
  }
  return numeral(value).format('$0,0.00');
}

export function formatCurrencyAbbreviate(value) {
  if (value == null) {
    return '$0';
  }
  if (value < 1000) {
    return numeral(value).format('($0)');
  } else if (value >= 10000) {
    return numeral(value).format('($0a)').toUpperCase();
  }
  return numeral(value).format('($0.0a)').toUpperCase();
}

export function formatPercent(value) {
  if (value == null) {
    return cellDefaultValue;
  }

  return numeral(value).format('0.0%');
}

export function formatPercent2(value) {
  if (value == null) {
    return cellDefaultValue;
  }

  return numeral(value).format('0.00%');
}

export function formatDecimal(value) {
  if (value == null) {
    return cellDefaultValue;
  }

  return numeral(value).format('0.00');
}

/**
 *
 * @param body {string} The file contents to be downloaded
 * @param contentType {string} The content-type of the file
 * @param filename {string|null} The optional filename
 */
export function downloadFile(body: BlobPart, contentType: string, filename: string = null, length?: number) {
  let url = '';
  // Check if it's excel file or csv
  if (contentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && length) {
    url = URL.createObjectURL(new Blob([new Uint8Array(body as ArrayBuffer, 0, length)], {type: contentType}));
  } else {
    const convertedCSV  = 'sep=\t\r\n' + body.toString();
    url = URL.createObjectURL(new Blob([convertedCSV], {type: contentType}));
  }

  if (filename) {
    // Create a tag to set filename for file
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  } else {
    window.open(url, '_blank');
  }
}

export function getBuyBoxIndex (buyBoxes: Classification[], value: number, buyBoxPropertyName: string) {
  const buyBoxCount = buyBoxes.length;

  for (let i = 0; i < buyBoxCount; i++) {
    if (value >= buyBoxes[buyBoxCount - 1][buyBoxPropertyName]) {
      return buyBoxCount - 1;
    }

    if (
      value >= buyBoxes[i][buyBoxPropertyName] &&
      value < buyBoxes[i + 1][buyBoxPropertyName]
    ) {
      return i;
    }
  }

  return null;
}
