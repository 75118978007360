/**
 * Inpiration: https://github.com/aws-samples/aws-mobile-ionic-sample/blob/master/client/src/app/sigv4.service.ts
 */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

// aws fixes by Ricky
// import * as aws4 from '../lib/aws4';
import * as aws4 from './request-signer';
const DEFAULT_TYPE = 'application/json';

export interface ISigV4Credentials {
  secretAccessKey?: string;
  accessKeyId?: string;
  sessionToken: string;
  region: string;
}




interface IRequestParams {
  endpoint: string;
  verb: any;
  path: string;
  headers?: any;
  body?: any;
}

@Injectable({
  providedIn: 'root'
})
export class Sigv4Http {
  linkParser = document.createElement('a');

  constructor(private http: HttpClient) { }

  public get(
    endpoint: any,
    path: string,
    creds: ISigV4Credentials
  ): Observable<HttpResponse<any>> {
    return this.request({ verb: 'GET', endpoint, path }, creds);
  }

  public post(
    endpoint: any,
    path: string,
    body: any,
    creds: ISigV4Credentials
  ): Observable<HttpResponse<any>> {
    return this.request({ verb: 'POST', endpoint, path, body }, creds);
  }

  public put(
    endpoint: any,
    path: any,
    body: any,
    creds: ISigV4Credentials
  ): Observable<HttpResponse<any>> {
    return this.request({ verb: 'PUT', endpoint, path, body }, creds);
  }

  public del(
    endpoint: any,
    path: any,
    creds: ISigV4Credentials
  ): Observable<HttpResponse<any>> {
    return this.request({ verb: 'DELETE', endpoint, path }, creds);
  }

  public request(
    request: IRequestParams,
    credentials: ISigV4Credentials
  ): Observable<HttpResponse<any>> {
    return this._request(request, credentials);
  }

  private _request(request: IRequestParams, credentials: ISigV4Credentials): Observable<HttpResponse<any>> {
    const reqEndpoint = /(^https?:\/\/[^\/]+)/g.exec(request.endpoint)[1];
    const reqPathComponent = request.endpoint.substring(reqEndpoint.length);
    const verb = request.verb;
    const path = `${reqPathComponent}/${request.path}`;
    const headers = request.headers || {}; // We currently don't accept headers overrides

    // If the user has not specified an override for Content type the use default
    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = DEFAULT_TYPE;
    }

    // If the user has not specified an override for Accept type the use default
    if (headers.Accept === undefined) {
      headers.Accept = DEFAULT_TYPE;
    }

    let body = request.body;
    // override request body and set to empty when signing GET requests
    body = body === undefined || verb === 'GET' ? '' : JSON.stringify(body);

    // If there is no body remove the content-type header so it is not included in SigV4 calculation
    if (body === '' || body === undefined || body === null) {
      delete headers['Content-Type'];
    }

    this.linkParser.href = reqEndpoint;

    const aws4Options = {
      host: this.linkParser.hostname,
      path,
      method: verb,
      headers,
      body
    };

    const aws4Sign = aws4.sign(aws4Options, {
      secretAccessKey: credentials.secretAccessKey,
      accessKeyId: credentials.accessKeyId,
      sessionToken: credentials.sessionToken,
      region: credentials.region,

    });

    aws4Sign.url = reqEndpoint + path;

    if (headers['Content-Type'] === undefined) {
      headers['Content-Type'] = DEFAULT_TYPE;
    }

    delete headers.Host;
    delete headers['Content-Length'];

    return this.http.request(verb, aws4Sign.url, {
      responseType: 'json',
      observe: 'response',
      body: aws4Sign.body,
      headers,  
    });
  }
}
