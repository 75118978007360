import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService, ApiService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { PasswordFormValidator } from 'src/app/validators/password';
import {ActiveToast, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: 'resetPassword.component.html',
  styleUrls: ['../auth.scss'],
})
export class ResetPasswordComponent implements OnDestroy, OnInit {
  loginForm: FormGroup;
  isBusy: boolean = false;
  isSubmitted: boolean = false;
  hasError: boolean = false;
  success: boolean = false;
  email: string;
  invitationId: string;
  hidePassword: boolean = true;

  private subscription: Subscription = null;

  private errorToast: ActiveToast<any>;

  private toastrOptionsError = { 
    disableTimeOut: true,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  private successToast: ActiveToast<any>;
  
  private toastrOptionsSuccess = { 
    disableTimeOut: false,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    },
    {
      validator: PasswordFormValidator.checkMatchingPassword
    });

    this.subscription = this.activatedRoute
      .queryParamMap
      .subscribe(params => {
        this.email =  params.get('email');
        this.invitationId =  params.get('id');
      });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  async resetPassword() {

    if (this.loginForm.valid) {
      this.hasError = false;
      this.isBusy = true;

      this.loginForm.controls.password.disable();
      this.loginForm.controls.confirmPassword.disable();

      try {
        await this.apiService.resetChangePassword(this.email, this.invitationId,this.loginForm.get('password').value.trim());
        this.success = true;
        this.isBusy = false;

        this.loginForm.controls.password.enable();
        this.loginForm.controls.confirmPassword.enable();

        this.successToast = this.toastr.success('Password successfully updated', null, this.toastrOptionsSuccess);

        this.successToast.onTap.subscribe(() => {
          this.successToast = null;
        });
      } catch (err) {
        console.error('Reset Password Error', err);

        this.errorToast = this.toastr.error("Reset Password Error", null, this.toastrOptionsError);
        this.errorToast.onTap.subscribe(() => {
          this.errorToast = null;
        });

        this.hasError = true;
        this.isBusy = false;

        this.loginForm.controls.password.enable();
        this.loginForm.controls.confirmPassword.enable();

      }
    } else {
      this.isSubmitted = true;
    }
  }

}
