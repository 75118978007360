
export type noteAction =
    'addNotes'
    | 'addSubnotes'
    | 'updateNotes'
    | 'deleteNotes'
    | 'updateNotifications'
    | 'getNotificationsCount';

export interface IQueryNote {
    AOListingID?: number | string;
    message?: string;
    section?: string;
    parentNote?: number;
    noteId?: number;
}

export interface INote extends IQueryNote {
    id?: number;
    agentName?: string;
    noteType?: noteTypes;
    noteStatus?: noteStatus;
    identityId?: string;
    firstName?: string;
    lastName?: string;
    viewed?: boolean;
    originalNoteId?: number;
    createDate?: any;
    updateDate?: any;
}

export enum noteStatus {
    active = 'A',
    updated = 'U',
    deleted = 'D'
}

export enum noteTypes {
    Rejection = 'R',
    SubNote = 'S',
    Note = 'N'
}


export enum noteEventTypeMessage {
    addNote = 'ADD_NOTE',
    updateNote = 'UPDATE_NOTE',
    deleteNote = 'DELETE_NOTE',
    addSubnote = 'ADD_SUB_NOTE'
}
