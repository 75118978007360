import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk';

import { ApiService, ListingsParams } from './api.service';
import { AwsCredentialsService } from './awsCredentials.service';
import { Sigv4Http, ISigV4Credentials } from './sigv4.service';
import { ConfigService } from './config.service';
import { Preferences } from '../models/preferences';
import {ExtendedProforma, IProformaNetYield, IProformaSaveModel, ProForma} from './data/proforma';
import { ListingProperty } from './data/listingPropety';
import { PropertyStatusType } from './data/propertyStatusType';
import { AuditItem } from './data/auditItem';
import { ListingComps } from './data/listingComps';
import { ListingResult } from './data/listingResult';
import { PhotoItem } from './data/photoItem';
import { S3Document, S3FileReference } from './data/document';
import { UserRolesPermissions } from '../roles-permissions/types';
import { INote, IQueryNote, noteAction } from './data/notes';
import {DateRangeFormatFilter} from "./data/dateRangeFilter";
import { ITag } from './data/tags';
import { EVBUS_EVENTS_NAMES } from './update-request-service/model/updateRequest';
import { IViewSetting, ViewMode } from '../ui/view-setting/viewSettingModel';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { AppInjector } from '../app.injector';
@Injectable({
  providedIn: 'root'
})
export class ApiHttpService extends ApiService {

  private errorToast: ActiveToast<any>;
  private toastr: ToastrService;

  private toastrOptionsError = {
    disableTimeOut: true,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  constructor(
    protected awsCredentialsService: AwsCredentialsService,
    protected configService: ConfigService,
    protected sigv4: Sigv4Http
  ) {
    super(awsCredentialsService, configService, sigv4);
    this.toastr = AppInjector.get(ToastrService);
  }

  resetPassword(email: string) {
    return new Promise((resolve, reject) => {
      this.sigv4.post(this.configService.getServerUrl(), 'resetpassword', { email }, this.awsCredentialsService.getUnauthorizedCredentials())
        .subscribe(
          (data) => {
            resolve(true);
          }, (err) => {
            reject(err);
          });
    });
  }

  resetChangePassword(email: string, id?: string, password?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      const body = {
        invitationId: id,
        email: email,
        password: password,
        password2: password
      };

      this.sigv4.post(this.configService.getServerUrl(), 'resetpassword', body, this.awsCredentialsService.getUnauthorizedCredentials())
        .subscribe((response) => {
          const result = response.body;
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  changePassword(password?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {

          const body = {
            password1: password,
            password2: password
          };

          this.sigv4.post(this.configService.getServerUrl(), 'password', body, creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }


  postAccept(email: string, id: string, password: string = null): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      const body = {
        id: id,
        email: email,
        password: password
      };

      this.sigv4.post(this.configService.getServerUrl(), 'accept', body, this.awsCredentialsService.getUnauthorizedCredentials())
        .subscribe((response) => {
          const result = response.body;
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }


  getListings(params: ListingsParams): Promise<ListingResult> {
    const {
      selectedColumns,
      viewMode,
      buyBoxId,
      groupKeys,
      dateRange,
      viewSetting,
      fromLine,
      filters,
      sort,
      update,
      fetchTMUser,
      getCount,
      address,
    } = params;
    const jsonFilter = encodeURIComponent(filters ? JSON.stringify(filters) : '');
    const jsonSort = encodeURIComponent(sort ? JSON.stringify(sort) : '');
    const viewSettingFilter = encodeURIComponent(viewSetting ? JSON.stringify(viewSetting) : '');

    return new Promise<ListingResult>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          const queryParams: string[] = [
            `viewMode=${viewMode}`,
            `buyBoxId=${buyBoxId}`,
            `groupKeys=${groupKeys}`,
            `fromDate=${dateRange.fromDate}`,
            `toDate=${dateRange.toDate}`,
            `viewSetting=${viewSettingFilter}`,
            `fromLine=${fromLine}`,
            `filters=${jsonFilter}`,
            `sort=${jsonSort}`,
            `update=${update ? 1 : 0}`,
            `fetchTMUser=${fetchTMUser ? 1 : 0}`,
            `getCount=${getCount ? 1 : 0}`,
          ];

          if (address) {
            queryParams.push(`address=${encodeURIComponent(address)}`);
          }
          if (selectedColumns) {
            queryParams.push(`selectedColumns=${selectedColumns}`);
          }

          this.sigv4.get(this.configService.getServerUrl(), `listing?${queryParams.join('&')}`, creds)
            .subscribe((response) => {

              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  downloadFilteredProperties(viewMode: ViewMode, buyBoxId: string, groupKeys: string[], dateRange: DateRangeFormatFilter, viewSetting: IViewSetting, fromLine: number, limit: number, filterModel: any, sortModel: any, update: boolean, fetchTMUser: boolean, getCount: boolean): Promise<any> {
    const jsonFilter = encodeURIComponent(filterModel ? JSON.stringify(filterModel) : '');
    const jsonSort = encodeURIComponent(sortModel ? JSON.stringify(sortModel) : '');
    const viewSettingFilter = encodeURIComponent(viewSetting ? JSON.stringify(viewSetting) : '');
    const firstname = this.awsCredentialsService.getProfile().firstName;
    const lastname = this.awsCredentialsService.getProfile().lastName;



      const queryParams: string[] = [
        `viewMode=${viewMode}`,
        `buyBoxId=${buyBoxId}`,
        `groupKeys=${groupKeys}`,
        `fromDate=${dateRange.fromDate}`,
        `toDate=${dateRange.toDate}`,
        `viewSetting=${viewSettingFilter}`,
        `fromLine=${fromLine}`,
        `limit=${limit}`,
        `filters=${jsonFilter}`,
        `sort=${jsonSort}`,
        `update=${update ? 1 : 0}`,
        `fetchTMUser=${fetchTMUser ? 1 : 0}`,
        `getCount=${getCount ? 1 : 0}`,
        `firstname=${firstname}`,
        `lastname=${lastname}`
      ];

   return this.get(this.configService.getServerUrl(), `download/property?${queryParams.join('&')}`);
  }

  getSignedURL(fileName: string): Promise<any> {
      const queryParams = `fileName=${fileName}`;
   return this.get(this.configService.getServerUrl(), `download/property?${queryParams}`);
  }

  getListingProperty(aoListingId: string): Promise<ListingProperty> {
    return new Promise<ListingProperty>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), 'listing?aoListingId=' + aoListingId, creds)
            .subscribe((response) => {

              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  getRolesPermissions(): Promise<UserRolesPermissions> {
    return new Promise<UserRolesPermissions>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), 'permissions', creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  getCoverageData(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          let query = 'permissions?coverageData=1';
          this.sigv4.get(this.configService.getServerUrl(), query, creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  getZipCodeCoverage(zipCode): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          let query = `permissions?coverageData=1&zipCode=${zipCode}`;
          this.sigv4.get(this.configService.getServerUrl(), query, creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }


  getPreferences(): Promise<Preferences> {
    return new Promise<Preferences>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), 'preferences', creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  savePreferences(preferences: Preferences): Promise<Preferences> {
    return new Promise<Preferences>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {

          const body = {
            preferences: preferences
          };

          this.sigv4.post(this.configService.getServerUrl(), 'preferences', body, creds)
            .subscribe((data) => {
              resolve(preferences);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  getProforma(aoListingId: any, flowId?): Promise<ExtendedProforma> {
    return new Promise<ExtendedProforma>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), `proforma?aolistingid=${aoListingId}${flowId? `&flowId=${flowId}` : ''}`, creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }


  saveProforma(
    proforma: ProForma,
    avmProforma: ProForma,
    userInputProforma: ProForma,
    message: string,
    reason: string,
    listingStatus: PropertyStatusType,
    selectedComparableIds: any[],
    listingData: any,
    version,
    evBusEvents: EVBUS_EVENTS_NAMES [],
    workFlowNote: any,
    sendToTransactionManager: boolean,
    pendingEvents: any,
    lastUpdateDate: string,
    workflowState: string
  ): Promise<IProformaSaveModel> {
    return new Promise<IProformaSaveModel>((resolve, reject) => {

      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.post(this.configService.getServerUrl(), `proforma`, { proforma: proforma, avmProforma: avmProforma, userInputProforma: userInputProforma, message: message, reason: reason, listingStatus: listingStatus, comps: selectedComparableIds, listingData, versioning: version, evBusEvents, workFlowNote, sendToTransactionManager, pendingEvents, lastUpdateDate, workflowState}, creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  downloadProforma(aoListingId: any, date: any): Promise<any> {
    return new Promise<ProForma>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), `proforma?aolistingid=${aoListingId}&download=true&date=${date}`, creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  hidePropertyListings(props: {AOListingID: string | number, StatusUpdateDate: string}[], message: string, forceUpdateOnConflict?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {

          const body = {
            action: 'hide',
            message: message,
            properties: props,
            forceUpdateOnConflict
          };

          this.sigv4.post(this.configService.getServerUrl(), `listing`, body, creds)
            .subscribe((response) => {
              const result = response.body;

              resolve(result);

            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }


  savePropertyStatus( props: {AOListingID: string | number, StatusUpdateDate: string, status: PropertyStatusType}[], forceUpdateOnConflict?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {

          const body = {
            action: 'status',
            properties: props,
            forceUpdateOnConflict
          };

          this.sigv4.post(this.configService.getServerUrl(), `listing`, body, creds)
            .subscribe((response) => {
              const result = response.body;

              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  saveProperty(aoListingId: any, data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {

          const body = {
            action: 'update',
            status: status,
            AOListingID: aoListingId,
            listingData: data,
          };

          this.sigv4.post(this.configService.getServerUrl(), `listing`, body, creds)
            .subscribe(() => {
              resolve(true);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  getListingPropertyAudit(aoListingId: any): Promise<AuditItem[]> {
    return new Promise<AuditItem[]>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), 'audit?aoListingId=' + aoListingId, creds)
            .subscribe((response) => {
              const result: AuditItem[] = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();

    });
  }

  getAuditHistory(aoListingId: any, source: string, avmProforma): Promise<AuditItem[]> {
    return new Promise<AuditItem[]>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), 'audit?aoListingId=' + aoListingId + '&history=1' +'&source='+source +'&avmProforma='+avmProforma, creds)
            .subscribe((response) => {
              const result: AuditItem[] = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();

    });
  }



  getListingPropertyPhotos(aoListingId: any): Promise<PhotoItem[]> {
    return new Promise<PhotoItem[]>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), `photo?aoListingId=${aoListingId}`, creds)
            .subscribe((response) => {
              const result: PhotoItem[] = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  getListingComparables(aoListingId: any, proformaId: any, filters: any): Promise<ListingComps[]> {
    return new Promise<ListingComps[]>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          const jsonFilter = encodeURIComponent(filters ? JSON.stringify(filters) : '');


          this.sigv4.get(this.configService.getServerUrl(), `comparables?aoListingId=${aoListingId}&proformaId=${proformaId}&filters=${jsonFilter}`, creds)
            .subscribe((response) => {
              const body = response.body;
              if (!!body.errorMessage) {
                reject(body.errorMessage);
              }
              const result: ListingComps[] = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();

    });
  }

  getComparablePhotos(aoListingId: any, aoCompId: any, aoPhotoGroupId: any): Promise<any[]> {
    return new Promise<ListingComps[]>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {

          this.sigv4.get(this.configService.getServerUrl(), `photo?aoListingId=${aoListingId}&aoListingCompsId=${aoCompId}&aoPhotoGroupId=${aoPhotoGroupId}`, creds)
            .subscribe((response) => {
              const result: any[] = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();

    });
  }

  getFileFromS3(aoListingId, filepath): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      AWS.config.region = this.configService.getRegion();

      this.awsCredentialsService.refreshCredentials()
        .then((credentials: ISigV4Credentials) => {

          AWS.config.credentials = new AWS.Credentials(
            credentials.accessKeyId,
            credentials.secretAccessKey,
            credentials.sessionToken);

          const s3 = new AWS.S3();
          s3.getObject({
            Bucket: this.configService.getS3Bucket(),
            Key: `doc/${aoListingId}/${filepath}`,
          }, (error, data) => {
            if (error) {
              console.log(error);
              reject(error);
            } else {
              resolve(data);
            }
          });
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  uploadFileToS3(document: S3Document): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      AWS.config.region = this.configService.getRegion();

      this.awsCredentialsService.refreshCredentials()
        .then((credentials: ISigV4Credentials) => {

          AWS.config.credentials = new AWS.Credentials(
            credentials.accessKeyId,
            credentials.secretAccessKey,
            credentials.sessionToken);

          const s3 = new AWS.S3();
          s3.upload({
            Bucket: this.configService.getS3Bucket(),
            Key: `doc/${document.AOListingID}/${document.filepath}`,
            Body: document.content,
            ContentType: document.mimeType
          }, (error) => {
            if (error) {
              console.log(error);
              reject(error);
            } else {
              resolve(true);
            }
          });
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  private get(endpoint: any, path: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(endpoint, path, creds)
            .subscribe((response) => {
              const responseBody = response.body;

              if (responseBody && responseBody.errorMessage) {
                this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
                console.log(responseBody.errorMessage);
                this.errorToast.onTap.subscribe(() => {
                  this.errorToast = null;
                });
                reject(responseBody);
              } else {
                resolve(responseBody);
              }
            },
              (error) => {
                if (error._body) {
                  try {
                    error = JSON.parse(error._body);
                    this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
                    console.log(error._body || error);
                    this.errorToast.onTap.subscribe(() => {
                      this.errorToast = null;
                    });
                  } catch (e) {
                    this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
                    this.errorToast.onTap.subscribe(() => {
                      this.errorToast = null;
                    });
                  }
                } else {
                  this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
                  console.log(error);
                  this.errorToast.onTap.subscribe(() => {
                    this.errorToast = null;
                  });
                }

                console.error(error);
                reject(error);
              });
        }, (err) => {
          reject(err);
        });

    });
  }


  private post(endpoint: any, path: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.post(endpoint, path, body, creds)
            .subscribe((response) => {
              const responseBody = response.body;

              if (responseBody?.errorMessage) {
                this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
                console.log(responseBody.errorMessage);
                this.errorToast.onTap.subscribe(() => {
                  this.errorToast = null;
                });
                reject(responseBody);
              } else {
                resolve(responseBody);
              }
            },
              (error) => {
                if (error._body) {
                  error = JSON.parse(error._body);
                  this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
                  console.log(error.message);
                  this.errorToast.onTap.subscribe(() => {
                    this.errorToast = null;
                  });
                } else {
                  this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
                  console.log(error);
                  this.errorToast.onTap.subscribe(() => {
                    this.errorToast = null;
                  });
                }

                console.error(error);
                reject(error);
              });
        }).catch();

    });
  }

  getNotes(aoListingId: any): Promise<INote[]> {
    return this.get(this.configService.getServerUrl(), `notes?aoListingId=${aoListingId}`);
  }

  getNotificationsCount(aoListingId: any): Promise<number> {
    return this.get(this.configService.getServerUrl(), `notes/getNotificationsCount?aoListingId=${aoListingId}`);
  }

  postNotes(action: noteAction, noteQuery: IQueryNote): Promise<any> {
    return this.post(this.configService.getServerUrl(), `notes/${action}`, { note: noteQuery });
  }

  updateNotifications(noteQuery: IQueryNote[]): Promise<any> {
    return this.post(this.configService.getServerUrl(), `notes/updateNotifications`, { notes: noteQuery });
  }

  downloadProperties(aoListingIds: number[]): Promise<any[]> {
    const firstname = this.awsCredentialsService.getProfile().firstName;
    const lastname = this.awsCredentialsService.getProfile().lastName;
    return this.get(this.configService.getServerUrl(), `download/property?aoListingIds=${aoListingIds}&firstname=${firstname}&lastname=${lastname}`);
  }

  getTags(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), `listing?tags=1`, creds)
            .subscribe((response) => {

              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  updateTags({tagData, AOListingID}: ITag): Promise<any> {
    const data = {
      action: 'tags',
      tagData,
      AOListingID,
    };
    return this.post(this.configService.getServerUrl(), 'listing', data);
  }

  getVersion(): Promise<any> {
    return this.get(this.configService.getServerUrl(), `versioning`);
  }

  getFlags(): Promise<any> {
    return this.get(this.configService.getServerUrl(), `featureflags`);
  }

  getPurchasingEntities(): Promise<any> {
    return this.get(this.configService.getServerUrl(), `purchasingEntity`);
  }

  getActiveTags(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), `listing?tags=A`, creds)
            .subscribe((response) => {

              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  getBulkPortfolioNames(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          this.sigv4.get(this.configService.getServerUrl(), `listing?bulkPortfolioNames=1`, creds)
            .subscribe((response) => {

              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }

  getClassification(AOListingID: number, rent: number): Promise<any> {
    return this.get(this.configService.getServerUrl(), `classification/${AOListingID}/${rent}`);
  }

  getNetYield(AOListingID: string, request: IProformaNetYield): Promise<any> {
    const urlRequest = Object.keys(request).map((key) => {
      // handle null values.
      if (request[key] === null) {
        return encodeURIComponent(key) + '=';
      }
      return encodeURIComponent(key) + '=' + encodeURIComponent(request[key]);
    }).join('&');
    return this.get(this.configService.getServerUrl(), `proforma?AOListingID=${AOListingID}&getNetYield=1&${urlRequest}`);
  }

  downloadFilteredComps(aoListingID: string, proformaId: string, filterModel: any, sortModel: any): Promise<any> {
    const jsonFilter = encodeURIComponent(filterModel ? JSON.stringify(filterModel) : '');
    const jsonSort = encodeURIComponent(sortModel ? JSON.stringify(sortModel) : '');
    const firstname = this.awsCredentialsService.getProfile().firstName;
    const lastname = this.awsCredentialsService.getProfile().lastName;

      const queryParams: string[] = [
        `type=Comps`,
        `aoListingId=${aoListingID}`,
        `proformaId=${proformaId}`,
        `filters=${jsonFilter}`,
        `sort=${jsonSort}`,
        `firstname=${firstname}`,
        `lastname=${lastname}`
      ];

   return this.get(this.configService.getServerUrl(), `download/property?${queryParams.join('&')}`);
  }

  // function called submitBulkUpload to send the s3 file reference to the server with the AOPortfolioID
  submitBulkUpload(bulkPortfolioName: string, bulkModelType: string, fileReference: S3FileReference): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.awsCredentialsService.refreshCredentials()
        .then((creds: ISigV4Credentials) => {
          const body = {
            fileReference,
            bulkModelType,
            bulkPortfolioName
          };
          this.sigv4.post(this.configService.getServerUrl(), 'upload', body, creds)
            .subscribe((response) => {
              const result = response.body;
              resolve(result);
            },
              (error) => {
                reject(error);
              });
        }).catch();
    });
  }
}
