import { Component, Input } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams, IServerSideStore } from 'ag-grid-community';
import { ListingComps } from 'src/app/services/data/listingComps';
import { ListingProperty } from 'src/app/services/data/listingPropety';

@Component({
  selector: 'a1-grid-tool-tip',
  templateUrl: './grid-tool-tip.component.html',
  styleUrls: ['./grid-tool-tip.component.scss']
})
export class GridToolTipComponent implements ITooltipAngularComp  {
  params: ITooltipParams;
  data: any;
  missingData = [];
  missinDataContent: string;
  exception;
  exceptionContent: string;
  displayOnTop = false;
  groupRowSelected: boolean = false;
  isHidden: boolean = false;

  _currentProperty: ListingProperty;

  @Input()
  get currentProperty():ListingProperty | ListingComps {
    return this.data;
  }
  set currentProperty(prop: ListingProperty | ListingComps) {
    this.data = prop;
    this.buildMissingDataContent();
    this.buildExceptionContent();
  }

  constructor() { }


  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  agInit(params: any): void {
    this.params = params;
    this.data = this.params.data;

    if (this.params.value == 'tooltipGroup' ) {
      const selectedRowCount = this.getSelectedGroupRowsCount(this.params.node.childStore );

      if (selectedRowCount != 0) {
        this.groupRowSelected = true;
      } else {
        this.isHidden = true;
      }
    } else {
      this.handleTooltipPosition();
      this.buildMissingDataContent();
      this.buildExceptionContent();
    }
  }

  buildMissingDataContent () {
    if (!this.data) {
      return;
    }
    this.missingData = [];
    if (!this.data.Beds || !this.data.BathsCount || !this.data.LivingAreaSqFt || !this.data.YearBuilt) {
           const dataToWatch = {
            beds: this.data.Beds,
            baths: this.data.BathsCount,
            sqft: this.data.LivingAreaSqFt,
            "year built":this.data.YearBuilt
          };
          Object.keys(dataToWatch).forEach(prop => {
            if (!dataToWatch[prop]) {
              this.missingData.push(prop);
            }
          });
          if (this.missingData.length > 0) {
            this.missinDataContent = `${this.missingData.join(', ')}`;
            this.missinDataContent = this.missinDataContent.charAt(0).toUpperCase() +this.missinDataContent.slice(1);
          }
        } else {
          this.missinDataContent = null;
        }
  }

  buildExceptionContent() {
    if(this.data.Exception) {
      this.exception = true;
      this.exceptionContent =  this.data.Exception;
    } else {
      this.exception = false;
    }
  }

  handleTooltipPosition() {
    if (this.params.api && this.params?.rowIndex) {
      if (((8>=this.params.api.getDisplayedRowCount()) && this.params?.rowIndex >= 5) ||  (this.params?.rowIndex >= 12)) {
        this.displayOnTop = true;
      }
    }
  }

  getSelectedGroupRowsCount(childStore: IServerSideStore) {
    let selectedRowCount = 0;
    if (childStore) {
      childStore.forEachNodeDeep((x) => {
        if (x.isSelected()) {
          selectedRowCount++;
        }
      });
    }
    return selectedRowCount;
  }
}
