import { Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, HostBinding, HostListener, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgControl, NgForm } from '@angular/forms';

import { Subject } from 'rxjs';

import * as numeral from 'numeral';
import * as _ from 'lodash';


const keyCodes = {
  enter: 13,
  escape: 27,
  left: 37,
  up: 38,
  right: 39,
  down: 40
};

const Helper = {
  createNumericRegex(hasDecimal: boolean, hasSign: boolean): RegExp {
    let regexString = '^';
    if (hasSign) {
      regexString += '-?';
    }

    regexString += '(?:(?:\\d+';
    if (hasDecimal) {
      regexString += '(\\.\\d*)?';
    }

    regexString += ')|(?:\\.\\d*))?$';
    return new RegExp(regexString);
  }
};

@Component({
  selector: 'app-numeric-input',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss'],
})
export class NumericInputComponent implements ControlValueAccessor, OnDestroy, DoCheck {
  static nextId = 0;

  @ViewChild('numericInput', { static: true }) numericInput: ElementRef;

  @Input() showDashIfNull: boolean = false;
  @Input() suffix: string = '';

  @Output() changed: EventEmitter<any> = new EventEmitter();
  @Output() focusChange: EventEmitter<boolean> = new EventEmitter();

  // ////////////////////
  // ControlValueAccessor

  // Function to call when the value changes.
  onChange = (value: any) => { };

  // Function to call when the input is touched
  onTouched = () => { };
  // ////////////////////


  // ////////////////////
  // MatFormFieldControl

  internalFormGroup: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  describedBy = '';

  // Need this to be able to focus() programmatically
  @HostBinding('attr.tabindex') tabindex = -1;
  @HostListener('focus') onFocus() {
    this.numericInput.nativeElement.focus();
  }

  @HostBinding('attr.id')
  @Input()
  get id(): string { return this._id; }
  set id(value: string) {
    this._id = value;
    this.stateChanges.next();
  }
  private _id: string = `app-numeric-input-${NumericInputComponent.nextId++}`;

  get empty() {
    return this.internalValue == null || this.internalValue == '';
  }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = value;
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.internalFormGroup.disable() : this.internalFormGroup.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get showLocked(): boolean { return this._showLocked; }
  set showLocked(value: boolean) {
    this._showLocked = value;
    this.stateChanges.next();
  }
  private _showLocked = false;

  @Input()
  get readonly(): boolean { return this._readonly; }
  set readonly(value: boolean) {
    this._readonly = value;
    // this._readonly ? this.internalFormGroup.disable() : this.internalFormGroup.enable();
    this.stateChanges.next();
  }
  private _readonly = false;

  @Input()
  get value(): string | null {
    return this.internalValue;
  }
  set value(value: string) {
    this.internalValue = value;
    this.setInputValue(value);

    this.stateChanges.next();
  }

  constructor(@Self() @Optional() private ngControl: NgControl,
    fb: FormBuilder,
    private elRef: ElementRef<HTMLElement>) {

    if (ngControl) {
      ngControl.valueAccessor = this;
    }

    this.internalFormGroup = fb.group({
      displayValue: '',
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this._disabled = this.ngControl.disabled;
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }


  // ////////////////////
  // ControlValueAccessor

  // Allows Angular to update the model
  // Update the model and changes needed for the view here.

  // ! Do NOT call onChange(). It will cause infinite recursion
  writeValue(value: any) {
    try {
      this.value = value;
    } catch (err) {
      console.log(err);
    }
  }

  // Allows Angular to register a function to call when the model changes.
  // Save the function as a property to call later here.
  registerOnChange(fn) {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
  // ControlValueAccessor
  // ////////////////////


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // FINALLY! Actual SSN Stuff
  //
  private numericRegex: RegExp;
  private autoCorrect = true;
  private inputValue: string;
  private previousValue = undefined;
  private internalValue = undefined;

  @Input()
  get decimals(): number { return this._decimals; }
  set decimals(value: number) {
    this._decimals = value;
    this.stateChanges.next();
  }
  private _decimals: number = 0;

  @Input()
  get min(): number { return this._min; }
  set min(value: number) {
    this._min = value;
    this.stateChanges.next();
  }
  private _min: number;

  @Input()
  get max(): number { return this._max; }
  set max(value: number) {
    this._max = value;
    this.stateChanges.next();
  }
  private _max: number;

  @Input()
  get format(): string { return this._format; }
  set format(value: string) {
    this._format = value;

    this.setInputValue(this.internalValue);
    this.stateChanges.next();
  }
  private _format: string = '($0,0.00)';


  // *********************** NUMERIC FIELD IMPLEMENTATION ************************
  // Base code came from
  // https://github.com/leovo2708/ngx-numeric-textbox
  // But I'm unable to tweak it so all the material matInput, mat-error, validation worrk properly
  // It was easier to bring everything here.

  handleNumericInput() {
    const element: HTMLInputElement = this.numericInput.nativeElement;
    const selectionStart = element.selectionStart;
    const selectionEnd = element.selectionEnd;
    const value = element.value;

    if (!this.isNumericValidInput(value)) {
      element.value = this.inputValue;
      this.setSelection(selectionStart - 1, selectionEnd - 1);
      this.updateValue(this.parseNumber(this.inputValue));

      this.internalFormGroup.setErrors({ 'onlynumber': true });
    } else {
      const orginalInputValue = this.parseNumber(value);
      const limitInputValue = this.restrictDecimals(orginalInputValue);

      // if (this.autoCorrect) {
      //   limitInputValue = this.limitValue(limitInputValue);
      // }
      if (this.isInRange(limitInputValue)) {

        if (orginalInputValue !== limitInputValue) {
          this.setInputValue(limitInputValue);
          this.setSelection(selectionStart, selectionEnd);
        } else {
          this.inputValue = value;
        }

        this.updateValue(limitInputValue);

      } else {
        element.value = this.inputValue;
        this.setSelection(selectionStart - 1, selectionEnd - 1);
        this.updateValue(this.parseNumber(this.inputValue));
      }
    }
  }

  handleNumericFocus() {
    if (!this.focused && !this._readonly) {
      this.focused = true;
      this.setInputValue();

      this.setSelection(0, this.internalFormGroup.get('displayValue').value.length);

      this.focusChange.emit(true);
    }
  }

  handleNumericBlur() {
    if (this.focused && !this._readonly) {
      this.focused = false;
      this.setInputValue();
      this.onTouched();

      this.focusChange.emit(false);

    }
  }

  handleNumericKeyDown(event: KeyboardEvent) {
    if (this._readonly) {
      return;
    }
    // A REMETTRE
    // if (!this.isBusy) {
    switch (event.which) {
      case keyCodes.down:
        this.addStep(-1);
        break;
      case keyCodes.up:
        this.addStep(1);
        break;
      case keyCodes.enter:
        // this.enter.emit();
        break;
      case keyCodes.escape:
        //  this.escape.emit();
        break;
    }
    // }
  }


  private isNumericValidInput(input: string) {
    let numericRegex = this.numericRegex;
    if (_.isNil(numericRegex)) {
      let hasDecimal = true;
      if (_.isNumber(this._decimals) && this._decimals === 0) {
        hasDecimal = false;
      }

      let hasSign = true;
      if (_.isNumber(this._min) && this._min >= 0 && this.autoCorrect) {
        hasSign = false;
      }

      numericRegex = Helper.createNumericRegex(hasDecimal, hasSign);
    }

    return numericRegex.test(input);
  }

  private parseNumber(input: string): number {
    return numeral(input).value();
  }

  private addStep(step: number) {
    let value = this.internalValue ? step + this.internalValue : step;
    value = this.limitValue(value);
    value = this.restrictDecimals(value);
    this.setInputValue(value);
    this.updateValue(value);
  }

  private limitValue(value: number): number {
    if (_.isNumber(this._max) && value > this._max) {
      return this._max;
    }

    if (_.isNumber(this._min) && value < this._min) {
      return this._min;
    }

    return value;
  }

  private isInRange(value: number): boolean {
    if (_.isNumber(value)) {
      if (_.isNumber(this._min) && value < this._min) {
        return false;
      }

      if (_.isNumber(this._max) && value > this._max) {
        return false;
      }
    }

    return true;
  }

  // private restrictModelValue(value: number): number {
  //   let newValue = this.restrictDecimals(value);
  //   if (this.autoCorrect && this.limitValue(newValue) !== newValue) {
  //     newValue = null;
  //   }

  //   return newValue;
  // }

  private restrictDecimals(value: number): number {
    if (_.isNumber(this._decimals)) {
      const words = String(value).split('.');
      if (words.length === 2) {
        const decimalPart = words[1];
        if (decimalPart.length > this._decimals) {
          value = parseFloat(words[0] + '.' + decimalPart.substr(0, this._decimals));
        }
      }
    }

    return value;
  }

  private setInputValue(value: any = null) {
    if (!this.numericInput) {
      return;
    }

    if (_.isNil(value)) {
      value = this.internalValue;
    }

    if (_.isNil(value) || value === '') {
      this.internalFormGroup.setValue({ displayValue: '' });
      this.inputValue = '';
      return;
    }

    const inputValue = this.formatValue(value);
    this.internalFormGroup.setValue({ displayValue: inputValue });

    this.inputValue = inputValue;
  }

  private updateValue(value: number) {
    if (this.internalValue !== value) {
      this.previousValue = this.internalValue;
      this.internalValue = value;
      this.onChange(value);
    }
  }

  private formatValue(value: number): string {
    if (!_.isNil(value)) {
      if (this.focused) {
        return this.formatInputValue(value);
      } else {
        return this.formatNumber(value);
      }
    }

    return '';
  }

  private formatInputValue(value: number): string {
    return String(value);
  }

  private formatNumber(value: number): string {
    return numeral(value).format(this._format) + this.suffix;
  }

  private setSelection(start: number, end: number) {
    this.numericInput.nativeElement.setSelectionRange(start, end);
  }

}
