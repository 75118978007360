import { Component, Input, NgZone, ViewContainerRef } from '@angular/core';
import { SelectComponent } from '../select/select.component';


@Component({
  selector: 'app-view-setting',
  templateUrl: './view-setting.component.html',
  styleUrls: ['./../select/select.component.scss','./view-setting.component.scss']
})
export class ViewSettingComponent extends SelectComponent {
  @Input() dropdownIcon: string = `a1-dropdown-chevron-icon`;
  isOptionsActive = false;
  @Input()
  get options(): any[] {
    return this._options;
  }
  set options(values: any[]) {
    this._options = values;
    this.updateSelectionOptions();
  }

  @Input()
  set disabled(value) {
    this._isDisabled = value;
    if (this._isDisabled) {
      this.close();
    }
  }

  constructor(vcr: ViewContainerRef, zone: NgZone) {
    super(vcr, zone);
   }

  updateSelection() {
    if (this.options) {
      this.selectedModel = this.options.find(
        currentOption => currentOption[this.idKey] === this.selectedId
      );
      this.isOptionsActive = this.options.find((currentOption) => !!currentOption.checked);
    } else {
      this.selectedModel = null;
    }
  }

  updateSelectionOptions() {
    if (this.options) {
      this.selectedModel = this.options.find(
        currentOption => currentOption[this.idKey] === this.selectedId
        );
        this.isOptionsActive = !!this.options.find((currentOption) => currentOption.checked);
      }
  }

  select(option) {
    if (option) {
      this._selectedId = option[this.idKey];
      this.selectedModel = option;
    } else {
      this._selectedId = null;
      this.selectedModel = null;
    }

  }
  onCheckboxOptionClick($event: MouseEvent, option, index?) {
    $event.preventDefault();
    $event.stopPropagation();
    if (index != undefined) {
      this.options[index].checked = !this.options[index].checked;
      this.isOptionsActive = !!this.options.find((currentOption) => !!currentOption.checked);
      this.selectChange.emit(index);
      this.close();
    }
  }
}
