import { Injectable } from '@angular/core';
import { ProFormaModel } from '../models/proformaModel';
import { ListingProperty } from './data/listingPropety';

import { calculate, insuranceCalculations  } from '@aveone/a1-proforma';
import { UnderwriterService } from './underwriter.service';

@Injectable({
  providedIn: 'root'
})
export class ProFormaCalculator {
  constructor(
    private underwriterService: UnderwriterService,
  ) {
  }

  calculate(listing: ListingProperty, proformaModel: ProFormaModel, isAvm: boolean, isConditionGrid: boolean) {
    const data = this.underwriterService.getCoverageCache();
    calculate(listing, proformaModel, isAvm, isConditionGrid, null, data);
  }
  calculateInsuranceFactors(listing: ListingProperty, proformaModel) {
    const data = this.underwriterService.getCoverageCache();
    return insuranceCalculations(listing, proformaModel, data);
  }
}

