import { Component, Input, ViewChild, OnInit, DoCheck, forwardRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { RolesPermissionsService } from 'src/app/roles-permissions';
import * as _ from 'lodash';

import { AuthenticationService, RouterExtService, UnderwriterService } from 'src/app/services';
import { first } from 'rxjs/operators';
import { SortDropdownComponent } from '../../sortDropdown/sortDropdown.component';
import { UserFilter, ListingPreferences } from 'src/app/models/preferences';
import { GridFilterService } from '../gridFilter.service';
import { GridFilterState } from '../gridFilterState';

interface FilterChipDef {
  key: string;
  name: string;
  description: string;
}

@Component({
  selector: 'app-grid-filter-buttons',
  styleUrls: ['./gridFilterButtons.component.scss'],
  templateUrl: './gridFilterButtons.component.html'
})
export class GridFilterButtonsComponent {
  @ViewChild(forwardRef(()=> SortDropdownComponent)) sortChip: SortDropdownComponent;

  @Input() disabled = false;
  @Input() hasUserFilters = false;
  @Input() reverseButtonOrder = false;

  private _gridName = null;
  @Input()
  get gridName(): string {
    return this._gridName;
  }
  set gridName(values: string) {
    this._gridName = values;
    this.init();
  }
  isSuperUser = false;
  state: GridFilterState;

  constructor(
    private gridFilterSerice: GridFilterService,  private rolesPermissionsService: RolesPermissionsService) {
   }

  init() {
    if (this._gridName) {
      const tmp = this.gridFilterSerice.getState(this.gridName);
      if (tmp) {
        this.state = tmp;
      }
    }
    this.rolesPermissionsService.isSuperUser().pipe(first()).subscribe((isSuperUser) => {
      if(isSuperUser) {
        this.isSuperUser = true;
      }
    });
  }

  toggleShowFilters() {
    this.state = this.gridFilterSerice.getState(this.gridName);

    this.state.toggleFiltersOn = !this.state.toggleFiltersOn;

    if (!this.state.showChips && !this.state.toggleFiltersOn) {
      this.sortChip.close();
    }
  }

  clearFilters() {
    this.gridFilterSerice.clearFilters(this.gridName);
  }


  onFilterSelectChange(event) {
    this.gridFilterSerice.filterSelectionChange(this.gridName, event.filterId, event.shareFilter);

    if (!this.state.showChips && !this.state.toggleFiltersOn) {
      this.sortChip.close();
    }
  }

  onFilterSelectRemoveClick(event) {
    if (this.state.currentFilterId == event.filterId && this.state.shareFilter == event.shareFilter && this.sortChip) {
      this.sortChip.close();
    }

    this.gridFilterSerice.deleteFilter(this.gridName, event.filterId, event.shareFilter);
  }

}
