/* eslint-disable @typescript-eslint/no-unused-vars */
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUpdateRequest, PROFORMA_EVENT_ACTION, STEP_ACTION, UPDATE_REQUEST_LEVEL } from 'src/app/services/update-request-service/model/updateRequest';
import * as _ from 'lodash';
import { APPROVAL_ACTIONS } from 'src/app/services/update-request-service/model/IRequest';


@Component({
  selector: 'app-update-request',
  templateUrl: './update-request.component.html',
  styleUrls: ['./update-request.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':leave', [
        animate('500ms ease-out', style({ height: 0, opacity: 0, position: 'absolute', top: 0, marginTop: '-42px' }))
      ]),
      transition(':enter', [
        animate('200ms 400ms ease-in', style({ height: '*', opacity: 1 }))
      ]),
    ]),
    trigger('openCloseConcession', [
      transition(':leave', [
        animate('200ms ease-out', style({ height: 0, opacity: 0 }))
      ]),
      transition(':enter', [
        animate('200ms 200ms ease-in', style({ height: '*', opacity: 1 }))
      ]),
    ]),
  ],
})
export class UpdateRequestComponent {

  commentIsOpen = false;
  level: UPDATE_REQUEST_LEVEL = UPDATE_REQUEST_LEVEL.WARN;
  levels = UPDATE_REQUEST_LEVEL;
  actions = {...STEP_ACTION,  ...PROFORMA_EVENT_ACTION};
  requestStyle;
  concessionFieldsConf = {
    active:false,
    nextAction: null,
    actionName: null
  };
  approvalConfig = {
    add: false,
    actionName:''
  };

  private _updateRequests: IUpdateRequest = null;
  @Input()
  get updateRequests(): IUpdateRequest {
    return this._updateRequests;
  }

  set updateRequests(conf: IUpdateRequest) {
    this._updateRequests = conf;

    setTimeout(() => {
      if(!_.isEqual(this.concessionFieldsConf, conf.activeStep.commands.panel.triggerConcessionFields.commands)) {
        this.concessionFieldsConf = conf.activeStep.commands.panel.triggerConcessionFields.commands;
      }
      this.buildApprovalAction();
    }, 200);

    this.requestStyle = this._updateRequests.activeStep.proforma.style || {} ;
    this.buildNextAction();
    this.level = this._updateRequests.activeStep.level;
  }

  @Input() isBusy = false;

  @Output() changeRequested: EventEmitter<any> = new EventEmitter();

  // @Output() concessionNoteChange: EventEmitter<any> = new EventEmitter();
  concessionNote = null;

  activeAction: any = STEP_ACTION.INFO;

  showValue = true;
  constructor() { }

  buildNextAction() {
    if(!this.concessionFieldsConf && !this._updateRequests) {
      this.activeAction = STEP_ACTION.NULL;
      return;
    }

    if(this._updateRequests && this._updateRequests.activeStep.proforma.hideValue) {
      this.showValue = false;
    } else {
      this.showValue = true;
    }
    if (this.updateRequests.activeStep.proforma.updateProforma) {
      this.activeAction = STEP_ACTION.UPDATE;
      return;
    }

    const updateProforma = this.updateRequests.activeStep.proforma.updateProforma;
    const updateConcession = this.updateRequests.activeStep.commands.panel.triggerConcessionFields.active;
    const preApproval = this._updateRequests.activeStep.commands.panel.preApproval.active;
    if (
      this.updateRequests.activeStep.multipleActions
      // (
      //    && (
      //   (updateProforma && updateConcession)
      // || (updateProforma && preApproval)
      // || (preApproval && updateConcession)
      // )
      // )
    ) {
      if(this.updateRequests.activeStep.multipleActions === PROFORMA_EVENT_ACTION.CONCESSION_APPROVAL) {
        this.activeAction = PROFORMA_EVENT_ACTION.CONCESSION_APPROVAL;
        return;
      }
      this.activeAction = STEP_ACTION.MULTIPLE;
      return;
    }
    if (
      this.updateRequests.activeStep.commands.panel.triggerConcessionFields.active
      &&
      !this.updateRequests.activeStep.proforma.updateProforma
      && !this._updateRequests.activeStep.commands.panel.preApproval.active
    ) {
      this.activeAction = STEP_ACTION.CONCESSION;
      return;
    }
    if (
      !this.updateRequests.activeStep.proforma.updateProforma
      && this._updateRequests.activeStep.commands.panel.preApproval.active
    ) {
      this.activeAction = STEP_ACTION.APPROVAL;
      return;
    }
    if (
      !this.updateRequests.activeStep.level
      && !this.updateRequests.activeStep.stepAction
    ) {
      this.activeAction = STEP_ACTION.NULL;
      return;
    }

    this.activeAction = STEP_ACTION.INFO;

  }


  sendUpdateAction($event) {
    if ($event == STEP_ACTION.CONCESSION_TRIGGER) {
      this.changeRequested.emit(
        {
          event: STEP_ACTION.CONCESSION_TRIGGER,
        }
      );
      this.updateRequests = this.updateRequests;
      return;
    }

    if ($event == STEP_ACTION.APPROVAL) {
      this.changeRequested.emit(
        {
          event: STEP_ACTION.APPROVAL,
        }
      );
      this.updateRequests = this.updateRequests;
      return;
    }

    if ($event == STEP_ACTION.UPDATE) {
      this.changeRequested.emit(
        {
          event: STEP_ACTION.UPDATE,
        }
      );
      this.updateRequests = this.updateRequests;
      return;
    }

  }

  // onConcessionNoteChange(newconcessionNote) {
  //   if(newconcessionNote) {
  //     this.concessionNoteChange.emit(newconcessionNote);
  //   }
  // }

  buildApprovalAction () {
    if (this._updateRequests.activeStep.commands.panel.preApproval.action === APPROVAL_ACTIONS.ADD) {
      this.approvalConfig = {
        add: true,
        actionName:'Add approval'
      };
    } else {
      this.approvalConfig = {
        add: false,
        actionName:'Remove approval'
      };
    }
  }

}
