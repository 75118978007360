import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import * as _ from 'lodash';
import * as moment from "moment";
import { ColDef, IFilter } from "ag-grid-community";

const datecolumnTypeNames = [
"humanDateColumn",
"calendarDateColumn",
"dateColumn"
];
export class TagsUtils {
  static activeTagsSubject = new BehaviorSubject<any>(undefined);
  static tagListObservable: Observable<any> = TagsUtils.activeTagsSubject.asObservable();
  static tagList;
  static canSort = false;
  static alreadyLoaded = false;
  static isTagSorting = false;
  static columnsOfTypeDate = {};
  static tagFilterInstance: IFilter = null;

  static resetTagUtils() {
    this.tagList= null;
    this.canSort = false;
    this.alreadyLoaded = false;
    this.isTagSorting = false;
    this.columnsOfTypeDate = {};
    this.tagFilterInstance = null;
  }

  static manageFilterInstance() {
    if(!this.tagFilterInstance) {
      return;
    }
    const model =  this.tagFilterInstance.getModel();
    if(!model || !model.values || model.values.length === 0) {
      return;
    }
    if (TagsUtils.isTagSorting) {
      this.tagFilterInstance.setModel({ values: model.values });
      return;
    }
    this.tagFilterInstance.setModel(null);
  }

  static canLoadTags = () => {
   return  !TagsUtils.alreadyLoaded ||
   (TagsUtils.alreadyLoaded && !TagsUtils.isTagSorting) ||
    !TagsUtils.tagList;
  }

  static getTagsList(forDropdown = false) {
    return this.tagListObservable.pipe(
      tap((val) => {
        this.tagList = val;
      }),
      map((list) => {
        if (list) {
          let tagArray = [];
          const tagsObject = list;
          for (const tagKey in tagsObject) {
            if (tagsObject[tagKey]) {
              if (forDropdown) {
                tagArray.push({
                  id: tagKey,
                  name: tagsObject[tagKey]
                });
              } else {
                tagArray.push(tagsObject[tagKey]);
              }
            }
          }

          tagArray = _.sortBy(tagArray, [tag => forDropdown ? tag.name?.toLowerCase() : tag.toLowerCase()] );
          return tagArray;
        }
        return null;
      }),
      map((list)=> {
        TagsUtils.manageFilterInstance();
        return list ?? [];
      })
    );
  }

  static getTagsIds(val) {
    if (this.tagList) {
      const tagsObject = this.tagList;
      for (const tagKey in tagsObject) {
        if (tagsObject[tagKey] && tagsObject[tagKey] == val) {
          return tagKey;
        }
      }
    }

    return null;
  }

  static getTagsValues(ids) {
    const tags = [];

    if (this.tagList && ids) {
      const tagsObject = this.tagList;
      for (const tagKey in tagsObject) {
        if (ids.includes(tagKey)) {
          tags.push(tagsObject[tagKey]);
        }
      }
    }

    return tags;
  }

  static getSortedTagsListing(sortingModel: any, listings:  any[], sort: string) {
    const dataWithoutTags = [];
    let dataWithTags = [];

    const sortableByTags = this.isTagsMainSorting(sortingModel);
    if( listings.length >= 1) {
      if (sortableByTags.isMainSorting) {
        listings.forEach((item) => {
          if (item.Tags) {
            dataWithTags.push(item);
          } else {
            dataWithoutTags.push(item);
          }
        });
      } else {
        dataWithTags= listings;
      }

    }

    const parsedTagList = this.tagList || {};
    if(sort == 'asc') {
      dataWithTags.sort( (itemA, itemB) => {
        if(this.canSortNodes(sortableByTags, sortingModel, itemA, itemB)) {
          return this.compareTags(itemA,itemB, 'asc');
        }
        return 0;

      });

      if(Object.keys(parsedTagList).length >0 ) {
        dataWithTags.map(prop => {
          if(prop.Tags) {
            prop.Tags.sort((tagIdA, tagIdB) => {
              if (parsedTagList[tagIdA]?.toUpperCase() < parsedTagList[tagIdB]?.toUpperCase()) {
                return 1;
              }
              if (parsedTagList[tagIdA]?.toUpperCase() > parsedTagList[tagIdB]?.toUpperCase()) {
                return -1;
              }
              return 0;
            });
          }
        });
      }

      return [...dataWithTags, ... dataWithoutTags];
    }

    dataWithTags.sort( (itemA, itemB) => {
      if(this.canSortNodes(sortableByTags, sortingModel, itemA, itemB)) {
        return this.compareTags(itemA,itemB, '');
      }
      return 0;
    });

    if(Object.keys(parsedTagList).length >0 ) {
      dataWithTags.map(prop => {
      if(prop.Tags) {
        prop.Tags.sort((tagIdA, tagIdB) => {
          if (parsedTagList[tagIdA]?.toUpperCase() > parsedTagList[tagIdB]?.toUpperCase()) {
            return 1;
          }
          if (parsedTagList[tagIdA]?.toUpperCase() < parsedTagList[tagIdB]?.toUpperCase()) {
            return -1;
          }
          return 0;
        });
      }

    });}

   return [... dataWithTags, ...dataWithoutTags];
  }

  static isTagsMainSorting(sortingModel: any) {
    if (Array.isArray(sortingModel) && sortingModel.length> 0) {
      const index= sortingModel.findIndex(value => value.colId === "Tags");
      return {
        isMainSorting: index === 0,
        position: index
      };
    }
    return {
      isMainSorting: false,
      position: undefined
    };

  }

  static canSortNodes (sortingTags: any, sortingModel: any[], nodeA: any, nodeB: any) {
    let sort = false;
    if (sortingTags.isMainSorting &&  (nodeA || nodeB)) {
      return true;
    }
    if (sortingTags.position) {
      const sortableSortingModel = sortingModel.slice(0, sortingTags.position);
      sortableSortingModel.forEach((sortingModelItem) => {
        if(this.columnsOfTypeDate[sortingModelItem.colId]) {
          sort = this.compareDateTime(nodeA[sortingModelItem.colId],nodeB[sortingModelItem.colId]);
        } else {
          sort = this.compareAlphanumerical(nodeA[sortingModelItem.colId], nodeB[sortingModelItem.colId]);
        }
      });
    }
    return sort;
  }

  static compareTags(nodeA, nodeB, direction) {
    if(Array.isArray(nodeA.Tags)) {
      if(Array.isArray(nodeB.Tags)) {
        return direction === 'asc' ? nodeA.Tags.length - nodeB.Tags.length : nodeB.Tags.length - nodeA.Tags.length;
      }
      return direction === 'asc' ? 1 : -1;
    } else {
      return direction === 'asc' ? -1 : 1;
    }
  }

  static gridCompareTags(nodeA, nodeB, direction) {
    if(!Array.isArray(nodeA.Tags)) {
      return direction == 'asc' ? -1 : 1;
    }

    if(!Array.isArray(nodeB.Tags)) {
      return direction == 'asc' ? 1 : -1;
    }

    if (nodeA.Tags.length > nodeB.Tags.length) {
      return -1;
    } else {
      return 1;
    }
  }

  static setColumnsType(columnDefs: ColDef[]) {
    columnDefs.forEach(column => {
      if (column.type && column.field) {
        if (datecolumnTypeNames.includes(column.type as string)) {
          this.columnsOfTypeDate[column.field] = column.type;
        }
      }
    });
  }

  static compareAlphanumerical(valueA, ValueB) {
    if (valueA === ValueB) {
      return true;
    }
    return false;
  }

  static compareDateTime = (valueA, valueB) => {
   if (!valueB && !valueA) {
      return true;
    }
    const dateA = moment(valueA);
    const dateB = moment(valueB);
    if( !dateA.isValid() || !dateB.isValid()) {
      if(valueB && valueA) {
        return valueA.toString().toUpperCase() == valueB.toString().toUpperCase();
      }
      return valueA == valueB;
    }

   return dateB.isSame(dateA);
  }

}
