export const customStyles = `
// Global
.a1 .myDpSelectorArrow:after, .a1 .myDpSelectorArrow:before {
  border: none;
}

.ng-mydp * {
  font-family: IBM Plex Sans !important;
}

.a1 .myDpSelector {
  margin-top: 4px;
  padding: 0;
}

// Header
.a1 .myDpMonthYearSelBar {
  height: 32px;
}

.a1 .myDpMonthYearText button {
  color: #0D61A1;
  font-weight: 500;
}

.a1 .myDpMonthBtn, .myDpYearBtn {
  font-size: 14px;
}

.a1 .myDpCalTable thead tr {
  background-color: #F2F7FC;
  height: 32px;
}

.a1 .myDpWeekDayTitle {
  font-size: 13px;
}

.a1 .myDpNextBtn button, .a1 .myDpPrevBtn button {
  font-family: angular-mydatepicker !important;
}

.a1 .myDpNextBtn button::before, .a1 .myDpPrevBtn button::before {
  color: #CECECE;
  font-size: 14px;
  position: absolute;
  top: 0;
}

.a1 .myDpPrevBtn button::before {

}

.a1 .myDpNextBtn button::before {
  right: 12px;
}

// Body
.a1 .myDpCalTable tbody tr {
  height: 32px;
}

.a1 .myDpDaycell {
  color: #000000;
}

.a1 .myDpDaycell.myDpNextMonth {
  color: #CECECE;
}

.a1 .myDpTableSingleDay {
  font-size: 13px;
  width: 32px;
  height: 32px;
}

.a1 .myDpDaycell:focus {
  box-shadow: none;
}

.a1 .myDpDayValue {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: block;
  text-align: center;
  padding: 9px 0;
  display: inline-block;
}

.a1 .myDpTableSingleDay .myDpMarkCurrDay  {
  border: none;
  background-color: #F2F7FC;
  color: #0D61A1;
  border-bottom: none;
}

.a1 .myDpSelectedDay, .a1 .myDpSelectedMonth, .a1 .myDpSelectedYear {
  border: none;
  background-color: #FFFFFF;
  color: white;
}

.a1 .myDpSelectedDay .myDpDayValue, .a1 .myDpSelectedMonth, .a1 .myDpSelectedYear {
  background-color: #0D61A1;
  color: white;
}

.a1 .myDpTableSingleDay:hover {
  background: inherit;
}

.a1 .myDpTableSingleDay:hover .myDpDayValue {
  background-color: #F0F0F0;
}

.a1 .myDpSelectedDay:hover .myDpDayValue {
  background-color: #0D61A1;
  color: white;
  opacity: 0.7;
}

.a1 .myDpMarkCurrYear, .a1 .myDpMarkCurrMonth {
  border: none;
}

`;