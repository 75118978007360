import { Component, Input, Output, EventEmitter, ViewContainerRef, NgZone, TemplateRef, HostBinding, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

import { createPopper, Placement } from '@popperjs/core';
import { UNDERWRITER_ACTIONS } from 'src/app/services/update-request-service/model/updateRequest';


@Component({
  selector: 'app-a1-split-button',
  styleUrls: ['./splitbutton.component.scss'],
  templateUrl: './splitbutton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitButtonComponent {
  @HostBinding('class') class = 'a1-split-button';

  @Input() disabled = false;
  @Input() labelKey = 'label';
  @Input() idKey = 'id';
  @Input() buttonName: 'uwDropdown' | 'tmDropdown' | 'dropdown' | 'single'= 'dropdown';

  private _buttonName;

  private _optionsDisabled: boolean;
  @Input()
  get optionsDisabled(): boolean {
    if (this._optionsDisabled !== null) {
      return this._optionsDisabled;
    }
    return this.disabled;
  }
  set optionsDisabled(disabled: boolean) {
    this._optionsDisabled = disabled;
  }

  private _options = [];
  @Input()
  get options(): any[] {
    return this._options;
  }
  set options(values: any[]) {
    this._options = values;
    this.updateOptions();
  }

  private _buttonClass = null;
  @Input()
  get buttonClass(): any {
    return this._buttonClass;
  }
  set buttonClass(value: any) {
    this._buttonClass = value;
    this.updateButtonClass();
  }

  @Output() closed = new EventEmitter();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();

  isOpen = false;

  mainAction: any;
  view: any;
  backdropView: any;
  popperRef: any;

  constructor(private vcr: ViewContainerRef, private zone: NgZone) { }

  updateOptions() {
    if (!this.options) {
      return;
    }
    let mainIndex;
    this.options.find((op, index) => {
      if(op.main) {
        this.mainAction = op;
        mainIndex = index;
        return true;
      }
      return false;
    });

    this.options.splice(mainIndex, 1);

    if (this.mainAction) {
      this.disabled = this.mainAction.disabled;
    }

    if (this.options.length == 1 && this.options[0].id !==  UNDERWRITER_ACTIONS.READY ) {
      this._buttonName ='single';
    } else {
      this._buttonName = this.buttonName;
    }
  }

  open(dropdownTpl: TemplateRef<any>, origin: any, backdropTpl: TemplateRef<any>, $event) {
    $event.stopPropagation();

    if (this.optionsDisabled) {
      return;
    }

    this.backdropView = this.vcr.createEmbeddedView(backdropTpl);
    const bd = this.backdropView.rootNodes[0];
    document.body.appendChild(bd);

    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const dropdown = this.view.rootNodes[0];

    document.body.appendChild(dropdown);
    // dropdown.style.width = `${origin.offsetWidth}px`;
    dropdown.style.minWidth = `${origin.offsetWidth}px`;


    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, dropdown, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: this.defaultOffsetValues(),
            },
          },
          {
            name: "autoWidth",
            enabled: true,
            fn: ({ state }) => {
              state.styles.popper.width = 'auto';
            },
            phase: "beforeWrite",
            requires: ["computeStyles"],
          }
        ],
        placement: 'top-end'

      });
    });

    this.isOpen = true;
  }

  offsetValues() {
    switch(this._buttonName) {
      case 'uwDropdown' :
        return [-70,4];
      case 'tmDropdown' :
        return [-58,4];
      case 'single' :
        return [-5, 4];
      default:
        return [0, 4];
    }
  }

  defaultOffsetValues() {
    return [0, 4];
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;

      this.closed.emit();
      this.popperRef.destroy();
      this.view.destroy();
      this.view = null;
      this.popperRef = null;

      this.backdropView.destroy();
      this.backdropView = null;
    }
  }

  select(option) {
    if (option.disabled) {
      return;
    }

    this.close();

    if (typeof option.select === 'function') {
      option.select(option);
      return;
    }

    if (option) {
      this.buttonClick.emit(option[this.idKey]);
    }
  }

  onBackdropClicked() {
    this.close();
  }

  onMainClick($event: MouseEvent) {
    $event.stopPropagation();

    if (this.disabled) {
      return;
    }

    this.buttonClick.emit(this.mainAction.id);
  }

  updateButtonClass() {

  }
}
