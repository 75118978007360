import {Component, NgZone, ViewContainerRef} from '@angular/core';
import {ParentModalComponent} from "../parentModalComponent";

@Component({
  selector: 'app-refresh-modal',
  templateUrl: './refresh-modal.component.html',
  styleUrls: ['../modal.component.scss'],
})
export class RefreshModalComponent extends ParentModalComponent {
  static readonly OK = 'ok';

  constructor(vcr: ViewContainerRef, zone: NgZone) {
    super();
    this.vcr = vcr;
    this.zone = zone;
  }

  onOkClick() {
    this.onClickClose(RefreshModalComponent.OK);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      this.closed.next(closeResult);
    });
  }

}
