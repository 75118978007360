import { Component, Input, Output, EventEmitter, ViewContainerRef, NgZone, TemplateRef, HostBinding, ViewChild, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { createPopper } from '@popperjs/core';
import { RolesPermissionsService } from 'src/app/roles-permissions';
import { UnderwriterService } from 'src/app/services';


@Component({
  selector: 'app-save-filter-dialog',
  styleUrls: ['./saveFilterDialog.component.scss'],
  templateUrl: './saveFilterDialog.component.html'
})
export class SaveFilterDialogComponent implements OnInit {
  @HostBinding('class') class = 'a1-dialog';

  @ViewChild('backdrop') backdropTpl: TemplateRef<any>;
  @ViewChild('panel') dropdownTpl: TemplateRef<any>;

  @Output() saveFilter = new EventEmitter<any>();

  @Input() origin: any;
  @Input() name: string;
  @Input() gridName: string;
  @Input() shareFilter: boolean;

  private _open: boolean;
  @Input()
  get open(): boolean {
    return this._open;
  }
  set open(value: boolean) {
    this._open = value;
    if (value) {
      this.openDropdown();
    } else {
      this.close();
    }
  }


  isOpen = false;
  updateFilter = false;
  sharedFilterCheckbox = false;
  view: any;
  backdropView: any;
  popperRef: any;
  buttonLabel = 'Save Filter';

  constructor(
    private vcr: ViewContainerRef,
    private zone: NgZone,
    private rolesPermissionsService: RolesPermissionsService,
    private underwriterService: UnderwriterService
    ) { }

  ngOnInit() {
    this.rolesPermissionsService.isSuperUser().pipe(first()).subscribe((isSuperUser) => {
      if(isSuperUser) {
        this.sharedFilterCheckbox = true;
      }
    });
  }

  onSaveClick() {

    const shareFilter = !this.sharedFilterCheckbox ? false : this.shareFilter;
    this.buttonLabel = 'Update Filter';
    this.updateFilter = true;
    this.saveFilter.emit({name: this.name, shareFilter: shareFilter});
  }

  openDropdown() {
    this.updateFilter = false;

    this.backdropView = this.vcr.createEmbeddedView(this.backdropTpl);
    const bd = this.backdropView.rootNodes[0];
    document.body.appendChild(bd);

    this.view = this.vcr.createEmbeddedView(this.dropdownTpl);
    const dropdown = this.view.rootNodes[0];

    document.body.appendChild(dropdown);
    dropdown.style.width = `${this.origin.offsetWidth}px`;
    dropdown.style.minWidth = `${this.origin.offsetWidth}px`;


    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(this.origin, dropdown, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, -12],
            },
          },
          {
            name: "autoWidth",
            enabled: true,
            fn: ({ state }) => {
              state.styles.popper.width = 'auto';
            },
            phase: "beforeWrite",
            requires: ["computeStyles"],
          }
        ]

      });
    });
    const filterCache = (this.underwriterService.getPreferencesCache(this.gridName)).filters;
    const foundFilter = filterCache?.filters?.find((f) => f.name == this.name);
    if (foundFilter) {
      this.updateFilter = true;
      this.buttonLabel = 'Update Filter';
    }
    // this.isOpen = true;
    setTimeout(() => {
      this.zone.run(() => {
        this.isOpen = true;
      });

    }, 10);
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;
      this.updateFilter = false;
      this.buttonLabel = 'Save Filter';
      setTimeout(() => {
        this.zone.run(() => {
          this.popperRef.destroy();
          this.view.destroy();
          this.view = null;
          this.popperRef = null;

          this.backdropView.destroy();
          this.backdropView = null;
          this.saveFilter.emit(null);
        });
      }, 125);
    }
  }

  onCheckboxShareFilterClick() {
    this.shareFilter = !this.shareFilter;
  }

  onBackdropClicked() {
    this.close();
  }
  onFieldChange(event) {
    const filterCache = (this.underwriterService.getPreferencesCache(this.gridName)).filters;
    const foundFilter = filterCache?.filters?.find((f) => f.name == this.name);
    if (foundFilter) {
      this.updateFilter = true;
      this.buttonLabel = 'Update Filter';
    } else {
      this.updateFilter = false;
      this.buttonLabel = 'Save Filter';
    }
  }
}
