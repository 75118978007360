import {
  UserPermission
} from './roles-permissions/types';

export const mainMenuItems = Object.freeze([
  {
    url: '/underwriting',
    label: 'Underwriter',
    active: true,
    visible: true,
    permissions: [
      UserPermission.UwRead,
      UserPermission.UwWrite
    ]
  },
  {
    url: '/bulk',
    label: 'Bulk Underwriter',
    active: false,
    visible: true,
    permissions: [
      UserPermission.UwRead,
      UserPermission.UwWrite
    ]
  },
]);
