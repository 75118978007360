import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from 'src/app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['../auth.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isBusy: boolean = false;
  isSubmitted: boolean = false;
  hasError: boolean = false;
  errorMessage: string = '';
  hidePassword: boolean = true;

  private subscriptions = new Subscription();

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });

    this.subscriptions.add(this.loginForm.statusChanges.subscribe(() => {

      if ((this.isSubmitted || this.loginForm.controls.email.touched) && this.loginForm.controls.email.errors) {
        this.errorMessage = 'Email address is invalid.';
        this.hasError = true;
      } else if ((this.isSubmitted || this.loginForm.controls.password.touched) && this.loginForm.controls.password.errors) {
        this.errorMessage = 'Password is invalid.';
        this.hasError = true;
      } else if (!(this.isSubmitted || this.loginForm.controls.email.errors || this.loginForm.controls.password.errors)) {
        this.errorMessage = '';
        this.hasError = false;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async login() {

    if (this.loginForm.valid) {
      this.hasError = false;
      this.isBusy = true;

      this.loginForm.controls.email.disable();
      this.loginForm.controls.password.disable();

      try {
        await this.authenticationService.login(this.loginForm.get('email').value.trim(), this.loginForm.get('password').value.trim());

        this.isBusy = false;

        this.loginForm.controls.email.enable();
        this.loginForm.controls.password.enable();

        this.router.navigate(['underwriting']);
      } catch (err) {
        if (err.error && err.error.message) {
          this.errorMessage = err.error.message;
          this.hasError = true;
        } else {
          this.errorMessage = err;
          this.hasError = false;
        }
        this.isBusy = false;
        this.isSubmitted = true;

        this.loginForm.controls.email.enable();
        this.loginForm.controls.password.enable();
      }
    } else {
      this.isSubmitted = true;
    }
  }
}
