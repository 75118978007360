import { ProformaVersioning } from "./proformaVersioningService";
import * as _ from 'lodash';
import { AuditItemModel } from "src/app/models/auditItemModel";
import * as JsUtils from 'src/app/utils/jsUtils';
import { AuditItem, IAuditItemObject, IPropertyAudit } from "../data/auditItem";
import { PropertyStatusCategoryTypeFromStatus, PropertyStatusType } from "../data/propertyStatusType";
import { formatUnderwritingStatus, getFieldDisplayName, getFieldValue, handleUnderwriterName } from "./activityLogUtils";
import { auditAndUpdateExcludedFields, listingFieldsToWatch } from "./activityLogDefinitions";
import { noteEventTypeMessage } from "../data/notes";
import { tagTitles } from "../data/tags";

export class ActivityLogBaseService extends ProformaVersioning {
  lastStatusBeforeHidden: PropertyStatusType;

  /**
   *
   * @param ai
   * @param model
   * @param propertyAudit
   * @param currentProperty
   * @returns
   */
  protected setListingForStatusAndUndo(ai: AuditItem, model: AuditItemModel, propertyAudit: IPropertyAudit, currentProperty: any) {

    if(model.auditItems.length > 0 && model.auditItems.find( auditItem => (auditItem.message == "TMStatus" || auditItem.message == "subStatus" || auditItem.message == "inspectionStatus"))) {
      model.previousUnderwritingStatusText = null;
    }

    if (ai.message == 'isHiddenForAll') {
      const newHiddenStatus = parseInt(ai.newValue, 10);
      if (newHiddenStatus) {
        model.underwritingStatusClass = 'a1-status-' + PropertyStatusCategoryTypeFromStatus(PropertyStatusType.Hidden);
        model.underwritingStatusText = propertyAudit.previousUnderwritingStatusText = formatUnderwritingStatus(PropertyStatusType.Hidden);
      } else {
        model.underwritingStatusClass = 'a1-status-' + PropertyStatusCategoryTypeFromStatus(this.lastStatusBeforeHidden || currentProperty.status);
        model.underwritingStatusText = propertyAudit.previousUnderwritingStatusText = formatUnderwritingStatus(this.lastStatusBeforeHidden || currentProperty.status);
      }

    } else if (ai.message == 'isTerminated') {
      model.underwritingStatusClass = 'a1-status-' + PropertyStatusCategoryTypeFromStatus(PropertyStatusType.Terminated);
      model.underwritingStatusText = propertyAudit.previousUnderwritingStatusText = formatUnderwritingStatus(PropertyStatusType.Terminated);

    } else if (model.header) {
      const newValue = parseInt(ai.newValue, 10);
      this.lastStatusBeforeHidden = newValue;
      let statusToShow = newValue;

      switch (newValue) {
        case PropertyStatusType.OfferReady:
          statusToShow = PropertyStatusType.OfferReadyReason;
          break;

        case PropertyStatusType.Counter:
          statusToShow = PropertyStatusType.Counter;
          break;

        case PropertyStatusType.NewPrice:
          statusToShow = currentProperty?.reasonFewComps ? PropertyStatusType.NewPriceReason : PropertyStatusType.NewPrice;
          break;
      }

      model.underwriting = statusToShow;
      model.underwritingStatusClass = 'a1-status-' + PropertyStatusCategoryTypeFromStatus(statusToShow);
      model.underwritingStatusText = formatUnderwritingStatus(statusToShow);

    } else {

      if(( formatUnderwritingStatus(ai.newValue) === formatUnderwritingStatus(PropertyStatusType.New))
          || (formatUnderwritingStatus(ai.newValue) === formatUnderwritingStatus(PropertyStatusType.Updated))) {
        model.underwritingStatusClass = null;
        model.underwritingStatusText = null;

        if(model.auditItems.length == 1) {
          return true;
        }

      } else {
        model.underwritingStatusClass = 'a1-status-' + PropertyStatusCategoryTypeFromStatus(parseInt(ai.newValue));
        model.underwritingStatusText = propertyAudit.previousUnderwritingStatusText  = formatUnderwritingStatus(ai.newValue);

        this.lastStatusBeforeHidden = parseInt(ai.newValue);
      }
      if (ai.newValue === '__INTEGRATION_BUYBOX__') {
        propertyAudit.previousUnderwritingStatusText = formatUnderwritingStatus(PropertyStatusType.OfferReady);
      } else {
        const excludeFields = ['updateIdentityId', 'updateDate'];
        if (excludeFields.includes(ai.message) ) {
          let shouldRemove = true;
          model.auditItems.forEach(item => {
            if (item.message != 'updateIdentityId' && item.message != 'updateDate') {
              shouldRemove = false;
            }
          });
          return shouldRemove;
        }
      }

      // Exception to make designer happy. Fake an entry for Closing ready
      if (ai.newValue == PropertyStatusType.ClosingReady) {
        model.text.push(`Sent to Closing Date: ${JsUtils.getMDYDateString(model.eventDate)}`);
      }

    }
     return false;
  }

  /**
   * Build model for auditItem of type Audit or update
   * @param model model to build
   * @param propertyAudit
   * @param ai auditItem
   * @param addStatement
   * @returns
   */
  protected setListingForAuditAndUpdate(model: AuditItemModel, propertyAudit: IPropertyAudit, ai: AuditItem, addStatement: IAuditItemObject) {
    model.previousUnderwritingStatusText = propertyAudit.previousUnderwritingStatusText;

    if (auditAndUpdateExcludedFields.includes(ai.message)) {
      let shouldRemove = true;
      model.auditItems.forEach(item => {
        if (item.message != 'updateIdentityId' && item.message != 'updateDate') {
          shouldRemove = false;
        }
      });
      return shouldRemove;
    }

    if(ai.message == "approval") {
      model.header = true;
      model.underwriting = PropertyStatusType.InvestorApprovalReady;
      return false;
    }
    if (ai.message == "status") {
      model.text.push(formatUnderwritingStatus(ai.newValue));
    } else {

      // TODO: Temporary fix until DB rename for Proforma monthlyRentAmount column to estimatedRentAmount
      if ('monthlyRentAmount' == ai.message && ai.uwCapRate != null) {
        ai.message = 'estimatedRentAmount';
      }
      if ('rentalConcessionAmount' == ai.message && ai.uwCapRate != null) {
        ai.message = 'proformaConcessionAmount';
      }

      if (['offerTerminateComments'].includes(ai.message)) {
        if (!JsUtils.isNullOrEmpty(ai.newValue)) {
          model.comments.push({
              type: 'comment',
              message: ai.newValue,
              noteType: ai.noteType
            });
        }

      } else if ('offerDueDiligenceDaysCount' == ai.message) {
        propertyAudit.currentOfferDueDiligenceDaysCount = ai.newValue;
        addStatement.addOfferDueDiligenceCount = ai;
      } else if ('offerDueDiligenceDaysType' == ai.message) {
        propertyAudit.currentOfferDueDiligenceDaysType = ai.newValue;
        addStatement.addOfferDueDiligenceType = ai;

      } else if ('isInspectionContingencyReleased' == ai.message) {
        model.text.push(`${ai.newValue == 1 ? 'Inspection Contingency Released: Yes' : 'Inspection Contingency Not Released: Yes'}`);

      } else if ('isConstructionRequired' == ai.message) {
        model.text.push(`${ai.newValue == 1 ? 'Construction Required: Yes' : 'Construction Not Required: Yes'}`);

      } else if ('isPostConstructionInitiated' == ai.message) {
        model.text.push(`${ai.newValue == 1 ? 'Post Construction Initiated: Yes' : 'Post Construction Initiated: No '}`);

      } else if ('isListedForRent' == ai.message) {
        model.text.push(`${ai.newValue == 1 ? 'Is Listed For Rent: Yes' : 'Is Listed For Rent: No '}`);

      } else if ('isRented' == ai.message) {
        model.text.push(`${ai.newValue == 1 ? 'Is Rented: Yes' : 'Is Rented: No '}`);

      } else if ('isConstructionInitiated' == ai.message) {
        model.text.push(`${ai.newValue == 1 ? 'Is Construction Initiated: Yes' : 'Is Construction Initiated: No '}`);

      } else {
        const column = getFieldDisplayName(ai.message);

        if (ai.oldValue === null) {
          let listingDataUpdated = '';

          if (listingFieldsToWatch.includes(ai.message)) {
            listingDataUpdated = "From None to ";
          }
          if(ai.message == 'documentsFolderUrl' || ai.message =='inspectionPortalUrl') {
            model.text.push(`${column}: `);
            model.url.push(ai.newValue);
          } else {
            model.text.push(`${column}: ${listingDataUpdated}${getFieldValue(ai.message, ai.newValue)}`);
            model.url.push('');
          }
          
        } else {
          model.text.push(`${column}: From ${getFieldValue(ai.message, ai.oldValue)} to ${getFieldValue(ai.message, ai.newValue)}`);
        }
      }
      handleUnderwriterName(ai, model);
    }
    return false;
  }

/**
 * Build Model for auditItem of type note
 * @param ai auditItem
 * @param model model to build
 */
  protected setListingForNotes(ai: AuditItem, model: AuditItemModel) {
    if (!_.isEmpty(ai.message) && ai.noteType != 'R') {

      switch (ai.message) {
        case noteEventTypeMessage.addNote:
          model.headerText = 'New Note';
        break;
        case noteEventTypeMessage.updateNote:
          model.headerText = 'Edited Note';
        break;
        case noteEventTypeMessage.deleteNote:
          model.headerText = 'Deleted Note';
        break;
        case noteEventTypeMessage.addSubnote:
          model.headerText = 'New Sub-note';
        break;
      }

      model.header = true;
    }
    let message = ai.newValue || ai.oldValue;
    if (ai.noteType == 'R') {
      message = message?.split(' \\ ').join(', ');
    }
    model.comments.push({
      type: 'note',
      message,
      noteType: ai.noteType
    });
  }

  /**
   * Build Model fot auditItem of type tag
   * @param ai auditItem
   * @param model model to build
   * @returns void ref model
   */
  protected setListingForTags(ai: AuditItem, model: AuditItemModel) {
    let tagTitle;
    let oldValue;
    let newValue;
    let auditOldValue;
    let auditNewValue;
    if (!_.isEmpty(ai.message)) {
      oldValue = ai.oldValue ? JSON.parse(ai.oldValue) : null;
      newValue = ai.newValue ? JSON.parse(ai.newValue) : null;
      const fetchedMessage = oldValue || newValue;
      if(_.isNil(fetchedMessage)) {
        return;
      }
        tagTitle = Object.keys(fetchedMessage)[0];
        auditOldValue = oldValue ? oldValue[tagTitle].split(',') : null;
        auditNewValue = newValue ? newValue[tagTitle].split(',') : null;
        model.headerText = tagTitles[tagTitle] + ' tag';
        model.headerText += (auditOldValue && auditOldValue.length > 1 || auditNewValue && auditNewValue.length > 1) ? 's' : '';
      model.header = true;
    }
    model.isTag = true;
    model.comments.push({
      action: tagTitle,
      oldValue: auditOldValue,
      newValue: auditNewValue,
    });
  }
}
