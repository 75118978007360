import { ListItem } from './listItem';
import * as _ from 'lodash';

/**
 * Most are directly in MySQL listingStatus.status
 * Some are interpreted from other fields and included here to help display
 */
export enum PropertyStatusType {
  /**
   * NOT saved in the DB.
   * This is an interpretation of MySQL listingStatus.isHiddenForALl
   * UW Grid if the option to show them is selected
   */
  Hidden = 0,

  /**
   * Newly ingested listing from AvenueOne DB to UnderwritingTool DB
   * UW Grid
   */
  New = 1,

  /**
   * UW Grid
   */
  Updated = 2,

  /**
   * After UW has underwrite a property
   * UW Grid
   */
  Underwritten = 3,

  /**
   * After UW has underwrite a property and confirm it is worth an offer.
   * UW Grid (BLUE) & TM Grid Actionable (GREEN)
   */
  OfferReady = 4,

  /**
   * UW Grid
   */
  Counter = 5,


  /**
   * After the UW re-underwrote a property when requested by TM
   * UW Grid (BLUE) NEED TO BE FAKE AS A OfferReady in Grid  & TM Grid Actionable  (YELLOW)
   */
  OfferReadyUrgent = 8,

  /**
   * TM Grid Pending
   */
  ConcessionToUW = 9,

  /**
   * After the TM sent a property back to the UW because the listing was updated while in the Offer process
   * UW Grid
   */
  NewPrice = 10,


  /**
   * Updated for the
   * TM Grid Actionable
   */
  TMUpdated = 20,




  /**
   * TM Grid Pending
   */
  OfferMade = 32,
  /**
   * TM Grid Actionable
   */
  Countered = 34,

  /**
   * Trader Countered
   */
  TraderCountered= 36,

  /**
   * TM Grid Pending
   */
  CounterSent = 38,

  /**
   * TM Grid Actionable
   */
  Accepted = 40,

  AcceptedWithDueDiligenceExtension = 41,

  /**
   * TM Grid Actionable
   */
  Expired = 50,

  /**
   * TM Grid Actionable
   */
  Declined = 55,

  /**
   * TM Grid Pending
   */
  ToInspect = 60,

  /**
   * TM Grid Pending
   */
  InspectionAccepted = 61,

  ToInspectWithDueDiligenceExtension = 62,

  InspectionAcceptedWithDueDiligenceExtension = 63,

  /**
   * TM Grid Actionable
   */
  Inspected = 65,


  /**
   * TM Grid Pending
   */
  ConcessionTM = 70,

   /**
   * TM Grid Actionable
   */
  ConcessionCountered = 71,



  /**
   * Closing Grid Pending
   */
  ClosingReady = 80,

  TitleSearch = 82,
  TitleReview = 84,
  ClearToClose = 86,
  ClosingSigning = 88,
  FundsWiring = 90,
  FundsWired = 92,
  TrailingDocsReview = 94,

  /**
   * Buying purchase is completed
   * Closing Grid Completed
   */
  DeedTransfered = 100,

  /**
   * TM Grid Completed
   */
  SentToConstruction = 110,

  PreConstruction = 111,
  ConstructionNotCompleted = 120,
  ConstructionQuartelyCompleted = 121,
  ConstructionHalfyCompleted = 122,
  ConstructionAlmostCompleted = 123,
  ConstructionCompleted = 124,

  PostConstruction = 130,

  /**
   * Leasing Grid
   */
  RentReady = 300,
  Listed = 310,

  /**
   * Leasing Grid Leased
   */
  Leased = 320,


  // new Status for rehabBudget
  InspectionReview = 1000,

  InvestorApprovalUrgent = 1020,

  OfferDelivered = 1033,

  InvestorApprovalUpdated = 1040,

  Canceled = 1056,

  LostDead = 1057,

  InvestorApprovalReady = 1060,

  ClearToCloseUrgent = 1080,

  ClearToCloseReady = 1100,

  PreInspectionReview = 1120,

  PreInvestorApprovalUpdated = 1140,

  PreInvestorApprovalReady = 1160,

  /**
   * NOT saved in the DB.
   * This will be an interpretation of NewPrice & OfferReadyUrgent for the TM Grid Pending
   *
   * TM Grid Pending
   */
  SentToUW = 9000,

  /**
   * NOT saved in the DB.
   * This will be an interpretation of OfferReady for the TM Grid Pending
   *
   * TM Grid Pending
   */
  OfferReadyUrgentTM = 9001,

  /**
   * NOT saved in the DB.
   * This will be an interpretation of Counter for the TM Grid Pending
   *
   * TM Grid Pending
   */
  CounterToUW = 9002,

  /**
   * Transaction is a failure for whatever reason (don't want to by anymore, deal not reached, etc)
   *
   * NOT saved in the DB.
   * This will be an interpretation listingStatus.isTerminated
   *
   * TM Grid Completed
   */
  Terminated = 9005,

  /**
   * NOT saved in the DB.
   * UM Grid Urgent
   */
  ConcessionUW = 9006,

  /**
   * NOT saved in the DB.
   * UW, TM Grid Offer Ready with reason for few comps
   */
  OfferReadyReason = 9007,

  /**
   * NOT saved in the DB.
   * UW, TM Grid Updated with reason for few comps
   */
  UpdatedReason = 9008,

  /**
   * NOT saved in the DB.
   * UW, TM Sent to UW with reason for few comps
   */
  SentToUWReason = 9009,

  /**
   * NOT saved in the DB.
   * UW, TM Sent to UW with reason for few comps
   */
  NewPriceReason = 9010,

  /**
   * NOT saved in the DB.
   * This will be an interpretation of OfferReady for the TM Grid Pending with reason
   * few comps
   *
   * TM Grid Pending
   */
  OfferReadyUrgentTMReason = 9011,

  /**
   * NOT saved in the DB.
   * This will be an interpretation of Counter for the TM Grid Pending with reason
   * few comps
   *
   * TM Grid Pending
   */
  CounterReason = 9012,

  /**
   * NOT saved in the DB.
   * Closing Grid for Maestro External
   */
  DeedTransferredExternal = 9100,

  /**
   * NOT saved in the DB.
   * Closing soon for Maestro status
   */
  ClosingSoon = 9500,
}

export enum PropertyStatusCategoryType {
  Actionable,
  Pending,
  Urgent,
  Hidden,
  Alert
}

export class PropertyStatusTypeListItem implements ListItem {
  key: PropertyStatusType | InvestorApprovalType;
  description: string;
  category?: PropertyStatusCategoryType;
}

export const PropertyStatusTypeListItems: PropertyStatusTypeListItem[] = [
  {
    key: PropertyStatusType.Hidden,
    description: 'Hidden',
    category: PropertyStatusCategoryType.Hidden
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.New,
    description: 'New',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Underwritten,
    description: 'Underwritten',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Updated,
    description: 'Updated',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.UpdatedReason,
    description: '! Updated',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.OfferReady,
    description: 'Offer Ready',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.OfferReadyReason,
    description: '! Offer Ready',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.TMUpdated,
    description: 'Updated',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.NewPrice,
    description: 'New Price',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.NewPriceReason,
    description: '! New Price',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.OfferReadyUrgent,
    description: 'Offer Ready',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.OfferMade,
    description: 'Offer Made',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Countered,
    description: 'Counter Accepted',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.OfferDelivered,
    description: 'Offer Delivered',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.TraderCountered,
    description: 'Trader Countered',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Counter,
    description: 'Seller Countered *',
    category: PropertyStatusCategoryType.Alert
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.InspectionReview,
    description: 'Inspection Review *',
    category: PropertyStatusCategoryType.Alert
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.InvestorApprovalUrgent,
    description: 'Inspection Review *',
    category: PropertyStatusCategoryType.Alert
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.InvestorApprovalUpdated,
    description: 'Inspection Review - Submitted for Approval',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.InvestorApprovalReady,
    description: 'Inspection Review - Approved',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.PreInspectionReview,
    description: 'Pre-Inspection Review *',
    category: PropertyStatusCategoryType.Alert
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.PreInvestorApprovalUpdated,
    description: 'Pre-Inspection Review - Submitted for Approval',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.PreInvestorApprovalReady,
    description: 'Pre-Inspection Review - Approved',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.CounterReason,
    description: '! Counter',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.CounterToUW,
    description: 'Counter to UW',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.CounterSent,
    description: 'Counter Sent',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Accepted,
    description: 'Accepted',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.AcceptedWithDueDiligenceExtension,
    description: '! Accepted',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Expired,
    description: 'Expired',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Declined,
    description: 'Declined',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Canceled,
    description: 'Canceled',
    category: PropertyStatusCategoryType.Hidden
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.LostDead,
    description: 'Lost/Dead',
    category: PropertyStatusCategoryType.Hidden
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ToInspect,
    description: 'To Inspect',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ToInspectWithDueDiligenceExtension,
    description: '! To Inspect',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.InspectionAccepted,
    description: 'Inspection Accepted',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.InspectionAcceptedWithDueDiligenceExtension,
    description: '! Inspection Accepted',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Inspected,
    description: 'Inspected',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConcessionTM,
    description: 'Concession Requested',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConcessionToUW,
    description: 'Concession To UW',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConcessionUW,
    description: 'Concession',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConcessionCountered,
    description: 'Concession Countered',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ClosingReady,
    description: 'Closing Ready',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.TitleSearch,
    description: 'Title Search',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.TitleReview,
    description: 'Title Review',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ClearToClose,
    description: 'Clear to Close',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ClearToCloseUrgent,
    description: 'Buyer Clear To Close *',
    category: PropertyStatusCategoryType.Alert
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ClearToCloseReady,
    description: 'Buyer Clear to Close - Updated',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ClosingSigning,
    description: 'Closing Signing',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.FundsWiring,
    description: 'Funds Wiring',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.FundsWired,
    description: 'Funds Wired',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.TrailingDocsReview,
    description: 'Trailing Docs Review',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.SentToUW,
    description: 'Sent to UW',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.SentToUWReason,
    description: '!Sent to UW',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.DeedTransfered,
    description: 'Deed Transferred',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.DeedTransferredExternal,
    description: 'Deed Transferred',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ClosingSoon,
    description: 'Closing Soon',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.PreConstruction,
    description: 'Pre-construction',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.SentToConstruction,
    description: 'Rehab Ready',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Terminated,
    description: 'Terminated',
    category: PropertyStatusCategoryType.Hidden
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.OfferReadyUrgentTM,
    description: 'Offer Ready *',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.OfferReadyUrgentTMReason,
    description: '! Offer Ready *',
    category: PropertyStatusCategoryType.Urgent
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConstructionNotCompleted,
    description: 'Ongoing - 0%',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConstructionQuartelyCompleted,
    description: 'Ongoing - 25%',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConstructionHalfyCompleted,
    description: 'Ongoing - 50%',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConstructionAlmostCompleted,
    description: 'Ongoing - 75%',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.ConstructionCompleted,
    description: 'Ongoing - 100%',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.PostConstruction,
    description: 'Post-construction',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.RentReady,
    description: 'Rent Ready',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Listed,
    description: 'Listed',
    category: PropertyStatusCategoryType.Actionable
  } as PropertyStatusTypeListItem,
  {
    key: PropertyStatusType.Leased,
    description: 'Leased',
    category: PropertyStatusCategoryType.Pending
  } as PropertyStatusTypeListItem,
];


export enum InvestorApprovalType {
  autoAssign = 0,
  pendingApproval = 1,
  autoApproval =2,
  approved =3


}

export enum approvalSubTypeText {
  autoApproval = 'Auto-Approved investor',
  default = 'By default'
}
export const InvestorApprovalStatusTypeListItems: PropertyStatusTypeListItem[] = [
  {
    key: InvestorApprovalType.autoAssign,
    description: '-',
  } as PropertyStatusTypeListItem,
  {
    key: InvestorApprovalType.pendingApproval,
    description: 'Pending approval',
  } as PropertyStatusTypeListItem,
  {
    key: InvestorApprovalType.autoApproval,
    description: 'Auto-Approved',
  } as PropertyStatusTypeListItem,
  {
    key: InvestorApprovalType.approved,
    description: 'Approved',
  } as PropertyStatusTypeListItem,
];


export const UnderwritingListPropertyStatusType: PropertyStatusType[] = [
  PropertyStatusType.New,
  PropertyStatusType.Underwritten,
  PropertyStatusType.OfferReady,
  PropertyStatusType.OfferReadyUrgent,
  PropertyStatusType.OfferReadyReason,
  // PropertyStatusType.OfferDelivered,
  PropertyStatusType.Counter,
  PropertyStatusType.Countered,
  PropertyStatusType.TraderCountered,
  PropertyStatusType.PreInspectionReview,
  PropertyStatusType.PreInvestorApprovalUpdated,
  PropertyStatusType.PreInvestorApprovalReady,
  PropertyStatusType.InspectionReview,
  PropertyStatusType.InvestorApprovalUpdated,
  PropertyStatusType.InvestorApprovalReady,
  PropertyStatusType.ClearToCloseReady,
  PropertyStatusType.Canceled,
  PropertyStatusType.LostDead,
  PropertyStatusType.Hidden,
];

export const InvestorApprovalListType: InvestorApprovalType[] = [
  InvestorApprovalType.autoAssign,
  InvestorApprovalType.pendingApproval,
  InvestorApprovalType.autoApproval,
  InvestorApprovalType.approved
];

export const TransactionListPropertyStatusType: PropertyStatusType[] = [
  PropertyStatusType.Updated,
  PropertyStatusType.OfferReady,
  PropertyStatusType.OfferReadyReason,
  PropertyStatusType.OfferMade,
  PropertyStatusType.Countered,
  PropertyStatusType.TraderCountered,
  PropertyStatusType.CounterSent,
  PropertyStatusType.Accepted,
  PropertyStatusType.AcceptedWithDueDiligenceExtension,
  PropertyStatusType.Expired,
  PropertyStatusType.Declined,
  PropertyStatusType.ToInspect,
  PropertyStatusType.ToInspectWithDueDiligenceExtension,
  PropertyStatusType.InspectionAccepted,
  PropertyStatusType.InspectionAcceptedWithDueDiligenceExtension,
  PropertyStatusType.Inspected,
  PropertyStatusType.CounterToUW,
  PropertyStatusType.ConcessionTM,
  PropertyStatusType.ConcessionToUW,
  PropertyStatusType.ConcessionCountered,
  PropertyStatusType.ClosingReady,
  PropertyStatusType.SentToUW,
  PropertyStatusType.SentToUWReason,
  PropertyStatusType.OfferReadyUrgentTM,
  PropertyStatusType.OfferReadyUrgentTMReason,
];

export const TransactionCompletedListPropertyStatusType: PropertyStatusType[] = [
  PropertyStatusType.DeedTransfered,
  PropertyStatusType.Terminated
];

export const PropertyStatusTypeSortMap = {
};

let sortIdx = 1;
PropertyStatusTypeSortMap[PropertyStatusType.New] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Updated] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Underwritten] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.OfferReady] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Counter] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConcessionUW] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.NewPrice] = sortIdx++;


PropertyStatusTypeSortMap[PropertyStatusType.OfferReady] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.OfferReadyReason] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.OfferReadyUrgentTM] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.TMUpdated] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.TraderCountered] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Countered] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Accepted] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.AcceptedWithDueDiligenceExtension] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ToInspect] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ToInspectWithDueDiligenceExtension] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.InspectionAccepted] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.InspectionAcceptedWithDueDiligenceExtension] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.CounterToUW] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConcessionToUW] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.SentToUW] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Inspected] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ClosingReady] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConcessionTM] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.CounterSent] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConcessionCountered] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Expired] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.OfferMade] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.OfferDelivered] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Declined] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Canceled] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.LostDead] = sortIdx++;

PropertyStatusTypeSortMap[PropertyStatusType.Hidden] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.DeedTransfered] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.Terminated] = sortIdx++;

PropertyStatusTypeSortMap[PropertyStatusType.ClosingSoon] = sortIdx++;

PropertyStatusTypeSortMap[PropertyStatusType.SentToConstruction] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.PreConstruction] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConstructionNotCompleted] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConstructionQuartelyCompleted] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConstructionHalfyCompleted] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConstructionAlmostCompleted] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.ConstructionCompleted] = sortIdx++;
PropertyStatusTypeSortMap[PropertyStatusType.PostConstruction] = sortIdx++;


export function PropertyStatusTypeText(enumValue: PropertyStatusType): string {
  if (_.isNil(enumValue)) {
    return 'New';
  }

  if (typeof enumValue == "string") {
    return enumValue;
  }

  return _.find(PropertyStatusTypeListItems, { key: enumValue})?.description;
}

export function PropertyStatusTypeFromDescription(enumValue: string): PropertyStatusType {
  if (_.isNil(enumValue)) {
    return PropertyStatusType.New;
  }

  // @ts-ignore
  return _.find(PropertyStatusTypeListItems, { description: enumValue})?.key;
}

export function PropertyStatusCategoryTypeFromStatus(enumValue: PropertyStatusType): PropertyStatusCategoryType {
  if (_.isNil(enumValue)) {
    return PropertyStatusCategoryType.Actionable;
  }

  return _.find(PropertyStatusTypeListItems, { key: enumValue })?.category;
}

export function InvestorApprovalStatusTypeText(enumValue: PropertyStatusType): string {
  if (_.isNil(enumValue) || (!InvestorApprovalStatusTypeListItems.map(inv => inv.key).includes(enumValue))) {
    return '-';
  }

  return _.find(InvestorApprovalStatusTypeListItems, { key: enumValue})?.description;
}

export function InvestorApprovalStatusTypeFromDescription(enumValue: string): PropertyStatusType {
  if (!enumValue || enumValue =='-') {
    return 0;
  }

  // @ts-ignore
  return _.find(InvestorApprovalStatusTypeListItems, { description: enumValue})?.key;
}
