import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Subject } from "rxjs";
import { DateRangeFormatFilter } from './data/dateRangeFilter';
import { ViewMode } from '../ui/view-setting/viewSettingModel';

const retry_interval = 10000;
const maximumRetryCount = 90;
@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private _isDownloading: Subject<boolean> = new Subject<boolean>();
  private _isCompsDownloading: Subject<boolean> = new Subject<boolean>();
  _isSearchAddressActive: Subject<boolean> = new Subject<boolean>();
  isDownloading = this._isDownloading.asObservable();
  isSearchAddressActive = this._isSearchAddressActive.asObservable();
  isCompsDownloading = this._isCompsDownloading.asObservable();


  constructor(private apiService: ApiService) {
    this._isDownloading.next(false);
  }

  downloadProperties(aoListingIds: number[]) {
    return new Promise<any>((resolve, reject) => {
      this._isDownloading.next(true);
      this.apiService.downloadProperties(aoListingIds).then(propertiesExcel => {
        resolve(propertiesExcel);
        this._isDownloading.next(false);
      }, (error) => {
        reject(error);
      });
    });
  }

  downloadFilteredProperties(params) {
    const { viewMode, groupKeys, viewSetting, dateRange, fromLine, limit, filters, sort, update, fetchTMUser, getCount } = params;
    const buyBoxId = viewMode !== ViewMode.BULK ? params.buyBoxId : null;
    return new Promise<any>((resolve, reject) => {
      const fromDate: DateRangeFormatFilter = dateRange
        ? {
          fromDate: dateRange.fromDate?.utc().format("YYYY-MM-DD HH:mm:ss") ?? '',
          toDate: dateRange.toDate?.utc().format("YYYY-MM-DD HH:mm:ss") ?? ''
        }
        : { fromDate: '', toDate: '' };
      this._isDownloading.next(true);
      this.apiService.downloadFilteredProperties(viewMode, buyBoxId, groupKeys, fromDate, viewSetting, fromLine, limit, filters, sort, update, fetchTMUser, getCount)
        .then(async (excelFilename) => {
          try {
            const link = await this.getSignedLink(excelFilename) as string;
            const request = new Request(link);
            const response = await fetch(request);
            const blob = await response.blob();
            const blobLength = blob.size;
            const stream = await blob.arrayBuffer();
            resolve({
              stream,
              blobLength,
              excelFilename,
              contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            this._isDownloading.next(false);
          } catch (er) {
            this._isDownloading.next(false);
            reject(er);
          }
        }, (error) => {
          this._isDownloading.next(false);
          reject(error);
        });
    });
  }


  async getSignedLink (fileName, retryDuration?): Promise <string> {
    const internRetryDuration = retryDuration ?? retry_interval;
    return new Promise(async (resolve, reject) => {
      const signedLink = await this.apiService.getSignedURL(fileName);
      if (signedLink) {
          resolve(signedLink);
      } else if (((internRetryDuration + retry_interval)/retry_interval) < maximumRetryCount) {
        setTimeout( async() => {
          const nn = await this.getSignedLink(fileName, internRetryDuration + retry_interval);
          resolve(nn);
        }, retry_interval);
      } else {
        reject('Download timeout');
      }
    });
  }

  

  async downloadComps (aoListingID , proformaId, filters) {
    return new Promise<any>((resolve, reject) => {
      this._isCompsDownloading.next(true);
      this.apiService.downloadFilteredComps(aoListingID, proformaId, filters, null).then(propertiesExcel => {
        resolve(propertiesExcel);
        this._isCompsDownloading.next(false);
      }, (error) => {
        reject(error);
      });
    });
  }

}
