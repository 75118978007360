import { ListItem } from './listItem';
import * as _ from 'lodash';


const defaultValue = '-';
export enum ConcessionStatusType {
  Declined = 0,
  Accepted = 1,
  Pending = 2,
  Countered = 3,
  CounterAccepted = 4,
}


export class ConcessionStatusTypeListItem implements ListItem {
  key: ConcessionStatusType;
  description: string;
}

/**
 * @namespace
 * {@link ConcessionStatusTypeListItems}
 */
export const ConcessionStatusTypeListItems: ConcessionStatusTypeListItem[] = [
  {
    key: ConcessionStatusType.Declined,
    description: 'Declined',
  } as ConcessionStatusTypeListItem,
  {
    key: ConcessionStatusType.Accepted,
    description: 'Accepted',
  } as ConcessionStatusTypeListItem,
  {
    key: ConcessionStatusType.Countered,
    description: 'Countered',
  } as ConcessionStatusTypeListItem,
  {
    key: ConcessionStatusType.Pending,
    description: 'Pending',
  } as ConcessionStatusTypeListItem,
  {
    key: ConcessionStatusType.CounterAccepted,
    description: 'Seller Counter Accepted',
  } as ConcessionStatusTypeListItem,
];

/**
 * map and return description of enumValue based on {@link ConcessionStatusTypeListItems}
 * @param enumValue
 * @returns
 */
export function ConcessionStatusTypeText(enumValue: ConcessionStatusType): string {
  if (_.isNil(enumValue)) {
    return defaultValue;
  }

  if (typeof enumValue == "string") {
    return enumValue;
  }

  return _.find(ConcessionStatusTypeListItems, { key: enumValue})?.description;
}

export function ConcessionStatusTypeFromDescription(enumValue: string): ConcessionStatusType {
  if (_.isNil(enumValue)) {
    return null;
  }

  return _.find(ConcessionStatusTypeListItems, { description: enumValue})?.key;
}
