import {Component} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'listing-status-renderer',
  templateUrl: './listing-status-renderer.component.html',
  styleUrls: ['./listing-status-renderer.component.scss']
})
export class ListingStatusRendererComponent {

    statusHistory: string;
    statusHistoryValue: string;
    listingStatus: string;

    agInit(params: ICellRendererParams): void {
        this.listingStatus = this.getValueToDisplay(params);
        this.statusHistoryValue = params.data.oldListingStatus ? params.data.oldListingStatus : null;
        this.listingStatus = !this.listingStatus ? '-' : this.listingStatus;

        const indicatorStatus =  this.listingStatus === '-' ? 'N/A' : this.listingStatus;
        const indicatorHistoryStatus =  this.statusHistoryValue === '-' || !this.statusHistoryValue ? 'N/A' : this.statusHistoryValue;
        this.statusHistory = this.statusHistoryValue ? indicatorHistoryStatus + " To " + indicatorStatus : null;   
    }

    private getValueToDisplay(params: ICellRendererParams) {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }

}
