import { Component, ViewContainerRef, NgZone } from '@angular/core';
import { ParentModalComponent } from '../parentModalComponent';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProformaConfig } from 'src/app/panels/proforma/proforma_config';
import { ITargetOption } from 'src/app/panels/proforma/proforma';
import { notEmptyValidator } from 'src/app/validators/a1-validator';

@Component({
  selector: 'app-investor-approval',
  templateUrl: './investor-approval.component.html',
  styleUrls: ['../modal.component.scss', './investor-approval.component.scss']
})

export class InvestorApprovalComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  modalForm: FormGroup;

  investorApprovalOptions: ITargetOption[] = new ProformaConfig().investorApprovalOptions;

  selectedOption: number;

  requireReason = false;

  constructor(vcr: ViewContainerRef, zone: NgZone, fb: FormBuilder) {
    super();

    this.modalForm = fb.group({
      investorApproval: ['', [Validators.required]]
    });

    this.vcr = vcr;
    this.zone = zone;
  }

  get investorApproval() {
    return this.modalForm.get('investorApproval').value;
  }
  
  updateConfig() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const investorApproval = this.config!.options!.approval;
  if( investorApproval) {
    this.investorApprovalOptions = investorApproval.options? investorApproval.options : this.investorApprovalOptions;
    this.selectedOption = investorApproval.preSelectedOption;
    this.investorApprovalChange(investorApproval.preSelectedOption);
  }
}
  get investorApprovalReason() {
    return this.modalForm.get('comment') ? this.modalForm.get('comment').value.toString().trim() : null;
  }
  
  get isSaveDisabled() {
    return !this.modalForm.valid;
  }

  onCancelClicked() {
    this.onClickClose(InvestorApprovalComponent.CANCEL);
  }

  onOkClick() {
    this.onClickClose(InvestorApprovalComponent.OK);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      if (closeResult === InvestorApprovalComponent.CANCEL) {
        this.closed.next({action: closeResult});
        return;
      }
      this.closed.next({action: closeResult, investorApproval: this.investorApproval, reason: this.investorApprovalReason});
    });
  }

  investorApprovalChange($event) {
      const selectedOption = this.investorApprovalOptions.filter(entityOptions => {
        return entityOptions.id == $event;
      });
      if ( selectedOption && selectedOption[0]) {
        this.modalForm.get('investorApproval').setValue(selectedOption[0].id);
        if (!$event) {
          if(!this.modalForm.get('comment')) {
            this.modalForm.addControl('comment', new FormControl('',[Validators.required, notEmptyValidator(2)]));
          }
          this.requireReason = true;
          return;
        }

        this.modalForm.removeControl('comment');
        this.requireReason = false;

      }
    }

    
}
