import {Component, ViewContainerRef, NgZone, ViewChild, ElementRef} from '@angular/core';
import {ParentModalComponent} from '../parentModalComponent';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {IFileInfo} from "../../uploadFiles/FileInfo";
import {ModalConfig} from "../modalConfig";
import {S3Document, S3FileReference} from "../../../services/data/document";
import {ApiService, S3Service} from "../../../services";
import {ToastrService} from "ngx-toastr";
import {downloadFile} from "../../../utils/jsUtils";

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['../modal.component.scss', './upload-modal.component.scss']
})
export class UploadModalComponent extends ParentModalComponent {
  @ViewChild("uploadFiles") inputFile: ElementRef<HTMLInputElement>;
  file: IFileInfo;

  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  modalForm: FormGroup;

  bulkModelTypes: string[] = [
    'A1 CO Acquisition Model',
    'A1 Portfolio Acquisition Model',
  ];
  bulkPortfolioNames: string[];
  fileName: string;
  systemError = false;

  loading: boolean = false;
  fileUploaded: boolean = false;
  validationSuccess: boolean = false;
  resultsFile: any = {};
  errorMessage: string;

  get title() {
    if (this.fileUploaded && this.validationSuccess) return 'Bulk Model Successfully Uploaded';
    // if (this.fileUploaded && !!this.errorMessage) return this.errorMessage;
    return this.config.title;
  }

  get bulkPortfolioName() {
    return this.modalForm.get('bulkPortfolioName').value;
  }

  get bulkModelType() {
    return this.modalForm.get('bulkModelType').value;
  }

  get isSaveDisabled() {
    return !this.modalForm.valid ||
      this.loading ||
      this.errorMessage ||
      (this.fileUploaded && this.validationSuccess);
  }

  get isCancelDisabled() {
    return !!this.loading;
  }

  get okText() {
    return !!this.file
      ? (this.fileUploaded && !this.validationSuccess ? 'View Results' : 'Upload')
      : this.config.okText;
  }

  get cancelText() {
    return !this.file || (this.fileUploaded && this.validationSuccess) ? this.config.cancelText : 'Replace File';
  }

  get currentIcon(): string {
    if (this.loading) {
      return 'a1-loader';
    }
    if (this.fileUploaded) {
      return this.validationSuccess ? 'check-circle-green' : 'error-circle';
    }
    return 'file-text';
  }

  constructor(
    vcr: ViewContainerRef,
    zone: NgZone,
    fb: FormBuilder,
    private s3Service: S3Service,
    private toastr: ToastrService,
    private apiService: ApiService
  ) {
    super();

    this.modalForm = fb.group({
      bulkPortfolioName: [null, Validators.required],
      bulkModelType: [null, Validators.required],
      bulkFile: [null, Validators.required],
    });

    this.vcr = vcr;
    this.zone = zone;
  }

  onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    const files: FileList = evt.dataTransfer.files;
    this.addFiles(files);
  }

  onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  open(config: ModalConfig) {
    super.open(config);

    this.bulkPortfolioNames = config.options.bulkPortfolioNames.map((name: string) => {
      return {id: name, label: name.replace(/_/g, ' ')};
    });
  }

  openUpload() {
    if (this.fileUploaded) {
      return;
    }

    this.inputFile.nativeElement.click();
  }

  onCloseClicked() {
    this.onClickClose(UploadModalComponent.CANCEL);
  }

  onCancelClicked() {
    if (!this.file || (this.fileUploaded && this.validationSuccess)) {
      this.onClickClose(UploadModalComponent.CANCEL);
    } else {
      this.modalForm.get('bulkFile').reset();
      this.fileUploaded = false;
      this.fileName = null;
      this.errorMessage = null;
      this.systemError = null;
      this.file = null;
    }
  }

  async onOkClick() {
    if (!this.file) {
      this.onClickClose(UploadModalComponent.OK);
      return;
    }

    if (this.fileUploaded && !this.validationSuccess) {
      downloadFile(this.resultsFile.body.data as ArrayBuffer, this.resultsFile.contentType, this.resultsFile.filename, this.resultsFile.body.data.length);
      return;
    }

    try {
      this.loading = true;

      const document: S3Document = (await this.s3Service.getFilesToSaveAndSaveToS3([this.file], 'BULK_UPLOAD'))[0];
      const fileReferences: S3FileReference = {
        id: document.id,
        AOListingID: -1,
        filename: document.filename,
        filepath: document.filepath
      };

      const submitResults = await this.apiService.submitBulkUpload(this.bulkPortfolioName, this.bulkModelType, fileReferences);

      if (submitResults.statusCode === 500) {
        this.errorMessage = submitResults.message;
        throw new Error(JSON.parse(submitResults.body).error);
      }
      if (!!submitResults.errorMessage) {
        throw new Error(submitResults.errorMessage);
      }

      this.fileUploaded = true;
      this.validationSuccess = submitResults.statusCode === 200 && submitResults.type !== 'Error';
      this.errorMessage =  !this.validationSuccess ? submitResults.message : null;
      this.resultsFile = this.validationSuccess ? {} : submitResults;

    } catch (e) {
      console.error(e);
      this.systemError = true;
      this.errorMessage = 'Something went wrong. Please try again later.';
      // this.toastr.error(
      //   'An error occurred while trying to upload the file. Please try again later.',
      //   null,
      //   {
      //     closeButton: true,
      //     messageClass: 'toast-message a1-toast-message'
      //   }
      // );
    } finally {
      this.loading = false;
    }
  }

  addFiles(files: FileList) {
    let acceptedTypes = this.inputFile.nativeElement.accept.split(',').map(type => type.trim());
    let fileType = files[0].type;
    let isAcceptedType = acceptedTypes.includes(fileType);
    if (!isAcceptedType) {
      return;
    }

    this.file = files[0];
    this.fileName = this.file.name;

    this.modalForm.get('bulkFile').setErrors(!!this.file.name ? null : {required: true});
  }

  onClickClose(result: string) {
    this.close(result).then((closeResult) => {
      if (closeResult === UploadModalComponent.CANCEL) {
        this.closed.next({action: closeResult});
        return;
      }
      this.closed.next({
        action: closeResult,
        bulkPortfolioName: this.bulkPortfolioName,
        bulkModelType: this.bulkModelType,
        file: this.file,
      });
    });
  }
}
