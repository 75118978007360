import {ColDef, ColumnState} from 'ag-grid-community';
// import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { BuyBox } from '../services/data/buybox';

/**
 * All user' preferences
 */
export class Preferences {
  buyboxes: UserBuyBoxModel = new UserBuyBoxModel();

  // filters: UserFilterModel = new UserFilterModel();

  propertyListing: ListingPreferences = new ListingPreferences();
  bulkPropertyListing: ListingPreferences = new ListingPreferences();
  compsListing: ListingPreferences = new ListingPreferences();
  transactionListing: ListingPreferences = new ListingPreferences();
  constructionListing: ListingPreferences = new ListingPreferences();
  leasingRentReadyListing: ListingPreferences = new ListingPreferences();
  leasingLeasedListing: ListingPreferences = new ListingPreferences();
  closingListing: ListingPreferences = new ListingPreferences();
}

/**
 * Preferences for a listing
 */
export class ListingPreferences {
  columns: ColumnState[] = [];

  filters: UserFilterModel = new UserFilterModel();
}

/**
 * Sort direction for a column
 */
export class SortModel {
  colId: string;
  sort: string;
}


export class UserBuyBoxModel {
  currentBuyBoxId: string;
  buyboxes: any [] = [];
}

export class UserFilter {
  name: string;
  shareFilter: boolean;
  filterModel: any;
  sortModel: SortModel[] = [];
}


export class UserFilterModel {
  // currentFilterId: string;
  filters: UserFilter[] = [];
}
