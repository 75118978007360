import { Component, Input } from "@angular/core";
import { ColumnsSettings, Columns } from "src/app/panels/propertyDetails/columnDefinition";
import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";

@Component({
  selector: 'app-export-pdf-preview',
  styleUrls: ['../../../panels/underwriter/underwriter.component.scss', 'exportPreview.component.scss'],
  templateUrl: 'exportPreview.component.html'
})
export class ExportPreviewComponent extends UnderwriterPanelComponent {
  @Input()
  get selectedEntityOptionsForExport(): any[] {
    return this.selectedEntityOptions;
  }
  set selectedEntityOptionsForExport(values: any[]) {
    this.selectedEntityOptions = values;
  }

  printMode = true;

  columnsSettings: ColumnsSettings = {
    id: "print",
    expanded: [Columns.characteristics],
  };
}
