import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyValue } from '@angular/common';
import { UnderwriterService } from 'src/app/services';
import { LightboxService } from 'src/app/ui/lightbox/lightbox.service';
import { PhotoItem } from 'src/app/services/data/photoItem';

@Component({
  selector: 'app-photos-panel',
  styleUrls: ['photos.component.scss'],
  templateUrl: 'photos.component.html'
})
export class PhotosPanelComponent {

  currentDisplayStyle: string;
  photoGridStyle:string;
  photoStyle:string;

  photoShowIncrement: number = 16;
  photoShowCount: number = this.photoShowIncrement;
  photoShowItems: Record<string, PhotoItem[]> = {};
  photoItems: PhotoItem[] = [];
  photosAreFetched: boolean = false;

  dateDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    const dateA = new Date(a.key), dateB = new Date(b.key);
    return dateA > dateB ? -1 : (dateB > dateA ? 1 : 0);
  }

  private _aoListingId;
  @Input()
  get aoListingId(): any {
    return this._aoListingId;
  }
  set aoListingId(value: any) {
    this._aoListingId = value;
    this.initialize();
  }

  private _active: boolean = false;
  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
    this.initialize();
  }

  @Input() loading = false;
  constructor(private underwriterService: UnderwriterService, private lightboxService: LightboxService) {

    this.switchDisplayStyle('grid');
  }

  @Output() onScroll = new EventEmitter<any>();
  scrollListener($event) {
    this.onScroll.emit($event);
  }

  initialize() {
    this.photosAreFetched = false;
    this.photoItems = [];
    this.photoShowItems = {};
    this.photoShowCount = this.photoShowIncrement;

    if (this._active && this._aoListingId) {
      this.loading = true;
      this.underwriterService.getListingPropertyPhotos(this._aoListingId).then((photoItems: PhotoItem[]) => {
        this.loading = false;
        this.photoItems = photoItems ? photoItems : [];
        this.photoShowItems = this.groupPhotosByDate(this.photoItems);
        this.photosAreFetched = true;
      }).catch(err => {
        this.loading = false;
      });
    }
  }

  groupPhotosByDate(photoItems: PhotoItem[]): Record<string, PhotoItem[]> {
    return photoItems.reduce((grouped: Record<string, PhotoItem[]>, photo: PhotoItem, index: number) => {
      if (index >= this.photoShowCount) { return grouped; }

      const imageDate: string = new Date(photo.ImageDate).toString();
      grouped[imageDate] = grouped[imageDate] || [];
      grouped[imageDate].push(photo);

      return grouped;
    }, {});
  }

  switchDisplayStyle(displayStyle: 'grid' | 'list') {
    this.currentDisplayStyle = displayStyle;
    this.photoGridStyle = `a1-photos-container-${this.currentDisplayStyle}-style`;
    this.photoStyle = `a1-photo-${this.currentDisplayStyle}-style`;
  }

  onScrollDown() {
    this.photoShowCount += this.photoShowIncrement;
    this.photoShowItems = this.groupPhotosByDate(this.photoItems);
  }

  openLightbox(index: number) {
    this.lightboxService.open(this.photoItems, index);
  }
}
