import {Component} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'current-price-renderer',
  templateUrl: './current-price-renderer.component.html',
  styleUrls: ['./current-price-renderer.component.scss']
})
export class CurrentPriceRendererComponent {

    indicatorValue: number;
    indicator: string;
    currentPrice: string;

    agInit(params: ICellRendererParams): void {
        this.currentPrice = this.getValueToDisplay(params);

        this.indicatorValue= params.data.oldCurrentPrice && params.value - params.data.oldCurrentPrice !== 0 ? params.value - params.data.oldCurrentPrice : null;
        this.indicator = this.indicatorValue ?  Math.abs(this.indicatorValue) < 500 ? "<$1K" : Math.abs(this.indicatorValue) >= 1000000 ? ">$1M" : "$"+ Math.round((Math.abs(this.indicatorValue))/1000) +"K" : null;   
    }

    private getValueToDisplay(params: ICellRendererParams) {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }

}
