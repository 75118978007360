import { Pipe, PipeTransform } from '@angular/core';

import * as numeral from 'numeral';

@Pipe({name: 'a1Currency'})
export class A1CurrencyPipe implements PipeTransform {
  transform(value: number): string {
    return numeral(value).format('($0,0.00)');
  }
}
