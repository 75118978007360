export enum ListingSources {
  /**
   * MLS Underwriter properties
   */
  AOListingView = 1,

  /**
   * reporting properties used by Mike S.
   * never displayed in the front-end
   */
  AOReportingView = 2,

  /**
   * bulk properties
   */
  AOPortfolioView = 3,

  /**
   * summary rows that contain grouped properties
   */
  DuplicateAOPropertyID = 4
}
