import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import * as _ from 'lodash';

import { ICellEditorAngularComp } from "ag-grid-angular";
import { UnderwriterService } from "src/app/services";
import { tagAction } from "src/app/services/data/tags";
import { TagsUtils } from "src/app/services/tagUtils";
import { NgSelectComponent } from "@ng-select/ng-select";
import { RowNode } from "ag-grid-community";
import * as JsUtils from 'src/app/utils/jsUtils';
import * as moment from "moment";
import {ActiveToast, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-select-cell-grid',
  templateUrl: './select-cell-grid.component.html',
  styleUrls: ['./select-cell-grid.component.scss'],
})
export class SelectCellGridComponent implements ICellEditorAngularComp, OnDestroy, AfterViewInit {
  params: any;

  loading = false;
  readonly = true;
  moreCount = 0;
  mutationObserver: MutationObserver;

  tags = [];

  notFoundText = 'All tags selected';


  private errorToast: ActiveToast<any>;

  private toastrOptionsError = { 
    disableTimeOut: true,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  @ViewChild(forwardRef(()=> NgSelectComponent)) ngSelect: NgSelectComponent;

  constructor(
    private underwriterService: UnderwriterService,
    private elementRef: ElementRef,
    private toastr: ToastrService
  ) {
    TagsUtils.getTagsList(true).subscribe({
      next: tagListValue => this.tags = tagListValue,
      error: err => console.log(err),
    });

    if (this.readonly) {
      this.mutationObserver = new MutationObserver(() => {
          this.calculateHasMoreChildren();
      });

      this.mutationObserver.observe(this.elementRef.nativeElement, {
        childList: true,
        subtree: true
      });
    }
  }

  ngAfterViewInit() {
    if (!this.readonly) {
      setTimeout(() => {
        this.ngSelect.focus();
      });
    }

    this.ngSelect.handleKeyDown = (keyDown: KeyboardEvent) => {
      if (!keyDown) {
        return;
      }

      if (this.readonly) {
        this.removeDropdownListener();
      } else if (keyDown.key == 'Escape') {
        this.removeDropdownListener();
      } else if (keyDown.key == 'Enter') {
        this.handleKeyCode();
      } else {
        this.ngSelect.handleKeyCode(keyDown);
      }
    };

  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }

  getDifference(event?) {
    const tagsIds = this.tags.map(tag => {
      return tag.id;
    });
   if(_.differenceBy(tagsIds, this.params.value,'id').length === 0) {
     if(event && event.term) {
       return 'No tags match';
     }
      return  'All tags selected';
   }
   return  'No tags match';
  }


  searchTerm($event) {
   this.notFoundText = this.getDifference($event);
  }


  keydownEnterEvent(event: Event) {
    event.preventDefault();
    (document.querySelector('.ng-input input') as HTMLInputElement).blur();
    this.ngSelect.blur();
    this.ngSelect.close();
  }

  async onValueAdded(value) {
    this.notFoundText = this.getDifference();
    try {
      this.loading = true;
      await this.updateTagsAndCommonUpdateDate(value, tagAction.ADD);
    } catch (error) {
      this.errorToast = this.toastr.error('failed to add new tags', null, this.toastrOptionsError);
      this.errorToast.onTap.subscribe(() => {
        this.errorToast = null;
      });
    }

    this.loading = false;
  }

  async onValueRemoved({value}) {
    try {
      this.loading = true;
      this.notFoundText = this.getDifference();
      await this.updateTagsAndCommonUpdateDate(value, tagAction.REMOVE);
    } catch (error) {
      this.errorToast = this.toastr.error('failed to remove tags', null, this.toastrOptionsError);
      this.errorToast.onTap.subscribe(() => {
        this.errorToast = null;
      });
    }

    this.loading = false;
  }

  compareSelectedValues(a, b) {
    return (a.id ?? a) === (b.id ?? b);
  }

  sortTags(tags) {
    return _.sortBy( tags, [tag => tag.name?.toLowerCase()] );
  }

  calculateHasMoreChildren() {
    if (!this.readonly || !this.elementRef.nativeElement) {
      return;
    }

    let totalWidth = 0;
    this.moreCount = 0;
    const containerWidth = this.elementRef.nativeElement.querySelector('.ng-select-container').clientWidth;
    const children = this.elementRef.nativeElement.querySelectorAll('.ng-value:not(.a1-tags-more)');
    const TAGS_MARGIN = 5;
    const HAS_MORE_SIZE = 25;

    for (let i = 0; i < children.length; i++) {
      // 5px for tags margin/gap
      totalWidth += children[i].getBoundingClientRect().width + TAGS_MARGIN;
      // 25px for the has more tag
      if ((HAS_MORE_SIZE + totalWidth) > containerWidth) {
        this.moreCount++;
      }
    }
  }

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  agInit(params: any): void {
    this.params = params;
    this.readonly = params.readonly || false;
  }

  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.params.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return false;
  }

  // Tells ag grid that we have a cell editor that should popup
  // on top of the grid
  isPopup() {
    return true;
  }

  /* Component Renderer Lifecycle methods */
  // gets called whenever the cell refreshes
  refresh(params) {
    this.agInit(params);
    this.calculateHasMoreChildren();

    // return true to tell the grid we refreshed successfully
    return true;
  }

  private handleKeyCode() {
    if (this.ngSelect.isOpen) {

      if (this.ngSelect.itemsList.markedItem) {
        this.ngSelect.toggleItem(this.ngSelect.itemsList.markedItem);
      } else if (this.ngSelect.showAddTag) {
        this.ngSelect.selectTag();
      }

    } else if (this.ngSelect.openOnEnter) {
      this.ngSelect.open();
    } else {
      return;
    }
  }

  private removeDropdownListener() {
    if (this.ngSelect.dropdownPanel) {
      const target =  document.querySelector('.ng-input input') as HTMLInputElement;
      if(target) {
        target.blur();
      }
      this.ngSelect.dropdownPanel.ngOnDestroy();
      this.ngSelect.dropdownPanel = null;
    }
    this.ngSelect.blur();
    this.ngSelect.close();
  }

  async updateTags(tag, aoListingIdx, action) {
    return this.underwriterService.updateTags({
      tagData: {
        operation: action,
        id: tag.id,
        name: tag.name,
      },
      AOListingID: aoListingIdx,
    });
  }

  async updateTagsAndCommonUpdateDate(tagtagValue, action) {
    const selectedRows = this.params.api.getSelectedRows();
      const isCurrentPropertyInSelection = selectedRows.filter(e => e.AOListingID === this.params.node.data.AOListingID).length > 0;
      let aoListingIdx = [];
      if (selectedRows.length && isCurrentPropertyInSelection) {
        aoListingIdx = selectedRows.map(listing => listing.AOListingID);
        const result = await this.updateTags(tagtagValue, aoListingIdx, action);
        this.params.api.getSelectedNodes().forEach((rowNode: RowNode, id) => {
          const currentTags = selectedRows[id].Tags ?? [];
          const newTags = action === tagAction.ADD
            ? [...currentTags.filter(tag => tag != tagtagValue.id), tagtagValue.id] : action === tagAction.REMOVE
            ? currentTags.filter(tag => tag != tagtagValue.id) : currentTags;
          if(!_.isEqual(currentTags, newTags)) {
            rowNode.setDataValue('Tags', newTags);
            if (result && result.commonUpdateDate) {
              rowNode.data['StatusUpdateDateMoment'] = moment(result.commonUpdateDate);
              rowNode.data['updateDate'] = result.commonUpdateDate;
              rowNode.data['StatusUpdateDate'] = result.commonUpdateDate;
              rowNode.setDataValue('StatusUpdateDateHuman', JsUtils.getDateCalendarString(result.commonUpdateDate));
            }
          }
        });
      } else {
        aoListingIdx = [this.params.node.data.AOListingID];
        const result = await this.updateTags(tagtagValue, aoListingIdx, action);
        if (result && result.commonUpdateDate) {
          this.params.node.data['StatusUpdateDateMoment'] = moment(result.commonUpdateDate);
          this.params.node.data['updateDate'] = result.commonUpdateDate;
          this.params.node.setDataValue('StatusUpdateDateHuman', JsUtils.getDateCalendarString(result.commonUpdateDate));
          this.params.node.data['StatusUpdateDate'] = result.commonUpdateDate;
        }
      }
  }

}
