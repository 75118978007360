import { ProFormaModel } from "src/app/models/proformaModel";
import { ListingProperty } from "../../data/listingPropety";
import { PropertyStatusType } from "../../data/propertyStatusType";
import { IRequest } from "../model/requestStep";
import * as JsUtils from '../../../utils/jsUtils';
import { VERSION_NAME } from "../model/versioning";
import { CONCESSION_ACTION, EVBUS_EVENTS_NAMES, PROFORMA_EVENT_ACTION, STEP_ACTION, UNDERWRITER_ACTIONS, UPDATE_REQUEST_LEVEL } from "../model/updateRequest";
import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";
import { getFieldChanges, PROFORMA_FIELDS_IDS } from "src/app/panels/proforma/proforma_config";
import { pendingEvBusEventsValidator } from "../utils/pendingEventsUtils";
import { UNDERWRITER_COMMANDS_NAME, UNDERWRITER_OPTIONS_LABEL } from "../model/underwriterCommandsAndActions";
import { IStatusUpdate } from "../model/IRequest";


function counterOfferFlowStatusValidation(currentProperty: ListingProperty) {
    return [
        PropertyStatusType.Counter,
        PropertyStatusType.CounterReason,
        PropertyStatusType.CounterSent,
        PropertyStatusType.CounterToUW,
        PropertyStatusType.Countered,
        PropertyStatusType.TraderCountered
    ].includes(currentProperty.status);
}

function counterOfferStatusCounter(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any, newValue: any) {
    return [
        PropertyStatusType.Counter,
        PropertyStatusType.CounterReason,
        PropertyStatusType.CounterSent,
        PropertyStatusType.CounterToUW,
    ].includes(currentProperty.status);
}

function traderCountered(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any, newValue: any) {
    return [
        PropertyStatusType.Counter,
        PropertyStatusType.CounterReason,
        PropertyStatusType.CounterSent,
        PropertyStatusType.CounterToUW,
        PropertyStatusType.TraderCountered
    ].includes(currentProperty.status);
}

function counterOfferFlowValuesValidation(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any, newValue: any) {
    const counterOfferAmount = !JsUtils.isNullOrEmpty(currentProperty?.transactionCounterSellerCounterOfferAmount) ?
        currentProperty?.transactionCounterSellerCounterOfferAmount :
        currentProperty?.transactionSellerCounterOfferAmount;

    return !isNaN(counterOfferAmount) ? counterOfferAmount : false;
}

function counterOfferStatusCountered(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any, newValue: any) {
    return [
        PropertyStatusType.Countered
    ].includes(currentProperty.status);
}

function counterOfferMessage(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any, newValue: any): string {
    return !JsUtils.isNullOrEmpty(currentProperty?.counterOfferMessage) ?
        currentProperty?.counterOfferMessage :
        null;
}

function counterOfferValueNotEqual(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any, newValue: any) {
    if (newValue == null) {
        return false;
    }
    return currentUwProforma.proforma[fieldToWatch] != newValue;

}

function counterOfferPendingEventBusValidator(underwriter: UnderwriterPanelComponent, ...uwArguments) {
    if (
        underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.HIGHEST_PURCHASE_PRICE_OFFER] != underwriter.proformaPanel.originalProforma[PROFORMA_FIELDS_IDS.HIGHEST_PURCHASE_PRICE_OFFER]
    ) {
        return true;
    }
    return false;
}

function concessionEventBusValidator(underwriter: UnderwriterPanelComponent, ...uwArguments) {
    if (
        underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION] != underwriter.proformaPanel.originalProforma[PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION]
        || underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION] != underwriter.proformaPanel.originalProforma[PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION]
    ) {
        return true;
    }
    return false;
}

function concessionPendingEventBusValidator(underwriter: UnderwriterPanelComponent, pendingEvent) {
    if (
        underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION] != pendingEvent[PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION].oldValue
        || underwriter.currentUwProforma.proforma[PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION] != pendingEvent[PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION].oldValue
    ) {
        return true;
    }
    return false;
}


/**
 * @description check if listing source is # to 3 bulk and if new listingStatu = 34 no need to check if already sent to TM
 * @param underwriter
 * @param uwArguments
 * @returns
 */
function counterOfferFlowEvBusEventValidator(underwriter: UnderwriterPanelComponent, ...uwArguments) {
    if (
      [PropertyStatusType.Countered, PropertyStatusType.TraderCountered].includes(underwriter.currentUwProforma.listingStatus)
        && underwriter.currentProperty.source !== 3
    ) {
        return true;
    }
    return false;
}

function counteredAcceptedValidator(underwriterPanel: UnderwriterPanelComponent, $event: any) {
    return underwriterPanel.updateRequests.value === underwriterPanel.currentUwProforma.proforma.highestPurchasePriceOffer;
}

function traderCounteredValidator(underwriterPanel: UnderwriterPanelComponent, $event: any): boolean {
  let originalValue = getFieldChanges(underwriterPanel.currentUwProforma.pendingEvents, 'highestPurchasePriceOffer');
  originalValue = originalValue? originalValue : underwriterPanel.currentUwProforma.initialProforma.highestPurchasePriceOffer;

  return (underwriterPanel.updateRequests.value > underwriterPanel.currentUwProforma.proforma.highestPurchasePriceOffer) &&
      (originalValue !== underwriterPanel.currentUwProforma.proforma.highestPurchasePriceOffer);
}

function traderRejesctsValidator(underwriterPanel: UnderwriterPanelComponent, $event: any) {
  let originalValue = getFieldChanges(underwriterPanel.currentUwProforma.pendingEvents, 'highestPurchasePriceOffer');
  originalValue = originalValue? originalValue : underwriterPanel.currentUwProforma.initialProforma.highestPurchasePriceOffer;
  return originalValue === underwriterPanel.currentUwProforma.proforma.highestPurchasePriceOffer;
}

const CounterOfferStatusUpdate: IStatusUpdate[] = [
    {
        status: PropertyStatusType.TraderCountered,
        rules: [counteredAcceptedValidator]
    },
    {
        status: PropertyStatusType.TraderCountered,
        rules: [traderCounteredValidator]
    },
    {
        status: PropertyStatusType.TraderCountered,
        rules: [traderRejesctsValidator]
    }
];

export const counterOffer: IRequest = {
    name: 'counterOfferFlow',
    displayName: 'Counter Offer',
    fieldToWatch: PROFORMA_FIELDS_IDS.HIGHEST_PURCHASE_PRICE_OFFER,
    externalLink: null,
    requester: null,

    rules: {
        status: counterOfferFlowStatusValidation,
        value: counterOfferFlowValuesValidation,
        comment: counterOfferMessage
    },

    steps: [
        /* step -1- */
        {
            stepAction: STEP_ACTION.UPDATE,
            listingName: 'Seller Countered *',
            listingIcon: null,
            level: UPDATE_REQUEST_LEVEL.ALERT,
            activators: [counterOfferStatusCounter],
            activeAction: {
                saveDraft: false,
                sendTM: true
            },
            DisableHideForEveryOne: false,
            multipleActions: PROFORMA_EVENT_ACTION.CONCESSION_APPROVAL,
            saveLabel: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
            proforma: {
                lockProforma: false,
                updateProforma: true,
                forceUpdate: false,
                updateProformaLabel: 'Update highest offer',
                editLocked: false,
                editLockOverrides: [
                    PROFORMA_FIELDS_IDS.MONTHLY_RENT_AMOUNT,
                    PROFORMA_FIELDS_IDS.HIGHEST_PURCHASE_PRICE_OFFER,
                    PROFORMA_FIELDS_IDS.TARGET_CONDITION_SCORE,
                    PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION,
                    PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION],
                updateOnChanges: false,
                message: 'Seller Countered: ',
                style: {
                    updateIcon: 'blocker'
                }
            },
            commands: {
                underwriter: {
                    options: {
                        ready: { id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
                        draft: { id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }

                    },
                    builder: null
                },
                panel: {
                    triggerConcessionFields: {
                        active: false,
                        action: CONCESSION_ACTION.UPDATE,
                        commands: {
                            active: false,
                            nextAction: null,
                            previousAction: null,
                            actionName: null
                        },
                        validators: null
                    },
                    preApproval: {
                        active: false
                    },
                }
            },
            actions: {
                saveDraft: {
                    active: true,
                    approval: null,
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.OFFER_READY,
                        validators: null
                    },
                    triggerEVBusEvent: {
                        active: true,
                        eventBus: [],
                        checkForPendingEvents: {
                            active: true,
                            validators: [pendingEvBusEventsValidator],
                            updateProperty: true,
                            pendingEvents: [
                                {
                                    active: false,
                                    evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                    fieldsToWatch: [
                                        PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                                        PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                                    ],
                                    validators: [concessionEventBusValidator]

                                },
                                {
                                    active: true,
                                    evbusEventName: EVBUS_EVENTS_NAMES.COUNTER_OFFER,
                                    fieldsToWatch: [
                                        PROFORMA_FIELDS_IDS.HIGHEST_PURCHASE_PRICE_OFFER,
                                    ],
                                    validators: [counterOfferPendingEventBusValidator]
                                }
                            ]
                        }
                    },
                    updateStatus: {
                        active: true,
                        status: PropertyStatusType.Counter,
                        validators: null,
                        fallBackStatus: null
                    }
                },
                sendTM: {
                    active: true,
                    approval: {
                        active: false,
                        // options: [
                        //     { id: 1, label: 'Youpiiiiiiii, I have investor approval' },
                        // ],
                        // preSelectedOption: 1
                    },
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.OFFER_READY,
                        validators: null
                    },
                    triggerEVBusEvent: {
                        active: true,
                        eventBus: [
                            {
                                active: true,
                                evbusEventName: EVBUS_EVENTS_NAMES.COUNTER_OFFER,
                                validators: [counterOfferFlowEvBusEventValidator]
                            }
                        ],
                        checkForPendingEvents: {
                            active: true,
                            validators: [pendingEvBusEventsValidator],
                            updateProperty: false,
                            pendingEvents: [
                                {
                                    active: false,
                                    evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                    fieldsToWatch: [PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION],
                                    validators: null

                                },
                                {
                                    active: true,
                                    evbusEventName: EVBUS_EVENTS_NAMES.COUNTER_OFFER,
                                    fieldsToWatch: [PROFORMA_FIELDS_IDS.HIGHEST_PURCHASE_PRICE_OFFER],
                                    validators: null
                                }
                            ]
                        }
                    },
                    updateStatus: {
                        active: true,
                        status: PropertyStatusType.Countered,
                        validators: CounterOfferStatusUpdate,
                        fallBackStatus: PropertyStatusType.OfferReady
                    }
                },
            }
            ,




        },

        /* step -2- */
        {
            stepAction: STEP_ACTION.UPDATE,
            listingName: 'Seller Countered II',
            listingIcon: null,
            level: UPDATE_REQUEST_LEVEL.ALERT,
            saveLabel: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
            activators: [counterOfferStatusCounter],
            activeAction: {
                saveDraft: false,
                sendTM: true
            },
            DisableHideForEveryOne: false,
            multipleActions: PROFORMA_EVENT_ACTION.PROFORMA_CONCESSION_APPROVAL,
            proforma: {
                lockProforma: false,
                updateProforma: false,
                forceUpdate: false,
                updateProformaLabel: null,
                editLocked: false,
                editLockOverrides: null,
                updateOnChanges: false,
                message: null,
                style: {
                    updateIcon: 'blocker'
                }
            },
            commands: {
                underwriter: {
                    options: {
                        ready: { id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
                        draft: { id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }

                    },
                    builder: null
                },
                panel: {
                    triggerConcessionFields: {
                        active: false,
                        action: CONCESSION_ACTION.UPDATE,
                        commands: {
                            active: false,
                            nextAction: null,
                            previousAction: null,
                            actionName: null
                        },
                        validators: null
                    },
                    preApproval: {
                        active: false
                    },
                }
            },

            actions: {
                saveDraft: {
                    active: true,
                    approval: null,
                    validators: null,
                    versioning: {
                        active: false,
                        update: false,
                        versionName: VERSION_NAME.COUNTER_OFFER,
                        validators: null
                    },
                    triggerEVBusEvent: null,
                    updateStatus: {
                        active: true,
                        status: PropertyStatusType.Counter,
                        validators: null,
                        fallBackStatus: null
                    }
                },

                sendTM: {
                    active: true,
                    approval: {
                        active: false,
                    },
                    validators: null,
                    versioning: {
                        active: true,
                        update: false,
                        versionName: VERSION_NAME.COUNTER_OFFER,
                        validators: null
                    },
                    triggerEVBusEvent: {
                        active: true,
                        eventBus: [
                            {
                                active: true,
                                evbusEventName: EVBUS_EVENTS_NAMES.COUNTER_OFFER,
                                validators: [counterOfferFlowEvBusEventValidator]
                            }
                        ],
                        checkForPendingEvents: null
                    },
                    updateStatus: {
                        active: true,
                        status: PropertyStatusType.Countered,
                        validators: null,
                        fallBackStatus: null
                    }
                },
            }

        },

        /* step -3- */
        {
            stepAction: null,
            listingName: 'Trader Countered',
            listingIcon: null/*'active-property'*/,
            level: UPDATE_REQUEST_LEVEL.WARN,
            saveLabel: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
            activators: [traderCountered],
            activeAction: {
                saveDraft: false,
                sendTM: false
            },
            DisableHideForEveryOne: true,
            multipleActions: null,
            proforma: {
                lockProforma: false,
                updateProforma: false,
                forceUpdate: false,
                updateProformaLabel: null,
                editLocked: false,
                editLockOverrides: null,
                updateOnChanges: false,
                message: null,
                style: {
                    updateIcon: 'active-property'
                }
            },

            commands: {
                underwriter: {
                    options: {
                        ready: { id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
                        draft: { id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }

                    },
                    builder: null
                },
                panel: {
                    triggerConcessionFields: {
                        active: false,
                        action: CONCESSION_ACTION.UPDATE,
                        commands: {
                            active: false,
                            nextAction: null,
                            previousAction: null,
                            actionName: null
                        },
                        validators: null
                    },
                    preApproval: {
                        active: false
                    },
                }
            },
            actions: {
                saveDraft: {
                    active: true,
                    approval: null,
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.OFFER_READY,
                        validators: null
                    },
                    triggerEVBusEvent: {
                        active: false,
                        eventBus: [
                            {
                                active: false,
                                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                validators: [concessionEventBusValidator]
                            }
                        ],
                        checkForPendingEvents: {
                            active: true,
                            validators: [pendingEvBusEventsValidator],
                            updateProperty: true,
                            pendingEvents: [
                                {
                                    active: false,
                                    evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                    fieldsToWatch: [
                                        PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                                        PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                                    ],
                                    validators: [concessionEventBusValidator]
                                }
                            ]
                        }
                    },
                    updateStatus: {
                        active: true,
                        status: null,
                        validators: null,
                        fallBackStatus: null
                    }
                },

                sendTM: {
                    active: true,
                    approval: {
                        active: false,
                    },
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.OFFER_READY,
                        validators: null
                    },
                    triggerEVBusEvent: {
                        active: false,
                        eventBus: [
                            {
                                active: false,
                                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                validators: [concessionEventBusValidator]
                            }
                        ],
                        checkForPendingEvents: {
                            active: true,
                            validators: [pendingEvBusEventsValidator],
                            updateProperty: false,
                            pendingEvents: [
                                {
                                    active: false,
                                    evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                    fieldsToWatch: [
                                        PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                                        PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                                    ],
                                    validators: [concessionPendingEventBusValidator]
                                }
                            ]
                        }
                    },
                    updateStatus: {
                        active: false,
                        status: null,
                        validators: null,
                        fallBackStatus: null
                    }
                },

            }
        },

        /* step -3- */
        {
            stepAction: null,
            listingName: 'Counter Accepted',
            listingIcon: null/*'active-property'*/,
            level: null,
            saveLabel: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
            activators: [counterOfferStatusCountered],
            activeAction: {
                saveDraft: false,
                sendTM: false
            },
            DisableHideForEveryOne: true,
            multipleActions: null,
            proforma: {
                lockProforma: false,
                updateProforma: false,
                forceUpdate: false,
                updateProformaLabel: null,
                editLocked: false,
                editLockOverrides: null,
                updateOnChanges: false,
                message: null,
                style: {
                    updateIcon: 'active-property'
                }
            },

            commands: {
                underwriter: {
                    options: {
                        ready: { id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
                        draft: { id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }

                    },
                    builder: null
                },
                panel: {
                    triggerConcessionFields: {
                        active: false,
                        action: CONCESSION_ACTION.UPDATE,
                        commands: {
                            active: false,
                            nextAction: null,
                            previousAction: null,
                            actionName: null
                        },
                        validators: null
                    },
                    preApproval: {
                        active: false
                    },
                }
            },
            actions: {
                saveDraft: {
                    active: true,
                    approval: null,
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.OFFER_READY,
                        validators: null
                    },
                    triggerEVBusEvent: {
                        active: false,
                        eventBus: [
                            {
                                active: false,
                                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                validators: [concessionEventBusValidator]
                            }
                        ],
                        checkForPendingEvents: {
                            active: true,
                            validators: [pendingEvBusEventsValidator],
                            updateProperty: true,
                            pendingEvents: [
                                {
                                    active: false,
                                    evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                    fieldsToWatch: [
                                        PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                                        PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                                    ],
                                    validators: [concessionEventBusValidator]
                                }
                            ]
                        }
                    },
                    updateStatus: {
                        active: false,
                        status: null,
                        validators: null,
                        fallBackStatus: null
                    }
                },

                sendTM: {
                    active: true,
                    approval: {
                        active: false,
                    },
                    validators: null,
                    versioning: {
                        active: true,
                        update: true,
                        versionName: VERSION_NAME.OFFER_READY,
                        validators: null
                    },
                    triggerEVBusEvent: {
                        active: false,
                        eventBus: [
                            {
                                active: false,
                                evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                validators: [concessionEventBusValidator]
                            }
                        ],
                        checkForPendingEvents: {
                            active: true,
                            validators: [pendingEvBusEventsValidator],
                            updateProperty: false,
                            pendingEvents: [
                                {
                                    active: false,
                                    evbusEventName: EVBUS_EVENTS_NAMES.CONCESSION,
                                    fieldsToWatch: [
                                        PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
                                        PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION
                                    ],
                                    validators: [concessionPendingEventBusValidator]
                                }
                            ]
                        }
                    },
                    updateStatus: {
                        active: false,
                        status: null,
                        validators: null,
                        fallBackStatus: null
                    }
                },

            }
        },
    ]
};


