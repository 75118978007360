import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";
import { PropertyStatusType } from "../../data/propertyStatusType";
import { IRequestStep } from "./requestStep";

export interface IUpdateRequest {
    flowName: string;
    field: string;
    value: number | string;
    displayValue: string;
    displayName: string;
    requester: string;
    comment: string;
    externalLink: string;
    activeStep: IRequestStep;
    initUwReference(uwReference: UnderwriterPanelComponent);
    getReference?: () => UnderwriterPanelComponent;
    setProperty?: (a: any) => void;
  }

  export enum EVBUS_EVENTS_NAMES {
    REHAB_BUDGET = 'sentInspectionForReview',
    COUNTER_OFFER = 'respondedToCounterOffer',
    CLOSING_PRICE = 'addedClosingPrice',
    CONCESSION = 'requestedConcession',
    OFFER_READY = 'setOfferReady',
    INVESTOR_APPROVAL = 'approvedByInvestor',
    BULK_TO_TRANSACTION_MANAGER = 'sendBulkToTransactionManager',
    PRE_INSPECTION = 'respondedToPreInspection'
  }

  export enum UPDATE_REQUEST_LEVEL {
    ALERT = 'alert',
    WARN = 'warn',
    INFO = 'info'
  }

  export enum STEP_ACTION {
    UPDATE = 'update',
    WARN = 'warn',
    INFO = 'info',
    CONCESSION_TRIGGER = 'concessionTrigger',
    APPROVAL = 'approval',
    CONCESSION = 'concession',
    MULTIPLE = 'multiple',
    NULL = 'null',
  }

  export enum PROFORMA_EVENT_ACTION {
    PROFORMA_CONCESSION = 'PROFORMA_CONCESSION',
    PROFORMA_CONCESSION_APPROVAL = 'PROFORMA_CONCESSION_APPROVAL',
    PROFORMA_APPROVAL = 'PROFORMA_APPROVAL',
    CONCESSION_APPROVAL = 'CONCESSION_APPROVAL',
  }

  export enum CONCESSION_ACTION {
    UPDATE= 'UPDATE',
    ADD= 'ADD',
    DELETE= 'DELETE'
  }
  export enum UNDERWRITER_ACTIONS {
    DRAFT = 'draft',
    READY = 'ready',
    CONTINUE = 'continue',
    HIDE = 'hide',
    CANCEL = 'cancel',
    NO ='no',
    SHOW='show',
    APPROVAL= 'approval',
    UNDO_APPROVAL ='undo',
    DRILLDOWN = 'drilldown'
  }

  export const WORKFLOW_STATES = {
    [PropertyStatusType.InspectionReview]: 'INSPECTION_REVIEW',
    [PropertyStatusType.PreInspectionReview]: 'PRE_INSPECTION_REVIEW',
    [PropertyStatusType.Counter]: 'COUNTER_OFFER'
  };

