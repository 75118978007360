import { Component, OnInit } from '@angular/core';
import {ICellRendererParams} from "ag-grid-community";
import {ListingProperty} from "../../../services/data/listingPropety";
import {ListingSources} from "../../../services/data/ListingSources";

@Component({
  selector: 'app-link-renderer',
  templateUrl: './link-renderer.component.html',
  styleUrls: ['./link-renderer.component.scss']
})
export class LinkRendererComponent {
  cellValue: string;
  listingUrl: string;
  countValue: string;
  isHeaderRow: boolean = false;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.listingUrl = params.data.ListingURL ?? "";
    this.isHeaderRow = params.data.isHeaderRow && params.data.groupCount > 1;
    this.countValue = this.getCountValue(params);
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.listingUrl = params.data.listingURL ?? "";
    this.isHeaderRow = params.data.isHeaderRow && params.data.groupCount > 1;
    this.countValue = this.getCountValue(params);
    this.cellValue = this.getValueToDisplay(params);
  }

  private getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  private getCountValue(params: ICellRendererParams) {
    const listing: ListingProperty = params.data;
    const copyID = listing.copyID;
    const groupCount =  listing.groupCount;
    let copyLabel = '';

    if(copyID && groupCount > 1) {
      copyLabel = copyID > 1 ? `Copy ${copyID}` : 'Copy';
      copyLabel += ' - ';
      copyLabel += listing.source === ListingSources.AOListingView ? 'MLS' : 'Bulk';
    }

    return copyLabel;
  }
}
