import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-no-properties-found',
  templateUrl: './no-properties-found.component.html',
  styleUrls: ['./no-properties-found.component.scss']
})
export class NoPropertiesFoundComponent implements ILoadingOverlayAngularComp {
  disabled = false;
  state = null;
  gridName;
  constructor() { }

  public params!: INoRowsOverlayParams & { noRowsOverLayGridName: () => string };

  agInit(
    params: INoRowsOverlayParams & { noRowsOverLayGridName: () => string }
  ): void {
    this.params = params;
    this.gridName = this.params.noRowsOverLayGridName();
  }

  clearFilters() {
    this.params.context.componentParent.clearFilters();
  }
}
