import { ListingProperty } from 'src/app/services/data/listingPropety';
import { ProFormaModel } from 'src/app/models/proformaModel';
import { ListingComps } from 'src/app/services/data/listingComps';
import { IUpdateRequest } from 'src/app/services/update-request-service/model/updateRequest';
import { IUnderwriterNote } from 'src/app/models/inderwriterNoteModel';
import { IProformaVersionModel } from 'src/app/services/activityLogService/proformaVersioningService';

export interface UnderwriterViewModel {
  property: ListingProperty;
  avmProforma: ProFormaModel;
  avmProformaVersioning: IProformaVersionModel;
  updateRequests?: IUpdateRequest;
  uwProforma: ProFormaModel;
  comps: ListingComps[];
  originalSelectedComps: {
    rent: number[];
    sale: number [];
    active: number [];
  };
  compsFirstLoad: boolean;
  isDirty?: boolean;
  underwriterRejectionReasons?: any;
  underwriterNote: IUnderwriterNote;
  duplicateListings?: ListingProperty[];
}
export enum UNDERWRITE_PANEL {
  PROFORMA_PANEL = 0,
  PHOTOS_PANEL = 1,
  ACTIVITY_LOG_PANEL = 3,
  DETAILS_PANEL = 2,
  NOTES_PANEL = 5,
}
