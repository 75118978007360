import { Component, Input, Output, EventEmitter, ViewContainerRef, NgZone, TemplateRef, HostBinding, forwardRef } from '@angular/core';

import * as JsUtils from 'src/app/utils/jsUtils';
import { createPopper, Placement } from '@popperjs/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILogGroupOption } from 'src/app/panels/proforma/proforma';

@Component({
  selector: 'app-log-history',
  templateUrl: './log-history.component.html',
  styleUrls: ['./log-history.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LogHistoryComponent),
      multi: true
    }
  ]
})
export class LogHistoryComponent {
  @HostBinding('class') class = 'a1-select';
  @Input() customClasses = null;
  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() labelKey = 'address';
  @Input() idKey = 'aoListingId';
  @Input() optionCss = '';
  @Input() dropdownIcon = `a1-dropdown-chevron-icon`;
  @Input() displayLabel = true;
  @Input() placement: Placement = 'bottom-end';
  @Input() offset = {
    skidding: 0,
    distance: 4,
  };

  private _options: ILogGroupOption[] = [];
  @Input()
  get options(): ILogGroupOption[] {
    return this._options;
  }
  set options(values: ILogGroupOption[]) {
    this._options = values;
    if (this._options && this._options.length> 10) {
      this.overflowItems = true;
    } else {
      this.overflowItems = false;
    }
    this.updateSelection();
  }

  private _selectedAoListingId = null;

  selectedId: any;

  @Input() nullable;

  @Input() placeholder;
  @Input() leftIconClass;
  @Input() optionRightIconClass;

  @Output() selectChange = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() optionRightButtonClicked: EventEmitter<any> = new EventEmitter();

  selectedHistory: ILogGroupOption;

  isOpen = false;
  view: any;
  backdropView: any;
  popperRef: any;

  overflowItems = false;
  constructor(private vcr: ViewContainerRef, private zone: NgZone) { }

  get selectedLogHistory() {
    return this.selectedHistory ? {
      label: this.selectedHistory[this.labelKey],
      source: this.selectedHistory.source,
      underwritingStatusClass: this.selectedHistory.underwritingStatusClass,
      underwritingStatusText: this.selectedHistory.underwritingStatusText,
      copyID: this.selectedHistory.copyID
    } : {
      label: this.placeholder,
      source: '',
      underwritingStatusClass: '',
      underwritingStatusText: null
    };
  }

  get dropdownCustomClasses() {
    return this.customClasses ? this.customClasses + '' : '';
  }

  updateSelection() {
    if (this.options) {
      if (this.options && this.options.length) {
        this.selectedHistory = this.options.find(
          currentOption => currentOption.main
          );

          this.selectedId = this.selectedHistory? this.selectedHistory.aoListingId : null;
      } else {
        this.selectedHistory = null;
        this.selectedId = null;
      }
    }
  }

  open(dropdownTpl: TemplateRef<any>, origin: any, backdropTpl: TemplateRef<any>, event) {
    if (this.disabled) {
      return;
    }

    this.backdropView = this.vcr.createEmbeddedView(backdropTpl);
    const bd = this.backdropView.rootNodes[0];
    document.body.appendChild(bd);

    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const dropdown = this.view.rootNodes[0];

    document.body.appendChild(dropdown);

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, dropdown, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this.offset.skidding, this.offset.distance],
            },
          },
          {
            name: "sameWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({ state }) => {
              state.styles.popper.minWidth = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.minWidth = `${state.elements.reference.getBoundingClientRect().width
                }px`;
            }
          },
          {
            name: 'flip',
            enabled: true,
            options: {
              flipVariations: false,
            },
          }
        ]
      });
    });

    this.isOpen = true;
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;

      this.closed.emit();
      this.popperRef.destroy();
      this.view.destroy();
      this.view = null;
      this.popperRef = null;

      this.backdropView.destroy();
      this.backdropView = null;
    }
  }

  isActive(option) {
    if (!this.selectedHistory) {
      return false;
    }
    return option[this.idKey] === this.selectedHistory[this.idKey];
  }

  select(option) {
    if (option) {
      this._selectedAoListingId = option[this.idKey];
      this.selectedHistory = option;
    } else {
      this._selectedAoListingId = null;
      this.selectedHistory = null;
    }

    this.selectChange.emit(this._selectedAoListingId);

    this.close();
  }

  onBackdropClicked() {
    this.close();
  }

  optionRightButtonClick($event: MouseEvent, option) {
    $event.preventDefault();
    $event.stopPropagation();

    this.optionRightButtonClicked.emit(option[this.idKey]);
    this.close();
  }

}
