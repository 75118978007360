import { PropertyStatusType, PropertyStatusTypeText } from './propertyStatusType';

export class ListingProperty {
  AOListingID: string = null;
  AOPortfolioID: string = null;
  proformaId: string = null;

  Address: string = null;
  source: number = null;
  Unit: string = null;
  City: string = null;
  State: string = null;
  ZipCode: string = null;
  Region: string = null;
  FIPS: any = null;
  CountyName: string = null;
  CensusTract: string = null;
  Latitude: number = null;
  Longitude: number = null;
  AOPropertyID: string = null;
  PropertyType: string = null;
  PropertySubtype: string = null;
  Beds: number = null;
  FullBaths: number = null;
  HalfBaths: number = null;
  YearBuilt: number = null;
  LivingAreaSqFt: number = null;
  TMStatus: string = null;
  SubStatus: string = null;
  InspectionStatus: string = null;
  ListingStatus: string = null;
  StatusDate: string = null;
  CurrentPrice: number = null;
  DaysOnMarket: number = null;
  RealDaysOnMarket: number = null;
  OrigPrice: number = null;
  ListingDate: string = null;
  ListingAgentName: string = null;
  ListingBrokerName: string = null;
  ListingAgentPhone: string = null;
  ListingAgentEmail: string = null;
  PublicRemarks: string = null;
  PrivateRemarks: string = null;
  MLSName: string = null;
  MLSListingID: string = null;
  LastUpdate: any = null;
  StatusUpdateDate: any = null;
  StatusUpdateDateHuman: any = null;
  lastUnderwrittenDate: any = null;
  lastUnderwrittenDateHuman: any = null;
  EstimatedRent: number = null;
  EstimatedRentStdDev: number = null;
  HOADues: number = null;
  PropertyTaxAppraisal: number = null;
  CurrentPropertyTax: number = null;
  PropertyTaxYear: number = null;
  SFHA: string = null;
  OccupancyRate: number = null;
  CreditLoss: number = null;
  ManagementFee: number = null;
  LeasingCost: number = null;
  LeaseFee: number = null;
  Maintenance1: number = null;
  Maintenance2: number = null;
  Maintenance3: number = null;
  Maintenance4: number = null;
  Maintenance5: number = null;
  Maintenance6: number = null;
  PropertyTaxRate: number = null;
  BidPrice: number = null;
  ClosingCost: number = null;
  AcquisitionCost: number = null;
  Concessions: number = null;
  OtherIncome: number = null;
  ConditionScore: number = null;
  Occupant: string = null;
  OwnerName: string = null;
  SaleAVM: number = null;
  SaleAVMStdDev: number = null;
  LastSaleDate: any = null;
  LastSalePrice: number = null;
  LastRentDate: any = null;
  LastRent: number = null;
  Zestimate: number = null;
  MedianIncome: number = null;
  SchoolScoreElementary: number = null;
  SchoolScoreMiddle: number = null;
  SchoolScoreHigh: number = null;
  CrimeScore: number = null;
  ListingURL: string = null;
  purchasingEntity: string = null;
  BuyBox: string = null;
  BuyBoxV2: string = null;
  buyBoxClassification: string = null;
  interimBuyBoxClassification: string = null;
  MinNetYield: number = null;
  OldListingStatus: string = null;
  LotSizeAcres: number = null;
  /**
   * if the state has a limit on how much an assessor can increase a property's value, that amount will be reported here.
   * If this is null or it is higher than the purchase price, assume the property is reappraised to the purchase price
   */
  MaxPropertyTaxValue: number = null;

  /**
   * Normal property and casualty insurance rate ($/sq ft)
   */
  InsuranceRate: number = null;

  /**
   * Assumed number of days between purchase and start of first lease
   */
  InitialCarryPeriod: number = null;

  StabilizationFee: number = null;

  /**
   * AVM Property will be repaired to this level
   */
  TargetConditionScore: number = null;

  /**
   * Minimum initial repair cost
   */
  MinRehabCost: number = null;

  RentCondition1: number = null;

  /**
   * Cost to repair home from condition 1 to condition 2 ($/sq ft)
   */
  RehabCost12: number = null;

  RentCondition2: number = null;

  /**
   * Cost to repair home from condition 2 to condition 3 ($/sq ft)
   */
  RehabCost23: number = null;

  RentCondition3: number = null;

  /**
   * Cost to repair home from condition 3 to condition 4 ($/sq ft)
   */
  RehabCost34: number = null;

  RentCondition4: number = null;

  /**
   * Cost to repair home from condition 4 to condition 5 ($/sq ft)
   */
  RehabCost45: number = null;

  RentCondition5: number = null;

  /**
   * Cost to repair home from condition 5 to condition 6 ($/sq ft)
   */
  RehabCost56: number = null;

  RentCondition6: number = null;


  Section8: any = null;

  /////////////////////////////////////////////////////////////////////////////
  // extras

  /**
   * Status of the listing excluding 'Hidden' as we want to keep those info separated in the DB
   */
  status: PropertyStatusType;

  /**
   * Special status to hide the property from all listings
   */
  isHiddenForAll: boolean;

  /**
   * Combination of status and isHiddenForAll
   */
  underwritingStatus: PropertyStatusType;
  underwritingStatusText: string;
  underwritingStatusClass: string;

  BathsCount: number = null;
  avmCapRate: number = null;
  uwCapRate: number = null;

  // for checkbox selection in grid
  id: any;

  // Track updated fields
  isUpdated: boolean = null;
  updated: string[] = null;
  StatusUpdateDateMoment: any = null;
  lastUnderwrittenDateMoment: any = null;

  missingData: boolean = false;
  missingDataTooltip: string = null;

  // Transaction management
  transactionSellerCounterOfferAmount?: number;
  transactionCounterSellerCounterOfferAmount?: number;
  counterOfferMessage?: string;
  transactionInspectionRepairEstimate?: number;
  TMExternalVendorName?: string;
  ClosingExternalVendorName?: string;

  // Offer Ready with few comps
  reasonFewComps: string = null;

  notesNotificationCount: number;
  isNotesTabViewed: boolean = false;
  currentNote: string = null;

  Tags: any = [];

  CompsMapCenter: string = null;
  CompsMapZoom: string = null;
  finalPurchasePriceOffer: number;
  inspectionPortalUrl: string = null;

  childCount: number;
  isHeaderRow: boolean = false;
  groupCount: number;
  children: ListingProperty[] = [];
  createDate: any = null;
  copyID: number;
  sentToTMDate: any = null;
  sentToTMUser: string = null;

  Exception: string = null;
  Channel: string = null;
  investorApproval: number = null;
  oldListingStatus: string = null;
  oldCurrentPrice: number = null;
  workflowState: string = null;
  isListingManuallyUpdated: boolean = false;
  AOMarket: string = null;
  underwritingClassificationClass: string = null;
  ClassificationToolTip: string = null;
  rejectionReasons: string = null;
  UQClassification: string = null;

  renovationFeePercent: number = null;
  minCapRate: number = null;
  minYieldRate: number = null;
}
