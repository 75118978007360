import { Component, forwardRef, NgZone, ViewChild, ViewContainerRef } from '@angular/core';
import { ParentModalComponent } from "../parentModalComponent";
import { FormGroup, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { ProformaConfig } from 'src/app/panels/proforma/proforma_config';
import { ITargetOption } from 'src/app/panels/proforma/proforma';
import { NgSelectComponent } from '@ng-select/ng-select';
import { debounceTime } from 'rxjs/operators';
import { ListingProperty } from 'src/app/services/data/listingPropety';
import { buildOptions, buildValidator, requiredFieldsName, requredListingFieldsConfig } from './missingDataUtils';

@Component({
  selector: 'app-missing-property-values',
  templateUrl: './missing-property-values.component.html',
  styleUrls: ['./missing-property-values.component.scss', '../modal.component.scss']
})
export class MissingPropertyValuesComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  showExceptionMessage = false;

  @ViewChild(forwardRef(() => NgSelectComponent)) ngSelect: NgSelectComponent;

  modalForm: FormGroup;
  yearsOfBuiltOptions = buildOptions(1900, new Date().getUTCFullYear(), 1, requiredFieldsName.YearBuilt);
  bedsOptions = buildOptions(1, 6, 1, requiredFieldsName.Beds);
  bathsOptions = buildOptions(1, 6, .5, requiredFieldsName.BathsCount);

  selectedEntityOptions: ITargetOption[] = new ProformaConfig().selectedEntityOptions;
  requiredFields = requredListingFieldsConfig;
  listingData: ListingProperty;
  exceptionDataEntries: any[];
  fullUpdate = false;
  ready = false;
  loading = false;
  readOnly = false;
  selectedYearBuilt: any = null;

  constructor(vcr: ViewContainerRef, zone: NgZone, fb: FormBuilder) {
    super();
    this.modalForm = fb.group({});
    this.vcr = vcr;
    this.zone = zone;
  }

  get livingAria() {
    if (!this.ready) {
      return new FormControl(null);
    }
    return this.modalForm.get(requiredFieldsName.LivingAreaSqFt);
  }

  get YearBuilt(): FormControl | AbstractControl {
    if (!this.ready) {
      return new FormControl(null);
    }
    return this.modalForm.get(requiredFieldsName.YearBuilt);
  }


  get isSaveDisabled() {
    return this.modalForm.valid;
  }

  onCancelClicked() {
    this.onClickClose(MissingPropertyValuesComponent.CANCEL);
  }

  onOkClick() {
    this.onClickClose(MissingPropertyValuesComponent.OK);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      this.closed.next({ action: closeResult, updatedListing: this.handleUpdatedData() });
    });
  }

  setBedsCount(index) {
    this.bedsOptions.forEach(bt => {
      if (bt.name == index) {
        bt.selected = true;
        this.modalForm.get(requiredFieldsName.Beds).setValue(bt.name, {emitEvent: true});
      } else {
        bt.selected = false;
      }
    });
  }

  setBathsCount(index) {
    this.bathsOptions.forEach(bt => {
      if (bt.name == index) {
        bt.selected = true;
        this.modalForm.get(requiredFieldsName.BathsCount).setValue(bt.name, {emitEvent: true});
      } else {
        bt.selected = false;
      }
    });
  }

  buildForm(fullUpdate: boolean) {
    const requiredFieldsControls = Object.keys(this.requiredFields) || [];
    if (fullUpdate) {
      this.fullUpdate = true;
      requiredFieldsControls.forEach(cr => {
        this.requiredFields[cr].value = !!this.listingData[cr] ? this.listingData[cr] : null;
      this.requiredFields[cr].missing = !(!!this.listingData[cr]);
        this.modalForm.addControl(cr, new FormControl(this.requiredFields[cr].value, buildValidator(this.requiredFields[cr])));
      });
      return;
    }
    this.fullUpdate = false;
    requiredFieldsControls.forEach(cr => {
      this.requiredFields[cr].value = !!this.listingData[cr] ? this.listingData[cr] : null;
      this.requiredFields[cr].missing = !(!!this.listingData[cr]) || this.exceptionDataEntries.includes(cr);
      if (this.requiredFields[cr].missing) {
        this.modalForm.addControl(cr, new FormControl(this.requiredFields[cr].value, buildValidator(this.requiredFields[cr])));
      }
    });
  }

  initListingRequiredFields() {
    this.listingData = this.config.options.listingData;
    this.exceptionDataEntries = this.config.options.exceptionEntries || [];

    this.buildForm(false);

    this.bathsOptions.forEach(bt => {
      if (bt.name == this.requiredFields[requiredFieldsName.BathsCount].value) {
        bt.selected = true;
        return;
      }
    });

    this.bedsOptions.forEach(bd => {
      if (bd.name == this.requiredFields[requiredFieldsName.Beds].value) {
        bd.selected = true;
        return;
      }
    });

    this.subscribeFromChanges(requiredFieldsName.LivingAreaSqFt);
    this.subscribeFromChanges(requiredFieldsName.YearBuilt);

    if(this.requiredFields[requiredFieldsName.YearBuilt].value) {
      this.selectedYearBuilt = {
        id: "_initial",
        name: this.requiredFields[requiredFieldsName.YearBuilt].value,
        label: this.requiredFields[requiredFieldsName.YearBuilt].value
      };
    }

    this.ready = true;
  }

  updateConfig() {
    setTimeout(() => {
      this.initListingRequiredFields();
    }, 100);
  }

  onValueAdded($event) {}

  onValueRemoved($event) {}

  searchTerm($event) { }

  compareSelectedValues(a, b) {
    return (a.id ?? a) === (b.id ?? b);
  }
  notFoundText = '';
  moreCount = 3;

  updateField(key, $event) {
    this.requiredFields[key].userInput = true;
    if (!this.modalForm.get(key).value && this.requiredFields[key].required) {
      this.requiredFields[key].hasError = true;
    } else {
      this.requiredFields[key].hasError = false;
    }
  }

  subscribeFromChanges(ctrl) {
    const control = this.modalForm.get(ctrl);
    if (!control) {
      return;
    }
    control
      .valueChanges
      .pipe(debounceTime(400))
      .subscribe((event) => {
        this.updateField(ctrl, event);
      });
  }

  handleUserInput(ctrl) {
    const control = this.modalForm.get(ctrl);
    let originalValue: string = control.value;
    originalValue = originalValue.replace(/,|\./gi, '');
    const parsedValue = parseFloat(originalValue);
    if (isNaN(parsedValue)) {
      control.reset();
      return;
    }


    control.setValue(parsedValue, {emitEvent: true});
  }

  handleUpdatedData() {
    let update = false;
    const updatedFields = {};

    const BathsCountControl = this.modalForm.get(requiredFieldsName.BathsCount);
    if (BathsCountControl && BathsCountControl.value !=  this.requiredFields[requiredFieldsName.BathsCount].value) {
      updatedFields[requiredFieldsName.BathsCount] = BathsCountControl.value;
      updatedFields[requiredFieldsName.FullBaths] = parseInt(BathsCountControl.value);
      updatedFields[requiredFieldsName.HalfBaths] = 2 * (parseFloat(BathsCountControl.value) - updatedFields[requiredFieldsName.FullBaths]);
      update = true;
    }

    const bedsControl = this.modalForm.get(requiredFieldsName.Beds);
    if (bedsControl && bedsControl.value !=  this.requiredFields[requiredFieldsName.Beds].value) {
      updatedFields[requiredFieldsName.Beds] = parseInt(bedsControl.value);
      update = true;
    }

    const yearBuiltControl = this.modalForm.get(requiredFieldsName.YearBuilt);
    if (yearBuiltControl && yearBuiltControl.touched) {
      updatedFields[requiredFieldsName.YearBuilt] = parseInt(yearBuiltControl.value?.name || yearBuiltControl.value);
      update = true;

    }

    const livingAreaSqFtControl = this.modalForm.get(requiredFieldsName.LivingAreaSqFt);
    if (livingAreaSqFtControl && livingAreaSqFtControl.value !=  this.requiredFields[requiredFieldsName.LivingAreaSqFt].value) {
      updatedFields[requiredFieldsName.LivingAreaSqFt] = parseInt(livingAreaSqFtControl.value);
      update = true;
    }
    updatedFields["isListingManuallyUpdated"] = (update || this.listingData?.Exception) ? true : false;
    return updatedFields;
  }

  onKeyDown($event: KeyboardEvent, ctrl) {
    if($event && ['ArrowUp', 'ArrowDown'].includes($event.key)) {
      const path = $event.key == 'ArrowUp'? 1 : -1;
      this.incrementValue(ctrl, path);
    }
  }

  incrementValue(ctrl, path) {
    const control = this.modalForm.get(ctrl);
    if (!control) {
      return;
    }
    const newValue = (control.value + path) > 0 ? control.value + path : 0;
    control.setValue(newValue);
  }

}
