import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import * as moment from 'moment';
import {DaterangepickerComponent, DaterangepickerDirective} from "ngx-daterangepicker-material";
import {LocaleConfig} from "ngx-daterangepicker-material/daterangepicker.config";
import {TIME_RANGE_CONFIGS, TimeRangeTypes} from "./time-range-configs";
import {RangePickerConfig} from "./range-picker-config.interface";
import {Vector2} from "./vector2.interface";
import {RANGE_PICKER_LOCALE_CONFIG} from "./range-picker-local-config";

@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss']
})
export class RangeDatepickerComponent implements AfterViewInit {
  private readonly DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  @ViewChild("startDateInput", {static: true}) startDateInputElem: ElementRef<HTMLInputElement>;
  @ViewChild("endDateInput", {static: true}) endDateInputElem: ElementRef<HTMLInputElement>;

  private datePickerHtmlElem: HTMLDivElement;
  private datePickerApplyButtonHtmlElem: HTMLButtonElement;
  private datePickerClearButtonHtmlElem: HTMLButtonElement;

  picker: DaterangepickerComponent;

  private _config: RangePickerConfig;
  @Input()
  set config(config: RangePickerConfig) {
    this._config = config ?? {timeRange: TimeRangeTypes.Last24Hours};
    if (config) {
      this.setDateRangePicker(config, true);
    }
  }
  get config() {
    return this._config;
  }

  @Output() dateRangeChange: EventEmitter<RangePickerConfig> = new EventEmitter();

  constructor(private selfRef: ElementRef, private renderer: Renderer2, private ngZone: NgZone) {
    this.localeConfig = RANGE_PICKER_LOCALE_CONFIG;

    this.rangesConfig = TIME_RANGE_CONFIGS
      .filter(x => x.isVisible)
      .reduce((acc, current) => {
        return {...acc, [current.label]: current.range};
      }, {});
  }

  timeRangeLabel: string = '';

  selectedRange: TimeRangeTypes = TimeRangeTypes.Custom;
  startDate: string = '';
  endDate: string = '';

  isOpen: boolean = false;
  hasChanges: boolean = false;

  localeConfig: LocaleConfig;
  rangesConfig: any;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initDateRangePicker();
    });
  }

  open(wrapElem: HTMLDivElement, originElem: HTMLButtonElement) {
    this.setPickerPosition(wrapElem,originElem);
    this.renderer.addClass(this.selfRef.nativeElement, 'active');
    this.isOpen = true;

    this.renderer.addClass(this.datePickerHtmlElem, 'a1-md-drppicker');
    this.renderer.setAttribute(this.datePickerApplyButtonHtmlElem, 'disabled', 'true');

    if (this._config.timeRange === TimeRangeTypes.Last24Hours) {
      this.toggleClearButtonState(true);
    }
  }

  close (resetPicker: boolean) {
    this.renderer.removeClass(this.selfRef.nativeElement, 'active');
    this.isOpen = false;

    if (resetPicker) {
      this.setDateRangePicker(this._config, true);
    }

    this.toggleClearButtonState(false);
  }

  onDateChange(e: any, changeType: 'start' | 'end' | 'range') {
    switch (changeType) {
      case "start":
        this.startDate = this.formatDate(e.startDate as moment.Moment);
        this.endDate = '';
        this.selectedRange = TimeRangeTypes.Custom;
        this.toggleHasChanges(true);
        break;
      case "end":
        this.endDate = this.formatDate(e.endDate as moment.Moment);
        this.selectedRange = TimeRangeTypes.Custom;
        this.toggleHasChanges(true);
        break;
      case "range":
        const label = e.label.toLowerCase();

        this.selectedRange = TIME_RANGE_CONFIGS.find(x => x.label.toLowerCase() === label)?.id ?? TimeRangeTypes.Custom;

        this.startDate = this.formatDate(e.dates[0] as moment.Moment);

        this.endDate = this.formatDate(e.dates[1] as moment.Moment);

        this.applyOverride();
        break;
    }
  }

  onDateInputKeyup(e: KeyboardEvent, dateInputType: 'start' | 'end') {
    const val = (e.target as HTMLInputElement).value;

    if (val.length !== 10) {
      return;
    }

    const date = moment(val, this.DATE_FORMAT);
    if (date.isValid()) {
      const startDate = dateInputType === 'start' ? date : this.picker.startDate;
      const endDate = dateInputType === 'end' ? date : this.picker.endDate;

      if (!endDate.isSameOrAfter(startDate)) {
        if (dateInputType === 'start') {
          endDate.month(startDate.month());
          endDate.date(startDate.date());
          endDate.year(startDate.year());
        } else {
          startDate.month(endDate.month());
          startDate.date(endDate.date());
          startDate.year(endDate.year());
        }
      }

      this.setDateRangePicker({
        timeRange: TimeRangeTypes.Custom,
        startDate,
        endDate
      });

      this.toggleHasChanges(true);
    }
  }

  onDateInputBlur(e: FocusEvent, dateInputType: 'start' | 'end') {
    const val = (e.target as HTMLInputElement).value;
    if (val.length !== 10 || !moment(val, this.DATE_FORMAT).isValid()) {
      if (dateInputType === 'start') {
        this.startDate = this.formatDate(this.picker.startDate);
      } else {
        this.endDate = this.formatDate(this.picker.endDate);
      }
    }
  }

  onResize(wrapElem: HTMLDivElement, originElem: HTMLButtonElement) {
    if(this.isOpen) {
      this.setPickerPosition(wrapElem,originElem);
    }
  }

  private initDateRangePicker() {
    // init html dom elem
    this.datePickerHtmlElem = this.selfRef.nativeElement.getElementsByClassName('md-drppicker')[0];
    this.datePickerApplyButtonHtmlElem = this.datePickerHtmlElem.querySelector('.btn:not(.clear)');
    this.datePickerClearButtonHtmlElem = this.datePickerHtmlElem.querySelector('.btn.clear');

    // change label for clear button
    this.datePickerClearButtonHtmlElem.innerText = 'Reset';

    // init picker
    this.picker = this.pickerDirective.picker;
    this.picker.clear = this.clearOverride;
    this.picker.clickApply = this.applyOverride;
    this.picker.hide = this.hideOverride;

    // set picker state
    this.setDateRangePicker(this._config, true);

    // show picker
    this.picker.show();
  }

  private clearOverride = () => {
    // set to default "last 24 hours"
    this.setDateRangePicker({
      timeRange: TimeRangeTypes.Last24Hours
    });

    // check if last state was not "last 24 hours" (no point in "applying" anything)
    this.toggleHasChanges(this._config.timeRange !== TimeRangeTypes.Last24Hours);

    // disable clear button state
    this.toggleClearButtonState(true);
  }

  private applyOverride = () => {
    // set label
    this.setLabel();

    // if we don't have a start or end date, something is wrong
    if (!this.picker.endDate || !this.picker.startDate) {
      console.error('We should have at least a start date...');
      return;
    }

    // build the config object then emit to parent
    this._config = {
      timeRange: this.selectedRange,
      startDate: moment(this.startDate),
      endDate: moment(this.endDate),
    };

    this.dateRangeChange.emit(this._config);

    this.close(false);
    this.toggleHasChanges(false);
  }

  private hideOverride = () => {
    return false;
  }

  private formatDate(date: moment.Moment): string {
    if(!date || !date.isValid()) {
      return '';
    }
    return date.format(this.DATE_FORMAT);
  }

  private setDateRangePicker(config: RangePickerConfig, setLabel: boolean = false) {
    const timeRange = TIME_RANGE_CONFIGS.find(x => x.id === config.timeRange);
    this.selectedRange = timeRange.id;
    if (timeRange.id === TimeRangeTypes.GridOverride) {
      this.picker.startDate =  moment();
      this.picker.endDate = moment();
      this.startDate = null;
      this.endDate = null;
    } else {
      this.picker.startDate = timeRange.id === TimeRangeTypes.Custom ? config.startDate :  moment(timeRange.range[0]);
      this.picker.endDate = timeRange.id === TimeRangeTypes.Custom ? config.endDate : moment(timeRange.range[1]);
      this.startDate = this.formatDate(this.picker.startDate);
      this.endDate = this.formatDate(this.picker.endDate);
    }

    this.picker.updateView();

    if (setLabel) {
      this.setLabel();
    }
  }

  private toggleHasChanges(hasChanges) {
    this.hasChanges = hasChanges;
    if (hasChanges && this.picker.startDate && this.picker.endDate) {
      this.renderer.removeAttribute(this.datePickerApplyButtonHtmlElem, 'disabled');
      this.toggleClearButtonState(false);
    } else {
      this.renderer.setAttribute(this.datePickerApplyButtonHtmlElem, 'disabled', 'true');
    }
  }

  private toggleClearButtonState(disabled) {
    if (!disabled) {
      this.renderer.removeAttribute(this.datePickerClearButtonHtmlElem, 'disabled');
    } else {
      this.renderer.setAttribute(this.datePickerClearButtonHtmlElem, 'disabled', 'true');
    }
  }

  private setPickerPosition(wrapElem: HTMLDivElement, originElem: HTMLButtonElement): Vector2 {
    return this.ngZone.runOutsideAngular(() => {
      const x = originElem.getBoundingClientRect().left - wrapElem.getBoundingClientRect().width + originElem.offsetWidth;
      const y = originElem.getBoundingClientRect().top + originElem.offsetHeight;
      wrapElem.style.left = `${x}px`;
      wrapElem.style.top = `${y}px`;
      return {x,y};
    });
  }

  private setLabel() {
    this.timeRangeLabel = TIME_RANGE_CONFIGS.find(x => x.id === this.selectedRange)?.label;
    const timeRangeID = TIME_RANGE_CONFIGS.find(x => x.id === this.selectedRange)?.id;
    const isSameDate = this.startDate && this.endDate && this.picker.startDate.isSame(this.picker.endDate, 'day');
    if (isSameDate) {
      this.timeRangeLabel = this.picker.startDate.format('MMM DD, YYYY');
    }
  }

  overrideRange(timeRange: TimeRangeTypes = TimeRangeTypes.GridOverride) {
    this.setDateRangePicker({timeRange}, true);
  }
}
