import {Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2} from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltipPosX: 'left' | 'right' | 'center' = 'left';
  @Input() tooltipPosY: 'top' | 'bottom' = 'top';
  @Input() tooltipOffsetX = 0;
  @Input() tooltipOffsetY = 0;
  @Input() tooltipIgnored: any = false;
  @Input() tooltipFocus: boolean = false;

  private _tooltipText: string = 'No listing link available';
  get tooltipText(): string {
    return this._tooltipText;
  }
  @Input()
  set tooltipText(text: string) {
    this._tooltipText = text;
    if (_.isNil(this._tooltipText) && this.tooltip) {
      this.hideTooltip();
      return;
    }

    if(this.tooltip) {
      this.tooltip.innerText = this._tooltipText;
    } else if (!this.tooltipIgnored && this.tooltipFocus && this._tooltipText) {
      this.showTooltip();
    }
  }

  tooltip: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('mouseenter') onMouseEnter() {
    if(_.isNil(this._tooltipText)) {
      if(this.tooltip) {
        this.hideTooltip();
      }
      return;
    }
    if (!this.tooltipIgnored && !this.tooltip) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.tooltipIgnored && this.tooltip) {
      this.hideTooltip();
    }
  }

  ngOnDestroy() {
   this.hideTooltip();
  }

  private showTooltip() {
    this.createTooltip();
    this.setTooltipPosition();
    this.renderer.setStyle(this.tooltip, 'opacity', '1');
  }

  private hideTooltip() {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }

  private createTooltip() {
    this.tooltip = this.renderer.createElement('span');
    this.tooltip.innerText = this._tooltipText;
    this.tooltip.classList.add('a1-tooltip-directive');
    this.renderer.appendChild(document.body, this.tooltip);
  }

  private setTooltipPosition() {
    const parentPosition = this.elementRef.nativeElement.getBoundingClientRect();
    const tooltipPosition = this.tooltip.getBoundingClientRect();
    const currentScrollPosition = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop || 0;

    let x, y;

    if (this.tooltipPosY === 'top') {
      y = parentPosition.top - tooltipPosition.height - this.tooltipOffsetY;
    } else {
      y = parentPosition.bottom + (+this.tooltipOffsetY);
    }

    if (this.tooltipPosX === 'left') {
      x = parentPosition.left;
    } else if (this.tooltipPosX === 'center') {
      x = (parentPosition.left + parentPosition.width/2) - tooltipPosition.width/2;
    } else {
      x = parentPosition.right - tooltipPosition.width;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${y + currentScrollPosition}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${x}px`);
  }
}
