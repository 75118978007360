import { Component, Input, OnInit, Output, EventEmitter, ViewContainerRef, NgZone, TemplateRef, HostBinding, ViewEncapsulation, ViewChild, ApplicationRef } from '@angular/core';

import { createPopper } from '@popperjs/core';
import { integer } from 'aws-sdk/clients/storagegateway';
import { Subject } from 'rxjs';
import { ModalConfig } from './modalConfig';
import { ParentModalComponent } from './parentModalComponent';


@Component({
  selector: 'app-a1-modal',
  styleUrls: ['./modal.component.scss'],
  templateUrl: './modal.component.html'
})
export class ModalComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  comment: string;

  constructor(vcr: ViewContainerRef, zone: NgZone) {
    super();
    this.vcr = vcr;
    this.zone = zone;
  }

  onCancelClicked() {
    this.onClickClose(ModalComponent.CANCEL);
  }

  onOkClick() {
    this.onClickClose(ModalComponent.OK);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      if (this.config.type === 'comment') {
        this.closed.next({action: closeResult, comment: this.comment});
      } else {
        this.closed.next(closeResult);
      }
    });
  }
}
