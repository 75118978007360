import * as numeral from 'numeral';
import * as _ from 'lodash';
import * as moment from 'moment';

import { RowNode } from 'ag-grid-community';

import * as JsUtils from 'src/app/utils/jsUtils';
import {
  PropertyStatusTypeText,
  PropertyStatusTypeFromDescription,
  PropertyStatusTypeSortMap,
} from 'src/app/services/data/propertyStatusType';

const defaultValue = '-';
export abstract class GridColumnTypes {

  static booleanFormatter = (params) => {
    return params?.value ? 'Yes' : 'No';
  }

  static booleanKeyCreator = (params) => {
    return params?.value ? 'Yes' : 'No';
  }


  static currencyFormatter = (params) => {
    if (params?.value === null) {
      return defaultValue;
    }
    return numeral(params?.value).format('$0,0.00');
  }

  static dateFormatter = (params) => {
    return JsUtils.getDateString(params?.value);
  }

  static UTCDateFormatter = (params) => {
    return JsUtils.getDateString(params?.value, true);
  }

  static distanceFormatter = (params) => {
    if (params?.value === null) {
      return defaultValue;
    }
    return numeral(params?.value).format('0.00');
  }

  static timeSpentNowFormatter = (params) => {
    return JsUtils.getDateString(params?.value);
  }

  static percentFormatter = (params) => {
    if (params?.value === null) {
      return defaultValue;
    }

    // Do not use the specific numeraljs formatter for percent
    // It messed up with the grid filters
    // See percentColumn column definition valueGetter and filterValueGetter
    return numeral(params?.value).format('0[.]0[00]') + '%';
  }


  static notNullFormatter = (params) => {
    if (_.isNil(params?.value) ) {
      return defaultValue;
    }
    return params?.value;
  }
  static otherNotNullFormatter = (params) => {
    if (_.isNil(params?.value) ) {
      return 'Other';
    }
    return params?.value;
  }


  static calendarFormatter = (params) => {
    return JsUtils.getDateCalendarString(params?.value);
  }

  static urlRenderer = (params) => {
    return params?.value ? `<a href=${params.value} target="_blank">${params.value}</a>` : defaultValue;
  }

  static underwriterStatusKeyCreator = (params) => {
    return PropertyStatusTypeText(params?.value);
  }

  static underwriterStatusComparator = (valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) => {
    if (typeof valueA == "string") {
      valueA = PropertyStatusTypeFromDescription(valueA);
    }

    if (typeof valueB == "string") {
      valueB = PropertyStatusTypeFromDescription(valueB);
    }

    if (PropertyStatusTypeSortMap[valueA] < PropertyStatusTypeSortMap[valueB]) {
      return -1;
    } else if (PropertyStatusTypeSortMap[valueA] > PropertyStatusTypeSortMap[valueB]) {
      return 1;
    }
    return 0;
  }


  static dateComparator = (filterLocalDateAtMidnight, cellValue) => {
    if (cellValue == null) {
      return 0;
    }

    const mfilterLocalDateAtMidnight = moment(filterLocalDateAtMidnight).utc();
    const mDate = moment(cellValue).utc().hour(0).minute(0).second(0);

    if (mDate.isBefore(mfilterLocalDateAtMidnight, 'day')) {
      return -1;
    } else if (mDate.isAfter(mfilterLocalDateAtMidnight, 'day')) {
      return 1;
    } else {
      return 0;
    }
  }

  static dateTimeComparator = (filterLocalDateAtMidnight, cellValue) => {
    if (cellValue == null) {
      return 0;
    }

    const mfilterLocalDateAtMidnight = moment(filterLocalDateAtMidnight);
    const mDate = moment(cellValue).local();

    if (mDate.isBefore(mfilterLocalDateAtMidnight)) {
      return -1;
    } else if (mDate.isAfter(mfilterLocalDateAtMidnight)) {
      return 1;
    } else {
      return 0;
    }
  }


  static columnTypes = {
    booleanColumn: {
      headerClass: 'ag-text-cell',
      cellClass: ['ag-text-cell', 'ag-center-aligned-cell'],
      filter: "agSetColumnFilter",
      filterParams: {
        values: function (params) {
          params?.success(["Yes", "No"]);
        }
      },
      valueFormatter: GridColumnTypes.booleanFormatter,
      // comparator: GridColumnTypes.underwriterStatusComparator,
      keyCreator: GridColumnTypes.booleanKeyCreator,

    },
    currencyColumn: {
      headerClass: 'ag-numeric-cell',
      cellClass: ['ag-numeric-cell', 'ag-right-aligned-cell'],
      filter: "agNumberColumnFilter",
      filterParams: { inRangeInclusive: true },
      valueFormatter: GridColumnTypes.currencyFormatter
    },
    currencyUpdatedColumn: {
      headerClass: 'ag-numeric-cell',
      cellClass: (params) => (params?.data?.oldCurrentPrice ? ['ag-numeric-cell', 'ag-right-aligned-cell', 'a1-col-updated'] : ['ag-numeric-cell', 'ag-right-aligned-cell']),
      filter: "agNumberColumnFilter",
      filterParams: { inRangeInclusive: true },
      valueFormatter: GridColumnTypes.currencyFormatter
    },
    listingStatusUpdatedColumn: {
      cellClass: (params) => (params?.data?.oldListingStatus ? ['ag-numeric-cell', 'ag-right-aligned-cell', 'a1-col-updated'] : ['ag-numeric-cell', 'ag-right-aligned-cell']),
      valueFormatter: GridColumnTypes.notNullFormatter
    },
    percentColumn: {
      headerClass: 'ag-numeric-cell',
      cellClass: ['ag-numeric-cell', 'ag-right-aligned-cell'],
      valueFormatter: GridColumnTypes.percentFormatter,
      filter: "agNumberColumnFilter",
      filterParams: { inRangeInclusive: true },
      filterValueGetter: function (params) {
        return (<any>(window.global)).numberRoundDecimal( params?.data?.[params?.colDef?.field]  * 100, 3);
      },
      valueGetter: function (params) {
        return params?.data?.[params?.colDef?.field] ?
          (<any>(window.global)).numberRoundDecimal( params?.data?.[params?.colDef?.field]  * 100, 3) :
          null;
      },
    },
    roundedPercentColumn: {
      headerClass: 'ag-numeric-cell',
      cellClass: ['ag-numeric-cell', 'ag-right-aligned-cell'],
      valueFormatter: GridColumnTypes.percentFormatter,
      filter: "agNumberColumnFilter",
      filterParams: { inRangeInclusive: true },
      filterValueGetter: function (params) {
        return (<any>(window.global)).numberRoundDecimal( params?.data?.[params?.colDef?.field], 0);
      },
      valueGetter: function (params) {
        return  params?.data?.[params?.colDef?.field] ?
          (<any>(window.global)).numberRoundDecimal( params?.data?.[params?.colDef?.field], 0) :
          null;
      },
    },
    leftAlignNumber: {
      headerClass: 'ag-numeric-cell',
      cellClass: 'ag-numeric-cell',
      filter: "agNumberColumnFilter",
      filterParams: { inRangeInclusive: true },
      valueFormatter: GridColumnTypes.notNullFormatter
    },
    numberColumn: {
      headerClass: 'ag-numeric-cell',
      cellClass: ['ag-numeric-cell', 'ag-center-aligned-cell'],
      filter: "agNumberColumnFilter",
      filterParams: { inRangeInclusive: true },
      valueFormatter: GridColumnTypes.notNullFormatter
    },
    centerColumn: {
      headerClass: 'ag-text-cell',
      cellClass: ['ag-text-cell', 'ag-center-aligned-cell'],
      valueFormatter: GridColumnTypes.notNullFormatter
    },
    leftColumn: {
      headerClass: 'ag-text-cell',
      cellClass: ['ag-text-cell', 'ag-left-aligned-cell'],
      valueFormatter: GridColumnTypes.notNullFormatter
    },
    leftColumnOther: {
      headerClass: 'ag-text-cell',
      cellClass: ['ag-text-cell', 'ag-left-aligned-cell'],
      valueFormatter: GridColumnTypes.otherNotNullFormatter
    },
    centerUpdatedColumn: {
      headerClass: 'ag-text-cell',
      cellClass: (params) => (params?.colDef && params?.data?.updated && params?.data?.updated.indexOf(params?.colDef?.field) >= 0 ? ['ag-text-cell', 'ag-center-aligned-cell', 'a1-col-updated'] : ['ag-text-cell', 'ag-center-aligned-cell']),
      valueFormatter: GridColumnTypes.notNullFormatter
    },

    updatedColumn: {
      cellClass: (params) => (params?.colDef && params?.data?.updated && params?.data?.updated.indexOf(params?.colDef?.field) >= 0 && 'a1-col-updated'),
      valueFormatter: GridColumnTypes.notNullFormatter
    },
    notNullColumn: {
      valueFormatter: GridColumnTypes.notNullFormatter
    },

    distanceColumn: {
      headerClass: 'ag-numeric-cell',
      cellClass: ['ag-numeric-cell', 'ag-center-aligned-cell'],
      valueFormatter: GridColumnTypes.distanceFormatter,
      filter: "agNumberColumnFilter",
      filterParams: { inRangeInclusive: true }
    },

    dateColumn: {
      cellClass: ['ag-text-cell', 'ag-center-aligned-cell'],
      suppressMenu: false,
      valueFormatter: GridColumnTypes.dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        inRangeInclusive: true,
        comparator: GridColumnTypes.dateComparator
      }
    },

    UTCDateColumn: {
      cellClass: ['ag-text-cell', 'ag-center-aligned-cell'],
      suppressMenu: false,
      valueFormatter: GridColumnTypes.UTCDateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        inRangeInclusive: true,
        comparator: GridColumnTypes.dateComparator
      }
    },

    zipColumn: {
      headerClass: 'ag-numeric-cell', cellClass: ['ag-numeric-cell', 'ag-center-aligned-cell'],
      filter: "agNumberColumnFilter",
      filterParams: {
        inRangeInclusive: true
      },
      filterValueGetter: function (params) {
        return parseInt(params.data[params.colDef.field]);
      },
      valueFormatter: GridColumnTypes.notNullFormatter
    },

    /**
     * After "2 Days ago" display the actual date
     */
    calendarDateColumn: {
      cellClass: ['ag-text-cell', 'ag-center-aligned-cell'], suppressMenu: false,
      valueFormatter: function(params)  {
        return params?.data?.StatusUpdateDateHuman;
      },
      valueGetter: function (params) {
        return params?.data?.StatusUpdateDateMoment;
      },
      filterValueGetter: function (params) {
        return params?.data?.StatusUpdateDateMoment;
      },
      comparator: GridColumnTypes.dateTimeComparator,

      filter: 'agDateColumnFilter',
      filterParams: {
        inRangeInclusive: true,
        comparator: GridColumnTypes.dateComparator
      }
    },

    lastUnderwrittenDateColumn: {
      cellClass: ['ag-text-cell', 'ag-center-aligned-cell'], suppressMenu: false,
      valueFormatter: function(params)  {
        return params?.data?.lastUnderwrittenDateHuman;
      },
      valueGetter: function (params) {
        return params?.data?.lastUnderwrittenDateMoment;
      },
      filterValueGetter: function (params) {
        return params?.data?.lastUnderwrittenDateMoment;
      },
      comparator: GridColumnTypes.dateTimeComparator,

      filter: 'agDateColumnFilter',
      filterParams: {
        inRangeInclusive: true,
        comparator: GridColumnTypes.dateComparator
      }
    },

    humanDateColumn: {
      cellClass: ['ag-text-cell', 'ag-center-aligned-cell'], suppressMenu: false,
      valueFormatter: function(params)  {
        return params?.data?.StatusUpdateDateHuman;
      },
      valueGetter: function (params) {
        return params?.data?.StatusUpdateDateMoment;
      },
      filterValueGetter: function (params) {
        return params?.data?.StatusUpdateDateMoment;
      },
      comparator: GridColumnTypes.dateTimeComparator,

      filter: 'agDateColumnFilter',
      filterParams: {
        inRangeInclusive: true,
        comparator: GridColumnTypes.dateComparator

      }
    },

    leaseTypeColumn: {
      headerClass: 'ag-text-cell',
      cellClass: ['ag-text-cell', 'ag-left-aligned-cell'],
      valueFormatter: (params) => {
        return params?.value == 'month' ? 'Month-to-Month' : params?.value == 'fixed' ? 'Fixed Term Lease' : defaultValue;
      },
      filter: "agNumberColumnFilter",
      filterParams: { inRangeInclusive: true }
    },

    timeSpentNowColumn: {
      cellClass: ['ag-text-cell', 'ag-center-aligned-cell'], suppressMenu: false,
      valueFormatter: (params) => {
        return JsUtils.getYearsMonthsElapsedNowString(params?.value);
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        inRangeInclusive: true,
        comparator: GridColumnTypes.dateComparator
      }
    },

    urlColumn: {
      headerClass: 'ag-text-cell',
      cellClass: ['ag-text-cell', 'ag-left-aligned-cell', 'a1-grid-cell-link'],
      cellRenderer: GridColumnTypes.urlRenderer,
      filter: false
    },

  };
}
