import { Component, ViewContainerRef, NgZone } from '@angular/core';
import { ParentModalComponent } from '../parentModalComponent';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-a1-send-to-tm-few-comps-modal',
  styleUrls: ['../modal.component.scss'],
  templateUrl: './sendToTMFewCompsModal.component.html'
})
export class SendToTMFewCompsModalComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  modalForm: FormGroup;

  constructor(vcr: ViewContainerRef, zone: NgZone, fb: FormBuilder) {
    super();

    this.modalForm = fb.group({
      comment: ''
    });

    this.vcr = vcr;
    this.zone = zone;
  }

  get comment() {
    return this.modalForm.get('comment').value;
  }

  get isSaveDisabled() {
    return !this.comment;
  }

  onCancelClicked() {
    this.onClickClose(SendToTMFewCompsModalComponent.CANCEL);
  }

  onOkClick() {
    this.onClickClose(SendToTMFewCompsModalComponent.OK);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      this.closed.next({action: closeResult, comment: this.comment});
    });
  }
}
