import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { NetworkService } from '../network.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: "root",
})
export class FeatureFlagsService {
  static isActive = false;
  private sessionName = "featureFlags";
  flagsProfil;
  isReady = false;
  constructor(
    private apiService: ApiService,
    private networkService: NetworkService
  ) {}
  async checkFlags() {
    const newFlags = await this.getFlagsProfil();
    return this.isFlagsUpdated(newFlags || '{}');
  }

  async initFlags() {
    const flags = await this.getFlagsProfil();
    this.flagsProfil = flags || {};
    this.refreshFlagsSession(flags);
    this.isReady = true;
  }

  private getFlagsProfil() {
    return new Promise<any>(async (resolve, reject) => {
      const isOnline = await this.networkService.checkIsOnline();
      if (!isOnline) {
        return;
      }

      this.apiService.getFlags().then(
        (featureFlags) => {
          resolve(featureFlags);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private isFlagsUpdated(parsedNewFlags): boolean {
    const parsedOldFlags = JSON.parse(sessionStorage.getItem(this.sessionName) || '{}');
    this.refreshFlagsSession(parsedNewFlags);

    if (_.isEmpty(parsedOldFlags) && _.isEmpty(parsedNewFlags)) {
      return false;
    }

    if (_.isEmpty(parsedOldFlags) && !_.isEmpty(parsedNewFlags)) {
      return _.some(Object.keys(parsedNewFlags), (fl) => parsedNewFlags[fl].enabled);
    }

    if (!_.isEmpty(parsedOldFlags) && _.isEmpty(parsedNewFlags)) {
      return false;
    }

    const disabledOldFlags = [];
    const enabledOldFlags = [];
    const disabledNewFlags = [];
    const enabledNewFlags = [];

    Object.keys(parsedOldFlags).forEach(pof => {
      if(parsedOldFlags[pof].enabled) {
        enabledOldFlags.push(pof);
      } else {
        disabledOldFlags.push(pof);
      }
    });

    Object.keys(parsedNewFlags).forEach(pof => {
      if(parsedNewFlags[pof].enabled) {
        enabledNewFlags.push(pof);
      } else {
        disabledNewFlags.push(pof);
      }
    });

    if (_.intersection(disabledOldFlags, enabledNewFlags).length) {
      return true;
    }

    if (_.intersection(enabledOldFlags, disabledNewFlags).length) {
      return true;
    }

    if (_.intersection(enabledOldFlags, enabledNewFlags).length) {
      const isIndicatorUpdated = _.some(enabledOldFlags, (enabledOldFlag) => {
        if (!_.isEqual(parsedOldFlags[enabledOldFlag],parsedNewFlags[enabledOldFlag])) {
          return true;
        }
        return false;
      });

      if (isIndicatorUpdated) {
        return true;
      }
    }

    const loadedNewFlags = _.difference(Object.keys(parsedNewFlags), Object.keys(parsedOldFlags)) || [];
    return _.some(loadedNewFlags, (loadedNewFlag) => {
      return parsedNewFlags[loadedNewFlag].enabled;
    });
  }

  private refreshFlagsSession(flags) {
    sessionStorage.setItem(this.sessionName, JSON.stringify(flags));
  }

  async getFeatureFlag(flagKey) {
    try {
      if (!this.isReady) {
        await this.initFlags();
        return this.getFeatureFlag(flagKey);
      }
      return new Promise<any>(async (resolve) => {
        if (this.flagsProfil && this.flagsProfil[flagKey]) {
          resolve(this.flagsProfil[flagKey]);
        }
        resolve(null);
      });
    } catch (err) {
      return Promise.resolve(null);
    }
  }

}
