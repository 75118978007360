import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AuthenticationService } from '../services/authentication.service';
import { UserPermission, UserRole, UserRolesPermissions } from './types';

@Injectable({providedIn: 'root'})
export class RolesPermissionsService {

  $userRolePermissions = this.authenticationService.authenticationState.pipe(
    filter(isAuthenticated => isAuthenticated),
    concatMap(() => {
      return from(this.apiService.getRolesPermissions());
    }
  ));

  constructor(
    private readonly authenticationService:AuthenticationService,
    private apiService: ApiService) {
  }

  public hasOneRequiredPermission(requiredPermissions: UserPermission[]): Observable<boolean> {
    return this.$userRolePermissions.pipe(map(({permissions}) => {
      return permissions.some(permission => requiredPermissions.includes(permission));
    }));
  }

  public hasAllRequiredPermissions(requiredPermissions: UserPermission[]): Observable<boolean> {
    return this.$userRolePermissions.pipe(map(({permissions}) => {
      return requiredPermissions.every(requiredPermission => permissions.includes(requiredPermission));
    }));
  }

  public isExternalVendor(): Observable<boolean> {
    return this.$userRolePermissions.pipe(map(({roles}) => {
      return roles.some(role => ['TM_EXTERNAL', 'CLOSING_EXTERNAL'].includes(role));
    }));
  }

  public isSuperUser(): Observable<boolean> {
    return this.$userRolePermissions.pipe(map(({roles}) => {
      return roles.some(role => ['SUW'].includes(role));
    }));
  }

  public hasOneRequiredRole(requiredPermissions: UserRole[]): Observable<boolean> {
    return this.$userRolePermissions.pipe(map(({roles}) => {
      return roles.some(role => requiredPermissions.includes(role));
    }));
  }
}
