import {Injectable} from '@angular/core';
import {v4 as uuid} from 'uuid';
import * as _ from 'lodash';

import {ApiService} from './api.service';
import {S3Document} from './data/document';
import {IFileInfo} from '../ui/uploadFiles/FileInfo';


@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor(private apiService: ApiService) {
  }

  getFileFromS3(aoListingId, filepath): Promise<any> {
    return this.apiService.getFileFromS3(aoListingId, filepath);
  }

  saveFilesToS3(aoListingID, files: IFileInfo[]): Promise<S3Document[]> {

    return new Promise<S3Document[]>((resolve, reject) => {
      // @ts-ignore
      const documents: S3Document[] = _.map(files, (f: File) => {
        const extension = f.name ? /(?:\.([^.]+))?$/.exec(f.name)[1] : '';
        const id = uuid();
        return {
          id: id,
          AOListingID: aoListingID,
          filename: f.name,
          filepath: id + '.' + extension,
          file: f,
          mimeType: f.type
        };
      });

      let promises = [];

      _.each(documents, (d: S3Document) => {
        promises.push(this.readFile(d.file));
      });

      Promise.all(promises).then(results => {
        promises = [];

        _.each(documents, (d: S3Document, idx) => {
          d.content = results[idx];
          promises.push(this.apiService.uploadFileToS3(d));
        });

        Promise.all(promises).then(uploadResults => {
          resolve(documents);
        }, (error) => {
          reject(error);
        });

      }, (error) => {
        reject(error);
      });
    });
  }

  async getFilesToSaveAndSaveToS3(formAttachments: IFileInfo[], AOListingID): Promise<S3Document[]> {
    const filesToSave: IFileInfo[] = formAttachments?.filter((file: IFileInfo) => !file.id);
    return await this.saveFilesToS3(AOListingID, filesToSave);
  }

  getFilesToDelete(updatedAttachments: IFileInfo[], originalAttachments: IFileInfo[], AOListingID): S3Document[] {
    const filesToDelete: S3Document[] = [];

    if (originalAttachments) {
      originalAttachments.forEach((originalFile: IFileInfo) => {
        const stillExists = updatedAttachments.find((file: IFileInfo) => file.id == originalFile.id);

        if (!stillExists) {
          filesToDelete.push({
            saveAction: 'delete',
            id: originalFile.id,
            AOListingID: AOListingID,
            filepath: originalFile.filepath
          } as S3Document);
        }
      });
    }

    return filesToDelete;
  }

  private readFile(file) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsArrayBuffer(file);
    });
  }
}
