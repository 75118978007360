import { IApproval, IRequestStyle, ITriggerEVBusEvent, IUpdateStatus } from "./IRequest";
import { IVersioning } from "./versioning";

export enum UNDERWRITER_OPTIONS_LABEL {
  DRAFT ='Draft',
  UNDERWRITE = 'Underwrite',
  SAVE_DRAFT = 'Save draft',
  SEND_TO_TM = 'Send to transaction manager',
  HIDE = 'Hide for everyone',
  GOBACK = 'MAIN ACTION',
  SUBMIT_FOR_APPROVAL = 'Submit for investor approval',
  UNDO_APPROVAL = 'Undo submit for investor approval',
  SHOW = 'Show for everyone',
   DRILLDOWN = 'Drilldown'
}

export interface IUnderwriterActions {
  /**
     * boolean to toggle send to transaction manager mechanism
     */
   active: boolean;

   action?:any;

   /**
    * an array of functions to activate this mechanism
    * if all the functions return false this mechanism is skipped
    * if validators is empty and active is true this mechanism is activated
    * @returns boolean
    */
   validators: any[];

   /**
    * options for configuring investor pre-approval
    * before sending to transaction manager
    */
   approval: IApproval;

   /**
    * options for configuring send to transaction manager version
    */
   versioning: IVersioning;

   /**
    * options for configuring event bus triggers in this step
    */
   triggerEVBusEvent: ITriggerEVBusEvent;

   /**
    * options for configuring overriding the listing status
    */
   updateStatus: IUpdateStatus;

   /**
    * used to override the comment css of update proforma mechanism
    */
   style?: IRequestStyle;
}

export enum UNDERWRITER_COMMANDS_NAME  {
  saveDraft = 'saveDraft',
  sendTM= 'sendTM',
  hide='hide',
  show='show',
  submitApproval='submitApproval',
  undoApproval ='undoApproval',
  drilldown = 'drilldown'
}

