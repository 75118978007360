import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent {
  theForm: FormGroup;

  constructor(fb: FormBuilder) {
    this.theForm = fb.group({
      email: '',
      currentDate: new Date().toISOString(),
      emptyDate: '',
      disabledDate: new Date().toISOString(),
      files: []
    });
  }

  get currentDate() {
    return this.theForm.get('currentDate').value;
  }

  get emptyDate() {
    return this.theForm.get('emptyDate').value;
  }

  get disabledDate() {
    return this.theForm.get('disabledDate').value;
  }
}
