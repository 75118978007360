export enum tagAction {
    CREATE = 'CREATE',
    ADD = 'ADD',
    REMOVE = 'REMOVE',
    DISABLE = 'DISABLE',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE'
}

export enum tagTitles {
    CREATE = 'Created',
    ADD = 'Added',
    REMOVE = 'Removed',
    DISABLE = 'Disabled',
    DELETE = 'Deleted',
    UPDATE = 'updated'
}
export interface ITag {
    tagData: {
      operation: tagAction;
      name?: string;
      id?: number;
    };
    AOListingID?: number[];
}
