import { UnderwriterPanelComponent } from "src/app/panels/underwriter/underwriter.component";
import { environment } from "src/environments/environment";
import { IExternalLink } from "../model/IRequest";
import { IRequest, IRequestStep } from "../model/requestStep";
import { STEP_ACTION, UPDATE_REQUEST_LEVEL } from "../model/updateRequest";

export function checkStatus(pattern: UnderwriterPanelComponent, activeRequests: IRequest[]): IRequest {
  const activeStatus = activeRequests.find(activeRequest => {
    return activeRequest.rules.status(pattern.currentProperty);
  });
  if (activeStatus) {
    return activeStatus;
  }
  return null;
}

export function getNextStep(pattern, activeRequest: any, newValue): IRequestStep {
  const fieldToWatch = activeRequest.fieldToWatch;

  let activeStep = activeRequest.steps.find(step => {
    if (!step.activators) {
      return false;
    }
    return step.activators.every(activator => {
      return !!activator(pattern.currentProperty, pattern.currentUwProforma, fieldToWatch, newValue);
    });
  });

  if (!activeStep) {
    activeStep = activeRequest.steps.find(step => step.stepAction == STEP_ACTION.INFO) || activeRequest.steps[0];
  }

  return activeStep;
}

export function buildExternalLink(pattern: UnderwriterPanelComponent, links: IExternalLink | string | Function) {
  if(!links) {
    return null;
  }
  if (typeof links === 'string') {
    return links;
  }
  if (typeof links === 'function') {
    return links(pattern);
  }
  if (links[environment.stage]) {
    return links[environment.stage].link;
  }
  return null;
}

export function getValueToUpdate(pattern: UnderwriterPanelComponent, activeRequest: IRequest): number {
  if (!activeRequest.rules.value) {
    return null;
  }
  return activeRequest.rules.value(pattern.currentProperty, pattern.currentUwProforma, activeRequest);
}

export function getUpdateComment(pattern: UnderwriterPanelComponent, activeRequest: IRequest): string {
  if (!activeRequest.rules.comment) {
    return null;
  }
  return activeRequest.rules.comment(pattern.currentProperty, pattern.currentUwProforma, activeRequest);
}


export function buildCustomUpdateMessage(pattern: UnderwriterPanelComponent, activeRequest: IRequest, activeStep: IRequestStep, newValue) {
  if(activeRequest.name == 'OfferReadyFlow') {
    return;
  }

  if (!newValue
    && ![
      STEP_ACTION.INFO,
      STEP_ACTION.NULL
    ].includes(activeStep.stepAction)
    && activeStep.stepAction) {
    newValue = pattern.currentUwProforma.proforma[activeRequest.fieldToWatch];
    if (pattern.currentUwProforma.proforma[activeRequest.fieldToWatch]) {
      // activeStep.proforma.message = `${activeRequest.displayName? activeRequest.displayName : activeRequest.fieldToWatch} updated: ${formatCurrency(pattern.currentUwProforma.proforma[activeRequest.fieldToWatch])}`;
      activeStep.proforma.updateProforma = false;
      activeStep.proforma.lockProforma = false;
      activeStep.activeAction.sendTM = true;
      // TODO:
      activeStep.activeAction.submitApproval = true;
      return newValue;
    }
    if (activeStep.proforma.updateProforma || activeStep.activeAction.sendTM) {
      activeStep.proforma.style = {
        messagePriority: UPDATE_REQUEST_LEVEL.ALERT
      };
      activeStep.proforma.message = `${activeRequest.displayName ? activeRequest.displayName : activeRequest.fieldToWatch} updated Value Missing`;
    }
  }
  return newValue;
}
