import { ApiService } from "../api.service";

import * as _ from 'lodash';

import { ProFormaConstants } from '../data/proforma';
import {
  PropertyStatusType,
  PropertyStatusTypeText
} from '../data/propertyStatusType';


/**
 * enum of different source used to build proforma versioning.
 * for performance reason FROM_PROFORMA is currently in use
 * @namespace
 */
export enum PROFORMA_VERSION_SOURCE {
  FROM_AUDIT_LOG = 'AUDIT_LOG',
  FROM_PROFORMA = 'PROFORMA'
}

/**
 * @interface IProformaVersionModel
 */
export interface IProformaVersionModel {
  source: PROFORMA_VERSION_SOURCE;
  data: any[];
  versionNames: string[];
}

interface IProformaVersioning {
/**
 * Build proforma versions based on version names (@param versions) and map {@link ProFormaConstants} each version can it self have multiple version
 * depending on userOverwrittenFields field
 *
 * @param versions versions to map
 * @param map object const default - {@link ProFormaConstants}.
 * @returns object representing different versions
 */
  mapVersion: (versions: any, map: any[]) => any;
/**
 *
 * @param element
 * @param logItems
 * @returns
 */
  checkVersionCriteria: (element: any, logItems: any) => string;

/**
 * Return calculated version based on grouped (by date) activityLog items (logItems)
 * @param key logItems object's key
 * @param logItems group of activityLog event (type 500)
 * @returns version Name
 * @defaultValue null
 */
  checkVersionCriteriaWithStatus: (key: any, logItems: any) => string;

 /**
 *
 * @param logItems group of activityLog event (type 500)
 * @returns status description
 */
  searchForStatus: (logItem) => string;

  /**
 *
 * @param AOListingID
 * @param source
 * @returns Promise\<IProformaVersionModel>
 */
  getAuditHistory: (AOListingID: any, source: PROFORMA_VERSION_SOURCE) => Promise<IProformaVersionModel>;

  /**
 *
 * @param AOListingID
 * @param source
 * @returns Promise \<IProformaVersionModel>
 */
  getAuditLogVersioning: (AOListingID: any, source: PROFORMA_VERSION_SOURCE) =>  Promise<IProformaVersionModel>;

  /**
   *
   * @param AOListingID
   * @param source
   * @returns Promise\<IProformaVersionModel>
   */
  getProformaVersioning: (AOListingID: any, source: PROFORMA_VERSION_SOURCE, grouped: boolean) => Promise<IProformaVersionModel>;
}


export abstract class ProformaVersioning implements IProformaVersioning {
  constructor(protected apiService: ApiService) {}

  mapVersion(versions: any, map: any[]) {
    const FinalVersion = {};
    const FinalVersionKeys = Object.keys(versions);

    FinalVersionKeys.forEach(versionName => {
      FinalVersion[versionName] = [];

      versions[versionName].forEach((vs: any[], index) => {

        FinalVersion[versionName][index] = {};
        vs.forEach(item => {

          if (map.includes(item.fieldName) && (FinalVersion[versionName][index][`${item.fieldName}`] == undefined || item.fieldName == 'userOverwrittenFields')) {
            if (item.fieldName == 'userOverwrittenFields') {
              FinalVersion[versionName][index][`${item.fieldName}`] = FinalVersion[versionName][index][`${item.fieldName}`]
                ? FinalVersion[versionName][index][`${item.fieldName}`].concat(JSON.parse(item.newValue))
                : FinalVersion[versionName][index][`${item.fieldName}`] = JSON.parse(item.newValue);

            } else {
              FinalVersion[versionName][index][`${item.fieldName}`] = item.newValue;
            }
          }
        });

        if (Array.isArray(FinalVersion[versionName][index][`userOverwrittenFields`])) {
          FinalVersion[versionName][index][`userOverwrittenFields`].reduce((accumulator, currentValue) => {
            if (accumulator.indexOf(currentValue) === -1) {
              accumulator.push(currentValue);
            }
            return accumulator;
          }, []);

          FinalVersion[versionName][index][`userOverwrittenFields`] = JSON.stringify(FinalVersion[versionName][index][`userOverwrittenFields`]);
        }


      });

    });

    return FinalVersion;
  }

  checkVersionCriteria(element: any, logItems: any) {
    const versionFound = logItems[element].find((gbd) => {
      return (gbd.eventType == 500) && (gbd.fieldName == 'VERSION');
    });
    if (versionFound) {
      if (versionFound.newValue) {
        return versionFound.newValue;
      }
      return 'OfferReady';
    }
    return null;
  }

  checkVersionCriteriaWithStatus(key: any, logItems: any) {
    let foundVf;
    logItems[key].forEach((gbd) => {
      const searchedStatus = this.searchForStatus(gbd);
      foundVf = searchedStatus ? searchedStatus : foundVf;
    });

    return foundVf;
  }

  searchForStatus(logItem) {
    if (logItem.fieldName == 'status') {
      if (parseInt(logItem.oldValue, 10) < PropertyStatusType.OfferReady &&
        [PropertyStatusType.OfferReady,
        PropertyStatusType.OfferReadyUrgent,
        PropertyStatusType.OfferReadyReason,
        PropertyStatusType.OfferReadyUrgentTM,
        PropertyStatusType.OfferReadyUrgentTMReason,]
          .includes(parseInt(logItem.newValue, 10))
      ) {
        return PropertyStatusTypeText(PropertyStatusType.OfferReady);
      }

      if (
        [PropertyStatusType.OfferReady,
        PropertyStatusType.OfferReadyUrgent,
        PropertyStatusType.OfferReadyReason,
        PropertyStatusType.OfferReadyUrgentTM,
        PropertyStatusType.OfferReadyUrgentTMReason,]
          .includes(parseInt(logItem.newValue, 10))
        &&
        ![PropertyStatusType.OfferReady,
        PropertyStatusType.OfferReadyUrgent,
        PropertyStatusType.OfferReadyReason,
        PropertyStatusType.OfferReadyUrgentTM,
        PropertyStatusType.OfferReadyUrgentTMReason,]
          .includes(parseInt(logItem.oldValue, 10))
        && parseInt(logItem.oldValue, 10) > PropertyStatusType.OfferReady

      ) {
        return PropertyStatusTypeText(parseInt(logItem.oldValue, 10));
      }

    }
    return null;


  }

  getAuditHistory(AOListingID: any, source = PROFORMA_VERSION_SOURCE.FROM_AUDIT_LOG, grouped = true): Promise<any> {
    if (source === PROFORMA_VERSION_SOURCE.FROM_AUDIT_LOG) {
      return this.getAuditLogVersioning(AOListingID, source);
    }
    return this.getProformaVersioning(AOListingID, source, grouped);
  }


  getAuditLogVersioning (AOListingID: any, source: PROFORMA_VERSION_SOURCE): Promise<IProformaVersionModel> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.getAuditHistory(AOListingID, source, false).then((auditItems: any[]) => {

        const groupByDates = _.groupBy(auditItems, 'createDate');
        const dates: any[] = _.keys(groupByDates);

        const versionObject = {};
        let lastVersionInserted;
        let subVersion = [];

        dates.forEach((element, index) => {
          const versionName = this.checkVersionCriteria(element, groupByDates);

          subVersion.push(groupByDates[element]);
          if (versionName) {
            if (versionObject[versionName]) {
              versionObject[versionName].push(subVersion);
            } else {
              versionObject[versionName] = [subVersion];
            }

            lastVersionInserted = versionName;
            subVersion = [];
          }
        });

        if(lastVersionInserted && subVersion.length> 0) {
          const missingElement = subVersion.map((vItm: any[]) => {
            return vItm.reduce((acc, current) => {
              return acc = acc.concat(current);
            }, []);
          });
          versionObject[lastVersionInserted][versionObject[lastVersionInserted].length -1 ] = (versionObject[lastVersionInserted][versionObject[lastVersionInserted].length -1 ]).concat(
            missingElement
            );
        }

        const versionObjectKeys = Object.keys(versionObject);
        const versionObjectReduced = _.cloneDeep(versionObject);

        versionObjectKeys.forEach(versionItem => {
          versionObjectReduced[versionItem] = versionObjectReduced[versionItem].map((vItm: any[]) => {
            return vItm.reduce((acc, current) => {
              return acc = acc.concat(current);
            }, []);
          });
        });

        const finaleVersions = this.mapVersion(versionObjectReduced, ProFormaConstants);
        const versionNames = Object.keys(finaleVersions || []);
        if(_.isEmpty(finaleVersions) || _.isEmpty(versionNames)) {
          resolve(null);
          return;
        }
        resolve({
          source,
          versionNames,
          data: finaleVersions
        });
      }, (error) => {
        reject(error);
      });
    });

  }

  getProformaVersioning (AOListingID: any, source: PROFORMA_VERSION_SOURCE, grouped: boolean): Promise<IProformaVersionModel> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.getAuditHistory(AOListingID, source, !grouped).then((auditItems: any[]) => {
        if (!grouped) {
          resolve(auditItems);
        }
        const versionNames = (/*mappedItems*/auditItems || []).map((vr) => {
          return vr.versionName;
        }).reduce((accumulator: any[],current) => {
          if(!accumulator.includes(current)) {
            accumulator.push(current);
          }
          return accumulator;
        },[]);
        const versionObject = {};
        /*mappedItems*/auditItems.forEach(mpItem => {
          if(!versionObject[mpItem.versionName]) {
            versionObject[mpItem.versionName] = [mpItem];
          } else {
            versionObject[mpItem.versionName].push(mpItem);
          }
        });

        if(_.isEmpty(versionObject) || _.isEmpty(versionNames)) {
          resolve(null);
          return;
        }
        resolve({
          source,
          versionNames,
          data: versionObject
        });
      }, (error) => {
        reject(error);
      });
    });

  }

}
