import * as moment from 'moment';
export class DateFormatRenderer {
  gui: HTMLElement;

  init(params) {
    const date = moment(params.value);
    const format = params.format ?? "YYYY-MM-DD";
    const defaultValue = params.defaultValue ?? "-";

    this.gui = document.createElement('span');
    this.gui.textContent = date.isValid() ? date.utc().format(format) : defaultValue;
  }

  getGui() {
    return this.gui;
  }
}
