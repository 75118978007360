import { IDependenciesTree, IProformaRow, ITargetOption } from './proforma';
import * as JsUtils from 'src/app/utils/jsUtils';
import { IUpdateRequest } from 'src/app/services/update-request-service/model/updateRequest';
import * as _ from 'lodash';

export enum VALIDATION_ERROR_TYPE {
  NONE= 0,
  REQUIRED = 1,
  PROFORMA = 2,
  WORKFLOW = 3,
  WARN = 4
}

export class ProformaConfig {
  rent: IProformaRow[];
  revenue: IProformaRow[];
  expenses: IProformaRow[];
  assetPurchase: IProformaRow[];
  extraFields: IProformaRow[];
  statusFields: IProformaRow[];
  requiredFields: IProformaRow[];
  shouldNotTriggerUWCalculationFieldsIds: string[];
  shouldNotOverwriteFields: string[];
  targetConditionButtonOptions: ITargetOption[];
  selectedEntityOptions: ITargetOption[];
  investorApprovalOptions: ITargetOption[];
  dependenciesRules: IDependenciesTree;
  availableVersions: any;

  constructor(isInvestorExport = false) {
    this.rent = [
      { id: 'monthlyRentAmount', label: 'Monthly Rent', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, isRequired: true, formatter: JsUtils.formatCurrency, validators:[{rule: this.notNullValue}] },
    ];

    this.revenue = [
        { id: 'grossPotentialRevenueAmount', label: 'Gross Potential Revenue', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
        { id: 'vacancyAllowanceAmount', label: 'Vacancy Allowance', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'creditLossAmount', label: 'Credit Loss', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'rentalConcessionAmount', label: 'Rental Concession', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
        { id: 'otherIncomeAmount', label: 'Other Income', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
        { id: 'netRevenueAmount', label: 'Net Revenue', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
    ];

    this.expenses = [
        { id: 'taxesAmount', label: 'Taxes', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'propertyManagementFeesAmount', label: 'Property Management Fees', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'maintenanceTurnoverAmount', label: 'Maintenance & Turnover', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'insuranceAmount', label: 'Insurance', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
        { id: 'hoaAmount', label: 'HOA', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'leasingCostsAmount', label: 'Leasing Costs', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'totalExpensesAmount', label: 'Total Expenses', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
        { id: 'netOperationIncomeAmount', label: 'Net Operation Income', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency, css: 'a1-proforma-row-total' },
        { id: 'recurringCapExAmount', label: 'Recurring Capex', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
        { id: 'netCashFlowAmount', label: 'Net Cash Flow', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency, css: 'a1-proforma-row-total' },
    ];

    this.assetPurchase = [
        { id: 'priceOfferAmount', label: 'Purchase Price Offer', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'repairBudgetAmount', label: 'Rehab Budget', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'carryCostAmount', label: 'Carry Cost', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
        { id: 'acquisitionCostAmount', label: 'Acquisition Cost', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'buyerPaidCommission', label: 'Buyer Paid Commission', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'stabilizationFeeAmount', label: (isInvestorExport ? 'A1 Fee' : 'A1 Stabilization Fee'), avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency },
        { id: 'totalLandedCostAmount', label: 'Total Landed Cost', avmDisplay: '', uwDisplay: '', diff: '0.0%', formatter: JsUtils.formatCurrency },
        { id: 'capRate', label: 'Net Cap Rate', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: false, formatter: JsUtils.formatPercent2, css: 'a1-proforma-row-total' },
        { id: 'netYield', label: 'Net Yield', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: false, formatter: JsUtils.formatPercent2, css: 'a1-proforma-row-total' },
    ];

    this.extraFields = [
        { id: 'highestPurchasePriceOffer', label: 'Highest Purchase Price Offer', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency, isRequired: true, hasDependencies: true, validators:[{rule: this.notNullValue, errorMessage: 'You cannot submit an empty Highest Purchase Price Offer field or one of $0'}] },
        { id: 'expectedResaleValue', label: 'EXPECTED RESALE VALUE', avmDisplay: '', uwDisplay: '', diff: '0.0%', editable: true, formatter: JsUtils.formatCurrency, isRequired: false },
    ];

    this.statusFields = [
        { id: 'recommendedConcession', label: 'Recommended Concession', avmDisplay: '', uwDisplay: '', diff: null, editable: true, formatter: JsUtils.formatCurrency, isRequired: true, hasDependencies: true, validators:[{rule: this.notNullValue}] },
        { id: 'minimumAcceptableConcession', label: 'Min. Acceptable Concession', avmDisplay: '', uwDisplay: '',hasError: VALIDATION_ERROR_TYPE.NONE ,diff: null, editable: true, formatter: JsUtils.formatCurrency, isRequired: true, hasDependencies: true }
    ];


    this.shouldNotTriggerUWCalculationFieldsIds = [
        'purchasingEntity',
        ...this.extraFields.map(extrafieldItem => {
            return extrafieldItem.id;
        }),
    ];

    this.shouldNotOverwriteFields = [
        ...this.rent.map(extrafieldItem => {
            return extrafieldItem.id;
        }),
    ];

    this.requiredFields = [...this.rent, ...this.revenue, ...this.expenses, ...this.assetPurchase, ...this.extraFields].filter(field => {
        return field.isRequired === true;
    });

    this.targetConditionButtonOptions = [
        { id: null, label: '-' },
        { id: 1, label: 1 },
        { id: 2, label: 2 },
        { id: 3, label: 3 },
        { id: 4, label: 4 },
        { id: 5, label: 5 },
        { id: 6, label: 6 }
    ];

    this.selectedEntityOptions = [
        { id: 'selectedEntity1', label: 'RS Rental I, LLC', disabled: true, hidden: true },
        { id: 'selectedEntity3', label: 'RS Rental II, LLC' },
        { id: 'selectedEntity2', label: 'YS Avon SFR III Propco LLC' },
    ];

    this.investorApprovalOptions = [
        { id: 1, label: 'Yes, I have investor approval' },
        { id: 0, label: 'No, I do not have investor approval' },
    ];

    this.dependenciesRules = {
        'recommendedConcession': {
          proforma: [
              {
                  field: 'minimumAcceptableConcession',
                  rules: [this.proformaMinimumValue],
                  reverse: true
              }
          ],
        }
        ,'minimumAcceptableConcession': {
          proforma: [
                {
                    field: 'recommendedConcession',
                    rules: [this.proformaMinimumValue]
                }
            ],
        }
        ,'highestPurchasePriceOffer': {
          workflow: [
            {
              flowName: 'counterOfferFlow',
              rules: [this.workFlowMinimumValue],
              errorMessage: 'Your offer is higher than the Seller\'s Offer'
            }
        ]
        }
    };

    this.availableVersions = {
        avmProforma: 'avmProforma',
        OfferReady: 'OfferReady',
        RehabBudget: 'RehabBudget', // A1-1207 rehabBudget Investor flow
    };
  }

  required(field, currentValue, dependencyIndex, extraField, allFields, proforma, reverse?) {
    if (currentValue) {
        extraField[dependencyIndex].isRequired = true;
        allFields = allFields.map(requiredItem => {
            if(requiredItem.id === extraField[dependencyIndex].id) {
                requiredItem.isRequired = true;
            }
            return requiredItem;
        });
        return;
    }
    extraField[dependencyIndex].isRequired = false;
    allFields = allFields.map(requiredItem => {
        if(requiredItem.id === extraField[dependencyIndex].id) {
            requiredItem.isRequired = false;
            requiredItem.hasError = VALIDATION_ERROR_TYPE.NONE;
        }
        return requiredItem;
    });
  }


  proformaMinimumValue(field, currentValue, dependencyIndex, extraField, allFields, uwProforma, reverse?) {
      if(!reverse) {
              if(uwProforma.proforma[extraField[dependencyIndex].id] < currentValue ) {
                  allFields = allFields.map(requiredItem => {
                    if(requiredItem.id === field.id) {
                          requiredItem.hasError = VALIDATION_ERROR_TYPE.PROFORMA;
                      }
                      return requiredItem;
                  });
                  extraField = extraField.map(extraItem => {
                      if(extraItem.id === field.id) {
                          extraItem.hasError = VALIDATION_ERROR_TYPE.PROFORMA;
                      }
                      return extraItem;
                  });

              } else {
                  extraField = extraField.map(extraItem => {
                      if(extraItem.id === field.id && extraItem.hasError == VALIDATION_ERROR_TYPE.PROFORMA) {
                          extraItem.hasError = VALIDATION_ERROR_TYPE.NONE;
                      }
                      return extraItem;
                  });
              }
          return;
      }

      if(!currentValue && !uwProforma.proforma[extraField[dependencyIndex].id]) {
          return;
      }

      if(!currentValue || currentValue < uwProforma.proforma[extraField[dependencyIndex].id]) {
          extraField[dependencyIndex].hasError = VALIDATION_ERROR_TYPE.PROFORMA;

          allFields = allFields.map(requiredItem => {
              if(requiredItem.id === extraField[dependencyIndex].id) {
                  requiredItem.hasError = VALIDATION_ERROR_TYPE.PROFORMA;
              }
              return requiredItem;
          });

          return;
      } else {
          if (extraField[dependencyIndex].hasError ==  VALIDATION_ERROR_TYPE.PROFORMA) {
            extraField[dependencyIndex].hasError = VALIDATION_ERROR_TYPE.NONE;
          }

              allFields = allFields.map(requiredItem => {
                  if(requiredItem.id === extraField[dependencyIndex].id && requiredItem.hasError == VALIDATION_ERROR_TYPE.PROFORMA) {
                      requiredItem.hasError = VALIDATION_ERROR_TYPE.NONE;
                  }
                  return requiredItem;
              });

              return;
      }
  }

  notNullValue(currentValue) {
      return currentValue? currentValue > 0 : false;
  }

  workFlowMinimumValue(field, currentValue, oldValue, referenceValues, extraField, allFields, updateRequest: IUpdateRequest, errorMessage, erroLevel) {
    const errorType = erroLevel? erroLevel : VALIDATION_ERROR_TYPE.WORKFLOW;
    let originalValue;
    const value = getFieldChanges(referenceValues, field.id);
    if(value) {
      originalValue = value;
    } else {
      originalValue = oldValue;
    }

    if ((currentValue!= originalValue) && (updateRequest.value < currentValue)) {
      allFields = allFields.map(requiredItem => {
        if (requiredItem.id === field.id) {
          requiredItem.hasError = errorType;
          requiredItem.css += " a1-proforma-flow-error";
          requiredItem.toolTip = errorMessage;
        }
        return requiredItem;
      });
      extraField = extraField.map(extraItem => {
        if (extraItem.id === field.id) {
          extraItem.hasError = errorType;
          extraItem.css += " a1-proforma-flow-error";
          extraItem.toolTip = errorMessage;
        }
        return extraItem;
      });

    } else {
      extraField = extraField.map(extraItem => {
        if (extraItem.id === field.id && extraItem.hasError == errorType) {
          extraItem.hasError = VALIDATION_ERROR_TYPE.NONE;
          extraItem.toolTip = null;
        }
        extraItem.css = (extraItem.css || '').replaceAll("a1-proforma-flow-error", '');
        return extraItem;
      });
    }
  }

}


export enum PROFORMA_FIELDS_IDS {
    TARGET_CONDITION_SCORE = 'targetConditionScore',
    MONTHLY_RENT_AMOUNT = 'monthlyRentAmount',
    GROSS_POTENTIAL_REVENUE_AMOUNT = 'grossPotentialRevenueAmount',
    VACANCY_ALLOWANCE_AMOUNT = 'vacancyAllowanceAmount',
    CREDIT_LOSS_AMOUNT = 'creditLossAmount',
    RENTAL_CONCESSION_AMOUNT = 'rentalConcessionAmount',
    OTHER_INCOME_AMOUNT = 'otherIncomeAmount',
    NET_REVENUE_AMOUNT = 'netRevenueAmount',
    TAXES_AMOUNT = 'taxesAmount',
    PROPERTY_MANAGEMENT_FEES_AMOUNT = 'propertyManagementFeesAmount',
    MAINTENANCE_TURNOVER_AMOUNT = 'maintenanceTurnoverAmount',
    INSURANCE_AMOUNT = 'insuranceAmount',
    HOA_AMOUNT = 'hoaAmount',
    LEASING_COSTS_AMOUNT = 'leasingCostsAmount',
    TOTAL_EXPENSES_AMOUNT = 'totalExpensesAmount',
    NET_OPERATION_INCOME_AMOUNT = 'netOperationIncomeAmount',
    RECURRING_CAPEX_AMOUNT = 'recurringCapExAmount',
    NET_CASH_FLOWA_MOUNT = 'netCashFlowAmount',
    PRICE_OFFER_AMOUNT = 'priceOfferAmount',
    REPAIR_BUDGET_AMOUNT = 'repairBudgetAmount',
    CARRY_COST_AMOUNT = 'carryCostAmount',
    ACQUISITION_COST_AMOUNT = 'acquisitionCostAmount',
    STABILIZATION_FEE_AMOUNT = 'stabilizationFeeAmount',
    TOTAL_LANDED_COST_AMOUNT = 'totalLandedCostAmount',
    CAP_RATE = 'capRate',
    NET_YIELD = 'netYield',
    HIGHEST_PURCHASE_PRICE_OFFER = 'highestPurchasePriceOffer',
    EXPECTED_RESALE_VALUE = 'expectedResaleValue',
    RECOMMENDED_CONCESSION = 'recommendedConcession',
    MINIMUM_ACCEPTABLE_CONCESSION = 'minimumAcceptableConcession',
    INVESTOR_APPROVAL = 'investorApproval',
    BUYER_PAID_COMMISSION = 'buyerPaidCommission'
}

export function getFieldChanges(referenceValues:any, fieldId: any) {
  const events = Object.keys(referenceValues || {});
  const found = events.find(ev => {
    const updatedFields = Object.keys(referenceValues[ev] || {});
    return updatedFields.includes(fieldId);
  });

  return found? !_.isNil(referenceValues[found][fieldId].newValue)? referenceValues[found][fieldId].newValue : referenceValues[found][fieldId].oldValue : null;
}
