export const listingFieldsToWatch = [
  'Beds',
  'FullBaths',
  'HalfBaths',
  'TotalBaths',
  'YearBuilt',
  'LivingAreaSqFt',
  'TMStatus',
  'subStatus',
  'inspectionStatus'
];

export const fileTypeStrings = {
  concession: "Concession Request",
  concessionSellerDecision: "Seller Decision",
  sellerCounter: "Seller Counter Letter",
  initialOffer: "Offer Letter",
  completeInspection: "Inspection Report",
  acceptedSeller: "Seller Decision",
  acceptedContract: "Contract",
  acceptedOffer: "Seller Decision",
  counterOffer: "Offer Letter",
  counterSellerCounter: "Seller Counter Letter",
  terminateOffer: "Contract",
  acceptedCounterSeller: "Seller Counter Letter",
  acceptedCounterContract: "Contract",
  titleReport: "Title Report",
  concessionDeclinedLetter: "Concession Declined Letter",
  dueDiligenceAmendmentDocument: "Due Diligence Amendment Document",
};

export const auditAndUpdateExcludedFields = ['updateIdentityId', 'updateDate'];
