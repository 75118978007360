//
// DB Object
// Use the ProFormaModel class for any extra data needed that are not saved to DB
export class ProForma {
  id: number = null;
  AOListingID: number = null;

  expectedResaleValue: number = null;
  targetConditionScore: number = null;

  // Revenue
  monthlyRentAmount: number = null;
  grossPotentialRevenueAmount: number = null;
  vacancyAllowanceAmount: number = null;
  creditLossAmount: number = null;
  rentalConcessionAmount: number = null;
  otherIncomeAmount: number = null;
  netRevenueAmount: number = null;

  // Expenses
  taxesAmount: number = null;
  propertyManagementFeesAmount: number = null;
  maintenanceTurnoverAmount: number = null;
  insuranceAmount: number = null;
  hoaAmount: number = null;
  leasingCostsAmount: number = null;
  totalExpensesAmount: number = null;
  netOperationIncomeAmount: number = null;
  recurringCapExAmount: number = null;
  netCashFlowAmount: number = null;

  // Asset Purchase
  priceOfferAmount: number = null;
  repairBudgetAmount: number = null;
  carryCostAmount: number = null;
  acquisitionCostAmount: number = null;
  stabilizationFeeAmount: number = null;
  buyerPaidCommission: number = null;
  totalLandedCostAmount: number = null;

  capRate: number = null;
  netYield: number = null;
  userOverwrittenFields: string = null;

  highestPurchasePriceOffer: number = null;
  purchasingEntity: string = null;
  buyBoxClassification: string = null;
  interimBuyBoxClassification: string = null;
  recommendedConcession: number = null;
  minimumAcceptableConcession: number = null;
  investorApproval: number = null;
  modelUpload: any;
}

export class ProFormaDownload {
  body: string;
  contentType: string;
  filename: string;
}

export class ExtendedProforma  extends ProForma {
  sendToTransactionManager: boolean = null;
  pendingEvents: any = null;
  workFlowNote: string = null;
}

export interface IOnHold {
  aoListingID?: string;
  user?: any;
  address?: string;
  lastUpdateDate?: string;
}

export type DUPLICATE_SOURCE = 'listing' | 'proforma';
export interface IDuplicateSubmission {
  onHold: IOnHold[];
  multiplePopup?: boolean;
  origin?: DUPLICATE_SOURCE;
}
export class IProformaSaveModel {
  proforma: ExtendedProforma;
  onHold: IOnHold;
}

export interface IProformaNetYield {
  purchasingEntity: string,
  region: string,
  UQClassification: string,
}
/**
 * array of different proforma fields {@link ProFormaConstants}
 */
export const ProFormaConstants  = [
  'id',
  'AOListingID',

  'expectedResaleValue',
  'targetConditionScore',

  // Revenue
  'monthlyRentAmount',
  'grossPotentialRevenueAmount',
  'vacancyAllowanceAmount',
  'creditLossAmount',
  'rentalConcessionAmount',
  'otherIncomeAmount',
  'netRevenueAmount',

  // Expenses
  'taxesAmount',
  'propertyManagementFeesAmount',
  'maintenanceTurnoverAmount',
  'insuranceAmount',
  'hoaAmount',
  'leasingCostsAmount',
  'totalExpensesAmount',
  'netOperationIncomeAmount',
  'recurringCapExAmount',
  'netCashFlowAmount',

  // Asset Purchase
  'priceOfferAmount',
  'repairBudgetAmount',
  'carryCostAmount',
  'acquisitionCostAmount',
  'stabilizationFeeAmount',
  'totalLandedCostAmount',

  'capRate',
  'netYield',
  'userOverwrittenFields',

  'highestPurchasePriceOffer',
  'purchasingEntity',
  'recommendedConcession',
  'minimumAcceptableConcession',
  'userOverwrittenFields'
];
