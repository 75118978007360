import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';

 /** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService {
  // Observables
  private _navigationEndedBehavior = new Subject<string>();
  navigationEnded = this._navigationEndedBehavior.asObservable();

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        // console.log(`NAVIGATION: "${this.previousUrl}" to "${this.currentUrl}"`);

        this._navigationEndedBehavior.next(this.currentUrl);
      }
    });
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
}
