import {ProFormaModel} from "src/app/models/proformaModel";
import {ListingProperty} from "../../data/listingPropety";
import {PropertyStatusType} from "../../data/propertyStatusType";
import {CONCESSION_ACTION, UNDERWRITER_ACTIONS} from "../model/updateRequest";
import {IRequest} from "../model/requestStep";
import {VERSION_NAME} from "../model/versioning";
import * as _ from 'lodash';
import {PROFORMA_FIELDS_IDS} from "src/app/panels/proforma/proforma_config";
import {UNDERWRITER_COMMANDS_NAME, UNDERWRITER_OPTIONS_LABEL} from "../model/underwriterCommandsAndActions";


function lostDeadFlowStatusValidation(currentProperty: ListingProperty) {
  return [
    PropertyStatusType.LostDead
  ].includes(currentProperty.status);
}

function lostDeadFlowValuesValidation(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
  if (currentUwProforma.proforma[fieldToWatch] != currentProperty?.finalPurchasePriceOffer) {
    return currentProperty?.finalPurchasePriceOffer;
  }
  return false;
}

function lostDead(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
  return !!_.get(currentUwProforma, 'proforma.investorApproval', null);
}


function lostDeadStatus(currentProperty: ListingProperty, currentUwProforma: ProFormaModel, fieldToWatch: any) {
  return [
    PropertyStatusType.LostDead,
  ].includes(currentProperty.status);
}


const LostDeadOverwriteFields = [
  PROFORMA_FIELDS_IDS.TARGET_CONDITION_SCORE,
  PROFORMA_FIELDS_IDS.MONTHLY_RENT_AMOUNT,
  PROFORMA_FIELDS_IDS.GROSS_POTENTIAL_REVENUE_AMOUNT,
  PROFORMA_FIELDS_IDS.VACANCY_ALLOWANCE_AMOUNT,
  PROFORMA_FIELDS_IDS.CREDIT_LOSS_AMOUNT,
  PROFORMA_FIELDS_IDS.RENTAL_CONCESSION_AMOUNT,
  PROFORMA_FIELDS_IDS.OTHER_INCOME_AMOUNT,
  PROFORMA_FIELDS_IDS.NET_REVENUE_AMOUNT,
  PROFORMA_FIELDS_IDS.TAXES_AMOUNT,
  PROFORMA_FIELDS_IDS.PROPERTY_MANAGEMENT_FEES_AMOUNT,
  PROFORMA_FIELDS_IDS.MAINTENANCE_TURNOVER_AMOUNT,
  PROFORMA_FIELDS_IDS.INSURANCE_AMOUNT,
  PROFORMA_FIELDS_IDS.HOA_AMOUNT,
  PROFORMA_FIELDS_IDS.LEASING_COSTS_AMOUNT,
  PROFORMA_FIELDS_IDS.TOTAL_EXPENSES_AMOUNT,
  PROFORMA_FIELDS_IDS.NET_OPERATION_INCOME_AMOUNT,
  PROFORMA_FIELDS_IDS.RECURRING_CAPEX_AMOUNT,
  PROFORMA_FIELDS_IDS.NET_CASH_FLOWA_MOUNT,
  PROFORMA_FIELDS_IDS.PRICE_OFFER_AMOUNT,
  PROFORMA_FIELDS_IDS.REPAIR_BUDGET_AMOUNT,
  PROFORMA_FIELDS_IDS.CARRY_COST_AMOUNT,
  PROFORMA_FIELDS_IDS.ACQUISITION_COST_AMOUNT,
  PROFORMA_FIELDS_IDS.STABILIZATION_FEE_AMOUNT,
  PROFORMA_FIELDS_IDS.TOTAL_LANDED_COST_AMOUNT,
  PROFORMA_FIELDS_IDS.CAP_RATE,
  PROFORMA_FIELDS_IDS.NET_YIELD,
  PROFORMA_FIELDS_IDS.HIGHEST_PURCHASE_PRICE_OFFER,
  PROFORMA_FIELDS_IDS.EXPECTED_RESALE_VALUE,
  PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION,
  PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION,
];

export const lostDeadFlow: IRequest = {
  name: 'lostDeadFlow',
  displayName: 'Purchase Price Offer',
  fieldToWatch: PROFORMA_FIELDS_IDS.PRICE_OFFER_AMOUNT,
  externalLink: null,
  requester: null,

  rules: {
    status: lostDeadFlowStatusValidation,
    value: lostDeadFlowValuesValidation,
    comment: null
  },

  steps: [
    {
      stepAction: null,
      listingName: 'Lost/Dead',
      listingIcon: null,
      level: null,
      activators: [lostDeadStatus, lostDead],
      activeAction: {
        sendTM: false,
        saveDraft: false,
        hide: false
      },
      DisableHideForEveryOne: true,
      multipleActions: null,
      proforma: {
        lockProforma: false,
        updateProforma: false,
        forceUpdate: false,
        updateProformaLabel: null,
        editLocked: true,
        editLockOverrides: LostDeadOverwriteFields,
        updateOnChanges: false,
        message: null,
        style: {
          updateIcon: 'active-property'
        }
      },
      commands: {
        underwriter: {
          options: {
            draft: {
              id: UNDERWRITER_ACTIONS.DRAFT,
              label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT,
              disabled: true,
              main: true,
              actionID: UNDERWRITER_COMMANDS_NAME.saveDraft
            }
          },
          builder: null
        },
        panel: {
          triggerConcessionFields: {
            active: false,
            action: CONCESSION_ACTION.UPDATE,
            commands: {
              active: false,
              nextAction: null,
              previousAction: null,
              actionName: null
            },
            validators: null
          },
          preApproval: {
            active: false
          },
        }
      },

      actions: {
        saveDraft: {
          active: true,
          approval: null,
          validators: null,
          versioning: {
            active: true,
            update: true,
            versionName: VERSION_NAME.LOST_DEAD,
            validators: null
          },
          triggerEVBusEvent: null,
          updateStatus: {
            active: false,
            status: null,
            validators: null,
            fallBackStatus: null
          }
        },

        sendTM: {
          active: false,
          approval: {
            active: false,
            options: [
              {id: 1, label: 'Yes, I have investor approval'},
            ],
            preSelectedOption: 1
          },
          validators: null,
          versioning: {
            active: true,
            update: true,
            versionName: VERSION_NAME.LOST_DEAD,
            previousVersion: VERSION_NAME.REHAB_BUDGET,
            validators: null
          },
          triggerEVBusEvent: null,
          updateStatus: {
            active: false,
            status: PropertyStatusType.LostDead,
            validators: null,
            fallBackStatus: null
          }
        }
      }
    },
  ]
};
