import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService, ApiService } from 'src/app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: 'changePassword.component.html'
})
export class ChangePasswordComponent implements OnDestroy, OnInit {
  loginForm: FormGroup;
  formValid: boolean = false;
  isBusy: boolean = false;
  hasError: boolean = false;

  success: boolean = false;
  email: string;
  invitationId: string;

  private subscription: Subscription = null;

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password1: ['', [Validators.required]],
      password2: ['', [Validators.required]],
    });

    this.subscription = this.activatedRoute
      .queryParamMap
      .subscribe(params => {

        this.email = params.get('email');
        this.invitationId = params.get('id');

      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription = null;
  }

  async changePassword() {
    console.log('click');
    if (this.loginForm.valid) {
      this.hasError = false;
      this.isBusy = true;

      try {
        await this.apiService.changePassword(this.loginForm.get('password1').value.trim());
        this.success = true;
        this.isBusy = false;
      } catch (err) {
        console.log('Change Password Error', err);
        this.hasError = true;
        this.isBusy = false;
      }
    }
  }


  validateForm() {
    this.formValid = this.loginForm.value.password1.trim().length >= 8 && this.loginForm.value.password1.trim() == this.loginForm.value.password2.trim();
  }

}
