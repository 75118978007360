import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

import * as AWS from 'aws-sdk';
import { ISigV4Credentials, Sigv4Http } from './sigv4.service';
import { Router } from "@angular/router";


interface IConfig {
  accessKey: any;
  secretKey: any;
  sessionToken: any;
  region: any;
  apiKey?: any;
  defaultContentType: any;
  defaultAcceptType: any;
}


class AwsConfig {
  accessKey: string;
  secretKey: string;
  sessionToken: string;
  region: string;
  apiKey: string;
  defaultContentType: string;
  defaultAcceptType: string;
}

export class AwsCredentials {
  accessControl: string;
  region: string;
  identityId: string;
  logins: any;
  fname: string;
  lname: string;
  email: string;
  password: string;
  // AWS expiration token
  expiration: number;
  // Cognito expiration token
  tokenExpiration: number;
  config: AwsConfig;
}

@Injectable({
  providedIn: 'root'
})
export class AwsCredentialsService {
  static STORAGE_ID = 'credentials';
  static LOGIN_TOKEN_EXPIRED = 'Login token expired';

  awsCredentials: AwsCredentials = new AwsCredentials();

  loginTimeToLive = 24 * 60 * 60 * 1000; // 24 hours

  constructor(
    private configService: ConfigService,
    protected sigv4: Sigv4Http,
    private router: Router,
  ) {
    this.awsCredentials.tokenExpiration = Date.now() + this.loginTimeToLive;
    this.awsCredentials.config = new AwsConfig();
    this.awsCredentials.config.accessKey = '';
    this.awsCredentials.config.secretKey = '';
    this.awsCredentials.config.sessionToken = '';
    this.awsCredentials.config.apiKey = undefined;
    this.awsCredentials.config.region = this.configService.getRegion();
    this.awsCredentials.config.defaultAcceptType = 'application/json';
    this.awsCredentials.config.defaultContentType = 'application/json';
  }

  login(email: string, password: string): Promise<AwsCredentials> {
    let userToken: string;

    return new Promise((resolve, reject) => {

      this.sigv4.post(this.configService.getServerUrl(), 'login', { email, password }, this.getUnauthorizedConfig())
        .subscribe(
          (data) => {
            const response = data.body;
            userToken = response.openIdToken;

            this.awsCredentials.email = email;
            this.awsCredentials.password = password;
            this.awsCredentials.fname = response.firstName;
            this.awsCredentials.lname = response.lastName;

            this.awsCredentials.region = this.awsCredentials.config.region = this.configService.getRegion();
            this.awsCredentials.identityId = response.identityId;
            this.awsCredentials.logins = {
              'cognito-identity.amazonaws.com': userToken
            };

            this.setAWSSdkCredentials({
              region: this.awsCredentials.region,
              identityId: this.awsCredentials.identityId,
              login: this.awsCredentials.logins
            });

            this.getAWSCredentialsFromAWS(this.awsCredentials.identityId, this.awsCredentials.logins)
              .then((awsCredentials: any) => {
                this.resetLoginToken();
                const localStorageAwsCredentials = this.storeCredentialsToLocalStorage(awsCredentials);
                resolve(localStorageAwsCredentials);
              })
              .catch(error => {
                console.log(JSON.stringify(error));
                console.log(error);
                if (error.code === 'MissingRequiredParameter') {
                  reject('Invalid email/password');
                } else {
                  reject(error);
                }
              });
          },
          (err) => {
            console.log(err);
            reject(err);
          });
    });
  }

  logout() {
    localStorage.removeItem(AwsCredentialsService.STORAGE_ID);
  }

  refreshCredentials(): Promise<ISigV4Credentials> {
    return new Promise<ISigV4Credentials>((resolve, reject) => {
      this.refreshCredentialsFromLocalStorage().then((awsCreds: AwsCredentials) => {

        resolve(this.getSigV4Credentials(awsCreds));

      }, (error) => {
        if (error === AwsCredentialsService.LOGIN_TOKEN_EXPIRED) {
          this.router.navigate(['/login']);
        }
        reject(error);
      });
    });
  }


  getUnauthorizedCredentials(): ISigV4Credentials {
    const config = this.getUnauthorizedConfig();
    return {
      // accessKey: config.accessKey,
      // secretKey: config.secretKey,
      sessionToken: config.sessionToken,
      // serviceName: 'execute-api',
      region: config.region,
      // defaultContentType: config.defaultContentType,
      // defaultAcceptType: config.defaultAcceptType
    };
  }

  getProfile() {
    const creds: AwsCredentials = this.getAWSCredentialsFromLocalStorage();
    if (creds) {
      return {
        firstName: creds.fname,
        lastName: creds.lname,
        email: creds.email,
        identityId: creds.identityId,
      };
    }
    return {};
  }

  private refreshCredentialsFromLocalStorage(): Promise<AwsCredentials> {
    return new Promise((resolve, reject) => {

      const creds = this.getAWSCredentialsFromLocalStorage();
      if (creds.email) {

        // Check if the cognito credentials are still valid
        if ((creds.tokenExpiration > 0 && creds.tokenExpiration < Date.now() + 10000) || !creds || !creds.logins) {
          return reject(AwsCredentialsService.LOGIN_TOKEN_EXPIRED);

          // TODO: implement refresh token
          // this.login(creds.email, creds.password)
          //   .then((newCredentials: AwsCredentials) => {
          //     this.storeCredentialsToLocalStorage(newCredentials);
          //     resolve(newCredentials);
          //   })
          //   .catch((error) => {
          //     reject(error);
          //   });
        } else if (creds.expiration > 0 && creds.expiration < Date.now() + 10000) {
          this.getAWSCredentialsFromAWS(creds.identityId, creds.logins)
            .then((awsCredentials: string) => {
              resolve(this.storeCredentialsToLocalStorage(awsCredentials));
            });
        } else {
          resolve(this.getAWSCredentialsFromLocalStorage());
        }
      } else {
        reject('no email');
      }
    });
  }

  private setAWSSdkCredentials(credentials: { region: any; identityId: any; login: any; }) {
    // Initialize the AWS config
    AWS.config.region = credentials.region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityId: credentials.identityId,
      Logins: credentials.login
    });
  }

  private getAWSCredentialsFromAWS(identityId: any, logins: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const cognitoidentity = new AWS.CognitoIdentity({ region: this.configService.getRegion() });
      const params = { IdentityId: identityId, Logins: logins };
      cognitoidentity.getCredentialsForIdentity(params, (err: any, awsCredentials: unknown) => {
        if (err) {
          reject(err);
        } else {
          resolve(awsCredentials);
        }
      });
    });
  }

  private getUnauthorizedConfig(): IConfig {
    const unathorizedConfig = {
      accessKey: '',
      secretKey: '',
      sessionToken: '',
      region: this.configService.getRegion(),
      apiKey: undefined,
      defaultContentType: 'application/json',
      defaultAcceptType: 'application/json',
    } as IConfig;

    return unathorizedConfig;
  }

  private getSigV4Credentials(creds: AwsCredentials): ISigV4Credentials {
    return {
      secretAccessKey: creds.config.secretKey,
      accessKeyId: creds.config.accessKey,
      sessionToken: creds.config.sessionToken,
      // serviceName: 'execute-api',
      region: creds.config.region,
      // defaultContentType: config.defaultContentType,
      // defaultAcceptType: config.defaultAcceptType
    };
  }

  /**
   * Save current user credentials
   * @param credentials Can be an object from AWS { Credentials, IdentityId } or our AwsCredentials
   */
  private storeCredentialsToLocalStorage(credentials: any): AwsCredentials {
    this.awsCredentials.config.accessKey = (credentials.Credentials) ? credentials.Credentials.AccessKeyId : credentials.config.accessKey;
    this.awsCredentials.config.secretKey = (credentials.Credentials) ? credentials.Credentials.SecretKey : credentials.config.secretKey;
    this.awsCredentials.config.sessionToken = (credentials.Credentials) ? credentials.Credentials.SessionToken : credentials.config.sessionToken;
    this.awsCredentials.identityId = (credentials.IdentityId) ? credentials.IdentityId : credentials.identityId; // note the Case for IdentityId vs identityId !
    this.awsCredentials.expiration = (credentials.Credentials) ?
      new Date(credentials.Credentials.Expiration).getTime() :
      new Date(credentials.expiration).getTime();

    const credentialsToStore = { ...this.awsCredentials };
    delete credentialsToStore.password;
    localStorage.setItem(AwsCredentialsService.STORAGE_ID, JSON.stringify(credentialsToStore));

    return this.awsCredentials;
  }

  private getAWSCredentialsFromLocalStorage(): AwsCredentials {
    let creds = JSON.parse(localStorage.getItem(AwsCredentialsService.STORAGE_ID)) as AwsCredentials;

    if (creds) {
      this.awsCredentials = creds;
    } else {
      creds = this.awsCredentials;
    }

    return creds;
  }

  private resetLoginToken() {
    this.awsCredentials.tokenExpiration = Date.now() + this.loginTimeToLive;
  }
}
