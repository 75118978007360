import { Component, Input, Output, EventEmitter, ViewContainerRef, NgZone, TemplateRef, HostBinding } from '@angular/core';

import { createPopper } from '@popperjs/core';


@Component({
  selector: 'app-sort-dropdown',
  styleUrls: ['./sortDropdown.component.scss'],
  templateUrl: './sortDropdown.component.html'
})
export class SortDropdownComponent {
  @HostBinding('class') class = 'a1-sort-dropdown';


  private _sortModel: any[];
  @Input()
  get sortModel(): any[] {
    return this._sortModel;
  }
  set sortModel(values: any[]) {
    this._sortModel = values;
    this.updateModel();
  }

  @Output() closed = new EventEmitter();
  @Output() sortModelChanged: EventEmitter<any> = new EventEmitter();

  optionRightIconClass;
  label;
  leftIconClass;
  sortIcon;

  isOpen = false;
  view: any;
  backdropView: any;
  popperRef: any;

  constructor(private vcr: ViewContainerRef, private zone: NgZone) { }

  updateModel() {
    this.sortIcon = '';

    if (!this._sortModel || this._sortModel.length == 0) {
      this.label = null;
      return;
    }

    if (this._sortModel.length == 1) {
      this.label = this._sortModel[0].display;

      if (this._sortModel[0].sort == 'asc') {
        this.sortIcon = 'arrow-up';
      } else if (this._sortModel[0].sort == 'desc') {
        this.sortIcon = 'arrow-down';
      }

    } else {
      this.label = `${this._sortModel.length} fields`;
    }
  }

  openDropdown(dropdownTpl: TemplateRef<any>, origin: any, backdropTpl: TemplateRef<any>, event: MouseEvent) {

    if (this.isOpen) {
      this.close();
      return;
    }

    event.preventDefault();
    event.stopPropagation();


    this.backdropView = this.vcr.createEmbeddedView(backdropTpl);
    const bd = this.backdropView.rootNodes[0];
    document.body.appendChild(bd);

    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const dropdown = this.view.rootNodes[0];

    document.body.appendChild(dropdown);
    dropdown.style.width = `${origin.offsetWidth}px`;
    dropdown.style.minWidth = `${origin.offsetWidth}px`;

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, dropdown, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
          {
            name: "autoWidth",
            enabled: true,
            fn: ({ state }) => {
              state.styles.popper.width = 'auto';
            },
            phase: "beforeWrite",
            requires: ["computeStyles"],
          }
        ]

      });
    });

    this.isOpen = true;
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;

      this.closed.emit();
      this.popperRef.destroy();
      this.view.destroy();
      // this.searchControl.patchValue('');
      this.view = null;
      this.popperRef = null;

      this.backdropView.destroy();
      this.backdropView = null;
    }
  }

  onBackdropClicked() {
    this.close();
  }

  clearSortClick($event) {
    // If only 1 item, the button act as "remove sort"
    if (this.sortModel.length == 1) {
      $event.preventDefault();
      $event.stopPropagation();

      this._sortModel = [];

      this.updateModel();
      this.sortModelChanged.emit(this._sortModel);

      this.close();
    }
  }

  optionClick(option) {
    if (option.sort == 'asc') {
      option.sort = 'desc';
    } else {
      option.sort = 'asc';
    }

    this.updateModel();
    this.sortModelChanged.emit(this._sortModel);
  }

  removeSortOptionClick($event: MouseEvent, idx) {
    $event.preventDefault();
    $event.stopPropagation();

    this._sortModel.splice(idx, 1);

    this.updateModel();
    this.sortModelChanged.emit(this._sortModel);

    if (this._sortModel.length == 0) {
      this.close();
    }
  }

  onDragDropChanged($event) {
    this._sortModel = ($event || [])
    .map((x, id) => {
      x.sortIndex = id;
      return x;
      });

    this.updateModel();
    this.sortModelChanged.emit(this._sortModel);
  }
}
