export interface IViewSettingOptions {
    id: string;
    label: string;
    disabled: boolean;
    hidden: boolean;
    checked: boolean;
}

export interface IViewSetting {
  hiddenListing?: boolean;
  updatedListing?: boolean;
}

/**
 * 2 modes 'MAESTRO' | 'BULK' {@link ViewMode}.
 */
export enum ViewMode {
  MAESTRO = 'maestro',
  BULK = 'bulk'
}
