import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService, ApiService } from 'src/app/services';
import { Subscription } from 'rxjs';
import {ActiveToast, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-lost-password',
  templateUrl: 'lostPassword.component.html',
  styleUrls: ['../auth.scss'],
})
export class LostPasswordComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isBusy: boolean = false;
  isSubmitted: boolean = false;
  hasError: boolean = false;
  errorMessage: string;

  private subscriptions: Subscription[] = [];

  private successToast: ActiveToast<any>;
  
  private toastrOptionsSuccess = { 
    disableTimeOut: false,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.subscriptions.push(this.loginForm.statusChanges.subscribe(() => {

      if((this.isSubmitted || this.loginForm.controls.email.touched) && this.loginForm.controls.email.errors) {
        this.errorMessage = 'Email address is invalid.';
        this.hasError = true;
      } else if(!(this.isSubmitted || this.loginForm.controls.email.errors)) {
        this.errorMessage = '';
        this.hasError = false;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = null;
  }

  async requestResetPassword() {
    if (this.loginForm.valid) {
      this.hasError = false;
      this.errorMessage = '';
      this.isBusy = true;

      this.loginForm.controls.email.disable();

      try {
        await this.apiService.resetPassword(this.loginForm.get('email').value.trim());

        this.loginForm.controls.email.enable();
        this.successToast = this.toastr.success('An email has been sent', null, this.toastrOptionsSuccess);

        this.successToast.onTap.subscribe(() => {
          this.successToast = null;
        });
        this.isBusy = false;
      } catch (err) {
        
        this.hasError = true;
        this.isBusy = false;
        this.isSubmitted = true;
        this.errorMessage = err.error?.message;

        this.loginForm.controls.email.enable();
      }
    } else {
      this.isSubmitted = true;
    }
  }
}
