export interface IUnderwriterNote {
  model: {
    oldValue: string;
    newValue: string;
  };
  required: boolean;
  placeHolder: string;
  type:'comment' | 'concession' | 'preInspection';
  hasError: boolean;
  disabled: boolean;
}

export const basicRejectionReasons = [
  'Below yield target',
  'Condo',
  'Duplex',
  'Dual parcel (package)',
  'Foreclosure',
  'Has inground pool',
  'Leased solar panels',
  'Lot size too large',
  'Manufactured home',
  'Multiple properties',
  'Outside of geo range',
  'PM coverage',
  'Reno capacity',
  'Rental / community restrictions',
  'Severe structural damage',
  'Single Lot',
  'Townhome',
  'Septic Tank',
  'Unfavorable location'
];

export const rejectionReasonsSeparator = / \\ /i;

export interface IRejectionComment {
    reasonMode: boolean;
    active: boolean;
    disabled: boolean;
    updated: boolean;
    reason: {
      oldValue: any;
      newValue: any;
    };
    hasError: boolean;
}
