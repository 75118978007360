import { IGridComponent } from '../iGridComponent';
import { ListingPreferences } from 'src/app/models/preferences';
import { ColDef, ColumnState } from 'ag-grid-community';

export interface FilterChipDef {
  key: string;
  name: string;
  description: string;
}

export class GridFilterBaseState {
  currentFilterId;
  shareFilter;
  filterModel = {};
  sortModel = [];
  view;
  showHidden = false;
  viewSetting: {
    hiddenListing?: boolean;
    updatedListing?: boolean
  } = null;
  showTerminated = false;
  timeId = 1;
  rangePickerConfig: RangePickerConfigState = null;
  columns: ColDef[] = [];
  columnsStates: ColumnState[];
}

export class GridFilterState extends GridFilterBaseState {
  grid: IGridComponent;
  listingPreferences: ListingPreferences;


  showChips = false;
  filterChips: FilterChipDef[] = [];

  clearFilterDisabled = true;
  showHideFilterDisabled = true;

  hasFiltersChanged;
  toggleFiltersOn = true;
  totalCount: number;
  totalFiltredCount: number;
  startRow: number;
  endRow: number;
  isBusy?: boolean;
}

export interface RangePickerConfigState {
  timeRange: number;
  startDate?: string;
  endDate?: string;
}
