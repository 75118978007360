import { ITargetOption } from "src/app/panels/proforma/proforma";
import { PROFORMA_FIELDS_IDS } from "src/app/panels/proforma/proforma_config";
import { PropertyStatusType } from "../../data/propertyStatusType";
import { EVBUS_EVENTS_NAMES } from "./updateRequest";

/**
 * interface for step styles and classes
 */
 export interface IRequestStyle {
  [key: string]: string;
}


/**
 * interface for configuring pre-approval mechanism
 */
 export interface IApproval {
  /**
   * boolean to override activation of the mechanism
   */
  active: boolean;

  /**
   * override default options in the select of the modal
   */
  options?: ITargetOption[];

  /**
   * override preselected option when the modal opens
   */
  preSelectedOption?: any;


  availableActions?: APPROVAL_ACTIONS;

  action?: APPROVAL_ACTIONS;
}

/**
 * configuration for event bus triggers
 */
 export interface ITriggerEVBusEvent {
  /**
   * boolean to toggle send to event bus mechanism
   */
  active: boolean;

  /**
   * array of requests to be sent in this trigger
   */
  eventBus: IEventBusRequest[];

  /**
   * mechanism to check for pending event bus triggers
   * before sending this trigger
   *
   * pending events are created when a user saves a draft/underwrite
   * it's also used when a user makes multiple changes which cancel
   * previous changes in consequence we cancel the event completely
   */
  checkForPendingEvents: IPendingEvents;
}


export enum APPROVAL_ACTIONS {
  ADD = 'add',
  REMOVE = 'remove',
  TRIGGER = 'trigger'

}


/**
 * configuration for event bus requests
 */
 export interface IEventBusRequest {
  /**
   * boolean to toggle this event bus request
   */
  active: boolean;

  /**
   * name of the event bus
   * indicates which event will be triggered
   */
  evbusEventName: EVBUS_EVENTS_NAMES;

  /**
   * an array of functions to activate this request
   * if all the functions return false this request is skipped
   * if validators is empty and active is true this request is activated
   * @returns boolean
   */
  validators: any[];

  /**
   * the fields that trigger this event
   * if these fields change, this request will be triggered
   */
  fieldsToWatch?: PROFORMA_FIELDS_IDS[];
}


/**
 * configuration for pending events mechanism
 */
 export interface IPendingEvents {
  /**
   * boolean to toggle pending events mechanism
   */
  active: boolean;

  /**
   * an array of functions to activate this mechanism
   * if all the functions return false this mechanism is skipped
   * if validators is empty and active is true this mechanism is activated
   * @returns boolean
   */
  validators: any[];

  /**
   * boolean to send pending events to the back end so that
   * that property can be updated in the db
   */
  updateProperty: boolean;

  /**
   * list of events to check if they are pending
   */
  pendingEvents: IEventBusRequest[];
}


/**
 * configuration for status updates
 */
 export interface IUpdateStatus {
  /**
   * boolean to toggle overriding the listing status
   */
  active: boolean;

  /**
   * the new status to be displayed
   */
  status: PropertyStatusType;

  /**
   * an array of functions to activate this mechanism
   * if all the functions return false this mechanism is skipped
   * if validators is empty and active is true this mechanism is activated
   * @returns boolean
   */
  validators: IStatusUpdate[];

  /**
   * fallback status text if the override wasn't found
   */
  fallBackStatus: PropertyStatusType;
}

export interface IStatusUpdate {
  status: PropertyStatusType;
  rules: any[];
}
export interface IExternalLink {
  [stage: string]: {
    link: string;
  };
}
