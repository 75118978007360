import { Component, ViewContainerRef, NgZone } from '@angular/core';
import { ParentModalComponent } from '../parentModalComponent';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-a1-terminate-modal',
  styleUrls: ['../modal.component.scss'],
  templateUrl: './terminateModal.component.html'
})
export class TerminateModalComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  modalForm: FormGroup;

  constructor(vcr: ViewContainerRef, zone: NgZone, fb: FormBuilder) {
    super();

    this.modalForm = fb.group({
      date: '',
      comment: '',
      files: []
    });

    this.vcr = vcr;
    this.zone = zone;
  }

  get date() {
    return this.modalForm.get('date').value;
  }

  get comment() {
    return this.modalForm.get('comment').value;
  }

  get files() {
    return this.modalForm.get('files').value;
  }

  get isSaveDisabled() {
    return !this.date || !this.comment;
  }

  onCancelClicked() {
    this.onClickClose(TerminateModalComponent.CANCEL);
  }

  onOkClick() {
    this.onClickClose(TerminateModalComponent.OK);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      this.closed.next({action: closeResult, form: this.modalForm});
    });
  }
}
