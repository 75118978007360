import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { RolesPermissionsService } from '../roles-permissions';
import { mainMenuItems } from 'src/app/main-menu-items';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {

  constructor(
    private readonly rolesPermissionsService: RolesPermissionsService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {

    if (!route.data?.permissions) {
      return of(true);
    }

    return combineLatest([
      this.rolesPermissionsService.hasOneRequiredPermission(route.data.permissions),
      this.rolesPermissionsService.$userRolePermissions
    ]).pipe(switchMap(([hasOneRequiredPermission, { permissions }]) => {

      if (hasOneRequiredPermission) {
        return of(true);
      }

      const { url } = mainMenuItems.find(menuItem =>
        !!permissions.find(userPermission =>
          menuItem.permissions.includes(userPermission)
        )
      );

      return of(this.router.parseUrl(url));
    }));
  }
}
