import { AuditItemModel } from 'src/app/models/auditItemModel';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuditItem } from 'src/app/services/data/auditItem';
import { S3Service, UnderwriterService } from 'src/app/services';
import { PropertyStatusType, PropertyStatusTypeFromDescription } from 'src/app/services/data/propertyStatusType';
import { downloadFile } from 'src/app/utils/jsUtils';
import { ActivityLogService } from 'src/app/services/activityLogService/activity-log.service';
import { ILogGroupOption } from '../proforma/proforma';
import { ListingProperty } from 'src/app/services/data/listingPropety';

@Component({
  selector: 'app-activity-log-panel',
  styleUrls: ['activityLog.component.scss'],
  templateUrl: 'activityLog.component.html'
})
export class ActivityLogPanelComponent {

  private selectedProperty;
  private _currentProperty;
  @Input()
  get currentProperty(): any {
    return this._currentProperty;
  }
  set currentProperty(value: any) {
    this._currentProperty = value;
    this.selectedProperty = value;
    this.initialize();
  }

  private _active: boolean = false;
  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
    if (value) {
      this.initialize();
      const isOlreadyLoaded = (this._duplicateListings || []).find(dl => dl.AOListingID == this._currentProperty?.AOListingID);
      if (isOlreadyLoaded) {
        this.buildLogGroupOptions(this._duplicateListings);
      } else {
        this.buildLogGroupOptions(this._duplicateListings);
      }
    }
  }

  private _version: number = 1;
  @Input()
  get version(): number {
    return this._version;
  }
  set version(value: number) {
    this._version = value;
  }

  private _duplicateListings: ListingProperty[] = [];
  @Input()
  get duplicateListings(): ListingProperty[] {
    return this._duplicateListings;
  }
  set duplicateListings(values: ListingProperty[]) {
    this.buildLogGroupOptions(values);
  }

  isActivityLogLoading = false;
  @Input() loading = false;
  items: AuditItemModel[] = [];

  sortOrder = 1;

  PropertyStatusType = PropertyStatusType;

  @Output() onScroll = new EventEmitter<any>();
  scrollListener($event) {
    this.onScroll.emit($event);
  }

  constructor(private underwriterService: UnderwriterService, private activityLogService: ActivityLogService, private s3Service: S3Service) {
  }

  initialize(AOListingID?) {
    this.items = [];
    if (this._active) {
      this.isActivityLogLoading = true;
      if (AOListingID) {
        const targetProperty = this._duplicateListings.find(dl => dl.AOListingID == AOListingID);
        if (targetProperty) {
          this.activityLogService.getListingPropertyAudit(targetProperty).then((auditItems: AuditItemModel[]) => {
            this.items = auditItems;
            this.isActivityLogLoading = false;
          });
        }
      } else if (this._currentProperty?.AOListingID) {
        if (!this._duplicateListings.length || this._duplicateListings.length === 1) {
          this.buildLogGroupOptions([]);
        }
        this.activityLogService.getListingPropertyAudit(this._currentProperty).then((auditItems: AuditItemModel[]) => {
          this.items = auditItems;
          this.isActivityLogLoading = false;
        });
      }
    }
  }

  openFile(filepath: string) {
    this.s3Service.getFileFromS3(this.selectedProperty?.AOListingID, filepath).then(result => {
      const { Body: body, ContentType: contentType } = result;
      downloadFile(body, contentType);
    });
  }

  activityLogSortChanged(id) {
    this.sortOrder = id;
  }

  shouldShowDownload(item: AuditItemModel) {
    const downloadableStatus = [
      PropertyStatusType.Underwritten,
      PropertyStatusType.OfferReady,
      PropertyStatusType.OfferReadyUrgent,
      PropertyStatusType.OfferReadyReason,
      PropertyStatusType.Countered,
      PropertyStatusType.PreInvestorApprovalReady,
      PropertyStatusType.InvestorApprovalReady,
      PropertyStatusType.ClearToCloseReady
    ];
    return downloadableStatus.includes(PropertyStatusTypeFromDescription(item.underwritingStatusText)) ||
      downloadableStatus.includes(PropertyStatusTypeFromDescription(item.previousUnderwritingStatusText));
  }

  download(item: AuditItemModel) {


    this.underwriterService.downloadProforma(this.selectedProperty?.AOListingID, item.eventDate).then(result => {
      const { body, contentType, filename } = result;
      downloadFile(body, contentType, filename);
    });
  }

  shouldShowFewCompsTitle(item: AuditItemModel) {
    return PropertyStatusTypeFromDescription(item.underwritingStatusText) === PropertyStatusType.OfferReadyReason;
  }

  onCopyChanges($event) {
    this.selectedProperty = (this._duplicateListings || []).find(dl => dl.AOListingID == $event);
    this.selectedProperty = this.selectedProperty || this._currentProperty;
    this.initialize($event);
  }

  logGroupOptions: ILogGroupOption[] = [];

  buildLogGroupOptions(values: ListingProperty[]) {
    const optionsToMap = (values && values.length)? values: [];
    if (optionsToMap.length) {
      this._duplicateListings = optionsToMap;
     this.logGroupOptions = optionsToMap.map(vl => {
        return {
          aoListingId: vl.AOListingID,
          address: vl.Address,
          main: vl.AOListingID == this.currentProperty.AOListingID,
          underwriteStatus: vl.underwritingStatus,
          source: vl.source == 1? 'MLS': 'BULK',
          underwritingStatusClass: vl.underwritingStatusClass,
          underwritingStatusText: vl.underwritingStatusText,
          copyID: (vl.copyID && vl.copyID > 1) ? vl.copyID : null
        };
      }) as any[];
    } else {
      this._duplicateListings = [];
      this.logGroupOptions = [];
    }
  }

}
