import { AuditItem } from '../services/data/auditItem';
import {PropertyStatusType} from "../services/data/propertyStatusType";

export class AuditItemFile {
  filename: string;
  filepath: string;
  isDeleted: boolean;
}

export class AuditItemFileType {
  type: string;
  label: string;
  files: any[] = [];
  hasDeleted: boolean = false;
}

export class AuditItemModel {
  auditItems: AuditItem[];
  text: string[] = [];
  url: string[] = [];
  comments: any[] = [];
  compsOld: any = null;
  compsNew: any = null;
  fileTypes: AuditItemFileType[] = [];
  header: boolean = false;
  headerText: string = '';

  underwriting: PropertyStatusType = null;
  underwritingStatusClass: string;
  underwritingStatusText: string;
  previousUnderwritingStatusText: string;

  capRateText: string = '';
  initials: string;
  eventDate: any;
  timestamptext: string;
  isTag = false;
}
