export interface UserRolesPermissions {
  roles: UserRole[];
  permissions: UserPermission[];
}

export enum UserRole {
  Admin = 'ADMIN',
  Uw = 'UW',
  ClosingExternal = 'CLOSING_EXTERNAL',
}

export enum UserPermission {
  AssetRead = 'asset_read',
  AssetWrite = 'asset_write',
  UwRead = 'uw_read',
  UwWrite = 'uw_write',
}
