import { Component } from '@angular/core';
import {ICellRendererParams} from "ag-grid-community";
import { ListingSources } from 'src/app/services/data/ListingSources';
import { ListingProperty } from 'src/app/services/data/listingPropety';

@Component({
  selector: "app-clipboard-copy-for-listing-renderer",
  templateUrl: "./clipboard-copy-for-listing-renderer.component.html",
  styleUrls: ["./clipboard-copy-for-listing-renderer.component.scss"],
})
export class ClipboardCopyForListingRendererComponent {
  cellValue: string;
  countValue: string;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.countValue = this.getCountValue(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    this.countValue = this.getCountValue(params);
  }

  private getValueToDisplay(params: ICellRendererParams) {
    return params.data ? params.data[params.colDef.field] : "-";
  }

  private getCountValue(params: ICellRendererParams) {
    const listing: ListingProperty = params.data;
    const copyID = listing.copyID;
    const groupCount =  listing.groupCount;
    let copyLabel = "";

    if (copyID) {
      copyLabel = copyID > 1 ? `Copy ${copyID}` : "Copy";
      copyLabel += " - ";
      copyLabel +=
        listing.source === ListingSources.AOListingView ? "MLS" : "Bulk";
    }

    return copyLabel;
  }
}
