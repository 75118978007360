import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, NgZone, Output, ViewContainerRef } from '@angular/core';
import { GridFilterService } from '../gridFilter/gridFilter.service';
import { GridFilterState } from '../gridFilter/gridFilterState';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'app-export-filtred',
  templateUrl: './export-filtred.component.html',
  styleUrls: ['./../select/select.component.scss', './export-filtred.component.scss'],
  animations: [
    trigger('slideExportButton', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('150ms ease-in', style({opacity: '*'}))
      ]),
      transition(':leave', [
        animate('150ms ease-out', style({opacity: '0'}))
      ]),
    ]),
  ],
})
export class ExportFiltredComponent extends SelectComponent {
  private _gridName = null;
  isOptionsActive = false;
  state: GridFilterState;
  exportFilteredText;

  @Input() isFetchCount: boolean = false;
  @Input() isDownloading: boolean = false;
  @Input() isAddressSearch: boolean = false;
  @Input() setIcon = true;
  @Input()
  get gridName(): string {
    return this._gridName;
  }
  set gridName(values: string) {
    this._gridName = values;
    this.init();
  }

  @Output() exportFilteredProperties: EventEmitter<boolean> = new EventEmitter();

  constructor(vcr: ViewContainerRef, zone: NgZone, private gridFilterSerice: GridFilterService) {
    super(vcr, zone);
   }

  exportFiltredProperties() {
    this.exportFilteredProperties.emit(true);
  }

  init() {
    if (this._gridName) {
      const tmp = this.gridFilterSerice.getState(this.gridName);
      if (tmp) {
        this.state = tmp;
      }
    }
  }

  get isfilterModelEmpty() {
    if(this.state && this._options[0]?.downLoadLimit) {
      this.exportFilteredText = ((this.state?.totalFiltredCount || this.state?.totalCount) > this._options[0]?.downLoadLimit) || (this.state?.totalFiltredCount === 0)? `The number of properties you can export is limited to ${this._options[0]?.downLoadLimit}.`: null;
    }
    return (Object.keys(this.gridFilterSerice.getFilterModel(this._gridName) || {}).length === 0 || this.state?.totalFiltredCount === 0);
  }

}
