import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { RolesPermissionsService } from './roles-permissions.service';
import { UserRole } from './types';
import _ from 'lodash';

@Directive({
  selector: '[requiredRole]'
})
export class RequiredRoleDirective {
  private userRoles: UserRole[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly rolesPermissionsService: RolesPermissionsService) {}

  @Input()
  set requiredRole(userRoles: UserRole[]) {
    this.userRoles = userRoles;
    this.updateView();
  }

  private updateView() {
    if (_.isEmpty(this.userRoles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.rolesPermissionsService.hasOneRequiredRole(this.userRoles).pipe(
      first()
    ).subscribe(hasOneRequiredPermission => hasOneRequiredPermission ?
      this.viewContainer.createEmbeddedView(this.templateRef) :
      this.viewContainer.clear()
    );
  }
}
