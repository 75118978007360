import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'floating-cell',
  template: `
    <span [ngStyle]="style">{{params.value != null ? params.valueFormatted : '-'}} </span>
  `,
})
export class PinnedPropertyRowRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public style: {
    [prop: string]: any
  };

  agInit(params: any): void {
    this.params = params;
    this.style = this.params.style;
  }

  refresh(): boolean {
    return false;
  }
}
