import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SelectComponent } from "src/app/ui/select/select.component";
import { IStatusPanelParams } from 'ag-grid-community';
import { GridFilterService } from "src/app/ui/gridFilter/gridFilter.service";
import { DownloadService } from 'src/app/services';
import { downloadFile } from "src/app/utils/jsUtils";

@Component({
  selector: 'app-status-bar-panel',
  styleUrls: ['statusBar.component.scss'],
  templateUrl: 'statusBar.component.html'
})
export class StatusBarPanelComponent {

    params: IStatusPanelParams;
    componentParent: any;
    isStatusBarOpened = false;

    endRow: any;
    startRow: any;

    @ViewChild('exportSelection') exportSelection: SelectComponent;

    private _gridName = null;
    @Input()
    get gridName(): string {
      return this._gridName;
    }
    set gridName(values: string) {
      this._gridName = values;
    }

    @Input() options: any[] = [];
    @Input() isBottomStatusPanelOpened: boolean = false;
    @Input() isGridLoading: boolean = false;
    @Input() isFetchCount: boolean = false;
    @Input() isBusy: boolean = false;
    @Input() isDownloading: boolean = false;
    @Input() selectedRows: any;
    @Input() selectOptions: any;
    @Input() optionalActionDisabled: any;


    @Output() selectExportChange: EventEmitter<any> = new EventEmitter();
    @Output() onListingAction: EventEmitter<any> = new EventEmitter();

    constructor(
        private gridFilterService: GridFilterService,
        private downloadService: DownloadService) {

    }

    initialize() {

    }

    getRowsCount() {
        const {totalCount, startRow, endRow} = this.gridFilterService.getLoadedRowCount(this.gridName);
        return !this.isFetchCount && totalCount != 'Loading...' ? startRow + ' - ' + endRow + ' of ' + totalCount + ' properties ' : 'Loading...';
    }

    getSelectedRowsCount() {
        return this.selectedRows ? this.selectedRows.length : 0;
    }

    async onExportSelectionChanged(id) {
        this.exportSelection.selectedId = null;

        if (id === 'exportPDF') {
          window.open(`/underwriting/export/${this.selectedRows.map((r) => r.AOListingID).join(',')}`, "_blank");
        } else if (id == 'exportExcel') {
          if (!this.isDownloading) {
            const aoListingIds = this.selectedRows.map(r => {
              return r.AOListingID;
            });

            const propertiesExcel = await this.downloadService.downloadProperties(aoListingIds);
            downloadFile(propertiesExcel.body.data as ArrayBuffer, propertiesExcel.contentType, propertiesExcel.filename, propertiesExcel.body.data.length);
          }
        }
      }

      executeListingAction($event) {
        this.onListingAction.emit($event);
      }
}
