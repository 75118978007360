import { Component, ElementRef, ViewContainerRef, NgZone, Renderer2, TemplateRef, ViewChild } from '@angular/core';

import { PhotoItem } from 'src/app/services/data/photoItem';

@Component({
  selector: 'app-a1-lightbox',
  styleUrls: ['./lightbox.component.scss'],
  templateUrl: './lightbox.component.html'
})
export class LightboxComponent {
  @ViewChild('backdrop', { static: true }) backdropTpl: TemplateRef<any>;
  @ViewChild('panel', { static: true }) dropdownTpl: TemplateRef<any>;
  @ViewChild('button') button: ElementRef;

  isOpen = false;

  images = [];
  currentSlide = 0;

  private backdropHtml;
  private modalHtml;
  private view: any;
  private backdropView: any;
  private keyListener: Function;

  constructor(private vcr: ViewContainerRef, private zone: NgZone, private renderer: Renderer2) { }

  openModal() {
    this.backdropView = this.vcr.createEmbeddedView(this.backdropTpl);
    this.backdropHtml = this.backdropView.rootNodes[0];
    document.body.appendChild(this.backdropHtml);

    this.view = this.vcr.createEmbeddedView(this.dropdownTpl);
    this.modalHtml = this.view.rootNodes[0];

    document.body.appendChild(this.modalHtml);

    setTimeout(() => {
      this.zone.run(() => {
        this.isOpen = true;
        this.plusSlides(1);
      });
      this.button.nativeElement?.focus();
    }, 10);

    this.keyListener = this.renderer.listen('window', 'keydown', (event) => {
      if (event.keyCode == 37) {
        this.plusSlides(-1);
      }
      if (event.keyCode == 39) {
        this.plusSlides(1);
      }
      if (event.keyCode == 27) {
        this.close();
      }
    });
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;

      setTimeout(() => {
        this.zone.run(() => {

          document.body.removeChild(this.modalHtml);
          this.modalHtml = null;

          document.body.removeChild(this.backdropHtml);
          this.backdropHtml = null;

          this.view.destroy();
          this.view = null;

          this.backdropView.destroy();
          this.backdropView = null;
        });
      }, 125);

      // destroy keydown listener
      this.keyListener();
    }
  }

  open(images: PhotoItem[], index: number): void {
    this.images = images;
    this.currentSlide = index;
    this.openModal();
  }

  plusSlides(n) {
    this.showSlides(this.currentSlide += n);
  }
  
  showSlides(n) {
    let i;
    const slides = document.getElementsByClassName("a1-lightbox-slide-content") as HTMLCollectionOf<HTMLElement>;
    const arrowPrevious = (document.getElementsByClassName("a1-lightbox-prev") as HTMLCollectionOf<HTMLElement>)[0];
    const arrowNext = (document.getElementsByClassName("a1-lightbox-next") as HTMLCollectionOf<HTMLElement>)[0];
    
    if (n > slides.length) {
      this.currentSlide = 1;
    }
    if (n < 1) {
      this.currentSlide = slides.length;
    }

    arrowPrevious.style.opacity = this.currentSlide == 1 ? "0.5" : "1";
    arrowNext.style.opacity = this.currentSlide == slides.length ? "0.5" : "1";
    
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    
    slides[this.currentSlide - 1].style.display = "block";
  }
}
