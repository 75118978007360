import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigService {
  private appConfig;

  public FAKE = environment.test;

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    return this.http
      .get('/assets/config/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  getServerUrl(): string {
    return this.appConfig ? this.appConfig.aws.ApiURI : '';
  }

  getS3Bucket(): string {
    return this.appConfig ? this.appConfig.aws.s3bucket : '';
  }

  getRegion() {
    return this.appConfig ? this.appConfig.aws.region : '';
  }

  getUwListingRefresh() {
    return this.appConfig ? this.appConfig.app.uwListingRefresh : 300000;
  }

}
