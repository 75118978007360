import * as _ from 'lodash';

import { ColDef } from 'ag-grid-community';
import { PinnedPropertyRowRendererComponent } from './pinnedPropertyRowRenderer';

export abstract class ComparableListingColumnDefinition {

  static columns: ColDef[] = [
    {
      headerName: "", field: "index", width: 32, filter: false, hide: false, pinned: true,
      colId: 'id',
      sortable: false, resizable: false, cellClass: ['col-center'],
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      headerComponentParams: {
        enableFilter: false
      },
      suppressMovable: true,
      lockPosition: true
    },


    {
      headerName: "",
      field: "missingDataTooltip",
      width: 22, minWidth: 22, filter: false, hide: false, pinned: true, sortable: false, resizable: false,
      tooltipField: 'missingDataTooltip',
      cellRenderer: function(params) {
        return params.value ? params.value : '';
      },
      cellClass: 'a1-missing-data-tooltip-comp',
      cellStyle: {padding: '0', lineHeight: '34px', paddingLeft: '4px'},
      suppressMovable: true,
      lockPosition: true
    },

    {
      headerName: "Sim.", field: "SourceSimilarity", width: 80,
      filter: "agNumberColumnFilter", filterParams: { inRangeInclusive: true },
      hide: false, pinned: true,
      type: "centerColumn",
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            component: PinnedPropertyRowRendererComponent,
            params: {
              style: { color: 'blue' },
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      }
    },
    // Column change name based on the View
    {
      headerName: "Rent / Sale", field: "LastLeasePrice", width: 100, hide: false, pinned: true, type: 'currencyColumn',
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            component: PinnedPropertyRowRendererComponent,
            params: {
              style: { color: 'blue' },
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
    },

    {
      headerName: "Sale", field: "LastSalePrice", width: 100, hide: true, pinned: true, type: 'currencyColumn',
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            component: PinnedPropertyRowRendererComponent,
            params: {
              style: { color: 'blue' },
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
    },
    // Column change name based on the View
    {
      headerName: "Rent Date / Sale Date", field: "LastLeaseDate", width: 125, hide: false, type: 'dateColumn',
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            component: PinnedPropertyRowRendererComponent,
            params: {
              style: { color: 'blue' },
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
      cellRenderer: 'dateFormatRenderer',
      cellRendererParams: {
        format: null,
        defaultValue: null
      }
    },

    {
      headerName: "Dist.", field: "Distance", width: 80, hide: false,
      type: 'distanceColumn',
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            component: PinnedPropertyRowRendererComponent,
            params: {
              style: { color: 'blue' },
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      }
    },

    { headerName: "AO Property ID", field: "AOPropertyID", width: 150, hide: true, type: 'numberColumn' },
    {
      headerName: "Address",
      field: "Address",
      width: 216,
      cellClass: "a1-address" ,
      filter: "agTextColumnFilter",
      hide: false,
      pinned: false,
      cellRenderer: "clipboardCopyRenderer"
    },

    { headerName: "Unit", field: "Unit", width: 150, filter: "agTextColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "City", headerClass: "text-centered", field: "City", width: 200, filter: "agTextColumnFilter", hide: false, type: "centerColumn" },
    { headerName: "State", headerClass: "text-centered", field: "State", width: 85, filter: "agTextColumnFilter", hide: false, type: "centerColumn" },
    { headerName: "Zip", field: "ZipCode", width: 110, hide: false, type: 'zipColumn' },

    { headerName: "Property Type", field: "PropertyType", width: 150, filter: "agSetColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "Property Subtype", field: "PropertySubtype", width: 150, filter: "agSetColumnFilter", hide: true, pinned: false },

    { headerName: "Beds", field: "Beds", width: 90, hide: false, type: 'numberColumn' },
    { headerName: "Baths", field: "BathsCount", width: 90, hide: false, type: 'numberColumn' },
    { headerName: "Full Baths", field: "FullBaths", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "Half Baths", field: "HalfBaths", width: 150, hide: true, type: 'numberColumn' },

    { headerName: "Year Built", field: "YearBuilt", width: 120, hide: false, type: 'numberColumn' },
    { headerName: "Sq Ft", field: "LivingAreaSqFt", width: 120, hide: false, type: 'numberColumn' },

    { headerName: "Condition Score", field: "ConditionScore", width: 150, hide: false, type: 'numberColumn' },
    { headerName: "Median Income", field: "MedianIncome", width: 150, hide: false, type: 'currencyColumn' },
    { headerName: "Inst. %", field: "PctInstitutionalOwnership", width: 150, hide: false, type: 'roundedPercentColumn' },
    { headerName: "Inst. Investor", field: "AOInstitutionalOwnerID", width: 150, hide: false, type: 'centerColumn' },

    { headerName: "FIPS", field: "FIPS", width: 150, filter: "agTextColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "Census Block", field: "CensusBlock", width: 150, filter: "agTextColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "Census Tract", field: "CensusTract", width: 150, filter: "agTextColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "Subdivision", field: "Subdivision", width: 150, filter: "agTextColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "Garage", field: "Garage", width: 150, filter: "agTextColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "Parking Spaces", field: "ParkingSpaces", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "Basement", field: "Basement", width: 150, filter: "agTextColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "Construction Type", field: "ConstructionType", width: 150, filter: "agTextColumnFilter", hide: true, type: "centerColumn" },
    { headerName: "Stories", field: "Stories", width: 150, hide: true, type: "numberColumn" },
    { headerName: "Pool", field: "Pool", width: 150, filter: "agTextColumnFilter", hide: true, type: 'numberColumn' },
    { headerName: "Lot Size Acres", field: "LotSizeAcres", width: 150, hide: true, type: "numberColumn" },
    { headerName: "Status", field: "Status", width: 150, hide: true, pinned: false,
      filter: "agSetColumnFilter",
      filterParams: {
          values: function (params) {
            params.success(["A", "P", "S", "X"]);
          }
        }
      },

    { headerName: "Status Detail", field: "StatusDetail", width: 150, filter: "agTextColumnFilter", hide: true, pinned: false },
    { headerName: "Last Listing Price", field: "LastListingPrice", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Last Listing Date", field: "LastListingDate", width: 150, filter: "agDateColumnFilter", hide: true, type: 'dateColumn' },
    { headerName: "Days On Market", field: "RealDaysOnMarket", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "AVM Value", field: "AVMValue", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Rent AVM Value", field: "RentAVMValue", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Parcel Number", field: "ParcelNumber", width: 150, filter: "agTextColumnFilter", hide: true, pinned: false },
    { headerName: "Source", field: "Source", width: 150, filter: "agTextColumnFilter", hide: true, pinned: false },
    { headerName: "AO Listing Comp ID", field: "AOCompID", width: 150, hide: true, type: 'numberColumn' },

  ];

  static reservedColumnsIdsDef = ['isHeaderRow','id','index', 'reasonFewComps', "missingDataTooltip", 'underwritingStatus', 'LastLeaseDate', 'Distance'];

}

export abstract class ComparableListingPrintColumnDefinition {

  static columns: ColDef[] = [
    { headerName: "Sim.", field: "SourceSimilarity", width: 50, type: "centerColumn", pinned: false, filter: false, suppressMenu: true },

    { headerName: "Rent / Sale", field: "LastLeasePrice", width: 100, type: 'currencyColumn', pinned: false, filter: false, suppressMenu: true },

    { headerName: "Address", field: "Address", width: 216, cellClass: "a1-address", pinned: false, filter: false, suppressMenu: true },
    { headerName: "City", headerClass: "text-centered", field: "City", width: 150, type: "centerColumn", pinned: false, filter: false, suppressMenu: true },
    { headerName: "State", headerClass: "text-centered", field: "State", width: 65, type: "centerColumn", pinned: false, filter: false, suppressMenu: true },

    { headerName: "Beds", field: "Beds", width: 65, hide: false, type: 'numberColumn', pinned: false, filter: false, suppressMenu: true },
    { headerName: "Baths", field: "BathsCount", width: 65, hide: false, type: 'numberColumn', pinned: false, filter: false, suppressMenu: true },

    { headerName: "Sq Ft", field: "LivingAreaSqFt", width: 90, hide: false, type: 'numberColumn', pinned: false, filter: false, suppressMenu: true },
  ];

}
