import {Component, NgZone, ViewContainerRef} from '@angular/core';
import {ParentModalComponent} from "../parentModalComponent";
import * as moment from 'moment';
import * as JsUtils from 'src/app/utils/jsUtils';
import { DUPLICATE_SOURCE, IDuplicateSubmission, IOnHold } from 'src/app/services/data/proforma';


@Component({
  selector: 'app-duplicate-submission',
  templateUrl: './duplicate-submission.component.html',
  styleUrls: ['./duplicate-submission.component.scss', '../modal.component.scss']
})
export class DuplicateSubmissionComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  agentName = 'another agent';
  address;
  addresses =[];
  slicedAddresses = [];
  editedTime;
  options: IDuplicateSubmission;
  reason;
  onHold: IOnHold[];
  multipleProperties = true;
  onHoldCount;
  concequences;

  private readonly sliceSize = 5;

  origin: DUPLICATE_SOURCE = 'proforma';

  currentPage = 0;

  propertyPager = '';

  constructor(vcr: ViewContainerRef, zone: NgZone) {
    super();
    this.vcr = vcr;
    this.zone = zone;
  }

  updateConfig() {
    this.initOnHoldOptions();
    if (this.origin === 'listing') {

      if (!this.multipleProperties) {
        this.agentName = this.onHold ? this.onHold[0]?.user : this.agentName;
        this.address = this.onHold ? this.onHold[0]?.address : undefined;
        if (!this.address) {
          this.config.text += 'the property because';
        } else {
          this.reason = 'because';
        }
        const updateDate = this.onHold ? this.onHold[0]?.lastUpdateDate ? this.onHold[0]?.lastUpdateDate : null : null;
        this.editedTime = updateDate ? JsUtils.getDateCalendarString(updateDate) : JsUtils.getDateCalendarString(moment(new Date()));
        this.concequences = 'If you continue, your changes will not be made.';
      } else {
        this.onHoldCount = this.onHold && this.onHold.length > 0 ? this.onHold.length : 0;

        this.config.text = 'You cannot modify';
        if (this.onHoldCount > 1) {
          this.config.text += ' the following';
          this.reason = ' properties because they were already saved by another user.';
          this.extractAddresses();
          this.setAddressesForPagination();
        } else if (this.onHoldCount === 1) {
          this.multipleProperties = false;
          this.reason = 'because';
          this.agentName = this.onHold ? this.onHold[0]?.user : this.agentName;
          this.address = this.onHold ? this.onHold[0]?.address : undefined;
          if (!this.address) {
            this.config.text += 'the property because';
          } else {
            this.reason = 'because';
          }
          const updateDate = this.onHold ? this.onHold[0]?.lastUpdateDate ? this.onHold[0]?.lastUpdateDate : null : null;
          this.editedTime = updateDate ? JsUtils.getDateCalendarString(updateDate) : JsUtils.getDateCalendarString(moment(new Date()));
          this.concequences = 'If you continue, your changes will not be made.';

        }

      }
    } else {
      this.agentName = this.onHold ? this.onHold[0]?.user : this.agentName;
      this.address = this.onHold ? this.onHold[0]?.address : undefined;
      if (!this.address) {
        this.config.text += 'the property because';
      } else {
        this.reason = 'because';
      }
      const updateDate = this.onHold ? this.onHold[0]?.lastUpdateDate ? this.onHold[0]?.lastUpdateDate : null : null;
      this.editedTime = updateDate ? JsUtils.getDateCalendarString(updateDate) : JsUtils.getDateCalendarString(moment(new Date()));
      this.concequences = 'If you continue, your changes will be lost.';
    }

  }

  private initOnHoldOptions() {
    this.options = this.config?.options;
    this.onHold = this.options.onHold;
    this.origin = this.options?.origin ? this.options?.origin : this.origin;


  }
  private extractAddresses() {
    this.onHold.forEach(onHoldItem => {
      if (onHoldItem.address) {
        this.addresses.push(onHoldItem.address);
      }
    });
  }

  setAddressesForPagination() {
    if(this.addresses.length === 0) {
      return;
    }
    if (this.addresses.length <= this.sliceSize) {
      this.slicedAddresses.push(this.addresses);
      return;
    }
    while (this.addresses.length> 0 ) {
      this.slicedAddresses.push(this.addresses.splice(0, this.sliceSize));
    }
    this.propertyPager =`${this.currentPage + 1} of ${this.slicedAddresses.length}`;

  }

  previous() {
    this.currentPage -= 1;
    this.propertyPager =`${this.currentPage + 1} of ${this.slicedAddresses.length}`;
  }
  next() {
    this.currentPage += 1;
    this.propertyPager =`${this.currentPage + 1} of ${this.slicedAddresses.length}`;
  }

  onOkClick() {
    this.onClickClose(DuplicateSubmissionComponent.OK);
  }

  onCancelClicked() {
    this.onClickClose(DuplicateSubmissionComponent.CANCEL);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      this.closed.next(closeResult);
    });
  }

}
