import { Component, QueryList, ViewChildren} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap } from "@angular/router";

import * as _ from 'lodash';
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ITargetOption } from "src/app/panels/proforma/proforma";
import { EntityUtils } from "src/app/services/entityUtils";
import { UnderwriterService } from "src/app/services/underwriter.service";
import { ExportPreviewComponent } from "./exportPreview/exportPreview.component";


@Component({
  selector: 'app-export-pdf',
  styleUrls: ['exportPDF.component.scss'],
  templateUrl: 'exportPDF.component.html'
})
export class ExportPDFComponent {
  isBusy = true;
  sentToPrint = false;
  loadedProperties = [];
  propertyIds = [];
  propertyIdsToLoad = [];

  windowInactive = false;

  entitiesSet = false;
  selectedEntityOptions: ITargetOption[] = [];

  @ViewChildren('exportPreview') exportPreview!: QueryList<ExportPreviewComponent>;

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private underwriterService: UnderwriterService
  ) {
    this.reloadEntities();
    this.activatedRoute.paramMap.pipe(
      switchMap((params: ParamMap) => of(params.get('aoListingIdx'))),
    ).subscribe((aoListingIdx: string) => {
      this.propertyIds = aoListingIdx.split(',');
      this.propertyIdsToLoad = [... this.propertyIds];

      this.titleService.setTitle(`A1 - Underwriter Export - ${this.propertyIds.join(', ')}`);
    });

    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.windowInactive = true;
      } else {
        this.windowInactive = false;
      }
    });

    EntityUtils.getEntities().subscribe(entities => {
      if (entities) {
        this.selectedEntityOptions = entities.filter(e => !!e.id);
        this.selectedEntityOptions.unshift({ id: 'default', label: 'Default', autoAssign: 'Auto-assign' });
        this.entitiesSet = true;
      } else {
        this.reloadEntities();
      }
    });
  }

  reloadEntities () {
    this.underwriterService.reloadEntities();
  }

  finishedLoading(aoListingID) {
    if (!this.loadedProperties.includes(aoListingID)) {
      this.loadedProperties.push(aoListingID);
    }

    this.isBusy = this.loadedProperties.length !== this.propertyIds.length;
    if (!this.isBusy) {

      setTimeout(() => {
        this.exportPreview.forEach((exportPreviewChild) => {
          exportPreviewChild.comparablesPanel.showSelectedCompsOnlyClick();
        });
      }, 1000);

      const printReadyInterval = setInterval(() => {
        let readyToPrint = true;
        this.exportPreview.forEach((exportPreviewChild) => {
          readyToPrint = readyToPrint && exportPreviewChild.comparablesPanel.markersSet && this.entitiesSet;
        });

        if (readyToPrint && !this.windowInactive && !this.sentToPrint) {
          setTimeout(() => {
            this.printPDF();
            clearInterval(printReadyInterval);
          }, 1000);
        }
      }, 500);
    }
  }

  printPDF(isUserIntent = false) {
    if (isUserIntent || !this.sentToPrint) {
      this.sentToPrint = true;
      window.print();
    }
  }

  listingReady(id) {
    const prop = this.propertyIds[this.propertyIds.indexOf(id) + 1];
    if (prop && this.propertyIdsToLoad.indexOf(prop) === - 1) {
      this.propertyIdsToLoad.push(prop);
    }
  }
}
