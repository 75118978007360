import { Component, Input, ViewEncapsulation, ViewChild } from '@angular/core';

import * as JsUtils from 'src/app/utils/jsUtils';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

import { ComparablesPanelComponent } from '../comparables.component';
import { LightboxService } from 'src/app/ui/lightbox/lightbox.service';
import { UnderwriterService } from 'src/app/services';

const statusDetail = [
  'active',
  'sold',
  'leased',
  'pending'
];


@Component({
  selector: 'app-comparable-preview',
  styleUrls: ['comparablePreview.component.scss'],
  templateUrl: 'comparablePreview.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ComparablePreviewComponent {
  compPanel: ComparablesPanelComponent;

  distance: string;
  date: string;

  init = false;
  otherStatusDetails = false;

  private _marker;
  @Input()
  get marker() {
    return this._marker;
  }
  set marker(marker) {
    this._marker = marker;
    this.otherStatusDetails = !statusDetail.includes((this._marker?.data?.StatusDetail || '').toLowerCase());

    this.initialize();
  }

  private _editLocked: Boolean;
  @Input()
  get editLocked(): Boolean {
    return this._editLocked;
  }
  set editLocked(editLocked: Boolean) {
    this._editLocked = editLocked;
  }

  @ViewChild(SwiperDirective, { static: false }) directiveRef: SwiperDirective;

  public config: SwiperConfigInterface = {
    // a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: {
      nextEl: '.left-arrow',
      prevEl: '.right-arrow'
    },
    pagination: false
  };

  public slides = [];

  currentIndex = 0;
  maxSlides = 0;

  constructor(private underwriterService: UnderwriterService, private lightboxService: LightboxService) {

  }

  onIndexChange(index) {
    this.currentIndex = index;
  }

  carouselPrevious() {
    this.directiveRef.prevSlide();
  }

  carouselNext() {
    this.directiveRef.nextSlide();
  }

  onAddRemoveCompClick() {
    this.compPanel.selectCompById(this.marker.data.AOCompID);
  }

  private async initialize() {
    if (this._marker == null) {
      this.date = null;
      this.distance = null;
      return;
    }

    this.date = JsUtils.getDateString(this.marker.data.StatusDate);
    this.distance = JsUtils.formatDecimal(this.marker.data.Distance);

    // Be sure to use the property aoListingId not the data.AOListingID as they don't represent the same thing... long story :(
    if (this.marker.data.AOPhotoGroupID) {
      this.slides = await this.underwriterService.getComparablePhotos(this.marker.aoListingId, this.marker.data.AOCompID, this.marker.data.AOPhotoGroupID);
    }

    this.init = true;

    if (!this.marker.selected) {
      this.compPanel.setHighlight(this.marker.data.AOCompID);
    }
  }

  openLightbox(index: number) {
    this.lightboxService.open(this.slides, index);
  }

  onSlideError($event, idx) {
    this.slides.splice(idx, 1);
  }
}
