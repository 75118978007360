import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import * as _ from 'lodash';
import * as JsUtils from 'src/app/utils/jsUtils';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService, AuthenticationService } from 'src/app/services';
import { animate, style, transition, trigger } from '@angular/animations';
import { INote } from 'src/app/services/data/notes';
import * as moment from 'moment';
import { UnderwriterViewModel } from "../underwriter/underwriterViewModel";
import {ActiveToast, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-property-notes-panel',
  templateUrl: './property-notes.component.html',
  styleUrls: ['./property-notes.component.scss'],
  animations: [
    trigger('noteAnimationTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)'})),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0, height: 0, 'padding-top': '0', 'padding-bottom': '0', transform: 'translateY(-100%)' }))
      ])
    ]),
  ]
})
export class PropertyNotesComponent {
  profile: any = {};

  isExpanded: boolean = false;

  isBusy: boolean = false;

  isNotesFormVisible: boolean = false;

  items: INote[] = [];

  noteActions = [
    // not MVP
    // {
    //   id:0,
    //   label:'Add Flag'
    // },
    // {
    //   id:1,
    //   label:'Edit'
    // },
    {
      id: 2,
      label:'Delete'
    }
  ];

  private _propertyModel: UnderwriterViewModel = null;
  @Input() get propertyModel(): UnderwriterViewModel {
    return this._propertyModel;
  }
  set propertyModel(value: UnderwriterViewModel) {
    if (value) {
      this._propertyModel = value;
      this.initialize();
    }
  }

  private _active: boolean = false;
  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
    this.initialize();
  }

  @Input() section = 'underwriting';

  @Input() loading: boolean = false;

  private searchChanged: Subject<void> = new Subject<void>();
  private changeSubscription: Subscription;

  searchNotesModel: string;

  notesFormGroup: FormGroup;
  fieldsToWatch = [
    'comment',
  ];

  disabled: boolean = false;

  @Output() onScroll = new EventEmitter<any>();
  scrollListener($event) {
    this.onScroll.emit($event);
  }

  @Output() onUpdateDate = new EventEmitter<any>();

  private errorToast: ActiveToast<any>;

  private toastrOptionsError = { 
    disableTimeOut: true,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {
    this.profile = this.authenticationService.getProfile();

    this.notesFormGroup = fb.group({
      comment: ['', [Validators.required]],
    });

    // Debounce the column changes related events a bit so
    // we don't spam-save the database on every change
    this.searchChanged
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.applyFilter();
      });
  }

  async initialize() {
    if (this._active && this._propertyModel) {
      this.isBusy = true;

      if(!JsUtils.isNullOrEmpty(this._propertyModel.property.currentNote)) {
        this.notesFormGroup.get('comment').setValue(this._propertyModel.property.currentNote);
        this.isNotesFormVisible = true;
        this.isExpanded = true;
        this._propertyModel.isDirty = true;
      } else if (this.notesFormGroup.dirty || this.isExpanded) {
        this.cancelNote();
      }

      this._propertyModel.property.isNotesTabViewed = true;
      this.loading = true;
     try {
      this.items = await this.apiService.getNotes(this._propertyModel.property.AOListingID);
      this.items.forEach((i) => {
        if (i.noteType == 'R') {
          i.message = i.message? i.message?.split(' \\ ').join(', '): '';
        }
      });
      this.loading = false;
     } catch(e) {
      this.loading = false;
     }
      const hasNotifications = _.some(this.items, (item: INote) => !item.viewed);
      if (hasNotifications) {
        await this.apiService.updateNotifications(this.items);
      }

      this.applyFilter();
      this.subscribeFormChangedEvent();

      this.isBusy = false;
    }
  }

  onSearchChange() {
    this.searchChanged.next();
  }

  applyFilter() {

  }

  cancelNote() {
    this.notesFormGroup.reset();
    this.isNotesFormVisible = false;
    this.isExpanded = false;
  }

  async noteActionsChanged(action: number, noteId: number) {
    if (action === 2) {
      try {
        const result = await this.apiService.postNotes('deleteNotes', {noteId});
        this.onUpdateDate.emit(result.updateDate);
        this.items = this.items.filter((item) => item.id !== noteId);
        if (this.items.length === 0) {
          this.isNotesFormVisible = false;
          this.isExpanded = false;
        }
      } catch(err) {
        this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
        console.log(err);
        this.errorToast.onTap.subscribe(() => {
          this.errorToast = null;
        });
      }
    }
  }

  async saveNote() {
    this.notesFormGroup.markAllAsTouched();

    if (this.notesFormGroup.valid) {
      try {
        const newNote: INote = {
          AOListingID: this._propertyModel.property.AOListingID,
          message: this.notesFormGroup.controls.comment.value,
          section: this.section,
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          viewed: true,
          identityId: this.profile.identityId,
          createDate: moment().utc()
        };

        const insertedNote = await this.apiService.postNotes('addNotes', newNote);
        newNote.id = insertedNote.insertId;
        newNote.updateDate = insertedNote.updateDate;
        this.onUpdateDate.emit(insertedNote.updateDate);
        this.items.push(newNote);

        this.isExpanded= false;
        this.notesFormGroup.reset();
      } catch(err) {
        this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
        console.log(err);
        this.errorToast.onTap.subscribe(() => {
          this.errorToast = null;
        });
      }
    }
  }

  subscribeFormChangedEvent() {
    if (!this.changeSubscription) {
      this.changeSubscription = this.notesFormGroup.valueChanges
        .pipe()
        .subscribe(() => {
          this._propertyModel.property.currentNote = this.notesFormGroup.get('comment').value;
          this._propertyModel.isDirty = this.notesFormGroup.dirty;
        });
    }
  }

}
