import { HostBinding, Component, Input } from '@angular/core';


@Component({
  selector: 'app-accordion',
  styleUrls: ['./accordion.component.scss'],
  templateUrl: './accordion.component.html'
})
export class AccordionComponent {
  @HostBinding('class') class = 'a1-accordion';

  @Input() title: string;
  @Input() expanded: boolean = false;
}
