import {Component, HostBinding, Input, Output, EventEmitter} from '@angular/core';

import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import * as _ from 'lodash';
import * as JsUtils from 'src/app/utils/jsUtils';
import { first } from 'rxjs/operators';

import {ListingProperty} from 'src/app/services/data/listingPropety';
import {
  ColumnsSettings,
  DetailsDef,
  DetailsGroupDef,
  ListingDetailsColumnDefinition,
} from './columnDefinition';
import { RolesPermissionsService } from 'src/app/roles-permissions';
// ---> Remove when we remove the feature flag 1357 from here
import { FeatureFlagsService } from 'src/app/services/featureFlagsService/feature-flags.service';
// ---> to here

export interface PropertyDetailsSaveValues {
  field: string;
  value: any;
}

@Component({
  selector: 'app-property-details-panel',
  styleUrls: ['propertyDetails.component.scss'],
  templateUrl: 'propertyDetails.component.html'
})
export class ProperDetailsComponent {
  @HostBinding('class') class = 'a1-property-details-panel';

  private _property: ListingProperty = null;
  @Input() get property(): ListingProperty {
    return this._property;
  }
  set property(value: ListingProperty) {
    this._property = value;

    this.initialize();
  }

  @Input() columnsSettings: ColumnsSettings;

  @Input() loading: boolean = false;

  @Input() insuranceFactors ;

  private searchChanged: Subject<void> = new Subject<void>();

  searchPropertyModel: string;

  editableFields = {};

  groups: DetailsGroupDef[] = [];

  @Output() save: EventEmitter<any> = new EventEmitter();

  isPropertyDelegated = false;

  @Output() onScroll = new EventEmitter<any>();
  scrollListener($event) {
    this.onScroll.emit($event);
  }

  // ---> Remove when we remove the feature flag 1357 from here 
  constructor(private rolesPermissionsService: RolesPermissionsService, private featureFlagsService: FeatureFlagsService) {
  // ---> to here and replace with the nex line
  // constructor(private rolesPermissionsService: RolesPermissionsService) {
    // Debounce the column changes related events a bit so
    // we don't spam-save the preferences while resizing/moving a column
    this.searchChanged
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.applyFilter();
      });
  }

  initialize() {
    if (!this._property) {
      this.searchPropertyModel = null;
    }

    this.rolesPermissionsService.isExternalVendor().pipe(first()).subscribe((isExternalVendor) => {
      if(!isExternalVendor) {
        this.isPropertyDelegated = !JsUtils.isNullOrEmpty(this._property?.TMExternalVendorName) ||
          !JsUtils.isNullOrEmpty(this._property?.ClosingExternalVendorName);
      }
    });

    this.groups = ListingDetailsColumnDefinition.initialize(this._property, this.columnsSettings);
    this.initializeForm();
    this.applyFilter();
  }

  initializeForm() {
    _.forEach(this.groups, (fg: DetailsGroupDef) => {
      _.forEach(fg.detailsDef, (dd: DetailsDef) => {
        if (dd.editable) {
          this.editableFields[dd.fieldId] = dd.value;
        }
      });
    });
  }

  onSearchChange() {
    this.searchChanged.next();
  }

  applyFilter() {
    if (this.searchPropertyModel == null || this.searchPropertyModel.trim() == '') {
      _.forEach( this.groups, (fg: DetailsGroupDef) => {
        fg.show = true;
        fg.expanded = fg.defaultExpanded;

        _.forEach(fg.detailsDef, (dd: DetailsDef) => {
          dd.show = true;
        });
      });
      return;
    }

    const search = this.searchPropertyModel.trim().toLowerCase();

    _.forEach(this.groups, (fg: DetailsGroupDef) => {

      _.forEach(fg.detailsDef, (dd: DetailsDef) => {
        dd.show = dd.label.toLowerCase().indexOf(search) > -1;
        if (!dd.show) {
          if (typeof dd.value == 'string') {
            dd.show = dd.value.toLowerCase().indexOf(search) > -1;
          }
        }

      });

      fg.show = _.some(fg.detailsDef, (dd) => dd.show);
      fg.expanded = fg.show;
    });
  }

  saveEditable(id, old) {
    if (this.editableFields?.[id] === old) {
      return;
    }

    const output: PropertyDetailsSaveValues = {
      field: id,
      value: this.editableFields[id]
    };
    this.save.emit(output);
  }

  onClearSearchPropertyModel() {
    this.searchPropertyModel = null;
    this.applyFilter();
  }
}
