import { Component, ViewContainerRef, NgZone } from '@angular/core';
import { ParentModalComponent } from '../parentModalComponent';


@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['../modal.component.scss','./error-modal.component.scss']
})
export class ErrorModalComponent extends ParentModalComponent {
  static readonly CANCEL = 'cancel';
  static readonly OK = 'ok';

  comment: string;

  constructor(vcr: ViewContainerRef, zone: NgZone) {
    super();
    this.vcr = vcr;
    this.zone = zone;
  }

  onCancelClicked() {
    this.onClickClose(ErrorModalComponent.CANCEL);
  }

  onOkClick() {
    this.onClickClose(ErrorModalComponent.OK);
  }

  onClickClose(result) {
    this.close(result).then((closeResult) => {
      if (this.config.type === 'comment') {
        this.closed.next({action: closeResult, comment: this.comment});
      } else {
        this.closed.next(closeResult);
      }
    });
  }
}
