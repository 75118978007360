import { Component, Input } from '@angular/core';

import * as _ from 'lodash';
import { GridFilterService } from '../gridFilter.service';
import { GridFilterState } from '../gridFilterState';
import {ActiveToast, ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-grid-filter-row',
  styleUrls: ['./gridFilterRow.component.scss'],
  templateUrl: './gridFilterRow.component.html'
})
export class GridFilterRowComponent  {
  @Input() hasUserFilters = false;

  private _gridName = null;
  @Input()
  get gridName(): string {
    return this._gridName;
  }
  set gridName(values: string) {
    this._gridName = values;
    this.init();
  }

  openFilterDialog = false;
  sortDropdownOpen = false;
  updatingFilter = false;

  state: GridFilterState;

  private successToast: ActiveToast<any>;

  private toastrOptionsSuccess = { 
    disableTimeOut: false,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  constructor(
    private gridFilterSerice: GridFilterService,
    private toastr: ToastrService
    ) {
  }

  init() {
    if (this._gridName) {
      const tmp = this.gridFilterSerice.getState(this._gridName);
      if (tmp) {
        this.state = tmp;
      }
    }
  }

  onRemoveFilterClick(filter, idx) {
    this.gridFilterSerice.removeFilterChip(this._gridName, filter.key, idx);
    this.init();
  }

  onSortDropdownModelChanged(sortModel) {
    this.gridFilterSerice.onSortDropdownModelChanged(this._gridName, sortModel);
  }

  onSaveFilterClick() {
    this.openFilterDialog = true;
  }

  async onFilterDialogSaved (filter) {
      if (filter && filter.name) {
        try {
          await this.gridFilterSerice.addNewFilter(this._gridName, filter.name, !!filter.shareFilter);
          this.openFilterDialog = false;
          const name = filter.name.length > 40 ? `${filter.name.substring(0, 40)}...` : filter.name;
          this.successToast = this.toastr.success(name + ' was updated successfully', null, this.toastrOptionsSuccess);

          this.successToast.onTap.subscribe(() => {
            this.successToast = null;
          });
        } catch (error) {
          this.openFilterDialog = false;
        }
      } else {
        this.openFilterDialog = false;
      }
  }
}
