import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ColumnState, ColDef } from 'ag-grid-community';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import * as _ from 'lodash';

import * as JsUtils from 'src/app/utils/jsUtils';
import { PropertyListingColumnDefinition } from 'src/app/pages/propertylist/propertyGrid/columnDefinition';
import { ViewMode } from 'src/app/ui/view-setting/viewSettingModel';


@Component({
  selector: 'app-column-editor-panel',
  styleUrls: ['columnEditor.component.scss'],
  templateUrl: 'columnEditor.component.html'
})
export class ColumnEditorPanelComponent {
  allColumnsChecked = false;
  someColumnsChecked = false;
  columnOrderingAndVisibility = [];
  manipulatedStates = [];
  private _reservedColumnsIds = PropertyListingColumnDefinition.reservedColumnsIdsDef;
  pinnedColumns = [];

  // the checkbox column
  private idState: ColumnState = null;

  searchColumnModel;
  isPanelOpened = false;


  private _open = false;
  private _viewMode = ViewMode.MAESTRO;

  @Input()
  set viewMode(vMode: ViewMode) {
    this._viewMode = vMode;
    this._reservedColumnsIds = PropertyListingColumnDefinition.reservedColumnsIdsDef;
  }

  @Input()
  get open(): boolean {
    return this._open;
  }
  set open(values: boolean) {
    this._open = values;
    this.initialize();
  }

  @Output() closed: EventEmitter<any> = new EventEmitter();

  private _columnState: ColumnState[];
  @Input()
  get columnState(): ColumnState[] {
    return this._columnState;
  }
  set columnState(columnState: ColumnState[]) {
    this._columnState = columnState;
    this.initialize();
    this.filterColumns();
  }

  private _columns: ColDef[];
  @Input()
  get columns(): ColDef[] {
    return this._columns;
  }
  set columns(columns: ColDef[]) {
    this._columns = columns;
    this.initialize();
    this.filterColumns();
  }

  @Input()
  get reservedColumnsIds() {
    return this._reservedColumnsIds;
  }
  set reservedColumnsIds(columnsIds) {
    this._reservedColumnsIds= columnsIds;
  }

  @Output() saveRequested: EventEmitter<any> = new EventEmitter();

  initialize() {

    if (!this._open) {
      return;
    }

    this.searchColumnModel = null;
    this.columnOrderingAndVisibility = [];
    this.manipulatedStates = [];
    this.idState = null;

    this.isPanelOpened = true;

    this.pinnedColumns= [];
    (this._columns || []).forEach((col, index) => {
      if (col.pinned) {
        this.pinnedColumns.push({
          colId: col.field,
          pinned: true,
          suppressMovable: col.suppressMovable,
          initalPosition: index
        });
      }
    });
  }

  onSearchChange() {
    this.filterColumns();
  }

  filterColumns() {
    if (!this._columnState || !this._columns) {
      return;
    }

    const manipulatedStatesCopy = this.manipulatedStates.reduce((els, el) => {
      els[el.colId] = {
        isVisible: el.isVisible,
      };
      return els;
    }, {});

    this.columnOrderingAndVisibility = [];
    const selected = [];
    let notSelected = [];

    const search = JsUtils.isNullOrEmpty(this.searchColumnModel) ? null : this.searchColumnModel.toLowerCase();

    this.idState = null;

    this._columnState.forEach((state: ColumnState, idx) => {
      // Skip the checkbox and tooltip columns
      if (!this._reservedColumnsIds.includes(state.colId)) {
        const colDef = this._columns.find(x => x.field === state.colId);
        const label: string = colDef ? colDef.headerName : state.colId;

        let add = true;
        if (search != null) {
          add = label.toLowerCase().indexOf(search) > -1;
        }

        if (add) {
          const manStat = manipulatedStatesCopy[state.colId];
          const obj = {
            colId: state.colId,
            label: label,
            isVisible: manStat ? manStat.isVisible : !state.hide,
            index: idx,
            suppressMovable: colDef.suppressMovable,
            state: state
          };
          if (obj.isVisible) {
            selected.push(obj);
          } else {
            notSelected.push(obj);
          }
        }
      } else {
        this.idState = state;
        this.idState.hide = false;
      }
    });

    notSelected = _.orderBy(notSelected, ['label']);

    const sortedSeletedAndNotSelected = this.adjustSorting(selected, notSelected);
    this.columnOrderingAndVisibility = sortedSeletedAndNotSelected.selected.concat(sortedSeletedAndNotSelected.notSelected);
    this.allColumnsChecked = notSelected.length === 0 && selected.length > 0;
    this.someColumnsChecked = !this.allColumnsChecked && selected.length > 0;
  }

/**
 *
 * @param selected visible columns in the grid (checked in the column settings)
 * @param notSelected invisible columns in the grid (not checked in the column settings)
 * @returns
 */
  adjustSorting(selected: any[], notSelected: any[]) {
    const adjustedPinnedColumns = [];
    const adjustedNotPinnedColumns = [];
    this.pinnedColumns.forEach(pcol => {
      const foundIndex = selected.findIndex((sl) => sl.colId == pcol.colId);
      if (foundIndex > -1) {
        adjustedPinnedColumns.push(selected[foundIndex]);
        adjustedNotPinnedColumns.push(selected[foundIndex].colId);
      }
    });
    adjustedNotPinnedColumns.forEach(el => {
      const selectedIndex = selected.findIndex(s => s.colId === el);
      if (selectedIndex > -1) {
        selected.splice(selectedIndex, 1);
      }
    });
    return {selected: adjustedPinnedColumns.concat(selected), notSelected };
  }

  closeClick() {
    this.close();
  }

  onColumnCheckClick(col, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    const newVisibleColumns = [];
    if (!col.isVisible) {
      newVisibleColumns.push(col.colId);
    }
    col.isVisible = !col.isVisible;

    const {selected, notSelected} = this.getSelectedNotSelected();
    this.allColumnsChecked = notSelected.length === 0 && selected.length > 0;
    this.someColumnsChecked = !this.allColumnsChecked && selected.length > 0;
    const manipulatedStatesCopy = this.manipulatedStates.reduce((els, el) => {
      els[el.colId] = true;
      return els;
    }, {});

    if (!manipulatedStatesCopy[col.colId]) {
      this.manipulatedStates.push(col);
    } else {
      this.manipulatedStates.map((ms) => {
        if (ms.colId === col.colId) {
          ms.isVisible = col.isVisible;
        }
      });
    }

    this.save(newVisibleColumns);
  }

  close() {
    this.isPanelOpened = false;
    this.closed.emit(true);
  }


//   export interface ColumnState {
//     colId: string;
//     hide?: boolean;
//     aggFunc?: string | IAggFunc | null;
//     width?: number;
//     pivotIndex?: number | null;
//     pinned?: boolean | string | 'left' | 'right';
//     rowGroupIndex?: number | null;
//     flex?: number;
// }
  resetClick() {
    const states: ColumnState[] = [];

    _.each(this._columns, (col: ColDef) => {
      states.push({
        colId: col.field,
        hide: col.hide,
        width: col.width,
        pinned: col.pinned ?? false,
      });
    });

    this.manipulatedStates = [];
    this.columnState = states;
    this.saveRequested.emit({states: states, refreshSessionGridOnly: false });
  }

  drop(event: CdkDragDrop<string[]>) {
    if(!this.columnOrderingAndVisibility[event.currentIndex].isVisible) {
      return;
    }
    moveItemInArray(this.columnOrderingAndVisibility, event.previousIndex, event.currentIndex);
    this.save(null, true);
  }

  onSelectAllCheckClick() {
    const {selected, notSelected} = this.getSelectedNotSelected();
    const newVisibleColumns = [];
    if (selected.length + notSelected.length > 0) {
      if (notSelected.length === 0) {
        this.allColumnsChecked = false;
        this.columnOrderingAndVisibility.forEach(x => x.isVisible = false);
      } else {
        this.allColumnsChecked = true;
        this.someColumnsChecked = false;
        this.columnOrderingAndVisibility.forEach(x => {
          if (!x.isVisible) {
            newVisibleColumns.push(x.colId);
          }
          x.isVisible = true;
        });
      }

      this.save(newVisibleColumns);
    }
  }

  private save(visibilityActions: any[], move = false) {
    const states: ColumnState[] = [];
    let refreshSessionGridOnly = false;
    const reservedColumnsStates = this.columnState.filter(columnStatElement => {
      if( this._reservedColumnsIds.includes(columnStatElement.colId)) {
        const state = {
          ...columnStatElement,
          hide: columnStatElement.hide,
        };
        states.push(state);
        this.setCurrentColumnState(state);
        return true;
      }
      return false;
    });

    let  selectedAndNotselectedColumns;
    if (move) {
      refreshSessionGridOnly = true;
      selectedAndNotselectedColumns = this.getSelectedNotSelected();
      this.columnOrderingAndVisibility = selectedAndNotselectedColumns.selected.concat(selectedAndNotselectedColumns.notSelected);
    } else {
      selectedAndNotselectedColumns = this.getAdjustedSelectedNotSelected(visibilityActions);
    }

    selectedAndNotselectedColumns.selected.concat(selectedAndNotselectedColumns.notSelected).forEach( filteredColumn => {
      let pos;
      const columnFound = reservedColumnsStates.find((columnState, index) => {
        if (columnState.colId === filteredColumn.colId) {
          pos = index;
          return true;
        }
        return false;
      });

      const state = {
        ...filteredColumn,
        hide: columnFound ? columnFound.hide : !filteredColumn.isVisible,
      };
      states.push(state);
      this.setCurrentColumnState(state);
      if (columnFound) {
        reservedColumnsStates.splice(pos, 1);
      }
    });

    reservedColumnsStates.forEach(columnState => {
      const state = {
        ...columnState,
        hide: columnState.hide,
      };
      states.push(state);
      this.setCurrentColumnState(state);
    });

    this.saveRequested.emit({states: states, refreshSessionGridOnly: refreshSessionGridOnly });

  }

  updateHiddenAndColumnsPosition() {
    const states: ColumnState[] = [];

    const reservedColumnsStates = this.columnState.filter(columnStatElement => {
      if( this._reservedColumnsIds.includes(columnStatElement.colId)) {
        const state = {
          ...columnStatElement,
          hide: columnStatElement.hide,
        };
        states.push(state);
        this.setCurrentColumnState(state);
        return true;
      }
      return false;
    });

    this.columnOrderingAndVisibility.forEach( filteredColumn => {
      let pos;
      const columnFound = reservedColumnsStates.find((columnState, index) => {
        if (columnState.colId === filteredColumn.colId) {
          pos = index;
          return true;
        }
        return false;
      });

      const state = {
        ...filteredColumn,
        hide: columnFound ? columnFound.hide : !filteredColumn.isVisible,
      };
      states.push(state);
      this.setCurrentColumnState(state);
      if (columnFound) {
        reservedColumnsStates.splice(pos, 1);
      }
    });

    reservedColumnsStates.forEach(columnState => {
      const state = {
        ...columnState,
        hide: columnState.hide,
      };
      states.push(state);
      this.setCurrentColumnState(state);
    });

    const sortedColumns = this.updateColumnsOrder(states);
    this.saveRequested.emit( {sortedColumns: states, refreshSessionGridOnly: false });
  }

  private getAdjustedSelectedNotSelected(columnsToCheck?: any[]) {
    let selected;
    if (columnsToCheck && columnsToCheck.length) {
      if (columnsToCheck.length === 1) {
        const isPinnedColumn = this.pinnedColumns.findIndex(pc => pc.colId === columnsToCheck[0]);
        if (isPinnedColumn> -1) {
          return this.getSelectedNotSelected();
        }
      }
      selected = this.columnOrderingAndVisibility.filter(x => x.isVisible && columnsToCheck.includes(x.colId));
    } else {
      selected = [];
    }
    const notSelected = this.columnOrderingAndVisibility.filter(x => !x.isVisible);
    return this.adjustSorting(selected, notSelected);
  }

  private getSelectedNotSelected() {
    const selected = this.columnOrderingAndVisibility.filter(x => x.isVisible);
    const notSelected = this.columnOrderingAndVisibility.filter(x => !x.isVisible);
    return this.adjustSorting(selected, notSelected);
  }

  private setCurrentColumnState(state: ColumnState) {
    const columnState = this._columnState.find(x => x.colId === state.colId);
    if (columnState) {
      columnState.hide = state.hide;
    }
  }

  onClearSearchPropertyModel() {
    this.searchColumnModel = null;
    this.filterColumns();
  }

  updateColumnsOrder(currentColumnsStates: ColumnState[]) {
    const currentFiltredColumnsStates = currentColumnsStates.filter(currentColumn => !this._reservedColumnsIds.includes(currentColumn.colId));
    const selectedColumnsIds = currentFiltredColumnsStates.map(col => col.colId);
    const currentColumnsStatesPos = [];

    this._columnState.forEach((cs, index) => {
      const filtredIndex = selectedColumnsIds.indexOf(cs.colId);
      if (filtredIndex > -1) {
        currentColumnsStatesPos.push({
          colId: cs.colId,
          filtredPosition: filtredIndex,
          originalPosition: index
        });
      }
    });

    const cc =  _.cloneDeep(currentColumnsStatesPos);
    for (let i = 0; i < currentColumnsStatesPos.length; i++) {
      for (let j = i; j < currentColumnsStatesPos.length; j++) {
        if(currentColumnsStatesPos[j].filtredPosition < currentColumnsStatesPos[i].filtredPosition) {
          const sw = currentColumnsStatesPos[j];
          const newPos = currentColumnsStatesPos[j].originalPosition;
          currentColumnsStatesPos[j] = currentColumnsStatesPos[i];
          currentColumnsStatesPos[i] = sw;
          currentColumnsStatesPos[i].originalPosition = currentColumnsStatesPos[j].originalPosition;
          currentColumnsStatesPos[j].originalPosition = newPos;

        }
      }
    }


    const newColumnsStates: ColumnState[] = _.cloneDeep(this._columnState);

    for (let i = 0; i < currentColumnsStatesPos.length; i++) {
      newColumnsStates.splice(currentColumnsStatesPos[i].originalPosition,1, currentFiltredColumnsStates[currentColumnsStatesPos[i].filtredPosition]);
    }

    return newColumnsStates;
  }

}
