import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { environment } from 'src/environments/environment';
import { PropertyListComponent } from './pages/propertylist/propertylist.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { PermissionsGuard } from './guards/permissions.guard';
import { ResetPasswordComponent } from './pages/auth/resetPassword/resetPassword.component';
import { LostPasswordComponent } from './pages/auth/lostPassword/lostPassword.component';
import { ChangePasswordComponent } from './pages/auth/changePassword/changePassword.component';
import { AppLayoutComponent } from './ui/appLayout/appLayout.component';
import { AcceptComponent } from './pages/auth/accept/accept.component';
import { StyleguideComponent } from './pages/styleguide/styleguide.component';
import { UserPermission } from './roles-permissions/types';
import { ExportPDFComponent } from './pages/exportPDF/exportPDF.component';
import { ViewMode } from './ui/view-setting/viewSettingModel';

const routes: Routes = [
  // no layout routes
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'accept',
    component: AcceptComponent
  },

  {
    path: 'lostpassword',
    component: LostPasswordComponent
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  },

  // App routes with header layout goes here
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: 'underwriting' , pathMatch: 'full' },
      {
        path: 'underwriting',
        canActivate: [AuthGuard, PermissionsGuard],
        component: PropertyListComponent,
        data: {
          viewMode: ViewMode.MAESTRO,
          permissions: [UserPermission.UwRead, UserPermission.UwWrite]
        }
      },
      {
        path: 'underwriting/:aoListingId',
        canActivate: [AuthGuard, PermissionsGuard],
        component: PropertyListComponent,
        data: {
          viewMode: ViewMode.MAESTRO,
          permissions: [UserPermission.UwRead, UserPermission.UwWrite]
        }
      },
      {
        path: 'bulk',
        canActivate: [AuthGuard, PermissionsGuard],
        component: PropertyListComponent,
        data: {
          viewMode: ViewMode.BULK,
          permissions: [UserPermission.UwRead, UserPermission.UwWrite]
        }
      },
      {
        path: 'bulk/:aoListingId',
        canActivate: [AuthGuard, PermissionsGuard],
        component: PropertyListComponent,
        data: {
          viewMode: ViewMode.BULK,
          permissions: [UserPermission.UwRead, UserPermission.UwWrite]
        }
      },
      {
        path: 'changepassword',
        canActivate: [AuthGuard, PermissionsGuard],
        component: ChangePasswordComponent
      },
      {
        path: 'underwriting/export/:aoListingIdx',
        canActivate: [AuthGuard, PermissionsGuard],
        component: ExportPDFComponent,
        data: {
          permissions: [UserPermission.UwRead, UserPermission.UwWrite]
        }
      },
    ]
  },

  { path: '**', redirectTo: '' }

];

// DEBUG only
if (!environment.production) {
  const i: number = routes.findIndex(x => x.path === '');
  routes.splice(i, 0, {
    path: 'styleguide',
    component: StyleguideComponent
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
