import {Injectable, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class NetworkService implements OnDestroy {
  private readonly TIMEOUT_INTERVAL = 1000;
  private readonly ERROR_MESSAGE = "BROWSER CURRENTLY OFFLINE. Please check internet connection.";

  private interval = null;

  private _destroy = new Subject();
  private isOnlineSource = new Subject<boolean>();

  isOnline$ = this.isOnlineSource.asObservable();
  isOnline = true;

  constructor() {
    // uncomment this code when realtime status needed
    /* this.isOnline$.pipe(takeUntil(this._destroy)).subscribe((isOnline) => {
      this.isOnline = isOnline;
    }); */
  }

  async initialize() {
    // uncomment this code when realtime status needed
    /* this.interval = setInterval(async () => {
      await this.checkIsOnline();
    }, this.TIMEOUT_INTERVAL); */
  }

  async checkIsOnline() {
    // if it's false, it is 100% sure that it's offline
    if (!window.navigator.onLine) {
      console.error(this.ERROR_MESSAGE);
      this.isOnlineSource.next(false);
      return false;
    }

    // if it's true, it may be a false positive, we need to do a quick HTTP call.
    try {
      const response = await this.isOnlineHttp();
      this.isOnlineSource.next(response.ok);
      return response.ok;
    } catch {
      console.error(this.ERROR_MESSAGE);
      this.isOnlineSource.next(false);
      return false;
    }
  }

  isOnlineHttp(): Promise<Response> {
    const url = new URL(window.location.origin);
    url.searchParams.set('rand', Math.random().toString(36));
    return fetch(url.toString(), { method: 'HEAD' });
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this._destroy.next();
    this._destroy.complete();
  }
}
