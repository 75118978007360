import {Component, OnInit} from "@angular/core";
import {environment} from 'src/environments/environment';
import {RefreshService} from "./services/refresh.service";
import {NetworkService} from "./services/network.service";
import { DataDogService } from "./services/data-dog.service";

@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private refreshService: RefreshService, private networkService: NetworkService, private dataDogService: DataDogService) {
    console.log(`Avenue One Underwriting Tool version ${environment.version} (${environment.stage}) ${!environment.production ? environment.branch : ''} ${environment.test ? 'Test flag enabled' : ''}`);
  }

  ngOnInit() {
    this.refreshService.initialize();

    // uncomment this code when realtime status needed
    // this.networkService.initialize();
  }
}
