import {Component} from "@angular/core";
import { ICellRendererParams, IServerSideStore } from "ag-grid-community";

@Component({
  selector: 'group-renderer',
  templateUrl: './group-renderer.component.html',
  styleUrls: ['./group-renderer.component.scss']
})
export class GroupRendererComponent {

   private params: any;
   isExpand: boolean = false;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.isExpand = params.node.expanded;
    }

    toggleExpand() {
        const selectedRowCount = this.getSelectedGroupRowsCount(this.params.node.childStore );

        if(selectedRowCount == 0) {
            this.params.node.setExpanded(!this.isExpand);
            this.isExpand = !this.isExpand;
        }
    }

    refresh(params: any): boolean {
        this.isExpand = params.node.expanded;
        return true;
    }

    getSelectedGroupRowsCount(childStore: IServerSideStore) {
        let selectedRowCount = 0;
        if (childStore) {
          childStore.forEachNodeDeep((x) => {
            if (x.isSelected()) {
              selectedRowCount++;
            }
          });
        }
        return selectedRowCount;
      }
}
