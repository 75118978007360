import * as moment from "moment";

export enum TimeRangeTypes {
  Custom,
  GridOverride,
  Today,
  Last24Hours,
  Last48Hours,
  Last72Hours,
  Last30Days,
  Last90Days,
  Last180Days,
}

export interface TimeRangeConfig {
  id: TimeRangeTypes;
  name: string;
  label: string;
  range: moment.Moment[];
  isVisible: boolean;
}

export const TIME_RANGE_CONFIGS: TimeRangeConfig[] = [
  {
    id: TimeRangeTypes.Custom,
    name: 'custom',
    label: 'Custom...',
    range: [],
    isVisible: false
  },
  {
    id: TimeRangeTypes.GridOverride,
    name: 'grid_custom',
    label: 'Custom...',
    range: [],
    isVisible: false
  },
  {
    id: TimeRangeTypes.Today,
    name: 'today',
    label: 'Today',
    range: [moment(), moment()],
    isVisible: false
  },
  {
    id: TimeRangeTypes.Last24Hours,
    name: 'last_24_hours',
    label: 'Last 24h',
    range: [moment().subtract(24, 'hours'), moment().add(1, 'day')],
    isVisible: true
  },
  {
    id: TimeRangeTypes.Last48Hours,
    name: 'last_48_hours',
    label: 'Last 48h',
    range: [moment().subtract(48, 'hours'), moment().add(1, 'day')],
    isVisible: true
  },
  {
    id: TimeRangeTypes.Last72Hours,
    name: 'last_72_hours',
    label: 'Last 72h',
    range: [moment().subtract(72, 'hours'), moment().add(1, 'day')],
    isVisible: true
  },
  {
    id: TimeRangeTypes.Last30Days,
    name: 'last_30_days',
    label: 'Last 30 days',
    range: [moment().subtract(30, 'days'), moment().add(1, 'day')],
    isVisible: true
  },
  {
    id: TimeRangeTypes.Last90Days,
    name: 'last_90_days',
    label: 'Last 90 days',
    range: [moment().subtract(90, 'days'), moment().add(1, 'day')],
    isVisible: true
  },
  {
    id: TimeRangeTypes.Last180Days,
    name: 'last_180_days',
    label: 'Last 180 days',
    range: [moment().subtract(180, 'days'), moment().add(1, 'day')],
    isVisible: true
  }
];
